import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Form, Formik, Field } from 'formik';
import {
  InputField,
  TextAreaField,
  SelectField,
  AsyncSelectField,
  Button,
  ArrayObjectField,
  FileInputField,
  MarkdownField,
} from '../../../shared/App/components';

// Actions
import { searchLocations } from '../cityActions';

// Utils
import { validate } from '../cityValidators';
import { slugify } from '../../../utils/slugify';

// Constants
const CITY_STATUS = ['published', 'private', 'draft'];
const LOCALES = ['en', 'vi-VN', 'ko-KR'];

class LocationPageForm extends Component {
  handleTitleBlur = (e, values, setFieldValue) => {
    // generate slug if does not exist
    if (!values.slug) {
      setFieldValue(
        'slug',
        slugify(e.target.value, {
          lower: true,
        })
      );
    }
  };

  render() {
    const { editMode, initialValues, city, handleSubmit } = this.props;

    return (
      <div className="content">
        <Formik
          initialValues={{ ...initialValues, editMode }}
          onSubmit={handleSubmit}
          enableReinitialize
          isInitialValid
          validate={validate}
        >
          {({ values, setFieldValue }) => {
            return (
              <Form>
                <div className="col-sm-8">
                  <div className="">
                    <div className="">
                      <InputField
                        sub="*"
                        name="title"
                        label="Title"
                        onBlur={e =>
                          this.handleTitleBlur(e, values, setFieldValue)
                        }
                        placeholder="Title for the landing page, for example: Things to do in Ho Chi Minh city. It will be used for page title, seo title..."
                      />
                      <InputField
                        sub="*"
                        name="name"
                        label="Name"
                        placeholder="Location name by wiki"
                      />
                      <InputField name="slug" label="Slug" />
                      <SelectField
                        name="template"
                        label="Template"
                        options={[
                          { label: 'Country', value: 'country' },
                          { label: 'City', value: 'city' },
                        ]}
                      />
                      <InputField
                        name="blog_category_id"
                        label="Blog Category ID"
                        placeholder="Category ID from Wordpress"
                      />
                      <MarkdownField
                        id="description"
                        name="description"
                        label="Description"
                      />
                      <MarkdownField id="notes" name="notes" label="Notes" />
                      <MarkdownField
                        id="overview"
                        name="overview"
                        label="Overview"
                      />
                      <ArrayObjectField
                        label="Facts"
                        name="facts"
                        arrayData={values.facts}
                        renderObject={(_, index) => (
                          <div className="flex flex-row">
                            <div className="col-md-3 col-xs-12">
                              <Field
                                name={`facts.${index}.title`}
                                className="form-control"
                                placeholder="Title"
                              />
                            </div>
                            <div className="col-md-3 col-xs-12">
                              <Field
                                name={`facts.${index}.value`}
                                className="form-control"
                                placeholder="Value"
                              />
                            </div>
                            <div className="flex flex-col gap-y-4 col-xs-12">
                              <Field
                                name={`facts.${index}.description`}
                                className="form-control"
                                placeholder="Description"
                              />
                            </div>
                          </div>
                        )}
                      />
                    </div>
                  </div>
                  <div className="">
                    <div className="">
                      <TextAreaField
                        id="meta_description"
                        name="meta_description"
                        label="Meta SEO Description"
                        rows={4}
                      />
                      {city && city.meta_photo && (
                        <img
                          alt=""
                          className="col-md-12"
                          src={values.meta_photo}
                        />
                      )}
                      <FileInputField
                        label="Metadata SEO Photo"
                        name="meta_photo"
                      />
                    </div>
                  </div>
                </div>
                <div className="col-sm-4">
                  <div className="box">
                    <div className="box-body">
                      <SelectField
                        name="locale"
                        label="Locale"
                        options={LOCALES.map(level => ({
                          label: level,
                          value: level,
                        }))}
                      />
                      <AsyncSelectField
                        name="location"
                        label="Location"
                        loadOptions={(inputValue, callback) => {
                          this.props
                            .searchLocations({ params: { search: inputValue } })
                            .then(response => {
                              const { results } = response.data;
                              const data = results.map(item => {
                                return {
                                  label: item.name,
                                  value: item.id,
                                };
                              });
                              callback(data);
                            });
                        }}
                      />
                      <InputField id="sort" name="sort" label="Sort" />
                      <SelectField
                        name="state"
                        label="Status"
                        options={CITY_STATUS.map(status => ({
                          label: status,
                          value: status,
                        }))}
                      />
                    </div>
                  </div>
                  <div className="box">
                    <div className="box-body">
                      <FileInputField
                        label="Background Main Photo"
                        name="background_photo"
                      />
                      {city && city.background_photo && (
                        <img
                          alt=""
                          className="col-md-12"
                          src={values.background_photo}
                        />
                      )}
                    </div>
                  </div>
                  <div className="box">
                    <div className="box-body">
                      <Button type="submit" displayType="primary" block>
                        <i className="fa fa-save" />{' '}
                        {editMode ? 'Save changes' : 'Create'}
                      </Button>
                    </div>
                  </div>
                </div>
              </Form>
            );
          }}
        </Formik>
      </div>
    );
  }
}

LocationPageForm.propTypes = {
  handleSubmit: PropTypes.func,
  pristine: PropTypes.bool,
  reset: PropTypes.func,
  submitting: PropTypes.bool,
  error: PropTypes.string,
  isSuccess: PropTypes.bool,
  isError: PropTypes.bool,
  submitSucceeded: PropTypes.bool,
  dispatch: PropTypes.func,
  editMode: PropTypes.bool,
};

LocationPageForm.defaultProps = {
  editMode: false,
};

export default connect(null, { searchLocations })(LocationPageForm);
