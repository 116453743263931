import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { FileInputField, AsyncSelectField } from '../../../shared/App/components';

import { formatDateTime } from '../../../utils/time';

class ExperienceCategory extends Component {
  render() {
    const { experience } = this.props;
    return (
      <Fragment>
        <div
          className="col-xs-12"
          style={{ paddingBottom: '10px', borderBottom: '1px solid #f4f4f4' }}
        >
          <div className="form-horizontal form-group">
            <AsyncSelectField
              name="category"
              label="Category"
              isMulti={true}
              loadOptions={(inputValue, callback) => {
                let params = { search: inputValue };
                const [language, country] = [experience.language, experience.country];
                if (language) params.language = language;
                if (country) params.country = country;
                this.props.searchCategoriesAction({ params }).then(
                  (response) => {
                    const data = response.data.results.map((item) => {
                      return {
                        label: item.title,
                        value: item.id
                      };
                    });
                    callback(data);
                  }
                );
              }}
            />
          </div>
        </div>
      </Fragment>
    );
  }
}

ExperienceCategory.propTypes = {
  experience: PropTypes.object
};

export default ExperienceCategory;
