export const validate = values => {
  const errors = {};

  if (!values.name) {
    errors.name = 'Please enter name.';
  }

  if (!values.code) {
    errors.code = 'Please enter slug.';
  }

  return errors;
};
