import { combineReducers } from 'redux';

import {
  FETCH_PAYMENTS,
  FETCH_PAYMENT,
  RESET_PAYMENT,
  UPDATE_PAYMENT,
  FETCH_PAYMENT_METHOD
} from './paymentActions';
import { FULFILLED, PENDING, REJECTED } from '../../utils/axiosAPI';
import { appendByIds } from '../../utils/reducer';

export const fetchStatusInitialState = {
  count: 0,
  next: null,
  previous: null,
  isFetching: false,
  error: null
};

export const fetchStatus = (state = fetchStatusInitialState, action) => {
  switch (action.type) {
    case FETCH_PAYMENTS + FULFILLED: {
      const { results, ...rest } = action.data;
      return { ...state, isFetching: false, ...rest };
    }
    case FETCH_PAYMENTS + REJECTED: {
      return { ...state, isFetching: false, error: action.data };
    }
    case FETCH_PAYMENTS + PENDING: {
      return { ...state, isFetching: true, error: null };
    }
    case FETCH_PAYMENTS: {
      return fetchStatusInitialState;
    }
    default: {
      return state;
    }
  }
};

export const fetchDetailStatus = (state = fetchStatusInitialState, action) => {
  switch (action.type) {
    case FETCH_PAYMENT + FULFILLED: {
      const { results, ...rest } = action.data;
      return { ...state, isFetching: false, ...rest };
    }
    case FETCH_PAYMENT + REJECTED: {
      return { ...state, isFetching: false, error: action.data };
    }
    case FETCH_PAYMENT + PENDING: {
      return { ...state, isFetching: true, error: null };
    }
    case FETCH_PAYMENT: {
      return fetchStatusInitialState;
    }
    default: {
      return state;
    }
  }
};

export const byId = (state = {}, action) => {
  switch (action.type) {
    case FETCH_PAYMENTS + FULFILLED: {
      return appendByIds(state, action.data.results, 'id');
    }
    case FETCH_PAYMENT + FULFILLED:
    case UPDATE_PAYMENT + FULFILLED: {
      const payment = state[action.data.id];
      const newPayment = action.data;

      return {
        ...state,
        [action.data.id]: { ...payment, ...newPayment }
      };
    }
    default: {
      return state;
    }
  }
};

export const visibleIds = (state = [], action) => {
  switch (action.type) {
    case FETCH_PAYMENTS + FULFILLED: {
      /**
       * Always replace the visible booking
       */
      return action.data.results.map(payment => payment.id);
    }
    case RESET_PAYMENT: {
      return [];
    }
    default: {
      return state;
    }
  }
};

export const transactions = (state = [], action) => {
  switch (action.type) {
    case FETCH_PAYMENT_METHOD + FULFILLED: {
      return [...state, ...action.data.results];
    }
    default: {
      return state;
    }
  }
};

export default combineReducers({
  fetchStatus,
  byId,
  visibleIds,
  transactions
});

export const getTransactions = state =>
  state.transaction.visibleIds.map(id => state.transaction.byId[id]);
export const getFetchStatus = state => state.transaction.fetchStatus;
export const getTransactionDetail = (state, id) => state.transaction.byId[id];
export const getFetchDetailStatus = state => state.transaction.fetchDetailStatus;
