import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';

import { Status, Alert } from '../../../../shared/App/components';
import { withOperation } from '../../../../utils/OperationalComponent';
import { withQuery } from '../../../../utils/QueryBasedComponent';
import {
  bookingFulfillmentConfig,
  bookingStatusConfig,
  bookingPaymentConfig,
} from '../../bookingUtils';
import {
  fetchBookingDetail,
  sendBookingNotification,
  cancelBooking,
} from '../../bookingActions';
import { getBookingDetail } from '../../bookingReducer';
import configApp from '../../../../config/configApp';
import TimelineBlock from '../../../ActionLog/components/TimelineBlock';
import BookingItemsBlock from './blocks/BookingItems';
import PaymentBlock from './blocks/Payment';
import BookingInfo from './blocks/BookingInfo';
import TravelerInfo from './blocks/TravelerInfo';
import CancelBookingForm from './blocks/CancelBookingForm';
import SendNotificationForm from './blocks/SendNotificationForm';

const initialState = {
  showModalEditBooking: false,
  showModalCancelBooking: false,
  showModalConfirmBooking: false,
  showModalSendBookingNotification: false,
};

class BookingDetailPage extends Component {
  state = initialState;

  componentDidMount() {
    const {
      executeActions,
      match,
      fetchBookingDetailAction,
      fetchBookingLogAction,
    } = this.props;

    executeActions({
      actions: [() => fetchBookingDetailAction({ id: match.params.cuid })],
    });
  }

  onCancel = values => {
    const { cancelBookingAction, executeActions, match } = this.props;
    this.handleCloseModal();
    executeActions({
      actions: [() => cancelBookingAction({ id: match.params.cuid })],
      successMsg: 'Update successfully',
    });
  };

  onSendConfirmNotification = () => {
    const { sendBookingNotificationAction, executeActions, match } = this.props;
    this.handleCloseModal();
    /**
     * Send trip notification
     */
    const data = { event: configApp.eventType.BOOKING_CONFIRM };
    executeActions({
      actions: [
        () => sendBookingNotificationAction({ id: match.params.cuid, data }),
      ],
      successMsg: 'Notification booking successfully',
    });
  };

  handleCloseModal = () => {
    this.setState(initialState);
  };

  handleOpenModalEditBooking() {
    this.setState({ showModalEditBooking: true });
  }

  handleOpenModalCancelBooking() {
    this.setState({ showModalCancelBooking: true });
  }

  handleOpenModalConfirmBooking() {
    this.setState({ showModalConfirmBooking: true });
  }

  handleOpenModalSendBookingNotification() {
    this.setState({ showModalSendBookingNotification: true });
  }

  renderAlert() {
    const { success, error } = this.props;

    let msg = '';
    let title = 'Success';
    let type = 'success';
    if (error) {
      msg = error.toString();
      title = 'Error';
      type = 'danger';
    } else if (success) {
      msg = success;
    }

    if (msg !== '') {
      return <Alert title={title} type={type} content={msg} />;
    }

    return null;
  }

  render() {
    const { booking = {}, isFetching, match } = this.props;

    return (
      <Fragment>
        <Helmet>
          <title>Booking #{`${match.params.cuid}`} - Inspitrip HQ</title>
        </Helmet>
        <section className="content-header">
          <div className="flex flex-row -mt-4">
            <ol className="breadcrumb">
              <li>
                <Link to={'/'}>InspitripHQ</Link>
              </li>
              <li>
                <Link to={'/bookings'}>Bookings</Link>
              </li>
              <li className="active">Booking #{booking.id}</li>
            </ol>
          </div>
          {this.renderAlert()}
          <div className="flex flex-row">
            <div className="flex flex-col">
              <h1 style={{ fontSize: '24px', marginTop: '15px' }}>
                Booking #{booking.id} &nbsp;
                <Status
                  state={`${booking.status}`}
                  statuses={bookingStatusConfig}
                />
                &nbsp;&nbsp;
                <Status
                  state={`${booking.payment_status}`}
                  statuses={bookingPaymentConfig}
                />
                &nbsp;&nbsp;
                <Status
                  state={`${booking.fulfillment_status}`}
                  statuses={bookingFulfillmentConfig}
                />
              </h1>
            </div>
            <div className="flex flex-col gap-y-2	text-right" style={{ marginLeft: 'auto' }}>
              <h2>
                <div className="dropdown" style={{ display: 'inline-block', marginLeft: '10px' }}>
                  <a className="dropdown-toggle btn btn-default" data-toggle="dropdown" href="#" aria-expanded="false">
                    ...
                  </a>
                  {booking.status === configApp.bookingState.COMPLETED ||
                  booking.status === configApp.bookingState.CANCELLED ? (
                    <ul className="dropdown-menu">
                      <li>
                        <a>No actions available</a>
                      </li>
                    </ul>
                  ) : (
                    <ul className="dropdown-menu">
                      {booking.status === configApp.bookingState.CONFIRMED && (
                        <li>
                          <a
                            onClick={this.handleOpenModalSendBookingNotification.bind(
                              this
                            )}
                          >
                            <i className="fa fa-envelope" />
                            Resend booking confirm email
                          </a>
                        </li>
                      )}
                      <li role="presentation" className="divider" />
                      <li>
                        <a onClick={this.handleOpenModalCancelBooking.bind(this)}>
                          <i className="fa fa-remove" />
                          Cancel this Booking
                        </a>
                      </li>
                    </ul>
                  )}
                </div>

              </h2>
            </div>
          </div>
        </section>
        <div className="content body">
          <div className="flex flex-row">
            <div className="flex flex-col w-full">
              <BookingItemsBlock bookingId={match.params.cuid} />
              <PaymentBlock isLoading={isFetching} booking={booking} />
              {/* <div className="col-sm-12 pl-4">
                <TimelineBlock id={match.params.cuid} contentType={'Booking'} />
              </div> */}
            </div>
          </div>
          <div className="flex flex-row">
            <div className="flex flex-col w-full">
              <div className="col-sm-12">
                <div className="col-sm-6">
                  <BookingInfo isLoading={isFetching} booking={booking} />
                </div>
                <div className="col-sm-6">
                  <TravelerInfo
                    isLoading={isFetching}
                    travelerId={booking.user && booking.user.id}
                  />  
                </div>
              </div>
            </div>
          </div>
          <div className="flex flex-row">
            <div className="flex flex-col w-full">
              <div className="col-sm-12 pl-4">
                <TimelineBlock id={match.params.cuid} contentType={'Booking'} />
              </div>
            </div>
          </div>

        </div>
        <CancelBookingForm
          onConfirm={this.onCancel}
          onDismiss={this.handleCloseModal}
          visible={this.state.showModalCancelBooking}
        />
        <SendNotificationForm
          onSubmit={this.onSendConfirmNotification}
          onDismiss={this.handleCloseModal}
          visible={this.state.showModalSendBookingNotification}
        />
      </Fragment>
    );
  }
}

BookingDetailPage.propTypes = {
  /**
   * Operational Props
   */
  success: PropTypes.string,
  error: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  isFetching: PropTypes.bool,
  executeActions: PropTypes.func,
  /**
   * ConnectedProps
   */
  booking: PropTypes.object,
  /**
   * Actions
   */
  fetchBookingDetailAction: PropTypes.func.isRequired,
  sendBookingNotificationAction: PropTypes.func.isRequired,
  cancelBookingAction: PropTypes.func.isRequired,
};

const mapStateToProps = (state, ownProps) => ({
  booking: getBookingDetail(state, ownProps.match.params.cuid),
});

export default connect(mapStateToProps, {
  fetchBookingDetailAction: fetchBookingDetail,
  cancelBookingAction: cancelBooking,
  sendBookingNotificationAction: sendBookingNotification,
})(withOperation(withQuery(BookingDetailPage)));
