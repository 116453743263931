import React, { Component, Fragment } from 'react';
import { Helmet } from 'react-helmet';
import { connect } from 'react-redux';

// Component
import { Status } from '../../../shared/App/components';
import Breadcrumb from '../../../shared/App/components/Breadcrumb';
import FormStatus from '../../../shared/App/components/FormStatus';

// Actions
import { fetchTransaction, forceConfirmPayment } from '../paymentActions';
import { getTransactionDetail, getFetchDetailStatus } from '../paymentReducers';

// Utils
import { buildFormData, paymentStatusConfig } from '../paymentUtils';
import { displayCurrency } from '../../../utils/currency';
import { withOperation } from '../../../utils/OperationalComponent';
import { withQuery } from '../../../utils/QueryBasedComponent';
import ForceConfirmPaymentForm from './blocks/ForceConfirmPaymentForm';

const initialState = {
  showModalForceConfirm: false,
};

class TransactionDetailPage extends Component {
  state = initialState;

  componentDidMount() {
    const { executeActions, match, fetchTransactionAction } = this.props;
    executeActions({
      actions: [() => fetchTransactionAction(match.params.transactionId)],
    });
  }

  handleCloseModal = () => {
    this.setState(initialState);
  };

  handleOpenModalForceConfirm() {
    this.setState({ showModalForceConfirm: true });
  }

  onForceConfirm = values => {
    const {
      forceConfirmPaymentAction,
      executeActions,
      transaction,
      match,
    } = this.props;
    this.handleCloseModal();
    executeActions({
      actions: [
        () =>
          forceConfirmPaymentAction({ token: transaction.token, data: values }),
      ],
      successMsg: 'Update successfully',
    });
  };

  render() {
    const { transaction, match, isFetching, error, success } = this.props;

    return (
      <Fragment>
        <Helmet>
          <title>
            Transaction Detail #{`${match.params.transactionId}`} - Inspitrip HQ
          </title>
        </Helmet>
        <section className="content-header">
          <div className="flex flex-row" style={{ marginTop: '-15px', marginBottom: '15px' }}>
            <Breadcrumb
              history={[
                { title: 'InspitripHQ', url: '/' },
                { title: 'Transactions', url: '/payments/transactions' },
                { title: 'Detail', url: '' },
              ]}
            />
          </div>
          <div className="nav-tabs-custom">
            <FormStatus formState={{ error, success }} />
          </div>
          <div className="flex flex-row">
            <div className="flex flex-col gap-y-7">
              <h1 style={{ fontSize: '24px', marginTop: '15px' }}>Transaction</h1>
            </div>
          </div>
        </section>
        <div className="content body">
          <div className="flex flex-row">
            {isFetching || !transaction ? (
              <div className="overlay">
                <i className="fa fa-refresh fa-spin" />
                Loading...
              </div>
            ) : (
              <div className="content">
                <div className="flex flex-col gap-y-8">
                  <div className="box">
                    <div className="box-body pl-0">
                      <table className="table table-striped">
                        <tbody>
                          <tr>
                            <th>ID</th>
                            <th>Booking ID</th>
                            <th>Transaction ID</th>
                            <th>Transaction Code</th>
                            <th>Payment Method</th>
                            <th>Amount</th>
                            <th>Refunded Amount</th>
                            <th>Status</th>
                          </tr>
                          <tr>
                            <td>{transaction.id}</td>
                            <td>{transaction.bid ? transaction.bid : '-'}</td>
                            <td>{transaction.token}</td>
                            <td>{transaction.transaction_code}</td>
                            <td>{transaction.variant}</td>
                            <td>{transaction.captured_amount &&
                              displayCurrency({
                                amount: parseFloat(transaction.captured_amount),
                                currency: transaction.currency,
                              })}</td>
                            <td>{transaction.refunded_amount &&
                              displayCurrency({
                                amount: parseFloat(transaction.refunded_amount),
                                currency: transaction.currency,
                              })}</td>
                            <td><Status
                                state={`${transaction.status}`}
                                statuses={paymentStatusConfig}
                              /></td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
          {transaction &&
            (transaction.status == 'waiting' ||
              transaction.status == 'input') &&
            !transaction.bid &&
            transaction.cart_id && (
              <div className="flex flex-row">
                <div className="content">
                  <div className="flex flex-col gap-y-8">
                    <button
                      className="btn btn-primary"
                      onClick={this.handleOpenModalForceConfirm.bind(this)}
                    >
                      Confirm this payment
                    </button>
                  </div>
                </div>
              </div>
            )}
        </div>
        <ForceConfirmPaymentForm
          onSubmit={this.onForceConfirm}
          onDismiss={this.handleCloseModal}
          visible={this.state.showModalForceConfirm}
          transaction={transaction}
        />
      </Fragment>
    );
  }
}

// Retrieve data from store as props
const mapStateToProps = (state, props) => {
  return {
    isFetching: getFetchDetailStatus(state),
    transaction: getTransactionDetail(state, props.match.params.transactionId),
  };
};

export default connect(mapStateToProps, {
  fetchTransactionAction: fetchTransaction,
  forceConfirmPaymentAction: forceConfirmPayment,
})(withOperation(withQuery(TransactionDetailPage)));
