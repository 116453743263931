import React from 'react';
import { Modal } from '../../../../../shared/App/components';
import configApp from '../../../../../config/configApp';

const CancelBookingForm = ({ onConfirm, onDismiss, visible }) => (
  <Modal
    title="Are you sure to cancel this Booking?"
    confirmText="Yes, sure"
    dismissText="Cancel"
    onConfirm={() => onConfirm({ state: configApp.bookingState.CANCELLED })}
    {...{ onDismiss, visible }}
  >
    <p>
      This would cancel according Booking Items as well.
    </p>
  </Modal>
);

export default CancelBookingForm;
