// import { Avatar } from 'antd';
import { Fragment } from 'react';
import { Link } from 'react-router-dom';
import { Status, Avatar } from '../../shared/App/components';

import configApp from '../../config/configApp';
import { displayCurrency } from '../../utils/currency';
import { formatDateTime, timeAgo } from '../../utils/time';

/**
 * transformBookingDetail changes the field user and localguide of booking detail
 * to user_id and localguide_id to avoid collision with the user and local object
 * @param {*} booking - the booking detail object
 */
export const transformBookingDetail = booking => {
  let newBooking = { ...booking };
  delete newBooking.user;
  delete newBooking.localguide;

  return {
    ...newBooking,
    user_id: booking.user,
    localguide_id: booking.localguide,
  };
};

export const bookingStatusConfig = {
  [configApp.bookingState.CONFIRMED]: {
    title: 'Confirmed',
    color: Status.colors.GREEN,
  },
  [configApp.bookingState.PENDING]: {
    title: 'Pending',
    color: Status.colors.YELLOW,
  },
  [configApp.bookingState.COMPLETED]: {
    title: 'Completed',
    color: Status.colors.BLUE,
  },
  [configApp.bookingState.CANCELLED]: {
    title: 'Cancelled',
    color: Status.colors.GREY,
  },
};

export const bookingFulfillmentConfig = {
  fulfilled: { title: 'Fulfilled', color: Status.colors.GREEN },
  partially_fulfilled: {
    title: 'Partially Fulfilled',
    color: Status.colors.ORANGE,
  },
  unfulfilled: { title: 'Unfulfilled', color: Status.colors.YELLOW },
};

export const bookingPaymentConfig = {
  unpaid: { title: 'Unpaid', color: Status.colors.YELLOW },
  partially_paid: { title: 'Partially Paid', color: Status.colors.ORANGE },
  partially_refunded: {
    title: 'Partially Refunded',
    color: Status.colors.ORANGE,
  },
  paid: { title: 'Paid', color: Status.colors.GREEN },
  refunded: { title: 'Refunded', color: Status.colors.ORANGE },
};

export const bookingColumns = [
  {
    id: 'id',
    title: 'ID',
    render: ({ id }) => <Link to={`/bookings/${id}`}>#{id}</Link>,
  },
  {
    id: 'user',
    title: 'Traveler',
    render: ({ user }) => {
      if (!user) return null;

      return (
        <Link to={`/users/${user.id}`}>
          <span>
            <Avatar
              img_src={user.photo}
              user={user}
            />
            &nbsp;{`${user.first_name} ${user.last_name}`}
          </span>
        </Link>
      );
    },
  },
  {
    id: 'status',
    title: 'Status',
    render: ({ status }) => {
      return <Status state={status} statuses={bookingStatusConfig} />;
    },
  },
  {
    id: 'payment_status',
    title: 'Payment',
    render: ({ payment_status }) => {
      return <Status state={payment_status} statuses={bookingPaymentConfig} />;
    },
  },
  {
    id: 'fulfillment_status',
    title: 'Fulfillment',
    render: ({ fulfillment_status }) => {
      return (
        <Status
          state={fulfillment_status}
          statuses={bookingFulfillmentConfig}
        />
      );
    },
  },
  {
    id: 'booking_item_ids',
    title: 'Booking Item',
    render: ({ id, booking_item_ids }) => (
      <div>
        {booking_item_ids &&
          booking_item_ids.map(item => (
            <Fragment key={item}>
              <Link
                to={`/bookings/${id}/fulfillments/${item}`}
              >{`${id}-${item}`}</Link>
              <br />
            </Fragment>
          ))}
      </div>
    ),
  },
  {
    id: 'cost',
    title: 'Price',
    render: ({ cost, currency_code }) => (
      <span>
        {displayCurrency({ amount: parseFloat(cost), currency: currency_code })}
      </span>
    ),
  },
  {
    id: 'created',
    title: 'Created Date',
    render: ({ created }) => (
      <div style={{ width: '100px', whiteSpace: 'normal' }}>
        {formatDateTime(created)}
      </div>
    ),
  },
  {
    id: 'modified',
    title: 'Last Update',
    render: ({ modified }) => <span>{timeAgo(modified)}</span>,
  },
];
