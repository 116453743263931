import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';

// Components
import CollectionEditForm from '../components/CollectionEditForm';
import FormStatus from '../../../shared/App/components/FormStatus';
import Breadcrumb from '../../../shared/App/components/Breadcrumb';

// Actions
import { createCollection } from '../collectionActions';

// Utils
import { withOperation } from '../../../utils/OperationalComponent';
import { withQuery } from '../../../utils/QueryBasedComponent';
import { transformCollectionDetail } from '../collectionUtils';

class CollectionCreatePage extends Component {
  handleSubmit = values => {
    const { executeActions, createCollectionAction } = this.props;

    // transform data
    const newCollection = transformCollectionDetail(values);

    executeActions({
      actions: [() => createCollectionAction({ params: newCollection })],
      onSuccess: () => {
        this.props.history.push('/collections');
      },
      successMsg: 'Create successfully',
      failureMsg: 'Create fail',
    });
  };

  render() {
    const { error, success } = this.props;
    const errorData = error ? error.data || 'Something went wrong.' : '';
    return (
      <Fragment>
        <Helmet>
          <title>Create Collection - Inspitrip HQ</title>
        </Helmet>
        <section className="content-header">
          <div className="flex flex-row" style={{ marginTop: '-15px', marginBottom: '15px' }}>
            <div className="flex flex-row" style={{ marginTop: '-15px', marginBottom: '15px' }}>
              <Breadcrumb
                history={[
                  { title: 'InspitripHQ', url: '/' },
                  { title: 'Collections', url: '/collections' },
                  { title: 'Create Collection', url: '' },
                ]}
              />
            </div>
          </div>

          <div className="nav-tabs-custom">
            <FormStatus formState={{ error: errorData, success }} />
          </div>

          <div className="flex flex-row">
            <div className="flex flex-col gap-y-7">
              <h1 style={{ fontSize: '24px', marginTop: 0 }}>
                Create Collection
              </h1>
            </div>
          </div>
        </section>
        <div className="content body">
          <div className="">
            <CollectionEditForm
              initialValues={{ locale: 'en', limit: 5, is_active: 'publish' }}
              handleSubmit={this.handleSubmit}
            />
          </div>
        </div>
      </Fragment>
    );
  }
}

export default connect(null, {
  createCollectionAction: createCollection,
})(withOperation(withQuery(CollectionCreatePage)));
