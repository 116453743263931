import { combineReducers } from 'redux';

// actions
import {
  FETCH_SERVICES,
  FETCH_SERVICE,
  CREATE_SERVICE,
  UPDATE_SERVICE,
  DELETE_SERVICE
} from './actions';

// utils
import { appendByIds, deleteKeyValueFromById } from '../../../../utils/reducer';
import { FULFILLED } from '../../../../utils/axiosAPI';

const byId = (state = {}, action) => {
  switch (action.type) {
    case FETCH_SERVICES + FULFILLED: {
      return appendByIds(state, action.data, 'id');
    }
    case FETCH_SERVICE + FULFILLED:
    case CREATE_SERVICE + FULFILLED:
    case UPDATE_SERVICE + FULFILLED: {
      return { ...state, [action.data.id]: action.data };
    }
    case DELETE_SERVICE + FULFILLED: {
      return deleteKeyValueFromById(state, action.meta.serviceId);
    }
    default: {
      return state;
    }
  }
};

const subAllIds = (state = [], action) => {
  switch (action.type) {
    case FETCH_SERVICES + FULFILLED: {
      return [...action.data.map(service => service.id)];
    }
    case CREATE_SERVICE + FULFILLED: {
      return [...state, action.data.id];
    }
    case DELETE_SERVICE + FULFILLED: {
      return state.filter(id => id !== action.meta.serviceId);
    }
    default: {
      return state;
    }
  }
};

const allIds = (state = {}, action) => {
  switch (action.type) {
    case FETCH_SERVICES + FULFILLED:
    case CREATE_SERVICE + FULFILLED:
    case DELETE_SERVICE + FULFILLED: {
      const variantIds = state[action.meta.variantId];
      return {
        ...state,
        [action.meta.variantId]: subAllIds(variantIds, action)
      };
    }
    default: {
      return state;
    }
  }
};

export default combineReducers({
  byId,
  allIds
});

const getPath = state => state.productOption.service;

export const getServiceIds = (state, variantId) => getPath(state).allIds[variantId] || [];
export const getService = (state, id) => getPath(state).byId[id];
