import { combineReducers } from 'redux';

import {
  FETCH_QUICK_FACTS,
  CREATE_QUICK_FACT,
  DELETE_QUICK_FACT,
  UPDATE_QUICK_FACT
} from './actions';
import { appendByIds, deleteKeyValueFromById } from '../../utils/reducer';
import { FULFILLED } from '../../utils/axiosAPI';

const byId = (state = {}, action) => {
  switch (action.type) {
    case FETCH_QUICK_FACTS + FULFILLED: {
      return appendByIds(state, action.data, 'id');
    }
    case UPDATE_QUICK_FACT + FULFILLED:
    case CREATE_QUICK_FACT + FULFILLED: {
      return appendByIds(state, [action.data], 'id');
    }
    case DELETE_QUICK_FACT + FULFILLED: {
      return deleteKeyValueFromById(state, action.meta.factId);
    }
    default: {
      return state;
    }
  }
};

const allIds = (state = [], action) => {
  switch (action.type) {
    case FETCH_QUICK_FACTS + FULFILLED: {
      return action.data.map(fact => fact.id);
    }
    case CREATE_QUICK_FACT + FULFILLED: {
      return [...state, action.data.id];
    }
    case DELETE_QUICK_FACT + FULFILLED: {
      return state.filter(id => id !== action.meta.factId);
    }
    default: {
      return state;
    }
  }
};

export default combineReducers({ allIds, byId });

export const getAllIds = state => state.quickFacts.allIds;
export const getQuickFact = (state, id) => state.quickFacts.byId[id];
