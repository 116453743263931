import { cmsAPI } from '../../utils/axiosAPI';

// FETCH cities list
export const FETCH_CITIES = 'locationPage/FETCH_CITIES';
export const fetchCities = ({ params }) => {
  return dispatch =>
    cmsAPI(dispatch, {
      method: 'get',
      url: '/cms/admin/v1/location-pages',
      params,
      type: FETCH_CITIES,
    });
};

export const FETCH_CITY_FIELDS = 'locationPage/FETCH_CITY_FIELDS';
export const fetchCityFields = () => {
  return dispatch =>
    cmsAPI(dispatch, {
      method: 'options',
      url: '/cms/admin/v1/location-pages',
      type: FETCH_CITY_FIELDS,
    });
};

//
export const RESET_CITIES = 'locationPage/RESET_CITIES';
export const resetCities = () => ({
  type: RESET_CITIES,
});

export const CREATE_CITY = 'locationPage/CREATE_CITY';
export const createCity = ({ data }) => {
  return dispatch =>
    cmsAPI(dispatch, {
      method: 'post',
      url: '/cms/admin/v1/location-pages',
      data,
      type: CREATE_CITY,
    });
};

export const UPDATE_CITY = 'locationPage/UPDATE_CITY';
export const updateCity = ({ id, data }) => {
  return dispatch =>
    cmsAPI(dispatch, {
      method: 'patch',
      url: `/cms/admin/v1/location-pages/${id}`,
      data,
      type: UPDATE_CITY,
    });
};

export const FETCH_CITY = 'locationPage/FETCH_CITY';
export function getCity({ id }) {
  return dispatch =>
    cmsAPI(dispatch, {
      method: 'get',
      url: `/cms/admin/v1/location-pages/${id}`,
      type: FETCH_CITY,
    });
}

export const SEARCH_PARENT_OF_LEVEL = 'locationPage/SEARCH_LOCATIONS';
export const searchLocations = ({ params }) => {
  return dispatch =>
    cmsAPI(dispatch, {
      method: 'get',
      url: '/cms/admin/v1/locations',
      params,
      type: SEARCH_PARENT_OF_LEVEL,
    });
};

export const DELETE_PLACE = 'locationPage/DELETE_PLACE';
export function deletePlace({ id }) {
  return dispatch =>
    cmsAPI(dispatch, {
      method: 'delete',
      url: `/cms/admin/v1/location-pages/${id}`,
      type: DELETE_PLACE,
    });
}
