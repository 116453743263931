import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Modal } from '../../../../../shared/App/components';

// containers
import NetPriceForm from './NetPriceForm';
import {
  updateSupplierPrices,
  fetchBookingItemDetail,
} from '../../../bookingItemActions';

// utils
import { withOperation } from '../../../../../utils/OperationalComponent';

class NetPriceModal extends React.Component {
  buildInitialValues = () => {
    const { supplier_amount, options, extra_services, currency_code } = this.props.bookingItem;
    return { supplier_amount, options, extra_services, currency_code };
  };

  handleSubmit = values => {
    const {
      bookingItem,
      executeActions,
      updateSupplierPricesAction,
      fetchItemDetailAction,
      onDismiss
    } = this.props;
    const { options, extra_services } = values;

    executeActions({
      actions: [
        () => updateSupplierPricesAction({
          bookingId: bookingItem.booking,
          itemId: bookingItem.id,
          data: { units: options, extra_services }
        })
      ],
      onSuccess: () => {
        fetchItemDetailAction({
          bookingId: bookingItem.booking,
          itemId: bookingItem.id,
        });
        return onDismiss();
      },
      successMsg: 'Update successfully'
    });
  };

  render() {
    return (
      <Modal
        title="Edit net price"
        hideFooter
        onDismiss={this.props.onDismiss}
        visible
      >
        <NetPriceForm
          handleSubmit={this.handleSubmit}
          initialValues={this.buildInitialValues()}
        />
      </Modal>
    );
  }
}

NetPriceModal.propTypes = {
  onDismiss: PropTypes.func,
  bookingItem: PropTypes.object
};

const mapDispatchToProps = {
  updateSupplierPricesAction: updateSupplierPrices,
  fetchItemDetailAction: fetchBookingItemDetail,
};

export default connect(
  null,
  mapDispatchToProps
)(withOperation(NetPriceModal));

