import { productAPI } from '../../utils/axiosAPI';

// FETCH categories list
export const FETCH_CATEGORIES = 'category/FETCH_CATEGORIES';
export const fetchCategories = ({ params }) => {
  return dispatch =>
    productAPI(dispatch, {
      method: 'get',
      url: '/product/admin/v1/categories',
      params,
      type: FETCH_CATEGORIES,
    });
};

export const FETCH_CATEGORY_FIELDS = 'category/FETCH_CATEGORY_FIELDS';
export const fetchCategoryFields = () => {
  return dispatch =>
    productAPI(dispatch, {
      method: 'options',
      url: '/product/admin/v1/categories',
      type: FETCH_CATEGORY_FIELDS,
    });
};

//
export const RESET_CATEGORIES = 'category/RESET_CATEGORIES';
export const resetCategories = () => ({
  type: RESET_CATEGORIES,
});

export const CREATE_CATEGORY = 'category/CREATE_CATEGORY';
export const createCategory = ({ data }) => {
  return dispatch =>
    productAPI(dispatch, {
      method: 'post',
      url: '/product/admin/v1/categories',
      data,
      type: CREATE_CATEGORY,
    });
};

export const UPDATE_CATEGORY = 'category/UPDATE_CATEGORY';
export const updateCategory = ({ id, data }) => {
  return dispatch =>
    productAPI(dispatch, {
      method: 'patch',
      url: `/product/admin/v1/categories/${id}`,
      data,
      type: UPDATE_CATEGORY,
    });
};

export const FETCH_CATEGORY = 'category/FETCH_CATEGORY';
export function getCategory({ id }) {
  return dispatch =>
    productAPI(dispatch, {
      method: 'get',
      url: `/product/admin/v1/categories/${id}`,
      type: FETCH_CATEGORY,
    });
}

export const SEARCH_PARENT_OF_LEVEL = 'category/SEARCH_CATEGORIES';
export const searchCategories = ({ params }) => {
  return dispatch =>
    productAPI(dispatch, {
      method: 'get',
      url: '/product/admin/v1/categories',
      params,
      type: SEARCH_PARENT_OF_LEVEL,
    });
};

export const DELETE_CATEGORY = 'category/DELETE_CATEGORY';
export function deleteCategory({ id }) {
  return dispatch =>
    productAPI(dispatch, {
      method: 'delete',
      url: `/product/admin/v1/categories/${id}`,
      type: DELETE_CATEGORY,
    });
}
