import React from 'react';
import { Link } from 'react-router-dom';

export const transformCategoryDetail = category => {
  let newCategory = { ...category };
  const localeSplit = newCategory.locale ? newCategory.locale.split('-') : ['en', ''];
  newCategory.language = localeSplit[0] || '';
  newCategory.country = localeSplit[1] || '';

  if (category.parent) {
    newCategory.parent = category.parent.value;
  }

  delete newCategory.editMode;
  delete newCategory.locale;
  return newCategory;
};

export const buildFormData = category => {
  if (!category) return {};
  return {
    ...category,
    parent: (category.parent ? { value: category.parent.id, label: category.parent.title } : null),
    locale: `${category.language}${category.country && `-${category.country}`}`
  };
};

// TODO: Build locale link

export const categoryColumns = () => {
  return [
    {
      id: 'id',
      title: 'ID',
      render: ({ id }) => <Link to={`/categories/${id}`}>{id}</Link>
    },
    {
      id: 'title',
      title: 'Title',
      render: ({ id, title }) => <Link to={`/categories/${id}`}>{title}</Link>
    },
    {
      id: 'parent',
      title: 'Parent',
      render: ({ parent }) => <div>{parent && parent.id ? `${parent.title} #${parent.id}` : ''}</div>
    },
    {
      id: 'language',
      title: 'Language',
      render: ({ language }) => <div>{language}</div>
    },
    {
      id: 'country',
      title: 'Country',
      render: ({ country }) => <div>{country}</div>
    }
  ];
};
