import { notificationAPI } from '../../utils/axiosAPI';

export const FETCH_EMAIL_LOGS = 'emailLog/FETCH_EMAIL_LOGS';
export const FETCH_EMAIL_LOG = 'emailLog/FETCH_EMAIL_LOG';
export const RESET_EMAIL_LOG = 'emailLog/RESET_EMAIL_LOG';

export const fetchEmailLogs = params => {
  return dispatch =>
    notificationAPI(dispatch, {
      method: 'get',
      url: '/notification/admin/v1/logs',
      params,
      type: FETCH_EMAIL_LOGS,
    });
};

export const resetEmailLogs = () => ({
  type: RESET_EMAIL_LOG,
});

export const fetchEmailLog = id => {
  return dispatch =>
    notificationAPI(dispatch, {
      method: 'get',
      url: `/notification/admin/v1/logs/${id}`,
      type: FETCH_EMAIL_LOG,
    });
};
