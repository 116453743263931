import { Avatar } from 'antd';
import PropTypes from 'prop-types';
import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import { Box } from '../../../../../shared/App/components';
import { withOperation } from '../../../../../utils/OperationalComponent';
import { formatDateTime } from '../../../../../utils/time';
import { fetchUser } from '../../../../User/userActions';
import { getUserDetail } from '../../../../User/userReducers';

class TravelerInfo extends React.Component {
  componentDidMount() {
    const { travelerId, executeActions, fetchTravelerAction } = this.props;

    if (travelerId) {
      executeActions({
        actions: [() => fetchTravelerAction({ id: travelerId })],
      });
    }
  }

  componentDidUpdate(prevProps) {
    const { travelerId, executeActions, fetchTravelerAction } = this.props;
    const { travelerId: prevTravelerId } = prevProps;

    if (prevTravelerId !== travelerId) {
      executeActions({
        actions: [() => fetchTravelerAction({ id: travelerId })],
      });
    }
  }

  render() {
    const { traveler = {}, isLoading, isFetching } = this.props;

    return (
      <Box
        isLoading={isLoading || isFetching}
        noHeader
        body={
          <Fragment>
            <div className='box-body'>
              <div>
                <label>Traveler</label>
              </div>
              <ul className="list-group">
                {traveler.first_name && (
                  <li className="list-group-item">
                    <div style={{ margin: '0', padding: '10px 0' }} className="flex flex-row">
                      <span className="col-xs-5">Name</span>
                      <div className="col-xs-7 text-right">
                        <Link to={`/users/${traveler.id}`}>
                          {/* <Avatar src={traveler.photo} size={'medium'} />
                          &nbsp;&nbsp; */}
                          <div>
                            {`${traveler.first_name} ${traveler.last_name}`} <br />
                            <span style={{ fontSize: '12px', color: 'grey' }}>
                              3 hours ago
                            </span>
                          </div>
                        </Link>
                      </div>
                    </div>
                  </li>
                )}
                {traveler.email && (
                  <li className="list-group-item">
                    <div
                      style={{ margin: '0', padding: '10px 0' }}
                      className="flex flex-row"
                    >
                      <span className="col-xs-5">Email</span>
                      <a
                        href={`mailto:${traveler.email}`}
                        className="col-xs-7 text-right"
                        style={{ wordBreak: 'break-all' }}
                      >
                        {traveler.email}
                      </a>
                    </div>
                  </li>
                )}
                {traveler.phone && (
                  <li className="list-group-item">
                    <div
                      style={{ margin: '0', padding: '10px 0' }}
                      className="flex flex-row"
                    >
                      <span className="col-xs-5">Phone</span>
                      <span className="col-xs-7 text-right">
                        {traveler.phone}
                      </span>
                    </div>
                  </li>
                )}
                {traveler.social_contact && traveler.social_contact.whatsapp && (
                  <li className="list-group-item">
                    <div
                      style={{ margin: '0', padding: '10px 0' }}
                      className="flex flex-row"
                    >
                      <span className="col-xs-5">Whatsapp</span>
                      <span className="col-xs-7 text-right">
                        {traveler.social_contact.whatsapp}
                      </span>
                    </div>
                  </li>
                )}
                {traveler.nationality && (
                  <li className="list-group-item">
                    <div
                      style={{ margin: '0', padding: '10px 0' }}
                      className="flex flex-row"
                    >
                      <span className="col-xs-5">Nationality</span>
                      <span className="col-xs-7 text-right">
                        {traveler.nationality}
                      </span>
                    </div>
                  </li>
                )}
                {traveler.date_joined && (
                  <li className="list-group-item">
                    <div
                      style={{ margin: '0', padding: '10px 0' }}
                      className="flex flex-row"
                    >
                      <span className="col-xs-5">Registered date</span>
                      <span className="col-xs-7 text-right">
                        {formatDateTime(traveler.date_joined)}
                      </span>
                    </div>
                  </li>
                )}
              </ul>
            </div>

          </Fragment>
        }
      />
    );
  }
}

TravelerInfo.propTypes = {
  travelerId: PropTypes.number,
  /**
   * ConnectedProps
   */
  traveler: PropTypes.object,
  /**
   * Operational Props
   */
  success: PropTypes.string,
  error: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  isFetching: PropTypes.bool,
  executeActions: PropTypes.func,
  /**
   * Actions
   */
  fetchTravelerAction: PropTypes.func,
};

const mapStateToProps = (state, ownProps) => ({
  traveler: getUserDetail(state, ownProps.travelerId),
});

export default connect(mapStateToProps, {
  fetchTravelerAction: fetchUser,
})(withOperation(TravelerInfo));
