import { cmsAPI } from '../../utils/axiosAPI';

export const FETCH_PROFILE = 'FETCH_PROFILE';
export const RESET_PROFILE = 'RESET_PROFILE';
export const FETCH_PROFILE_DETAIL = 'FETCH_PROFILE_DETAIL';
export const CREATE_PROFILE = 'CREATE_PROFILE';
export const UPDATE_PROFILE = 'UPDATE_PROFILE';
export const DELETE_PROFILE = 'DELETE_PROFILE';

export const fetchProfiles = ({ params }) => {
  return dispatch =>
    cmsAPI(dispatch, {
      method: 'get',
      url: '/cms/admin/v1/profiles',
      params,
      type: FETCH_PROFILE,
    });
};

export const resetProfile = () => ({
  type: RESET_PROFILE,
});

export const fetchProfileDetail = ({ id }) => dispatch =>
  cmsAPI(dispatch, {
    method: 'get',
    url: `/cms/admin/v1/profiles/${id}`,
    type: FETCH_PROFILE_DETAIL,
  });

export const createProfile = data => dispatch =>
  cmsAPI(dispatch, {
    method: 'post',
    url: '/cms/admin/v1/profiles',
    data,
    type: CREATE_PROFILE,
  });

export const updateProfile = (id, data) => dispatch =>
  cmsAPI(dispatch, {
    method: 'patch',
    url: `/cms/admin/v1/profiles/${id}`,
    data,
    type: UPDATE_PROFILE,
  });

export const deleteProfile = ({ id }) => dispatch =>
  cmsAPI(dispatch, {
    method: 'delete',
    url: `/cms/admin/v1/profiles/${id}`,
    type: DELETE_PROFILE,
  });
