import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { Box, Button } from '../../../../../shared/App/components';
import { formatDateTime, formatDate } from '../../../../../utils/time';
import { getLocalePrefixByLanguage } from '../../../../../utils/localeUtils';
import configService from '../../../../../config/configService';
import { displayCurrency } from '../../../../../utils/currency';
import {
  fetchExperienceDetail,
  fetchVariantDetail,
} from '../../../bookingItemActions';
import NetPriceModal from './NetPriceModal';

class BookingItemInfo extends React.Component {
  state = {
    experienceDetail: {},
    variantDetail: {},
    isEditingNetPrice: false,
    isFetching: false
  };

  componentDidMount() {
    this.fetchExperience();
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.bookingItem.experience_id !==
      this.props.bookingItem.experience_id
    ) {
      this.fetchExperience();
    }
  }

  toggleEditing = isEditingNetPrice => this.setState({ isEditingNetPrice });

  fetchExperience = () => {
    const { bookingItem } = this.props;

    if (!bookingItem.experience_id) {
      return;
    }

    this.setState({ isFetching: true });
    return this.props.fetchExperienceDetail({ id: bookingItem.experience_id })
      .then(expRes => {
        return this.props
          .fetchVariantDetail({ id: bookingItem.experience_id, ogId: bookingItem.experience_variant_id })
          .then(variantRes =>
            this.setState({
              isFetching: false, experienceDetail: expRes.data, variantDetail: variantRes.data
            })
          );
      });
  };

  render() {
    const { experienceDetail, variantDetail, isFetching } = this.state;
    const { bookingItem } = this.props;
    const {
      start_date,
      booking,
      cost,
      supplier_amount,
      created,
      modified,
      pickup_location,
      pickup_time,
      options,
      extra_services,
      currency_code: currency,
      language
    } = bookingItem;

    const localePrefix = getLocalePrefixByLanguage(language);

    return (
      <Box
        isLoading={isFetching}
        body={
          <Fragment>
            <div className="box-body text-center">
              <a
                href={`${configService.mainSite}${localePrefix}experiences/${
                  experienceDetail.slug
                }-${experienceDetail.id}`}
                target="_blank"
              >
                <img
                  className="img-responsive"
                  role="presentation"
                  width={200}
                  src={experienceDetail.background_photo}
                  onError={({ currentTarget }) => {
                    currentTarget.onerror = null; // prevents looping
                    currentTarget.src="https://storage.googleapis.com/inspitrip/backend/fallbackphoto/no-product-image.jpeg";
                  }}
                />
              </a>
            </div>
            <div className="col-xs-12" style={{ paddingBottom: '10px', borderBottom: '1px solid #f4f4f4' }}>
              <a href={`${configService.mainSite}${localePrefix}/experiences/${experienceDetail.slug}-${experienceDetail.id}`} target="_blank">
                <strong>{experienceDetail.title} - {experienceDetail.id}</strong>
              </a>
              <div>{variantDetail && variantDetail.title}</div>
            </div>
            <ul className="list-group" style={{ clear: 'both' }}>
              <li className="list-group-item">
                <a style={{ margin: '0', padding: '10px 0' }} className="flex flex-row">
                  <span className="col-xs-5">Travel Date</span>
                  <span className="col-xs-7 text-right">
                    {formatDate(start_date)}
                  </span>
                </a>
              </li>
              <li className="list-group-item">
                <a className="flex flex-row" style={{ margin: '0', padding: '10px 0' }}>
                  <span className="col-xs-4">Location</span>
                  <span className="col-xs-8 text-right">
                    {`${experienceDetail.place_name}, ${experienceDetail.place_country}`} <br />
                  </span>
                </a>
              </li>
              <li className="list-group-item">
                <a className="flex flex-row" style={{ margin: '0', padding: '10px 0' }}>
                  <span className="col-xs-4">Units</span>
                  <span className="col-xs-8 text-right">
                    {options && options.length > 0 && options.map(
                      option => (
                        <Fragment key={option.unit_type_id}>
                          {option.title} x {option.quantity}<br />
                        </Fragment>
                      )
                    )}
                  </span>
                </a>
              </li>
              <li className="list-group-item">
                <a className="flex flex-row" style={{ margin: '0', padding: '10px 0' }}>
                  <span className="col-xs-4">Services</span>
                  <span className="col-xs-8 text-right">
                    {extra_services && extra_services.length > 0 && extra_services.map(
                      service => (
                        <Fragment key={service.service_id}>
                          {service.title} x {service.quantity}<br />
                        </Fragment>
                      )
                    )}
                    {extra_services && extra_services.length === 0 && '-'}
                  </span>
                </a>
              </li>
              <li className="list-group-item">
                <a className="flex flex-row" style={{ margin: '0', padding: '10px 0' }}>
                  <span className="col-xs-4">Booking</span>
                  <span className="col-xs-8 text-right">
                    <Link
                      to={`/bookings/${booking}`}
                    >
                      {booking}
                    </Link>
                  </span>
                </a>
              </li>
              <li className="list-group-item">
                <a className="flex flex-row" style={{ margin: '0', padding: '10px 0' }}>
                  <span className="col-xs-5">Net price</span>
                  <span className="col-xs-7 text-right">
                    <Button
                      onClick={() => this.setState({ isEditingNetPrice: true })}
                      type="button"
                      displayType="primary"
                    >Update</Button> {supplier_amount && displayCurrency({ amount: parseFloat(supplier_amount), currency })}
                  </span>
                </a>
              </li>
              <li className="list-group-item">
                <a className="flex flex-row" style={{ margin: '0', padding: '10px 0' }}>
                  <span className="col-xs-5">Total amount</span>
                  <span className="col-xs-7 text-right">
                    {displayCurrency({ amount: parseFloat(cost), currency })}
                  </span>
                </a>
              </li>
              <li className="list-group-item">
                <a className="flex flex-row" style={{ margin: '0', padding: '10px 0' }}>
                  <span className="col-xs-7">Pickup location</span>
                  <span className="col-xs-5 text-right">{pickup_location}</span>
                </a>
              </li>
              <li className="list-group-item">
                <a className="flex flex-row" style={{ margin: '0', padding: '10px 0' }}>
                  <span className="col-xs-7">Pickup time</span>
                  <span className="col-xs-5 text-right">{pickup_time}</span>
                </a>
              </li>
              <li className="list-group-item">
                <a style={{ margin: '0', padding: '10px 0' }} className="flex flex-row">
                  <span className="col-xs-5">Created date</span>
                  <span className="col-xs-7 text-right">
                    {formatDateTime(created)}
                  </span>
                </a>
              </li>
              <li className="list-group-item">
                <a style={{ margin: '0', padding: '10px 0' }} className="flex flex-row">
                  <span className="col-xs-5">Last update</span>
                  <span className="col-xs-7 text-right">
                    {formatDateTime(modified)}
                  </span>
                </a>
              </li>
            </ul>
            {this.state.isEditingNetPrice && (
              <NetPriceModal
                bookingItem={bookingItem}
                onDismiss={() => this.toggleEditing(false)}
              />
            )}
          </Fragment>
        }
        noHeader
      />
    );
  }
}

BookingItemInfo.propTypes = {
  isLoading: PropTypes.bool,
  bookingItem: PropTypes.object
};

BookingItemInfo.defaultProps = {
  bookingItem: {}
};

export default connect(
  null,
  { fetchExperienceDetail, fetchVariantDetail }
)(BookingItemInfo);
