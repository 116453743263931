import React, { Component } from 'react';
import PropTypes from 'prop-types';

import {
  InputField,
  Modal,
  MarkdownField,
  Button,
  SelectField,
} from '../../../shared/App/components';
import { Form, Formik } from 'formik';
import FormStatus from '../../../shared/App/components/FormStatus';
import FroalaField from '../../../shared/App/components/FroalaField';

const validate = values => {
  const errors = {};

  if (!values.question) {
    errors.question = 'Please enter question text.';
  }

  if (!values.answer) {
    errors.answer = 'Please enter answer text.';
  }

  return errors;
};

class ExperienceEditQuestionModal extends Component {
  constructor() {
    super();
    this.resetState = this.resetState.bind(this);
    this.state = {
      showConfirmDelete: false,
      editError: undefined,
      deleteError: undefined,
      templateQuestion: null,
      templateAnswer: null,
      template_faq: '',
    };
  }

  onSubmit(values, form) {
    const { expId, id, updateExperienceFAQ, handleCloseModal } = this.props;
    this.resetState();
    updateExperienceFAQ({ expId, faqId: id, data: values })
      .then(() => {
        handleCloseModal();
        form.resetForm();
      })
      .catch(error => {
        this.setState({
          editError: error.data || 'Oops! Please contact your administrator.',
        });
      });
  }

  resetState() {
    this.setState({
      error: undefined,
    });
  }

  handleOpenConfirmDeleteModal() {
    this.setState({
      showConfirmDelete: true,
    });
  }

  handleCloseModal() {
    this.setState({
      showConfirmDelete: false,
    });
  }

  render() {
    const {
      handleCloseModal,
      showAddModalEditFaq,
      question,
      answer,
      deleteFAQ,
      expId,
      id,
      faqTemplates,
    } = this.props;
    const selectOptions =
      faqTemplates && faqTemplates.length > 0
        ? faqTemplates.map(faq => ({
            label: faq.question,
            value: faq.id,
          }))
        : [];
    const {
      question: templateQuestion,
      answer: templateAnswer,
      template_faq,
      showConfirmDelete,
    } = this.state;
    const { resetState } = this;
    if (showConfirmDelete) {
      return (
        <Modal
          title="Delete Question"
          confirmText="Delete"
          dismissText="Cancel"
          onDismiss={this.handleCloseModal.bind(this)}
          visible={showConfirmDelete}
          onConfirm={() => {
            this.setState({ deleteError: undefined });
            deleteFAQ({ expId, faqId: id, meta: { expId, faqId: id } })
              .then(() => {
                handleCloseModal();
                this.handleCloseModal();
              })
              .catch(error => {
                this.setState({
                  deleteError:
                    error.data || 'Oops! Please contact your administrator.',
                });
              });
          }}
        >
          {this.state.deleteError ? (
            <FormStatus formState={{ error: this.state.deleteError }} />
          ) : null}
          <div>Do you want to delete this one?</div>
        </Modal>
      );
    }

    return (
      <Modal
        title="Edit Question"
        hideFooter
        onDismiss={handleCloseModal}
        visible={showAddModalEditFaq}
      >
        {this.state.editError ? (
          <FormStatus formState={{ error: this.state.editError }} />
        ) : null}
        <Formik
          initialValues={{
            question: templateQuestion || question,
            answer: templateAnswer || answer,
            template_faq,
          }}
          validate={validate}
          onSubmit={this.onSubmit.bind(this)}
          enableReinitialize
        >
          {({ isSubmitting, isValid, values }) => (
            <Form>
              <SelectField
                name="template_faq"
                label="Template"
                options={selectOptions}
                onChange={selectedOption => {
                  faqTemplates.forEach(faq => {
                    if (faq.id === parseInt(selectedOption.value, 10)) {
                      this.setState({
                        question: faq.question,
                        answer: faq.answer,
                        template_faq: selectedOption.value,
                      });
                    }
                  });
                }}
              />
              <InputField name="question" label="Question" />
              <FroalaField
                id="answer"
                name="answer"
                label="Answer"
                placeholder="Answer content"
              />
              <div className="flex flex-row">
                <div className="col-xs-4">
                  <Button
                    className="btn btn-flat text-red"
                    onClick={() => {
                      this.handleOpenConfirmDeleteModal();
                    }}
                  >
                    <i className="fa fa-trash-o"></i> Delete this question
                  </Button>
                </div>
                <div className="col-xs-8 text-right">
                  <Button
                    className="btn btn-default"
                    onClick={() => {
                      resetState();
                      handleCloseModal();
                    }}
                  >
                    Cancel
                  </Button>
                  &nbsp;&nbsp;
                  <Button className="btn  btn-primary" type="submit">
                    Save changes
                  </Button>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </Modal>
    );
  }
}

ExperienceEditQuestionModal.propTypes = {
  handleCloseModal: PropTypes.func,
  faqTemplates: PropTypes.array,
  showAddModalEditFaq: PropTypes.bool,
  question: PropTypes.string,
  answer: PropTypes.string,
  id: PropTypes.number,
  updateExperienceFAQ: PropTypes.func,
};

export default ExperienceEditQuestionModal;
