import { combineReducers } from 'redux';
import {
  FETCH_USERS, FETCH_USER, RESET_USER,
  USER_UPDATE
} from './userActions';
import { appendByIds } from '../../utils/reducer';
import { PENDING, FULFILLED, REJECTED } from '../../utils/axiosAPI';

// Initial State
export const fetchInitialState = {
  count: 0,
  next: null,
  previous: null,
  isFetching: false,
  error: null
};

export const fetchStatus = (state = fetchInitialState, action) => {
  switch (action.type) {
    case FETCH_USERS + FULFILLED: {
      const { results, ...rest } = action.data;
      return { ...state, isFetching: false, count: 0, ...rest };
    }
    case FETCH_USERS + REJECTED: {
      return { ...state, isFetching: false, error: action.data };
    }
    case FETCH_USERS + PENDING: {
      return { ...state, isFetching: true, error: null };
    }
    case FETCH_USERS: {
      return fetchInitialState;
    }
    default: {
      return state;
    }
  }
};

export const byId = (state = {}, action) => {
  switch (action.type) {
    case FETCH_USERS + FULFILLED: {
      return appendByIds(state, action.data.results, 'id');
    }
    case USER_UPDATE + FULFILLED:
    case FETCH_USER + FULFILLED: {
      const user = state[action.data.id];
      return {
        ...state,
        [action.data.id]: { ...user, ...action.data }
      };
    }
    default: {
      return state;
    }
  }
};

export const visibleIds = (state = [], action) => {
  switch (action.type) {
    case FETCH_USERS + FULFILLED: {
      /**
       * Always replace the visible user
       */
      return action.data.results.map(user => user.id);
    }
    case RESET_USER: {
      return [];
    }
    default: {
      return state;
    }
  }
};

export default combineReducers({
  fetchStatus,
  byId,
  visibleIds
});

export const getUsers = state => state.user.visibleIds.map(id => state.user.byId[id]);
export const getFetchStatus = state => state.user.fetchStatus;
export const getUserDetail = (state, id) => state.user.byId[id];
