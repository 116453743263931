import { combineReducers } from 'redux';

// Actions
import { FETCH_CATEGORIES, RESET_CATEGORIES, CREATE_CATEGORY, FETCH_CATEGORY, UPDATE_CATEGORY } from './categoryActions';

// Constants
import { FULFILLED, PENDING, REJECTED } from '../../utils/axiosAPI';
import { appendByIds } from '../../utils/reducer';

const fetchStatusInitialState = {
  count: 0,
  next: null,
  previous: null,
  isFetching: false,
  error: null
};

// Seperate reuse for all reducer
export const fetchStatus = (state = fetchStatusInitialState, action) => {
  switch (action.type) {
    case FETCH_CATEGORIES + FULFILLED: {
      const { results, ...rest } = action.data;
      return { ...state, isFetching: false, ...rest };
    }
    case FETCH_CATEGORIES + REJECTED: {
      return { ...state, isFetching: false, error: action.data };
    }
    case FETCH_CATEGORIES + PENDING: {
      return { ...state, isFetching: true, error: null };
    }
    case RESET_CATEGORIES: {
      return fetchStatusInitialState;
    }
    default: {
      return state;
    }
  }
};

export const byId = (state = {}, action) => {
  switch (action.type) {
    case FETCH_CATEGORIES + FULFILLED: {
      return appendByIds(state, action.data.results, 'id');
    }
    case CREATE_CATEGORY + FULFILLED: {
      return {
        [action.data.id]: action.data,
        ...state
      };
    }
    case UPDATE_CATEGORY + FULFILLED:
    case FETCH_CATEGORY + FULFILLED: {
      return {
        [action.data.id]: action.data,
        ...state
      };
    }
    default:
      return state;
  }
};

export const visibleIds = (state = [], action) => {
  switch (action.type) {
    case FETCH_CATEGORIES + FULFILLED: {
      return action.data.results.map(item => item.id);
    }
    case CREATE_CATEGORY + FULFILLED: {
      return [action.data.id, ...state];
    }
    case RESET_CATEGORIES: {
      return [];
    }
    default: {
      return state;
    }
  }
};

//
const initialCategoryFields = {
  isFetching: false,
  action: {}
};

export default combineReducers({
  fetchStatus,
  visibleIds,
  byId
});

// Selector
export const getCategories = state => state.category.visibleIds.map(id => state.category.byId[id]);
export const getCategoryDetail = (state, id) => state.category.byId[id];
export const getFetchStatus = state => state.category.fetchStatus;
