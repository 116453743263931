import queryString from 'query-string';
import deepEqual from 'fast-deep-equal';

const ASCENDING = 'ascending';
const DESCENDING = 'descending';

export const parseQueryString = queries => {
  return queryString.parse(queries);
};

export const compareQueryStrings = (q1, q2) => {
  const params1 = parseQueryString(q1);
  const params2 = parseQueryString(q2);

  return deepEqual(params1, params2);
};

export const parseParams = params => {
  return queryString.stringify(params);
};

export const resetParams = (params) => {
  return params.reduce((obj, current) => {
    return { ...obj, [current]: undefined };
  }, {});
};

export const appendToQueryString = (query, params) => {
  const oldParams = parseQueryString(query);
  const newParams = { ...oldParams, ...params };

  return `?${parseParams(newParams)}`;
};

export const parseOrderingQuery = (ordering) => {
  if (ordering) {
    let sortDirection = ASCENDING;
    let sortBy = ordering;
    if (sortBy.startsWith('-')) {
      sortDirection = DESCENDING;
      sortBy = sortBy.slice(1);
    }

    return { sortBy, sortDirection };
  }

  return undefined;
};

export const createOrderingQuery = ({ sortBy, sortDirection }) => {
  const direction = sortDirection === ASCENDING ? '' : '-';
  return `${direction}${sortBy}`;
};

export const parseFilterParams = ({ queries, params }) => {
  let inputValue = null;
  let selectedField = null;
  for (let i = 0; i < params.length; i++) {
    if (queries[params[i]]) {
      selectedField = params[i];
      inputValue = queries[selectedField];
      break;
    }
  }

  return { inputValue, selectedField };
};
