import React from 'react';
import { Modal } from '../../../../../shared/App/components';

const SendNotificationForm = ({ onSubmit, onDismiss, visible }) => (
  <Modal
    title="Are you sure to send email confirmation of this Booking?"
    confirmText="Yes, sure"
    dismissText="Cancel"
    onConfirm={onSubmit}
    {...{ onDismiss, visible }}
  >
    <p>
      This action will send email confirmation to traveller.
    </p>
  </Modal>
);

export default SendNotificationForm;
