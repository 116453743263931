import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';

import { formatDateTime, timeAgo } from '../../utils/time';

/**
 * transformProfileDetail changes the field user and localguide of profile detail
 * to user_id and localguide_id to avoid collision with the user and local object
 * @param {*} profile - the profile detail object
 */
export const transformProfileDetail = profile => {
  let newProfile = { ...profile };
  delete newProfile.user;
  delete newProfile.localguide;

  return ({
    ...newProfile
    // user_id: profile.user,
    // localguide_id: profile.localguide
  });
};

export const profileColumns = [
  {
    id: 'id',
    title: 'ID',
    render: ({ id }) => <Link to={`/audience/profiles/${id}`}>#{id}</Link>
  },
  {
    id: 'language',
    title: 'Language',
    render: ({ language }) => <span>{language}</span>
  },
  {
    id: 'country',
    title: 'Country',
    render: ({ country }) => <span>{country}</span>
  },
  {
    id: 'createdDate',
    title: 'Created Date',
    render: ({ created }) => <div style={{ width: '100px', whiteSpace: 'normal' }}>{formatDateTime(created)}</div>
  },
  {
    id: 'lastUpdate',
    title: 'Last Update',
    render: ({ modified }) => <span>{timeAgo(modified)}</span>
  },
];
