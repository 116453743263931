import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Modal } from '../../../../shared/App/components';

// containers
import ServiceForm from './ServiceForm';

// actions
import {
  fetchService,
  createService,
  updateService,
  deleteService,
} from '../../reducers/service/actions';

import { getService } from '../../reducers/service';

// utils
import { withOperation } from '../../../../utils/OperationalComponent';

// config
import configApp from '../../../../config/configApp';

class ServiceModal extends React.Component {

  componentDidMount() {
    const { executeActions, experienceId, variantId, serviceId } = this.props;

    if (this.isEditMode()) {
      executeActions({
        actions: [
          () => this.props.fetchService({ experienceId, variantId, serviceId })
        ]
      });
    }
  }

  isEditMode = () => this.props.mode === configApp.editMode.EDIT;

  buildInitialValues = () => {
    if (!this.isEditMode()) {
      return {
        experience: this.props.experienceId,
        variant: this.props.variantId
      };
    }

    const {
      label,
      description,
      price,
      supplier_price,
      limit_min,
      limit_max
    } = this.props.service;
    return { label, description, price, supplier_price, limit_min, limit_max };
  };

  handleSubmit = values => {
    const {
      executeActions,
      experienceId,
      variantId,
      serviceId,
      onDismiss
    } = this.props;
    executeActions({
      actions: [
        () => {
          if (this.isEditMode()) {
            return this.props.updateService({
              experienceId,
              variantId,
              serviceId,
              data: values
            });
          }
          return this.props.createService({
            experienceId,
            variantId,
            data: values
          });
        }
      ],
      onSuccess: onDismiss
    });
  };

  handleDelete = () => {
    if (window.confirm('Delete this service?')) {
      const {
        executeActions,
        experienceId,
        variantId,
        serviceId,
        onDismiss
      } = this.props;
      executeActions({
        actions: [
          () =>
            this.props.deleteService({
              experienceId,
              variantId,
              serviceId
            })
        ],
        onSuccess: onDismiss
      });
    }
  };

  render() {
    return (
      <Modal
        title={`${this.props.mode} Service`}
        hideFooter
        onDismiss={this.props.onDismiss}
        visible
      >
      {
        !this.isEditMode() || this.props.service ?
          <ServiceForm
            handleSubmit={this.handleSubmit}
            handleDelete={this.handleDelete}
            initialValues={this.buildInitialValues()}
            isEditing={this.isEditMode()}
          /> :
          <div>loading..</div>
      }
      </Modal>
    );
  }
}

ServiceModal.propTypes = {
  onDismiss: PropTypes.func,
  mode: PropTypes.oneOf([configApp.editMode.EDIT, configApp.editMode.ADD]),
  experienceId: PropTypes.number.isRequired
};

ServiceModal.defaultProps = {
  mode: configApp.editMode.ADD
};

const mapStateToProps = (state, ownProps) => ({
  service: getService(state, ownProps.serviceId)
});

const mapDispatchToProps = {
  fetchService,
  createService,
  updateService,
  deleteService
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withOperation(ServiceModal));
