import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

// containers
import Service from './ServiceItem';

// selectors
import { getVariantServices, getVariant } from '../reducers/variant';

// utils
import { withOperation } from '../../../utils/OperationalComponent';

class ServiceList extends Component {

  render() {
    const { variantId, experienceId, services, variant } = this.props;
    if (this.props.isFetching) {
      return <h5>Loading services...</h5>;
    }

    if (!services || services.length === 0) {
      return null;
    }

    return (
      <Fragment>
        <h5><i className="fa fa-th"></i> Services</h5>
        {services.map(service => (
          <Service
            key={service.id}
            variantId={variantId}
            experienceId={experienceId}
            service={service}
          />
        ))}
      </Fragment>
    );
  }
}

ServiceList.propTypes = {
  variantId: PropTypes.number.isRequired,
  experienceId: PropTypes.number.isRequired
};

const mapStateToProps = (state, ownProps) => ({
  services: getVariantServices(state, ownProps.variantId),
  variant: getVariant(state, ownProps.variantId)
});

const mapDispatchToProps = {

};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withOperation(ServiceList));
