import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import { Listings } from '../../../../shared/App/components';
import configService from '../../../../config/configService';
import { parseQueryString } from '../../../../utils/urlQuery';
import FilterForm from '../../../../shared/Form/FilterForm';
import SearchForm from '../../../../shared/Form/SearchForm';
import { withOperation } from '../../../../utils/OperationalComponent';
import { withQuery } from '../../../../utils/QueryBasedComponent';

import { fetchBookingItems, resetBookingItems } from '../../bookingItemActions';
import { getBookingItems, getFetchStatus } from '../../bookingItemReducer';
import { bookingColumns } from '../../bookingItemUtils';
import {
  getSearchData,
  getFilterData,
  keyOptions,
} from '../../bookingItemFilters';

const initialState = {
  showModalFilter: false,
  sortParams: {},
};

const sortDirection = { asc: '', desc: '-' };

// Fullfillment page
class BookingListPage extends Component {
  state = initialState;

  componentDidMount() {
    const {
      setQueryCallback,
      bookings,
      location: { search },
    } = this.props;
    setQueryCallback(this.handleFetchBookingItems);

    if (bookings.length === 0) {
      this.handleFetchBookingItems(parseQueryString(search));
    }
  }

  componentWillUnmount() {
    if (this.cancel) this.cancel();
    this.props.resetBookingAction();
  }

  handleFetchBookingItems = params => {
    const { fetchBookingItemsAction } = this.props;
    const { page = 1, ordering } = params || {};
    if (this.cancel) this.cancel();
    this.cancel = fetchBookingItemsAction({
      params: { page, ordering },
    }).cancel;
  };

  handlePageChange = page => {
    this.props.pushParams({ page });
  };

  handleFilterChange = values => {
    let params = {};
    values.filter.map(
      (item, index) => (params[item.key + item.type] = item.value)
    );
    params.search = this.props.queryParams.search;
    this.props.replaceParams(params);
    this.handleCloseModal();
  };

  handleSearchChange = values => {
    this.props.pushParams({ search: values.search });
  };

  handleOpenModalFilter = () => {
    this.setState({ showModalFilter: true });
  };

  handleCloseModal = () => {
    this.setState(initialState);
  };

  decorateBookingList() {
    return this.props.bookings.map((booking, index) => {
      return {
        ...booking,
        index: index + 1,
      };
    });
  }

  handleSort = values => {
    this.props.pushParams({
      ordering: `${sortDirection[values.sortDirection]}${values.sortBy}`,
    });
    this.setState({ sortParams: values });
  };

  render() {
    const {
      bookings,
      fetchStatus: { count, isFetching },
      queryParams: { page },
    } = this.props;
    const { sortParams } = this.state;
    const searchData = getSearchData(this.props.queryParams);
    const filterData = getFilterData(this.props.queryParams);

    return (
      <Fragment>
        <Helmet>
          <title>Fulfillment - Inspitrip HQ</title>
        </Helmet>
        <section className="content-header">
          <div className="flex flex-row" style={{ marginTop: '-15px', marginBottom: '15px' }}>
            <ol className="breadcrumb">
              <li>
                <Link to={'/'}>InspitripHQ</Link>
              </li>
              <li className="active">Fulfillment</li>
            </ol>
          </div>
          <div className="flex flex-row">
            <div className="flex flex-col gap-y-7">
              <h1 style={{ fontSize: '24px', marginTop: 0 }}>Fulfillment</h1>
            </div>
          </div>
        </section>
        <section className="content-header">
          <div className="flex flex-row">
            <div className="col-sm-12 pl-0 pr-0">
              <SearchForm
                onSubmit={this.handleSearchChange}
                searchContent={searchData}
                filterData={filterData}
                handleOpenModalFilter={this.handleOpenModalFilter.bind(this)}
                placeHolderContent="Search keyword, ie: Booking ID, User Name, Email"
              />
            </div>
          </div>
        </section>

        <div className="content body">
          <Listings
            listingsTitle="Fulfillment"
            data={this.decorateBookingList(bookings)}
            columns={bookingColumns}
            actions={this.actions}
            onSortHeader={this.handleSort}
            page={parseInt(page || 1, 10)}
            dataCount={count}
            itemsPerPage={configService.itemsCountPerPage}
            onPageChange={this.handlePageChange}
            pageRange={3}
            isFetching={isFetching}
            sortParams={sortParams}
            noHeader
          />
        </div>
        <FilterForm
          onSubmit={this.handleFilterChange}
          onDismiss={this.handleCloseModal}
          visible={this.state.showModalFilter}
          arrayData={filterData}
          keyOptions={keyOptions}
        />
      </Fragment>
    );
  }
}

BookingListPage.propTypes = {
  /**
   * ConnectedProps
   */
  fetchStatus: PropTypes.object,
  bookings: PropTypes.array,
  /**
   * Actions
   */
  fetchBookingItemsAction: PropTypes.func.isRequired,
  resetBookingAction: PropTypes.func.isRequired,
  /**
   * QueryComponentProps
   */
  setQueryCallback: PropTypes.func.isRequired,
  pushParams: PropTypes.func.isRequired,
  queryParams: PropTypes.object,
};

BookingListPage.need = [
  ({ query }) => {
    return fetchBookingItems({ params: query });
  },
];

const mapStateToProps = state => ({
  fetchStatus: getFetchStatus(state),
  bookings: getBookingItems(state),
});

export default connect(mapStateToProps, {
  fetchBookingItemsAction: fetchBookingItems,
  resetBookingAction: resetBookingItems,
})(withOperation(withQuery(BookingListPage)));
