import React from 'react';
import { Link } from 'react-router-dom';

// Components
import { Status } from '../../shared/App/components';

// Utils
import { timeAgo } from '../../utils/time';

// Constants
export const StatusConfig = {
  [true]: { title: 'publish', color: Status.colors.GREEN },
  [false]: { title: 'pending', color: Status.colors.GREY }
};

export const transformCollectionDetail = collection => {
  let newCollection = { ...collection };
  const localeSplit = newCollection.locale ? newCollection.locale.split('-') : ['en', ''];
  newCollection.language = localeSplit[0] || '';
  newCollection.country = localeSplit[1] || '';
  newCollection.is_active = newCollection.is_active === 'publish' || false;
  newCollection.list_id = newCollection.list_id && newCollection.list_id.length > 0 ? newCollection.list_id.map(item => item.value) : [];
  delete newCollection.editMode;
  delete newCollection.locale;
  return newCollection;
};

export const buildFormData = collection => {
  if (!collection) return {};

  return {
    ...collection,
    locale: `${collection.language}${collection.country && `-${collection.country}`}`,
    title: collection.title,
    code: collection.code,
    is_active: collection.is_active ? 'publish' : 'pending',
    limit: collection.limit,
    list_id: collection.list_id && collection.list_id.length > 0 ? collection.list_id.map(item => ({ label: item, value: item })) : null
  };
};

export const collectionColumns = () => {
  return [
    {
      id: 'id',
      title: 'ID',
      render: ({ id }) => {
        return <div>{id}</div>;
      }
    },
    {
      id: 'language',
      title: 'Locale',
      render: ({ language, country }) => (
        <span>
          {language} {`${country && `-${country}`}`}
        </span>
      )
    },
    {
      id: 'title',
      title: 'Title',
      render: ({ id, title }) => <Link to={`/collections/${id}`}>{title}</Link>
    },
    {
      id: 'is_active',
      title: 'Status',
      render: ({ is_active }) => {
        return <Status state={`${is_active}`} statuses={StatusConfig} />;
      }
    },
    {
      id: 'list_id',
      title: 'Hand Picked',
      render: ({ list_id }) => {
        if (!list_id || list_id.length === 0) return <span>None data</span>;
        return (
          <span>
            {list_id.map(item => {
              return (
                <span>
                  <a style={{ background: '#3c8dbc', borderRadius: '10px', padding: '0px 6px', color: 'white' }} href={`/products/${item}`}>
                    {item}
                  </a>
                  {'  '}
                </span>
              );
            })}
          </span>
        );
      }
    },
    {
      id: 'modified',
      title: 'Updated',
      render: ({ modified }) => <span>{timeAgo(modified)}</span>
    }
  ];
};
