import React from 'react';
import PropTypes from 'prop-types';
import { Form, Formik } from 'formik';
import { NumericInputField, Modal, InputField } from '../../../../../shared/App/components';

const RefundForm = ({ onSubmit, onDismiss, visible, payment }) => (
  <Formik
    enableReinitialize
    initialValues={{
      captured_amount: payment && payment.captured_amount,
      amount: payment && payment.amount_paid,
      reason: ''
    }}
    onSubmit={onSubmit}
  >
    {({ isSubmitting, isValid, values, submitForm }) => (
      <Modal
        title="Make a Refund"
        confirmText="Save changes"
        dismissText="Cancel"
        onConfirm={submitForm}
        onDismiss={onDismiss}
        visible={visible}
      >
        <Form>
          <div className="flex flex-row">
            <div className="col-md-3 col-sm-4 col-xs-5">
              {payment && <NumericInputField label="Amount Paid" disabled name="captured_amount" />}
              {payment && <NumericInputField
                name="amount"
                label="Refund Amount"
                validate={value => {
                  if (payment && parseFloat(value) > parseFloat(payment.captured_amount)) {
                    return 'Cannot refund more than amount paid';
                  }
                  return undefined;
                }}
              />}
            </div>
            <div className="col-xs-12">
              <InputField name="reason" label="Reason (optional)" />
            </div>
          </div>
        </Form>
      </Modal>
    )}
  </Formik>
);

RefundForm.propTypes = {
  onSubmit: PropTypes.func,
  onDismiss: PropTypes.func,
  visible: PropTypes.bool,
  booking: PropTypes.object
};

export default RefundForm;
