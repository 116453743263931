import React, { Component } from 'react';

// Components
import { InputField, TextAreaField, SelectField } from '../../../shared/App/components';
import FroalaField from '../../../shared/App/components/FroalaField';

// Utils
import { slugify } from '../../../utils/slugify';

const LOCALES = ['en', 'vi-VN', 'ko-KR'];

class InformationContainer extends Component {
  handleTitleBlur = (e, values, setFieldValue) => {
    // generate slug if does not exist
    if (!values.slug) {
      setFieldValue(
        'slug',
        slugify(e.target.value, {
          lower: true
        })
      );
      setFieldValue('page_title', e.target.value);
    }
  };

  render() {
    const { values, setFieldValue } = this.props;

    return (
      <div className="">
        <div className="">
          <SelectField name="locale" label="Locale" options={LOCALES.map(level => ({ label: level, value: level }))} />
          {/* TODO: apply search place */}
          <InputField name="place" label="Place ID" />

          <InputField
            sub="*"
            name="title"
            label="Title"
            onBlur={e => this.handleTitleBlur(e, values, setFieldValue)}
            placeholder="Display title for the landing page, for example: Things to do in Ho Chi Minh page. It will be used for page title, seo title..."
          />
          <InputField name="slug" label="Slug" />
          <InputField name="page_title" label="Page title" />
          <InputField name="meta_title" label="Meta Title" />
          <FroalaField name="introduction" label="Introduction" />
          <FroalaField name="description" label="Description" />
          <FroalaField name="expectation" label="Expectation" />

          <TextAreaField id="meta_description" name="meta_description" label="Meta SEO Description" rows={4} />
        </div>
      </div>
    );
  }
}

export default InformationContainer;
