import { productAPI, cmsAPI, FULFILLED } from '../../utils/axiosAPI';

export const FETCH_EXPERIENCES = 'FETCH_EXPERIENCES';
export const RESET_EXPERIENCES = 'RESET_EXPERIENCES';
export const FETCH_EXPERIENCE_DETAIL = 'FETCH_EXPERIENCE_DETAIL';
export const CREATE_EXPERIENCE = 'CREATE_EXPERIENCE';
export const UPDATE_EXPERIENCE = 'UPDATE_EXPERIENCE';
export const DELETE_EXPERIENCE = 'DELETE_EXPERIENCE';
export const FETCH_EXPERIENCE_VARIANTS = 'FETCH_EXPERIENCE_VARIANTS';
export const FETCH_EXPERIENCE_STORIES = 'FETCH_EXPERIENCE_STORIES';

export const fetchExperiences = ({ params }) => {
  return dispatch =>
    productAPI(dispatch, {
      method: 'get',
      url: '/product/admin/v1/products',
      params,
      type: FETCH_EXPERIENCES,
    });
};

export const resetExperiences = () => ({
  type: RESET_EXPERIENCES,
});

export const fetchExperienceDetail = ({ id }) => dispatch =>
  productAPI(dispatch, {
    method: 'get',
    url: `/product/admin/v1/products/${id}`,
    type: FETCH_EXPERIENCE_DETAIL,
  });

export const updateExperience = ({ id, data }) => {
  return dispatch =>
    productAPI(dispatch, {
      method: 'patch',
      url: `/product/admin/v1/products/${id}`,
      data,
      type: UPDATE_EXPERIENCE,
    });
};

export const fetchExperienceStories = ({ id }) => {
  return dispatch =>
    productAPI(dispatch, {
      method: 'get',
      url: `/product/admin/v1/products/${id}/stories`,
      type: FETCH_EXPERIENCE_STORIES,
    });
};

export const ADD_EXPERIENCE_STORY = 'ADD_EXPERIENCE_STORY';
export const addExperienceStory = ({ id, data }) => {
  return dispatch =>
    productAPI(dispatch, {
      method: 'post',
      url: `/product/admin/v1/products/${id}/stories`,
      data,
      type: ADD_EXPERIENCE_STORY,
    });
};

export const DELETE_EXPERIENCE_STORY = 'DELETE_EXPERIENCE_STORY';
export const deleteStoryPhoto = ({ expId, id }) => {
  return dispatch =>
    productAPI(dispatch, {
      method: 'delete',
      url: `/product/admin/v1/products/${expId}/stories/${id}`,
      type: DELETE_EXPERIENCE_STORY,
      meta: { id },
    });
};

export const FETCH_EXPERIENCE_STORIES_OPTIONS =
  'FETCH_EXPERIENCE_STORIES_OPTIONS';
export const fetchExperienceStoriesOptions = ({ id }) => {
  return dispatch =>
    productAPI(dispatch, {
      method: 'options',
      url: `/product/admin/v1/products/${id}/stories`,
      type: FETCH_EXPERIENCE_STORIES_OPTIONS,
    });
};

export const FETCH_EXPERIENCE_FAQS = 'FETCH_EXPERIENCE_FAQS';
export const fetchExperienceFAQ = (id, params) => {
  return dispatch =>
    productAPI(dispatch, {
      method: 'get',
      url: `/product/admin/v1/products/${id}/faqs`,
      params,
      type: FETCH_EXPERIENCE_FAQS,
    });
};

export const ADD_EXPERIENCE_FAQ = 'ADD_EXPERIENCE_FAQ';
export const addExperienceFAQ = ({ expId, data }) => {
  return dispatch =>
    productAPI(dispatch, {
      method: 'post',
      url: `/product/admin/v1/products/${expId}/faqs`,
      data,
      type: ADD_EXPERIENCE_FAQ,
    });
};

export const UPDATE_EXPERIENCE_FAQ = 'UPDATE_EXPERIENCE_FAQ';
export const updateExperienceFAQ = ({ expId, faqId, data }) => {
  return dispatch =>
    productAPI(dispatch, {
      method: 'patch',
      url: `/product/admin/v1/products/${expId}/faqs/${faqId}`,
      data,
      type: UPDATE_EXPERIENCE_FAQ,
    });
};

export const DELETE_EXPERIENCE_FAQ = 'DELETE_EXPERIENCE_FAQ';
export const deleteExperienceFAQ = ({ expId, faqId, meta }) => {
  return dispatch =>
    productAPI(dispatch, {
      method: 'delete',
      url: `/product/admin/v1/products/${expId}/faqs/${faqId}`,
      type: DELETE_EXPERIENCE_FAQ,
      meta,
    });
};

export const SEARCH_EXPERIENCE_USER = 'SEARCH_EXPERIENCE_USER';
export const searchLocalGuide = ({ params }) => {
  return dispatch =>
    productAPI(dispatch, {
      method: 'get',
      url: '/product/admin/v1/users',
      params,
      type: SEARCH_EXPERIENCE_USER,
    });
};

export const SEARCH_EXPERIENCE_LOCATION = 'SEARCH_EXPERIENCE_LOCATION';
export const searchExperienceLocation = ({ params }) => {
  return dispatch =>
    cmsAPI(dispatch, {
      method: 'get',
      url: '/cms/admin/v1/locations',
      params,
      type: SEARCH_EXPERIENCE_LOCATION,
    });
};

export const ADD_EXPERIENCE = 'ADD_EXPERIENCE';
export const addExperience = ({ data }) => {
  return dispatch =>
    productAPI(dispatch, {
      method: 'post',
      url: '/product/admin/v1/products',
      data,
      type: ADD_EXPERIENCE,
    });
};

export const FETCH_EXPERIENCE_FIELDS = 'FETCH_EXPERIENCE_FIELDS';
export const fetchExperienceFields = () => {
  return dispatch =>
    productAPI(dispatch, {
      method: 'options',
      url: '/product/admin/v1/products',
      type: FETCH_EXPERIENCE_FIELDS,
    });
};

export const FETCH_DURATION_TYPES = 'FETCH_DURATION_TYPES';
// TODO: Replace this with real API
export const fetchDurationTypes = () => ({
  type: `${FETCH_DURATION_TYPES}${FULFILLED}`,
  data: [
    { value: 3, label: 'Less than 3 hours' },
    { value: 5, label: '3 - 5 hours' },
    { value: 9, label: '6 - 9 hours' },
    { value: 12, label: '10 - 12 hours' },
    { value: 24, label: '1 day' },
    { value: 48, label: '2 - 3 days' },
    { value: 72, label: 'More than 3 days' },
  ],
});

export const DUPLICATE_EXPERIENCE = 'DUPLICATE_EXPERIENCE';
export const duplicateExperience = id => {
  return dispatch =>
    productAPI(dispatch, {
      method: 'post',
      url: `/product/admin/v1/products/${id}/duplicate`,
      type: DUPLICATE_EXPERIENCE,
    });
};

export const FETCH_FAQ_TEMPLATES = 'FETCH_FAQ_TEMPLATES';
export const fetchFAQTemplates = (id, params) => {
  return dispatch =>
    productAPI(dispatch, {
      method: 'get',
      url: `/product/admin/v1/products/${id}/faqs`,
      params,
      type: FETCH_FAQ_TEMPLATES,
    });
};

export const FETCH_ITINERARIES = 'FETCH_ITINERARIES';
export const fetchItineraries = ({ id }) => {
  return dispatch =>
    productAPI(dispatch, {
      method: 'get',
      url: `/product/admin/v1/products/${id}/itinerary-photos`,
      type: FETCH_ITINERARIES,
    });
};

export const ADD_ITINERARY_PHOTO = 'ADD_ITINERARY_PHOTO';
export const addExperienceItineraryPhoto = ({ id, data }) => {
  return dispatch =>
    productAPI(dispatch, {
      method: 'post',
      url: `/product/admin/v1/products/${id}/itinerary-photos`,
      data,
      meta: { id },
      type: ADD_ITINERARY_PHOTO,
    });
};

export const EDIT_ITINERARY_PHOTO = 'EDIT_ITINERARY_PHOTO';
export const editExperienceItineraryPhoto = ({ id, itinerary_id, data }) => {
  return dispatch =>
    productAPI(dispatch, {
      method: 'patch',
      url: `/product/admin/v1/products/${id}/itinerary-photos/${itinerary_id}`,
      data,
      meta: { id },
      type: EDIT_ITINERARY_PHOTO,
    });
};

export const DELETE_ITINERARY_PHOTO = 'DELETE_ITINERARY_PHOTO';
export const deleteExperienceItineraryPhoto = ({ id, itinerary_photo_id }) => {
  return dispatch =>
    productAPI(dispatch, {
      method: 'delete',
      url: `/product/admin/v1/products/${id}/itinerary-photos/${itinerary_photo_id}`,
      type: DELETE_ITINERARY_PHOTO,
      meta: { id, itinerary_photo_id },
    });
};
