import Pica from 'pica';

import configApp from '../config/configApp';

const LIMIT_DIMENSION = configApp.limitDimension;

export const imageResizer = (image, file, callback) => {
  const pica = new Pica();

  const canvas = document.createElement('canvas');
  const aspectRatio = image.width / image.height;

  // check if width or height of image is larger than limit
  if (image.width > LIMIT_DIMENSION || image.height > LIMIT_DIMENSION) {
    if (aspectRatio > 1) {
      canvas.width = LIMIT_DIMENSION;
      canvas.height = LIMIT_DIMENSION / aspectRatio;
    } else {
      canvas.height = LIMIT_DIMENSION;
      canvas.width = LIMIT_DIMENSION * aspectRatio;
    }
  } else {
    canvas.height = image.height;
    canvas.width = image.width;
  }

  const { name, type, lastModified } = file;
  pica.resize(image, canvas)
    .then(result => pica.toBlob(result, type, 0.6))
    .then(blob => {
      const resized = new File([blob], name, { type, lastModified });
      callback(resized);
    });
};

export const resizeImage = (file, callback) => {
  const reader = new FileReader();
  reader.readAsDataURL(file);

  reader.onload = () => {
    const image = new Image;
    image.src = reader.result.toString();

    // Resize the image
    image.onload = () => {
      imageResizer(image, file, callback);
    };
  };
};
