// utils
import { productAPI } from '../../../../utils/axiosAPI';

export const FETCH_UNITS = 'FETCH_UNITS';
export const fetchUnits = ({ experienceId, variantId }) => dispatch =>
  productAPI(dispatch, {
    method: 'get',
    url: `/product/admin/v1/products/${experienceId}/variants/${variantId}/units`,
    type: FETCH_UNITS,
    meta: { variantId },
  });

export const FETCH_UNIT = 'FETCH_UNIT';
export const fetchUnit = ({ experienceId, variantId, unitId }) => dispatch =>
  productAPI(dispatch, {
    method: 'get',
    url: `/product/admin/v1/products/${experienceId}/variants/${variantId}/units/${unitId}`,
    type: FETCH_UNIT,
    meta: { variantId, unitId },
  });

export const CREATE_UNIT = 'CREATE_UNIT';
export const createUnit = ({ experienceId, variantId, data }) => dispatch =>
  productAPI(dispatch, {
    method: 'post',
    url: `/product/admin/v1/products/${experienceId}/variants/${variantId}/units`,
    type: CREATE_UNIT,
    meta: { variantId },
    data,
  });

export const UPDATE_UNIT = 'UPDATE_UNIT';
export const updateUnit = ({
  experienceId,
  variantId,
  unitId,
  data,
}) => dispatch =>
  productAPI(dispatch, {
    method: 'patch',
    url: `/product/admin/v1/products/${experienceId}/variants/${variantId}/units/${unitId}`,
    type: UPDATE_UNIT,
    meta: { variantId, unitId },
    data,
  });

export const DELETE_UNIT = 'DELETE_UNIT';
export const deleteUnit = ({ experienceId, variantId, unitId }) => dispatch =>
  productAPI(dispatch, {
    method: 'delete',
    url: `/product/admin/v1/products/${experienceId}/variants/${variantId}/units/${unitId}`,
    type: DELETE_UNIT,
    meta: { variantId, unitId },
  });

export const FETCH_FIXED_PRICES = 'FETCH_FIXED_PRICES';
export const fetchFixedPrices = ({ productId, variantId, unitId }) => {
  return dispatch =>
    productAPI(dispatch, {
      method: 'get',
      url: `/product/admin/v1/products/${productId}/variants/${variantId}/units/${unitId}/prices`,
      type: FETCH_FIXED_PRICES,
    });
};

export const CREATE_FIXED_PRICE = 'CREATE_FIXED_PRICE';
export const createFixedPrice = (productId, variantId, unitId, data) => {
  return dispatch =>
    productAPI(dispatch, {
      method: 'post',
      url: `/product/admin/v1/products/${productId}/variants/${variantId}/units/${unitId}/prices`,
      type: CREATE_FIXED_PRICE,
      data,
    });
};

export const UPDATE_FIXED_PRICE = 'UPDATE_FIXED_PRICE';
export const updateFixedPrice = (
  productId,
  variantId,
  unitId,
  fixedPriceId,
  data
) => {
  return dispatch =>
    productAPI(dispatch, {
      method: 'patch',
      url: `/product/admin/v1/products/${productId}/variants/${variantId}/units/${unitId}/prices/${fixedPriceId}`,
      type: UPDATE_FIXED_PRICE,
      data,
    });
};

export const DELETE_FIXED_PRICE = 'DELETE_NET_FIXED_PRICE';
export const deleteFixedPrice = (
  productId,
  variantId,
  unitId,
  fixedPriceId
) => {
  return dispatch =>
    productAPI(dispatch, {
      method: 'delete',
      url: `/product/admin/v1/products/${productId}/variants/${variantId}/units/${unitId}/prices/${fixedPriceId}`,
      type: DELETE_FIXED_PRICE,
    });
};

export const FETCH_NET_FIXED_PRICES = 'FETCH_NET_FIXED_PRICES';
export const fetchNetFixedPrices = ({ productId, variantId, unitId }) => {
  return dispatch =>
    productAPI(dispatch, {
      method: 'get',
      url: `/product/admin/v1/products/${productId}/variants/${variantId}/units/${unitId}/supplier-prices`,
      type: FETCH_NET_FIXED_PRICES,
    });
};

export const CREATE_NET_FIXED_PRICE = 'CREATE_NET_FIXED_PRICE';
export const createNetFixedPrice = (productId, variantId, unitId, data) => {
  return dispatch =>
    productAPI(dispatch, {
      method: 'post',
      url: `/product/admin/v1/products/${productId}/variants/${variantId}/units/${unitId}/supplier-prices`,
      type: CREATE_NET_FIXED_PRICE,
      data,
    });
};

export const UPDATE_NET_FIXED_PRICE = 'UPDATE_NET_FIXED_PRICE';
export const updateNetFixedPrice = (
  productId,
  variantId,
  unitId,
  fixedPriceId,
  data
) => {
  return dispatch =>
    productAPI(dispatch, {
      method: 'patch',
      url: `/product/admin/v1/products/${productId}/variants/${variantId}/units/${unitId}/supplier-prices/${fixedPriceId}`,
      type: UPDATE_NET_FIXED_PRICE,
      data,
    });
};

export const DELETE_NET_FIXED_PRICE = 'DELETE_NET_FIXED_PRICE';
export const deleteNetFixedPrice = (
  productId,
  variantId,
  unitId,
  fixedPriceId
) => {
  return dispatch =>
    productAPI(dispatch, {
      method: 'delete',
      url: `/product/admin/v1/products/${productId}/variants/${variantId}/units/${unitId}/supplier-prices/${fixedPriceId}`,
      type: DELETE_NET_FIXED_PRICE,
    });
};
