// Utils
import { getFilter, getSearch } from '../../utils/filter';

// Constants
import configApp from '../../config/configApp';

const stateSubOptions = [
  { value: configApp.reviewState.INIT, label: 'INIT' },
  { value: configApp.reviewState.PENDING, label: 'PENDING' },
  { value: configApp.reviewState.APPROVED, label: 'APPROVED' },
  { value: configApp.reviewState.REJECTED, label: 'REJECTED' },
];
export const keyOptions = [
  { value: 'state', label: 'Status', type: 'select', subOptions: stateSubOptions },
];

// Can override if you want
export const getFilterData = (queryParams, options = []) => {
  return getFilter(queryParams, [...options, ...keyOptions]);
};

export const getSearchData = queryParams => {
  return getSearch(queryParams);
};
