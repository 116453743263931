import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { SaveOutlined } from '@ant-design/icons';
import { Button } from 'antd';

// Components
import { Form, Formik, Field } from 'formik';
import {
  InputField,
  SelectField,
  ArrayObjectField,
  AsyncSelectField,
} from '../../../shared/App/components';
import { searchProducts } from '../collectionActions';

// Utils
import { validate } from '../collectionValidators';

// Constants
const STATUS = ['publish', 'pending'];
const LOCALES = ['en', 'vi-VN', 'ko-KR'];
const PAGES = [
  { value: 'city', label: 'City Page' },
  { value: 'country', label: 'Country Page' },
  { value: 'homepage', label: 'Home Page' },
];

class CollectionEditForm extends Component {
  render() {
    // Add editMode
    const { editMode, initialValues, handleSubmit } = this.props;

    return (
      <div className="content">
        <Formik
          initialValues={{ ...initialValues, editMode }}
          onSubmit={handleSubmit}
          enableReinitialize
          isInitialValid
          validate={validate}
        >
          {({ isSubmitting, values }) => {
            return (
              <Form>
                <div className='col-sm-12'>
                  <div className="col-sm-8">
                    <div className="">
                      <div className="">
                        <InputField name="code" label="Code" />
                        <InputField
                          sub="*"
                          name="title"
                          label="Title"
                          placeholder="Collection title"
                        />
                        <InputField type="number" name="limit" label="Limit" />
                        <SelectField
                          type="page"
                          name="page"
                          label="Landing Page"
                          options={PAGES}
                        />
                        <InputField
                          type="place_code"
                          name="place_code"
                          label="Location code (For showing only in this particular location)"
                          placeholder="e.i. ho-chi-minh-city"
                        />
                        <ArrayObjectField
                          label="Filters (loading products by filters automatically)"
                          name="filters"
                          arrayData={values.filters}
                          renderObject={(_, index) => (
                            <div className="flex flex-row">
                              <div className="flex flex-col gap-y-4 col-xs-12">
                                <Field
                                  name={`filters.${index}.field`}
                                  className="form-control"
                                  placeholder="Key"
                                />
                              </div>
                              <div className="flex flex-col gap-y-4 col-xs-12">
                                <Field
                                  name={`filters.${index}.operator`}
                                  className="form-control"
                                  placeholder="Key"
                                />
                              </div>
                              <div className="flex flex-col gap-y-4 col-xs-12">
                                <Field
                                  name={`filters.${index}.value`}
                                  className="form-control"
                                  placeholder="Value"
                                />
                              </div>
                            </div>
                          )}
                        />
                        <AsyncSelectField
                          name="list_id"
                          label="Sticky products"
                          isMulti={true}
                          loadOptions={(inputValue, callback) => {
                            let params = { search: inputValue };
                            const [language, country] = values.locale.split('-');
                            if (language) params.language = language;
                            if (country) params.country = country;
                            this.props
                              .searchProductsAction({ params })
                              .then(response => {
                                const data = response.data.results.map(item => {
                                  return {
                                    label: `${item.title} (${item.id})`,
                                    value: item.id,
                                  };
                                });
                                callback(data);
                              });
                          }}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-sm-4">
                    <div className="box">
                      <div className="box-body">
                        <SelectField
                          name="locale"
                          label="Locale"
                          options={LOCALES.map(level => ({
                            label: level,
                            value: level,
                          }))}
                        />
                        <SelectField
                          name="is_active"
                          label="Status"
                          options={STATUS.map(status => ({
                            label: status,
                            value: status,
                          }))}
                        />
                        <InputField type="number" name="sort" label="Sort" />
                        <Button
                          className="mt-4"
                          type="primary"
                          htmlType="submit"
                          disabled={isSubmitting}
                        >
                          <SaveOutlined /> {editMode ? 'Save changes' : 'Create'}
                        </Button>
                      </div>
                    </div>
                  </div>
                </div>

              </Form>
            );
          }}
        </Formik>
      </div>
    );
  }
}

CollectionEditForm.propTypes = {
  handleSubmit: PropTypes.func,
  pristine: PropTypes.bool,
  reset: PropTypes.func,
  submitting: PropTypes.bool,
  error: PropTypes.string,
  isSuccess: PropTypes.bool,
  isError: PropTypes.bool,
  submitSucceeded: PropTypes.bool,
  dispatch: PropTypes.func,
  editMode: PropTypes.bool,
};

CollectionEditForm.defaultProps = {
  editMode: false,
};

export default connect(null, {
  searchProductsAction: searchProducts,
})(CollectionEditForm);
