import { bookingAPI, productAPI } from '../../utils/axiosAPI';

export const FETCH_BOOKING_ITEMS = 'FETCH_BOOKING_ITEMS';
export const RESET_BOOKING_ITEMS = 'RESET_BOOKING_ITEMS';
export const FETCH_BOOKING_ITEM_DETAIL = 'FETCH_BOOKING_ITEM_DETAIL';
export const CREATE_BOOKING_ITEM = 'CREATE_BOOKING_ITEM';
export const UPDATE_BOOKING_ITEM = 'UPDATE_BOOKING_ITEM';
export const DELETE_BOOKING_ITEM = 'DELETE_BOOKING_ITEM';
export const CREATE_NEW_TRIP_ITEM = 'CREATE_NEW_TRIP_ITEM';
export const DELETE_TRIP_ITEM = 'DELETE_TRIP_ITEM';

export const fetchBookingItems = ({ params }) => {
  return dispatch =>
    bookingAPI(dispatch, {
      method: 'get',
      url: '/booking/admin/v1/booking-items',
      params,
      type: FETCH_BOOKING_ITEMS,
    });
};

export const resetBookingItems = () => ({
  type: RESET_BOOKING_ITEMS,
});

export const fetchBookingItemDetail = ({ bookingId, itemId }) => dispatch =>
  bookingAPI(dispatch, {
    method: 'get',
    url: `/booking/admin/v1/bookings/${bookingId}/items/${itemId}`,
    type: FETCH_BOOKING_ITEM_DETAIL,
  });

export const updateBookingItem = ({ bookingId, itemId, data }) => dispatch =>
  bookingAPI(dispatch, {
    method: 'patch',
    url: `/booking/admin/v1/bookings/${bookingId}/items/${itemId}`,
    data,
    type: UPDATE_BOOKING_ITEM,
  });

export const UPDATE_SUPPLIER_PRICES = 'UPDATE_SUPPLIER_PRICES';
export const updateSupplierPrices = ({ bookingId, itemId, data }) => dispatch =>
  bookingAPI(dispatch, {
    method: 'put',
    url: `/booking/admin/v1/bookings/${bookingId}/items/${itemId}/supplier-prices`,
    data,
    type: UPDATE_SUPPLIER_PRICES,
  });

export const sendBookingItemNotification = ({ itemId, data }) => dispatch =>
  bookingAPI(dispatch, {
    method: 'put',
    url: `/booking/admin/v1/booking-items/${itemId}/notify_localguide`,
    data,
    type: '',
  });

export const assignBookingItem = ({ itemId, data }) => dispatch =>
  bookingAPI(dispatch, {
    method: 'put',
    url: `/booking/admin/v1/booking-items/${itemId}/assign`,
    data,
    type: UPDATE_BOOKING_ITEM,
  });

export const unassignBookingItem = ({ itemId }) => dispatch =>
  bookingAPI(dispatch, {
    method: 'put',
    url: `/booking/admin/v1/booking-items/${itemId}/unassign`,
    type: UPDATE_BOOKING_ITEM,
  });

export const cancelBookingItem = ({ itemId }) => dispatch =>
  bookingAPI(dispatch, {
    method: 'put',
    url: `/booking/admin/v1/booking-items/${itemId}/cancel`,
    type: UPDATE_BOOKING_ITEM,
  });

export const fetchExperienceDetail = ({ id }) => dispatch =>
  productAPI(dispatch, {
    method: 'get',
    url: `/product/admin/v1/products/${id}`,
    type: '',
  });

export const fetchVariantDetail = ({ id, ogId }) => dispatch =>
  productAPI(dispatch, {
    method: 'get',
    url: `/product/admin/v1/products/${id}/variants/${ogId}`,
    type: '',
  });
