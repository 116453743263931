import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Button } from '../../shared/App/components';

import QuickFactItemEditor from './QuickFactItemEditor';
import { getQuickFact } from './reducers';
import { updateQuickFact, deleteQuickFact } from './actions';

const mode = {
  EDIT: 'EDIT',
  DISPLAY: 'DISPLAY'
};

class QuickFactItem extends React.Component {
  state = {
    mode: mode.DISPLAY
  };

  toggleDisplayMode = () => this.setState({ mode: mode.DISPLAY });

  toggleEditMode = () => this.setState({ mode: mode.EDIT });

  buildInitialValues = () => {
    const { label, option, description } = this.props.quickFact;
    return {
      label,
      option,
      description
    };
  };

  handleSubmit = values => {
    const { experienceId, quickFact } = this.props;
    this.props
      .updateQuickFact({
        experienceId,
        factId: quickFact.id,
        data: values
      })
      .then(() => this.toggleDisplayMode())
      .catch(() => this.toggleDisplayMode());
  };

  handleDelete = () => {
    const { experienceId, quickFact } = this.props;
    this.props.deleteQuickFact({ experienceId, factId: quickFact.id });
  };

  render() {
    if (this.state.mode === mode.DISPLAY) {
      const { quickFact } = this.props;
      return (
        <div
          className="row box-comments"
          style={{ padding: '8px', borderBottom: '1px solid gainsboro' }}
        >
          <div className="col-sm-8">
            <div>
              <strong>{quickFact.label}: </strong>
              {quickFact.option || ''}
            </div>
            <div>{quickFact.description}</div>
          </div>
          <div className="col-sm-4 text-right">
            <Button
              type="button"
              onClick={this.toggleEditMode}
              style={{ marginRight: '7px' }}
            >
              <i className="fa fa-edit" />
            </Button>
            <Button
              displayType="danger"
              type="button"
              onClick={this.handleDelete}
            >
              <i className="fa fa-trash-o" />
            </Button>
          </div>
        </div>
      );
    }

    return (
      <div style={{ marginTop: '4px' }}>
        <QuickFactItemEditor
          onSubmit={this.handleSubmit}
          onCancel={this.toggleDisplayMode}
          initialValues={this.buildInitialValues()}
        />
      </div>
    );
  }
}

QuickFactItem.propTypes = {
  experienceId: PropTypes.number.isRequired,
  id: PropTypes.number.isRequired
};

const mapStateToProps = (state, ownProps) => ({
  quickFact: getQuickFact(state, ownProps.id)
});

const mapDispatchToProps = {
  updateQuickFact,
  deleteQuickFact
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(QuickFactItem);
