import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';

// Components
import PaymentEditForm from '../components/PaymentEditForm';
import FormStatus from '../../../shared/App/components/FormStatus';
import Breadcrumb from '../../../shared/App/components/Breadcrumb';

// Actions
import { createPayment } from '../paymentActions';

// Utils
import { withOperation } from '../../../utils/OperationalComponent';
import { transformPaymentDetail } from '../paymentUtils';
import { withQuery } from '../../../utils/QueryBasedComponent';

class paymentCreatePage extends Component {
  handleSubmit = values => {
    const { executeActions, createPaymentAction } = this.props;
    // transform data
    const newPayment = transformPaymentDetail(values);

    executeActions({
      actions: [() => createPaymentAction({ params: newPayment })],
      onSuccess: props => {
        this.props.history.push('/payments/methods');
      },
      successMsg: 'Create successfully',
      failureMsg: 'Create fail',
    });
  };

  render() {
    const { error, success } = this.props;
    const errorData = error ? error.data || 'Something went wrong.' : '';
    return (
      <Fragment>
        <section className="content-header">
          <div className="flex flex-row" style={{ marginTop: '-15px', marginBottom: '15px' }}>
            <Breadcrumb
              history={[
                { title: 'InspitripHQ', url: '/' },
                { title: 'Payments', url: '/payment' },
                { title: 'Create Payment', url: '' },
              ]}
            />
          </div>

          <div className="nav-tabs-custom">
            <FormStatus formState={{ error: errorData, success }} />
          </div>

          <div className="flex flex-row">
            <div className="flex flex-col gap-y-7">
              <h1 style={{ fontSize: '24px', marginTop: 0 }}>Create Payment</h1>
            </div>
          </div>
        </section>
        <div className="content body">
          <div className="">
            <PaymentEditForm
              initialValues={{ state: 'active' }}
              handleSubmit={this.handleSubmit}
            />
          </div>
        </div>
      </Fragment>
    );
  }
}

export default connect(null, {
  createPaymentAction: createPayment,
})(withOperation(withQuery(paymentCreatePage)));
