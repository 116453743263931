import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';

// Components
import LocationForm from '../components/LocationForm';
import Breadcrumb from '../../../shared/App/components/Breadcrumb';
import FormStatus from '../../../shared/App/components/FormStatus';

// Actions
import { createCity } from '../locationActions';

// Utils
import { withOperation } from '../../../utils/OperationalComponent';
import { withQuery } from '../../../utils/QueryBasedComponent';
import { transformPlaceDetail } from '../locationUtils';

class LocationCreatePage extends Component {
  handleSubmit = values => {
    const { executeActions, match, createCityAction } = this.props;
    const data = transformPlaceDetail(values);

    executeActions({
      actions: [() => createCityAction({ data })],
      onSuccess: () => this.props.history.push('/locations'),
      successMsg: 'Create successfully',
    });
  };

  render() {
    const { error, success } = this.props;
    return (
      <Fragment>
        <Helmet>
          <title>Create Locations - Inspitrip HQ</title>
        </Helmet>
        <section className="content-header">
          <div className="flex flex-row" style={{ marginTop: '-15px', marginBottom: '15px' }}>
            <Breadcrumb
              history={[
                { title: 'InspitripHQ', url: '/' },
                { title: 'Locations', url: '/locations' },
                { title: 'Create location', url: '' },
              ]}
            />
          </div>

          <div className="nav-tabs-custom">
            <FormStatus formState={{ error, success }} />
          </div>

          <div className="flex flex-row">
            <div className="flex flex-col gap-y-7">
              <h1 style={{ fontSize: '24px', marginTop: 0 }}>
                Create location
              </h1>
            </div>
          </div>
        </section>
        <div className="content body">
          <div className="">
            <LocationForm
              initialValues={{ level: 'city' }}
              handleSubmit={this.handleSubmit}
            />
          </div>
        </div>
      </Fragment>
    );
  }
}

export default connect(null, {
  createCityAction: createCity,
})(withOperation(withQuery(LocationCreatePage)));
