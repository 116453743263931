import React, { Component, Fragment } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';

// Components
import { Listings, Modal } from '../../../shared/App/components';
import FilterForm from '../../../shared/Form/FilterForm';
import SearchForm from '../../../shared/Form/SearchForm';
import Breadcrumb from '../../../shared/App/components/Breadcrumb';
// Utils
import { cityColumns } from '../locationUtils';
import { parseQueryString } from '../../../utils/urlQuery';

import { getSearchData, getFilterData, keyOptions } from '../locationFilters';
import { languageOptions } from '../../../utils/filter';

// Actions
import { fetchCities, deletePlace } from '../locationActions';

// Seletector
import { getCities, getFetchStatus } from '../locationReducers';

// Constants
import configService from '../../../config/configService';
import { withOperation } from '../../../utils/OperationalComponent';
import { withQuery } from '../../../utils/QueryBasedComponent';

const sortDirection = { asc: '', desc: '-' };

class LocationListPage extends Component {
  state = {
    showedDeleteModalConfirm: false,
    deletedId: null,
    showModalFilter: false,
    sortParams: { sortBy: 'modified', sortDirection: 'desc' },
  };

  componentDidMount() {
    const {
      setQueryCallback,
      cities,
      location: { queryString },
    } = this.props;

    setQueryCallback(this.handleFetchCities);

    if (cities && cities.length === 0) {
      this.handleFetchCities(parseQueryString(queryString));
    }
  }

  handleFetchCities = params => {
    const { fetchCitiesAction } = this.props;
    const { page = 1, ordering = '-modified' } = params || {};
    if (this.cancel) this.cancel();
    this.cancel = fetchCitiesAction({ params: { page, ordering } }).cancel;
  };

  handlePageChange = page => {
    this.props.pushParams({ page });
  };

  handleDeletePlace = () => {
    const { deletedId } = this.state;
    this.props.deletePlaceAction({ id: deletedId }).then(res => {
      this.setState({ showedDeleteModalConfirm: false }, () => {
        this.handleFetchCities(1);
      });
    });
  };

  // actions in Listing
  actions = [
    {
      title: <i className="fa fa-external-link" />,
      callback: ({ id }) => {
        this.props.history.push(`/locations/${id}`);
      },
      render: () => <i className="fa fa-external-link" />,
      type: 'primary',
    },
    {
      title: 'Delete',
      callback: ({ id }) => {
        // Show confirm popup
        this.setState({ showedDeleteModalConfirm: true, deletedId: id });
      },
      render: () => <i className="fa fa-remove" />,
      type: 'danger',
    },
  ];

  handleFilterChange = values => {
    let params = {};
    values.filter.map(
      (item, index) => (params[item.key + item.type] = item.value)
    );
    params.search = this.props.queryParams.search;
    this.props.replaceParams(params);

    this.handleCloseModal();
  };

  handleSearchChange = values => {
    this.props.pushParams({ search: values.search });
  };

  handleOpenModalFilter = () => {
    this.setState({ showModalFilter: true });
  };

  handleCloseModal = () => {
    this.setState({ showModalFilter: false });
  };

  handleSort = values => {
    this.props.pushParams({
      ordering: `${sortDirection[values.sortDirection]}${values.sortBy}`,
    });
    this.setState({ sortParams: values });
  };

  render() {
    // Data
    const { cities = [], fetchStatus, queryParams, fields = {} } = this.props;
    const { count, isFetching } = fetchStatus || {
      count: 0,
      isFetching: false,
    };
    const { page } = queryParams || { page: 1 };
    const { showedDeleteModalConfirm } = this.state;
    const { sortParams } = this.state;

    // Add options to filter
    const languageOpt = languageOptions();
    const otherOptions = [];
    otherOptions.push(languageOpt);

    const searchData = getSearchData(this.props.queryParams);
    const filterData = getFilterData(this.props.queryParams, otherOptions);

    return (
      <Fragment>
        {showedDeleteModalConfirm && (
          <Modal
            title="Are you sure to delete this Place?"
            confirmText="Yes, sure"
            dismissText="Cancel"
            onConfirm={() => {
              this.handleDeletePlace();
            }}
            onDismiss={() => {
              this.setState({ showedDeleteModalConfirm: false });
            }}
            visible={showedDeleteModalConfirm}
          >
            <p>This would delete the place show on home page.</p>
          </Modal>
        )}
        <Helmet>
          <title>All Locations - Inspitrip HQ</title>
        </Helmet>
        <section className="content-header">
          <div className="flex flex-row" style={{ marginTop: '-15px', marginBottom: '15px' }}>
            <Breadcrumb
              history={[
                { title: 'InspitripHQ', url: '/' },
                { title: 'Locations', url: '/' },
              ]}
            />
          </div>
          <div className="flex flex-row">
            <div className="flex flex-col gap-y-7">
              <h1 style={{ fontSize: '24px', marginTop: 0 }}>Locations</h1>
            </div>
            <div className="flex flex-col gap-y-2	  text-right" style={{ marginLeft: 'auto' }}>
              <Link to="/locations/new" className="btn btn-primary">
                <i className="fa fa-plus" />
                {' Add'}
              </Link>
            </div>
          </div>
        </section>
        <section className="content-header">
          <div className="flex flex-row">
            <div className="col-sm-12 pl-0 pr-0">
              <SearchForm
                onSubmit={this.handleSearchChange}
                searchContent={searchData}
                filterData={filterData}
                handleOpenModalFilter={this.handleOpenModalFilter}
                placeHolderContent="Type search text"
              />
            </div>
          </div>
        </section>
        <div className="content body">
          <section style={{ marginTop: '15px' }}>
            <Listings
              listingsTitle="Location List"
              data={cities}
              columns={cityColumns()}
              actions={this.actions}
              onSortHeader={this.handleSort}
              page={parseInt(page || 1, 10)}
              dataCount={count || 0}
              itemsPerPage={configService.itemsCountPerPage}
              onPageChange={this.handlePageChange}
              pageRange={3}
              isFetching={isFetching}
              sortParams={sortParams}
              noHeader
            />
          </section>
        </div>
        <FilterForm
          onSubmit={this.handleFilterChange}
          onDismiss={this.handleCloseModal}
          visible={this.state.showModalFilter}
          arrayData={filterData}
          keyOptions={[...keyOptions, ...otherOptions]}
        />
      </Fragment>
    );
  }
}

const mapStateToProps = state => ({
  fetchStatus: getFetchStatus(state),
  cities: getCities(state),
});

export default connect(mapStateToProps, {
  fetchCitiesAction: fetchCities,
  deletePlaceAction: deletePlace,
})(withOperation(withQuery(LocationListPage)));
