import { userAPI } from '../../utils/axiosAPI';

// Export Constants
export const AUTH_REQUEST = 'AUTH_REQUEST';
export const AUTH_UPDATE = 'AUTH_UPDATE';
export const AUTH_RESET = 'AUTH_RESET';
export const AUTH_LOAD = 'AUTH_LOAD';

const loginURL = () => {
  return 'userbase/app/v1/auth';
};

// Export Actions
export function login(parameters) {
  return dispatch => {
    return userAPI(dispatch, {
      method: 'post',
      url: loginURL(),
      data: parameters,
      type: AUTH_REQUEST,
    });
  };
}

export function update(token, user) {
  return {
    type: AUTH_UPDATE,
    token,
    user,
  };
}

export const SET_AUTH = 'SET_AUTH';
export function setAuth(token, user) {
  return {
    type: SET_AUTH,
    token,
    user,
  };
}
