import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { withOperation } from '../../../../utils/OperationalComponent';
import { withQuery } from '../../../../utils/QueryBasedComponent';
import { getExperienceDetail } from '../../experienceReducer';
import {
  fetchExperienceDetail,
  duplicateExperience,
} from '../../experienceActions';
import ExperienceHeader from '../../components/ExperienceHeader';
import ProductOptionContainer from '../../../ProductOption/components/ProductOptionContainer';
import { fetchVariants } from '../../../ProductOption/reducers/variant/actions';

class ProductOptionPage extends Component {
  state = {
    showAddModal: false,
    showModalPhoto: false,
    faq: { question: '', answer: '', id: -1 },
    photoModalUrl: '',
    option: {},
    group: '',
  };

  componentDidMount() {
    const {
      executeActions,
      fetchVariantsAction,
      fetchExperienceDetailAction,
      match,
      experience = {},
    } = this.props;

    if (experience.id !== parseInt(match.params.cuid, 10)) {
      executeActions({
        actions: [
          () => fetchExperienceDetailAction({ id: match.params.cuid }),
          () => fetchVariantsAction(match.params.cuid),
        ],
      });
    }
  }

  handleCloseModal() {
    this.setState({
      showAddModal: false,
      showAddModalEditSettings: false,
      showAddModalPhoto: false,
      showModalPhoto: false,
      option: {},
      group: '',
    });
  }

  handleOpenModal() {
    this.setState({ showAddModal: true });
  }

  handleOpenModalEditSettings() {
    this.setState({ showAddModalEditSettings: true });
  }

  buildSelectValue(options, value) {
    let selectValue = '';
    if (options) {
      Object.keys(options).forEach(key => {
        if (options[key] === value) {
          selectValue = key;
        }
      });
    }
    return selectValue;
  }

  render() {
    const { experience = {}, match } = this.props;

    const productId = parseInt(match.params.cuid);

    return (
      <Fragment>
        <Helmet>
          <title>
            Edit Product #{`${match.params.cuid}`} - Options - Inspitrip HQ
          </title>
        </Helmet>
        <ExperienceHeader experience={experience} editMode />
        <section className="content body">
          <div className="flex flex-row">
            <div className="flex flex-col gap-y-8">
              <div className="nav-tabs-custom">
                <ul className="nav nav-tabs">
                  <li className="">
                    <Link to={`/products/${productId}/information`}>
                      Information
                    </Link>
                  </li>
                  <li className="">
                    <Link to={`/products/${productId}/settings`}>Settings</Link>
                  </li>
                  <li className="active">
                    <Link to={`/products/${productId}/options`}>Options</Link>
                  </li>
                  <li className="">
                    <Link to={`/products/${productId}/media`}>Media</Link>
                  </li>
                  <li className="">
                    <Link to={`/products/${productId}/faqs`}>FAQs</Link>
                  </li>
                </ul>
                <div className="tab-content">
                  <div className="tab-pane active">
                    <ProductOptionContainer experienceId={productId} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </Fragment>
    );
  }
}

ProductOptionPage.contextTypes = {
  router: PropTypes.object,
};

ProductOptionPage.propTypes = {
  /**
   * Operational Props
   */
  success: PropTypes.string,
  error: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  executeActions: PropTypes.func,
  /**
   * Actions
   */
  fetchExperienceDetailAction: PropTypes.func.isRequired,
  fetchVariantsAction: PropTypes.func.isRequired,
};

const mapStateToProps = (state, ownProps) => ({
  experience: getExperienceDetail(state, ownProps.match.params.cuid),
});

export default connect(mapStateToProps, {
  fetchExperienceDetailAction: fetchExperienceDetail,
  fetchVariantsAction: fetchVariants,
  duplicateExperienceAction: duplicateExperience,
})(withOperation(withQuery(ProductOptionPage)));
