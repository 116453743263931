import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';

// Components
import { Listings, Modal } from '../../../shared/App/components';
import Breadcrumb from '../../../shared/App/components/Breadcrumb';
import FilterForm from '../../../shared/Form/FilterForm';
import SearchForm from '../../../shared/Form/SearchForm';

// Utils
import { getSearchData, getFilterData, keyOptions } from '../couponFilters';
import { languageOptions } from '../../../utils/filter';
import { withOperation } from '../../../utils/OperationalComponent';
import { withQuery } from '../../../utils/QueryBasedComponent';
import { couponColumns } from '../couponUtils';
import { parseQueryString } from '../../../utils/urlQuery';

// Actions
import { fetchCoupons, deleteCoupon } from '../couponActions';

// Seletector
import { getCoupons, getFetchStatus } from '../couponReducers';

// Constants
import configService from '../../../config/configService';

const sortDirection = { asc: '', desc: '-' };

class CouponListPage extends Component {
  state = {
    showedDeleteModalConfirm: false,
    deletedId: null,
    sortParams: {},
  };
  componentDidMount() {
    const {
      setQueryCallback,
      coupons,
      location: { search },
    } = this.props;
    setQueryCallback(this.handleFetchCoupons);
    if (!coupons || coupons.length === 0) {
      this.handleFetchCoupons(parseQueryString(search));
    }
  }

  handleFetchCoupons = params => {
    const { fetchCouponsAction } = this.props;
    const { page = 1, ordering } = params || {};
    if (this.cancel) this.cancel();
    this.cancel = fetchCouponsAction({ params: { page, ordering } }).cancel;
  };

  handlePageChange = page => {
    this.props.pushParams({ page });
  };

  handleDeleteCoupon = () => {
    const { deletedId } = this.state;
    this.props.deleteCouponAction({ id: deletedId }).then(res => {
      this.setState({ showedDeleteModalConfirm: false }, () => {
        this.handleFetchCoupons(1);
      });
    });
  };

  handleFilterChange = values => {
    let params = {};
    values.filter.map(
      (item, index) => (params[item.key + item.type] = item.value)
    );
    params.search = this.props.queryParams.search;
    this.props.replaceParams(params);
    this.handleCloseModal();
  };

  handleSearchChange = values => {
    this.props.pushParams({ search: values.search });
  };

  handleOpenModalFilter = () => {
    this.setState({ showModalFilter: true });
  };

  handleCloseModal = () => {
    this.setState({ showModalFilter: false });
  };

  handleSort = values => {
    this.props.pushParams({
      ordering: `${sortDirection[values.sortDirection]}${values.sortBy}`,
    });
    this.setState({ sortParams: values });
  };

  // actions in Listing
  actions = [
    {
      title: 'Edit',
      callback: ({ id }) => {
        this.props.history.push(`/coupons/${id}`);
      },
      render: () => <i className="fa fa-edit" />,
      type: 'primary',
    },
    {
      title: 'Delete',
      callback: ({ id }) => {
        // Show confirm popup
        this.setState({ showedDeleteModalConfirm: true, deletedId: id });
      },
      render: () => <i className="fa fa-remove" />,
      type: 'danger',
    },
  ];

  render() {
    // Data
    const { coupons, fetchStatus, queryParams, fields = {} } = this.props;
    const { count, isFetching } = fetchStatus || {
      count: 0,
      isFetching: false,
    };
    const { page } = queryParams || { page: 0 };
    const { showedDeleteModalConfirm } = this.state;
    const { sortParams } = this.state;

    // Add options to filter
    const languageOpt = languageOptions();
    const otherOptions = [];
    otherOptions.push(languageOpt);

    const searchData = getSearchData(this.props.queryParams);
    const filterData = getFilterData(this.props.queryParams, otherOptions);

    return (
      <div>
        {showedDeleteModalConfirm && (
          <Modal
            title="Are you sure to delete this Coupon?"
            confirmText="Yes, sure"
            dismissText="Cancel"
            onConfirm={() => {
              this.handleDeleteCoupon();
            }}
            onDismiss={() => {
              this.setState({ showedDeleteModalConfirm: false });
            }}
            visible={showedDeleteModalConfirm}
          >
            <p>This would delete the coupon show on home page.</p>
          </Modal>
        )}

        <Helmet>
          <title>All Coupons - Inspitrip HQ</title>
        </Helmet>
        <section className="content-header">
          <div className="flex flex-row" style={{ marginTop: '-15px', marginBottom: '15px' }}>
            <Breadcrumb
              history={[
                { title: 'InspitripHQ', url: '/' },
                { title: 'Coupons', url: '' },
              ]}
            />
          </div>
          <div className="flex flex-row">
            <div className="flex flex-col gap-y-7">
              <h1 style={{ fontSize: '24px', marginTop: 0 }}>Coupons</h1>
            </div>
            <div className="flex flex-col gap-y-2	  text-right" style={{ marginLeft: 'auto' }}>
              <Link to="/coupons/new" className="btn btn-primary">
                <i className="fa fa-plus" />
                {' Add'}
              </Link>
            </div>
          </div>
        </section>
        <section className="content-header">
          <div className="flex flex-row">
            <div className="col-sm-12 pl-0 pr-0">
              <SearchForm
                onSubmit={this.handleSearchChange}
                searchContent={searchData}
                filterData={filterData}
                handleOpenModalFilter={this.handleOpenModalFilter}
                placeHolderContent="Type search text"
              />
            </div>
          </div>
        </section>
        <div className="content body">
          <section style={{ marginTop: '15px' }}>
            <Listings
              listingsTitle="Experience List"
              data={coupons}
              columns={couponColumns()}
              actions={this.actions}
              onSortHeader={this.handleSort}
              page={parseInt(page || 1, 10)}
              dataCount={count || 0}
              itemsPerPage={configService.itemsCountPerPage}
              onPageChange={this.handlePageChange}
              pageRange={3}
              isFetching={isFetching}
              sortParams={sortParams}
              noHeader
            />
          </section>
        </div>
        <FilterForm
          onSubmit={this.handleFilterChange}
          onDismiss={this.handleCloseModal}
          visible={this.state.showModalFilter}
          arrayData={filterData}
          keyOptions={[...keyOptions, ...otherOptions]}
        />
      </div>
    );
  }
}

CouponListPage.need = [
  ({ query }) => {
    return fetchCoupons({ params: query });
  },
];

CouponListPage.propTypes = {};

const mapStateToProps = state => ({
  fetchStatus: getFetchStatus(state),
  coupons: getCoupons(state),
});

export default connect(mapStateToProps, {
  fetchCouponsAction: fetchCoupons,
  deleteCouponAction: deleteCoupon,
})(withOperation(withQuery(CouponListPage)));
