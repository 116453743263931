import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import Alert from '../../../shared/App/components/Alert';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import {
  fetchReviewDetail,
  updateReview,
  deleteReview,
} from '../reviewActions';
import ReviewEditForm from '../forms/ReviewEditForm';
import { getReviewDetail, getFetchStatus } from '../reviewReducer';
import { buildFormData, transformReviewDetail } from '../reviewUtils';
import { withOperation } from '../../../utils/OperationalComponent';
import { withQuery } from '../../../utils/QueryBasedComponent';

class ReviewEditPage extends Component {
  state = {};

  componentDidMount() {
    const { executeActions, match, fetchReviewDetailAction } = this.props;
    executeActions({
      actions: [() => fetchReviewDetailAction({ id: match.params.cuid })],
    });
  }

  handleSubmit = values => {
    const { executeActions, match, updateReviewAction } = this.props;
    const updatedData = transformReviewDetail(values);

    executeActions({
      actions: [
        () => updateReviewAction({ id: match.params.cuid, data: updatedData }),
      ],
      successMsg: 'Update successfully',
    });
  };

  handleDelete() {
    if (window.confirm('Are you sure?')) {
      const { executeActions, match, deleteReviewAction } = this.props;
      executeActions({
        actions: [() => deleteReviewAction({ id: match.params.cuid })],
        onSuccess: () => this.props.history.push('/reviews'),
        successMsg: 'Delete successfully',
        failureMsg: 'Delete fail',
      });
    }
  }

  renderAlert() {
    const { success, error } = this.props;

    let msg = '';
    let title = 'Success';
    let type = 'success';
    if (error) {
      msg = error.toString();
      title = 'Error';
      type = 'danger';
    } else if (success) {
      msg = success;
    }

    if (msg !== '') {
      return <Alert title={title} type={type} content={msg} />;
    }

    return null;
  }

  renderLoading = () => {
    const { isFetching } = this.props;

    return isFetching ? (
      <div className="overlay">
        <i className="fa fa-refresh fa-spin" />
        Loading...
      </div>
    ) : null;
  };

  render() {
    const { match, isFetching, review } = this.props;
    const initialValues = buildFormData(review);

    return (
      <Fragment>
        <Helmet>
          <title>Edit Review #{match.params.cuid} - Inspitrip HQ</title>
        </Helmet>
        <section className="content-header">
          <div className="flex flex-row" style={{ marginTop: '-15px', marginBottom: '15px' }}>
            <ol className="breadcrumb">
              <li>
                <Link to={'/'}>InspitripHQ</Link>
              </li>
              <li>
                <Link to={'/reviews'}>Reviews</Link>
              </li>
              <li className="active">Review Detail</li>
            </ol>
          </div>
          {this.renderAlert()}
          <div className="flex flex-row">
            <div className="flex flex-col gap-y-7">
              <h1 style={{ fontSize: '24px', marginTop: 0 }}>Review</h1>
            </div>
          </div>
        </section>
        <div className="content body">
          <div className="">
            {this.renderLoading()}
            {!isFetching && (
              <ReviewEditForm
                handleSubmit={this.handleSubmit.bind(this)}
                handleDelete={this.handleDelete.bind(this)}
                review={initialValues}
              />
            )}
          </div>
        </div>
      </Fragment>
    );
  }
}

const mapStateToProps = (state, props) => ({
  isFetching: getFetchStatus(state),
  review: getReviewDetail(state, props.match.params.cuid),
});

export default connect(mapStateToProps, {
  fetchReviewDetailAction: fetchReviewDetail,
  updateReviewAction: updateReview,
  deleteReviewAction: deleteReview,
})(withOperation(withQuery(ReviewEditPage)));
