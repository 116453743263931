import React from 'react';
import PropTypes from 'prop-types';
import { Form, Formik } from 'formik';
import {
  Modal,
  NumericInputField,
  InputField,
} from '../../../../../shared/App/components';

const CapturePaymentForm = ({ onSubmit, onDismiss, visible, payment }) => (
  <Formik
    enableReinitialize
    onSubmit={onSubmit}
    initialValues={{
      total: payment && payment.total,
      currency: payment && payment.currency,
      transaction_id: payment && payment.transaction_id,
    }}
  >
    {({ isSubmitting, isValid, values, submitForm }) => (
      <Modal
        title="Capture a payment"
        confirmText="Confirm"
        dismissText="Cancel"
        onConfirm={submitForm}
        onDismiss={onDismiss}
        visible={visible}
      >
        <Form>
          <div className="flex flex-row">
            <div className="col-md-3 col-sm-4 col-xs-5">
              {payment && (
                <NumericInputField label="Amount" disabled name="total" />
              )}
              {payment && (
                <InputField label="Currency" disabled name="currency" />
              )}
            </div>
            <div className="col-xs-12">
              {payment && (
                <InputField label="Transaction Code" name="transaction_id" />
              )}
            </div>
          </div>
        </Form>
      </Modal>
    )}
  </Formik>
);

CapturePaymentForm.propTypes = {
  onSubmit: PropTypes.func,
  onDismiss: PropTypes.func,
  visible: PropTypes.bool,
  booking: PropTypes.object,
};

export default CapturePaymentForm;
