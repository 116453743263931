import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Form, Formik } from 'formik';

import { Button, Modal, InputField } from '../../../shared/App/components';
import FormStatus from '../../../shared/App/components/FormStatus';
import { resizeImage } from '../../../utils/contentUtils';
import FroalaField from '../../../shared/App/components/FroalaField';

class AddItineraryModal extends Component {
  constructor() {
    super();
    this.resetState = this.resetState.bind(this);
    this.state = {
      showConfirmDelete: false,
      error: undefined,
      deleteError: undefined,
    };
  }

  onSubmit(values, form) {
    this.resetState();
    const { id } = this.props.itinerary_photo;
    const data = new FormData();
    const keys = Object.keys(values);
    keys.forEach(key => {
      if (key !== 'photo') {
        data.append(key, values[key]);
      }
    });

    const afterResizedCallback = () => {
      const {
        onSubmitAddItineraryPhoto,
        onSubmitEditItineraryPhoto,
        handleCloseModal,
      } = this.props;
      if (id) {
        onSubmitEditItineraryPhoto(data)
          .then(() => {
            handleCloseModal();
            form.resetForm();
          })
          .catch(error => {
            form.resetForm();
            this.setState({
              error: error.data || 'Oops! Please contact your administrator.',
            });
          });
      } else {
        onSubmitAddItineraryPhoto(data)
          .then(() => {
            handleCloseModal();
            form.resetForm();
          })
          .catch(error => {
            form.resetForm();
            this.setState({
              error: error.data || 'Oops! Please contact your administrator.',
            });
          });
      }
    };

    if (values.photo instanceof File) {
      resizeImage(values.photo, resized => {
        data.append('photo', resized);
        afterResizedCallback();
      });
    } else {
      // Allow send data without photo (edit the itinerary)
      afterResizedCallback();
    }
  }

  resetState() {
    this.setState({
      error: undefined,
    });
  }

  handleOpenConfirmDeleteModal() {
    this.setState({
      showConfirmDelete: true,
    });
  }

  handleCloseModal() {
    this.setState({
      showConfirmDelete: false,
    });
  }

  render() {
    const {
      handleCloseModal,
      showAddModal,
      itinerary_photo,
      experienceId,
      deleteItineraryPhoto,
      group,
    } = this.props;
    const {
      id,
      group: itinerary_group = '',
      description = '',
      photo = '',
    } = itinerary_photo;
    const { resetState } = this;
    const { showConfirmDelete } = this.state;
    const initialValues = {
      group: itinerary_group || group,
      description,
      photo,
    };
    if (showConfirmDelete) {
      return (
        <Modal
          title="Delete Itinerary photo"
          confirmText="Delete"
          dismissText="Cancel"
          onDismiss={this.handleCloseModal.bind(this)}
          visible={showConfirmDelete}
          onConfirm={() => {
            this.setState({ deleteError: undefined });
            deleteItineraryPhoto({ id: experienceId, itinerary_photo_id: id })
              .then(() => {
                handleCloseModal();
                this.handleCloseModal();
              })
              .catch(error => {
                this.setState({
                  deleteError:
                    error.data || 'Oops! Please contact your administrator.',
                });
              });
          }}
        >
          {this.state.deleteError ? (
            <FormStatus formState={{ error: this.state.deleteError }} />
          ) : null}
          <div>Do you want to delete this one?</div>
        </Modal>
      );
    }
    return (
      <Modal
        title={id ? 'Edit itinerary' : 'Add itinerary'}
        confirmText="Confirm"
        dismissText="Cancel"
        onConfirm={() => {}}
        onDismiss={handleCloseModal}
        visible={showAddModal}
        hideFooter
      >
        {this.state.error ? (
          <FormStatus formState={{ error: this.state.error }} />
        ) : null}
        <Formik
          initialValues={initialValues}
          onSubmit={this.onSubmit.bind(this)}
          enableReinitialize
          isInitialValid
        >
          {({
            isSubmitting,
            isValid,
            values,
            setFieldValue,
            handleReset,
            submitForm,
          }) => (
            <Form>
              <InputField name="group" label="Group" />
              <div className="form-horizontal form-group">
                <label htmlFor="input">Photo *</label>
                <input
                  id="photo-input"
                  className="form-control"
                  type="file"
                  name="photo"
                  onChange={event => {
                    setFieldValue(
                      event.currentTarget.name,
                      event.currentTarget.files[0]
                    );
                  }}
                />
              </div>
              <FroalaField name="description" label="Description" />
              <br />
              <div className="flex flex-row">
                <div className="col-sm-6">
                  {id ? (
                    <div className="">
                      <Button
                        className="btn btn-flat text-red"
                        type="button"
                        onClick={() => {
                          this.handleOpenConfirmDeleteModal();
                        }}
                        style={{ marginLeft: '-12px' }}
                      >
                        <i className="fa fa-trash-o" /> Delete itinerary
                      </Button>
                    </div>
                  ) : null}
                </div>
                <div className="col-sm-6 text-right">
                  <Button
                    className="btn btn-default"
                    type="button"
                    onClick={() => {
                      handleReset();
                      resetState();
                      document.getElementById('photo-input').value = '';
                      handleCloseModal();
                    }}
                  >
                    Cancel
                  </Button>
                  &nbsp;&nbsp;
                  <Button
                    className="btn  btn-primary"
                    type="button"
                    onClick={submitForm}
                  >
                    {id ? 'Save Changes' : 'Add'}
                  </Button>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </Modal>
    );
  }
}

AddItineraryModal.propTypes = {
  handleCloseModal: PropTypes.func,
  showAddModal: PropTypes.bool,
  validate: PropTypes.func,
  onSubmitAddHighlightPhoto: PropTypes.func,
};

export default AddItineraryModal;
