import {
  AUTH_REQUEST,
  AUTH_UPDATE,
  AUTH_RESET,
  AUTH_LOAD,
  SET_AUTH,
} from './AuthActions';
import { saveAuth, removeAuth } from '../../utils/localStorage';
import { FULFILLED, PENDING, REJECTED } from '../../utils/axiosAPI';

// Initial State
const initialState = {
  isFetching: false,
  isError: false,
  isAuth: false,
  user: {},
  token: '',
};

const authReducer = (state = initialState, action) => {
  switch (action.type) {
    // TODO: Implement here
    case AUTH_REQUEST + PENDING:
      return Object.assign({}, state, {
        user: {},
        token: '',
        isAuth: false,
        isFetching: true,
        isError: false,
      });
    case AUTH_REQUEST + FULFILLED:
      saveAuth(action.data.token, action.data.user);
      return Object.assign({}, state, {
        user: action.data.user,
        token: action.data.token,
        isAuth: true,
        isFetching: false,
        isError: false,
      });
    case AUTH_REQUEST + REJECTED:
      return Object.assign({}, state, {
        user: {},
        token: '',
        isAuth: false,
        isFetching: false,
        isError: true,
      });
    case AUTH_UPDATE:
      saveAuth(action.token, action.user);
      return Object.assign({}, state, {
        user: action.user,
        token: action.token,
        isAuth: true,
        isFetching: false,
        isError: false,
      });
    case AUTH_LOAD:
      return Object.assign({}, state, {
        user: action.user,
        token: action.token,
        isAuth: true,
        isFetching: false,
        isError: false,
      });
    case AUTH_RESET:
      removeAuth();
      return Object.assign({}, state, {
        user: {},
        token: '',
        isAuth: false,
        isFetching: false,
        isError: false,
      });
    case SET_AUTH:
      console.log(SET_AUTH);
      return Object.assign({}, state, {
        user: action.user,
        token: action.token,
        isAuth: true,
        isFetching: false,
        isError: false,
      });
    default:
      return state;
  }
};

export default authReducer;

export const getToken = state => state.auth.token;
export const getUser = state => state.auth.user;
export const getIsAuth = state => state.auth.isAuth;
