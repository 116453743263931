/**
 *
 *
 * @summary collectionValidator
 * @author cuonghx
 *
 * Created at     : 2019-03-28 15:04:02
 * Last modified  : 2019-03-28 15:05:47
 */

export const validate = values => {
  const errors = {};

  if (!values.campaign) {
    errors.campaign = 'Please enter campaign.';
  }

  if (!values.code) {
    errors.code = 'Please enter code.';
  }

  if (!values.value) {
    errors.value = 'Please enter amount.';
  }

  return errors;
};
