import React from 'react';
import { connect } from 'react-redux';
import { Box, Button, Status } from '../../../../../shared/App/components';
import { Link } from 'react-router-dom';
import { refundPayment, createPayment, capturePayment } from '../../../../Transactions/paymentActions';
import { getTransactions } from '../../../bookingReducer';
import { fetchBookingTransactions } from '../../../bookingActions';
import { getPayments } from '../../../../PaymentMethod/paymentReducers';
import { paymentStatusConfig } from '../../../../Transactions/paymentUtils';
import { withOperation } from '../../../../../utils/OperationalComponent';
import RefundForm from './RefundForm';
import CreatePaymentForm from './CreatePaymentForm';
import CapturePaymentForm from './CapturePaymentForm';
import { displayCurrency } from '../../../../../utils/currency';
import configService from '../../../../../config/configService';

const initialState = {
  showModalMakeRefund: false,
  showModalCreatePayment: false,
  showModalCapturePayment: false,
  paymentOnAction: null,
};

class Payment extends React.Component {
  state = initialState;

  componentDidMount() {
    const { booking, executeActions, fetchPaymentsAction } = this.props;
    if (booking && booking.id) {
      executeActions({
        actions: [() => fetchPaymentsAction({ bid: booking.id })]
      });
    }
  }

  componentDidUpdate(prevProps) {
    const { booking, executeActions, fetchPaymentsAction } = this.props;
    const { booking: prevBooking } = prevProps;

    if (booking && prevBooking && booking.id && booking.id !== prevBooking.id) {
      executeActions({
        actions: [() => fetchPaymentsAction({ bid: booking.id })]
      });
    }
  }

  onRefund = values => {
    const { refundPaymentAction, fetchPaymentsAction, executeActions, booking } = this.props;
    const paymentToken = this.state.paymentOnAction ? this.state.paymentOnAction.token : '';
    this.handleCloseModal();
    executeActions({
      actions: [
        () => refundPaymentAction({ token: paymentToken, data: values }).then(response => {
          return fetchPaymentsAction({ bid: booking.id });
        })
      ],
      successMsg: 'Refund successfully'
    });
  }

  onCreatePayment = values => {
    const { createPaymentAction, fetchPaymentsAction, booking, executeActions, match } = this.props;
    this.handleCloseModal();
    if (booking) {
      values.bank = {
        bank_name: 'Ngân hàng Thương mại Cổ Phần Á Châu (ACB)',
        account_id: '198240699',
        account_owner: 'Ngo Huynh Nguyen',
        logo: 'https://inspitrip-media.imgix.net/2019_99f27ed2-761f-4433-baf8-c0e20bb0ff58.png',
        branch: 'TP. Hồ Chí Minh',
        city: 'TP. Hồ Chí Minh'
      };

      executeActions({
        actions: [
          () => createPaymentAction({ data: values }).then(response => {
            return fetchPaymentsAction({ bid: booking.id });
          })
        ],
        successMsg: 'Create successfully'
      });
    }
  }

  onCapturePayment = values => {
    const { capturePaymentAction, executeActions, fetchPaymentsAction, booking } = this.props;
    const paymentToken = this.state.paymentOnAction ? this.state.paymentOnAction.token : '';
    this.handleCloseModal();
    if (paymentToken) {
      executeActions({
        actions: [
          () => capturePaymentAction({ token: paymentToken, data: values }).then(response => {
            return fetchPaymentsAction({ bid: booking.id });
          })
        ],
        successMsg: 'Capture payment successfully'
      });
    }
  }

  handleCloseModal = () => {
    this.setState(initialState);
  };

  handleOpenModalMakeRefund(paymentId) {
    const paymentOnActions = this.props.transactions.filter(item => item.id === paymentId);
    this.setState({ showModalMakeRefund: true, paymentOnAction: paymentOnActions[0] });
  }

  handleOpenModalCapturePayment(paymentId) {
    const paymentOnActions = this.props.transactions.filter(item => item.id === paymentId);
    this.setState({ showModalCapturePayment: true, paymentOnAction: paymentOnActions[0] });
  }

  handleOpenModalCreatePayment() {
    this.setState({ showModalCreatePayment: true });
  }

  render() {
    const { transactions = [], paymentMethods, booking, isLoading, isFetching } = this.props;
    const _this = this;
    const currencyOptions = configService.currencyCodes.map(currency => ({ label: currency, value: currency }));

    // const paymentMethodOptions = paymentMethods.map(method => ({ label: method.title, value: method.code }));
    const paymentMethodOptions = [
      { label: 'Bank Transfer', value: 'bank' }
    ];

    const statusOptions = [
      { label: 'Pending', value: 'pending' },
      { label: 'Success', value: 'success' }
    ];

    return (
      <div>
        <Box
          isLoading={isLoading || isFetching}
          noHeader
        >
          <div className="flex flex-row">
            <div className="col-xs-6 pl-0">
              <label>Payment</label>
            </div>
            <div className="col-xs-6 text-right pr-0 mb-4">
              <Button size="xs" onClick={_this.handleOpenModalCreatePayment.bind(this)}>
                <i className="fa fa-plus" />
                &nbsp;Add
              </Button>
            </div>
          </div>
          <table className="table table-striped">
            <tbody>
              <tr>
                <th>Transaction</th>
                <th>Method</th>
                <th>Paid amount</th>
                <th>Refunded amount</th>
                <th>Status</th>
                <th>Action</th>
              </tr>
              {
                transactions && transactions.map((item, key) => {
                  return (
                    <tr key={key}>
                      <td><Link to={`/payments/transactions/${item.id}`}>{item.transaction_id}</Link></td>
                      <td>{item.variant}</td>
                      <td>
                        {displayCurrency({ amount: item.captured_amount + item.refunded_amount, currency: item.currency })}
                      </td>
                      <td>
                        {item.refunded_amount ? displayCurrency({ amount: item.refunded_amount, currency: item.currency }) : '-'}
                      </td>
                      <td>
                        <Status state={item.status} statuses={paymentStatusConfig} />
                      </td>
                      <td>
                      {
                        item.captured_amount > 0 && (
                          <Button size="xs" onClick={_this.handleOpenModalMakeRefund.bind(this, item.id)}>
                            <i className="fa fa-credit-card" />
                            &nbsp;Refund
                          </Button>
                        )
                      }
                      {
                        item.status === 'preauth' && (
                          <Button size="xs" onClick={_this.handleOpenModalCapturePayment.bind(this, item.id)}>
                            <i className="fa fa-edit" />
                            &nbsp;Confirm
                          </Button>
                        )
                      }
                      </td>
                    </tr>
                  );
                })
              }
            </tbody>
          </table>
        </Box>
        <RefundForm
          onSubmit={this.onRefund}
          onDismiss={this.handleCloseModal}
          visible={this.state.showModalMakeRefund}
          payment={this.state.paymentOnAction}
        />
        <CreatePaymentForm
          onSubmit={this.onCreatePayment}
          onDismiss={this.handleCloseModal}
          visible={this.state.showModalCreatePayment}
          currencyOptions={currencyOptions}
          paymentMethodOptions={paymentMethodOptions}
          statusOptions={statusOptions}
          booking={booking}
        />
        <CapturePaymentForm
          onSubmit={this.onCapturePayment}
          onDismiss={this.handleCloseModal}
          visible={this.state.showModalCapturePayment}
          currencyOptions={currencyOptions}
          paymentMethodOptions={paymentMethodOptions}
          statusOptions={statusOptions}
          payment={this.state.paymentOnAction}
        />
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => ({
  transactions: getTransactions(state),
  paymentMethods: getPayments(state)
});

export default connect(
  mapStateToProps,
  {
    fetchPaymentsAction: fetchBookingTransactions,
    refundPaymentAction: refundPayment,
    createPaymentAction: createPayment,
    capturePaymentAction: capturePayment
  }
)(withOperation(Payment));
