import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Box, Button } from '../../../shared/App/components';
import { Draggable } from 'react-beautiful-dnd';

// containers
import UnitList from './UnitList';
import ServiceList from './ServiceList';
import UnitModal from '../forms/Unit/UnitModal';
import ServiceModal from '../forms/Service/ServiceModal';
import VariantModal from '../forms/Variant/VariantModal';

// selectors
import { getVariant } from '../reducers/variant';

// utils
import { withOperation } from '../../../utils/OperationalComponent';
import { displayCurrency } from '../../../utils/currency';

// config
import configApp from '../../../config/configApp';

class VariantItem extends React.Component {
  state = {
    isEditing: false,
    isAddingOption: false,
    isAddingService: false,
  };

  toggleEditing = isEditing => this.setState({ isEditing });

  toggleAddingOption = isAddingOption => this.setState({ isAddingOption });

  toggleAddingService = isAddingService => this.setState({ isAddingService });

  renderUnits = () => {
    const { id, experienceId } = this.props;

    return (
      <Fragment>
        <UnitList variantId={id} experienceId={experienceId} />
        {this.state.isAddingOption && (
          <UnitModal
            experienceId={experienceId}
            variantId={id}
            mode={configApp.editMode.ADD}
            onDismiss={() => this.toggleAddingOption(false)}
          />
        )}
      </Fragment>
    );
  };

  renderServices = () => {
    const { id, experienceId } = this.props;

    return (
      <Fragment>
        <ServiceList variantId={id} experienceId={experienceId} />
        {this.state.isAddingService && (
          <ServiceModal
            variantId={id}
            experienceId={experienceId}
            mode={configApp.editMode.ADD}
            onDismiss={() => this.toggleAddingService(false)}
          />
        )}
      </Fragment>
    );
  };

  renderDetails = isCompact => {
    return (
      <div style={{ display: isCompact && 'none' }}>
        {this.renderUnits()}
        {this.renderServices()}
        <div className="flex flex-row" style={{ marginTop: '10px' }}>
          <div className="col-sm-4" />
          <div className="col-sm-8 text-right">
            <Button
              style={{ marginRight: '7px' }}
              onClick={() => this.toggleAddingOption(true)}
            >
              <i className="fa fa-plus" /> Add unit
            </Button>
            <Button onClick={() => this.toggleAddingService(true)}>
              <i className="fa fa-plus" /> Add service
            </Button>
          </div>
        </div>
      </div>
    );
  };

  render() {
    const { id, index, variant, experienceId, isSorting } = this.props;
    return (
      <Draggable draggableId={id} index={index} isDragDisabled={!isSorting}>
        {(provided, snapshot) => (
          <div
            className={`box box-${snapshot.isDragging ? 'primary' : 'default'}`}
            ref={provided.innerRef}
            style={{
              cursor: isSorting && (snapshot.isDragging ? 'grabbing' : 'grab'),
            }}
            {...provided.draggableProps}
            {...provided.dragHandleProps}
          >
            <div className="box-body">
              {variant && (
                <Fragment>
                  <div className="flex flex-row">
                    <div className="col-sm-8">
                      <h4 style={{ marginBottom: '5px' }}>
                        {variant.title} &nbsp;
                        <span
                          style={{ fontWeight: 'normal', fontSize: '14px' }}
                        >
                          {displayCurrency({
                            currency: variant.currency_code,
                            amount: variant.price_display,
                          })}
                        </span>
                      </h4>
                      <div
                        className="text-muted"
                        style={{ marginBottom: '10px' }}
                      >
                        {variant.code}
                      </div>
                      {/* <div dangerouslySetInnerHTML={{ __html: variant.text_html }} /> */}
                    </div>
                    <div className="col-sm-4 text-right">
                      <span
                        style={{ marginRight: '5px', padding: '5px 10px' }}
                        className={`badge bg-${
                          variant.is_active ? 'green' : 'grey'
                        }`}
                      >
                        {variant.is_active ? 'Active' : 'Inactive'}
                      </span>
                      {!isSorting && (
                        <Button
                          size="sm"
                          onClick={() => this.toggleEditing(true)}
                        >
                          <i className="fa fa-pencil" /> Edit
                        </Button>
                      )}
                    </div>
                  </div>
                  {this.state.isEditing && (
                    <VariantModal
                      experienceId={experienceId}
                      variantId={variant.id}
                      onDismiss={() => this.toggleEditing(false)}
                      mode={configApp.editMode.EDIT}
                    />
                  )}
                  {this.renderDetails(isSorting)}
                </Fragment>
              )}
            </div>
          </div>
        )}
      </Draggable>
    );
  }
}

VariantItem.propTypes = {
  id: PropTypes.number.isRequired,
  index: PropTypes.number.isRequired,
  variant: PropTypes.object.isRequired,
  experienceId: PropTypes.number.isRequired,
};

const mapStateToProps = (state, ownProps) => ({
  variant: getVariant(state, ownProps.id),
});

const mapDispathToProps = {};

export default connect(
  mapStateToProps,
  mapDispathToProps
)(withOperation(VariantItem));
