import { combineReducers } from 'redux';

// Actions
import { FETCH_CITIES, RESET_CITIES, CREATE_CITY, FETCH_CITY, UPDATE_CITY } from './locationActions';

// Constants
import { FULFILLED, PENDING, REJECTED } from '../../utils/axiosAPI';
import { appendByIds } from '../../utils/reducer';

const fetchStatusInitialState = {
  count: 0,
  next: null,
  previous: null,
  isFetching: false,
  error: null
};

// Seperate reuse for all reducer
export const fetchStatus = (state = fetchStatusInitialState, action) => {
  switch (action.type) {
    case FETCH_CITIES + FULFILLED: {
      const { results, ...rest } = action.data;
      return { ...state, isFetching: false, ...rest };
    }
    case FETCH_CITIES + REJECTED: {
      return { ...state, isFetching: false, error: action.data };
    }
    case FETCH_CITIES + PENDING: {
      return { ...state, isFetching: true, error: null };
    }
    case RESET_CITIES: {
      return fetchStatusInitialState;
    }
    default: {
      return state;
    }
  }
};

export const byId = (state = {}, action) => {
  switch (action.type) {
    case FETCH_CITIES + FULFILLED: {
      return appendByIds(state, action.data.results, 'id');
    }
    case CREATE_CITY + FULFILLED: {
      return {
        [action.data.id]: action.data,
        ...state
      };
    }
    case UPDATE_CITY + FULFILLED:
    case FETCH_CITY + FULFILLED: {
      return {
        [action.data.id]: action.data,
        ...state
      };
    }
    default:
      return state;
  }
};

export const visibleIds = (state = [], action) => {
  switch (action.type) {
    case FETCH_CITIES + FULFILLED: {
      return action.data.results.map(item => item.id);
    }
    case CREATE_CITY + FULFILLED: {
      return [action.data.id, ...state];
    }
    case RESET_CITIES: {
      return [];
    }
    default: {
      return state;
    }
  }
};

//
const initialCityFields = {
  isFetching: false,
  action: {}
};

export default combineReducers({
  fetchStatus,
  visibleIds,
  byId
});

// Selector
export const getCities = state => state.location.visibleIds.map(id => state.location.byId[id]);
export const getCityDetail = (state, id) => state.location.byId[id];
export const getFetchStatus = state => state.location.fetchStatus;
