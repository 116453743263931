import React, { Component } from 'react';
import PropTypes from 'prop-types';
// Components
import { InputField } from '../App/components';
import ImgUploadModal from '../ImgUploadModal';

class ImageInputField extends Component {
  render() {
    const { onUpload, name, label, placeholder } = this.props;
    return (
      <React.Fragment>
        <label style={{ marginTop: '10px' }}>{label}</label>
        <div className="input-group">
          <InputField style={{ marginRight: 0, marginLeft: 0, marginBottom: 0 }} name={name} placeholder={placeholder} />
          <ImgUploadModal name={name} btnTitle={<i className="fa fa-upload" />} onSuccess={onUpload} />
        </div>
      </React.Fragment>
    );
  }
}
ImageInputField.propTypes = {
  onUpload: PropTypes.func.isRequired,
  label: PropTypes.string,
  name: PropTypes.string.isRequired,
  placeholder: PropTypes.string
};

export default ImageInputField;
