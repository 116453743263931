import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { Modal } from '../../../shared/App/components';

class ExperiencePhotoModal extends Component {
  render() {
    const { handleCloseModal, showModalPhoto, photoUrl } = this.props;
    return (
      <Modal
        title="Photo name"
        confirmText="Save"
        dismissText="Cancel"
        onConfirm={() => {}}
        onDismiss={handleCloseModal}
        visible={showModalPhoto}
        hideFooter
      >
        <img
          className="img-responsive"
          role="presentation"
          src={photoUrl}
          style={{ marginBottom: '5px' }}
        />
      </Modal>
    );
  }
}

ExperiencePhotoModal.propTypes = {
  handleCloseModal: PropTypes.func,
  showModalPhoto: PropTypes.bool,
  photoModalUrl: PropTypes.string
};

export default ExperiencePhotoModal;
