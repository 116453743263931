// Utils
import PRNG from './prng';
import { Avatar as AvatarAntd } from 'antd';

const getStandForName = (user) => {
    let initial = ''
    if (user.first_name) initial += user.first_name.charAt(0)
    if (user.last_name) initial += user.last_name.charAt(0)
    if (initial) return initial
    return 'U'
}

const Avatar = ({
  img_src,
  user,
  cropOption = { fit: 'crop', crop: 'edges', w: 300, h: 300, q: 70 },
  optional = false,
  hasRandomBackgroundColor = true,
}) => {
  const generateBackgroundColor = (name = 'default') => {
    let seed = 0
    for (let i = 0; i < name.length; i++) {
      seed += name.charCodeAt(i)
    }

    const rng = new PRNG(seed)

    const r = Math.floor(rng.nextFloat() * 255)
    const g = Math.floor(rng.nextFloat() * 255)
    const b = Math.floor(rng.nextFloat() * 255)
    const a = 0.4
    return `rgba(${r},${g},${b},${a})`
  }

  return (
    <div style={{ display: 'inline', height: '32px', lineHeight: '32px' }}>
      {
        img_src ? <AvatarAntd src={img_src} /> : (
          <div style={{ float: 'left', marginRight: '2px', width: '32px', height: '32px', borderRadius: '50%', background: generateBackgroundColor(user.first_name || user.last_name), fontSize: '12px', color: '#fff', textAlign: 'center', lineHeight: '32px' }}>{getStandForName(user)}</div> 
        )
      }
    </div>
  )
}

export default Avatar
