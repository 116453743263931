import _slugify from 'slugify';

const extendedCharmaps = {
  Đ: 'D',
  đ: 'd',
};

_slugify.extend(extendedCharmaps);

export const slugify = _slugify;
