import { Avatar } from 'antd';
import PropTypes from 'prop-types';
import React, { Fragment } from 'react';
import { connect } from 'react-redux';

import { Box } from '../../../../../shared/App/components';
import { withOperation } from '../../../../../utils/OperationalComponent';
import { formatDateTime } from '../../../../../utils/time';
import { fetchUser } from '../../../../User/userActions';
import { getUserDetail } from '../../../../User/userReducers';

class TravelerInfo extends React.Component {
  componentDidMount() {
    const { travelerId, fetchUserAction, executeActions } = this.props;
    if (travelerId) {
      executeActions({
        actions: [() => fetchUserAction({ id: travelerId })],
      });
    }
  }

  componentDidUpdate(prevProps) {
    const { travelerId, fetchUserAction, executeActions } = this.props;
    const { travelerId: prevTravelerId } = prevProps;
    if (prevTravelerId !== travelerId) {
      executeActions({
        actions: [() => fetchUserAction({ id: travelerId })],
      });
    }
  }

  render() {
    const { isLoading, isFetching, traveler = {} } = this.props;

    return (
      <Fragment>
        <div className="box-body pl-0">
          <label>Traveler</label>
        </div>
        <ul className="list-group">
          <li className="list-group-item">
            <div className="flex flex-row">
              <span className="col-xs-5">Email</span>
              <a href={`mailto:${traveler.email}`} className="col-xs-7 text-right" style={{ wordBreak: 'break-all' }}>
                {traveler.email}
              </a>
            </div>
          </li>
          <li className="list-group-item">
            <div className="flex flex-row">
              <span className="col-xs-5">Phone</span>
              <span className="col-xs-7 text-right">{traveler.phone}</span>
            </div>
          </li>
          <li className="list-group-item">
            <div className="flex flex-row">
              <span className="col-xs-5">Whatsapp</span>
              <span className="col-xs-7 text-right">
                {traveler.whatsapp}
              </span>
            </div>
          </li>
          <li className="list-group-item">
            <div className="flex flex-row">
              <span className="col-xs-5">Nationality</span>
              <span className="col-xs-7 text-right">
                {traveler.nationality}
              </span>
            </div>
          </li>
          <li className="list-group-item">
            <div className="flex flex-row">
              <span className="col-xs-5">Registered date</span>
              <span className="col-xs-7 text-right">
                {formatDateTime(traveler.date_joined)}
              </span>
            </div>
          </li>
        </ul>
      </Fragment>
    );
  }
}

TravelerInfo.propTypes = {
  isLoading: PropTypes.bool,
  travelerId: PropTypes.number,
  /**
   * ConnectedProps
   */
  traveler: PropTypes.object,
  /**
   * Operational Props
   */
  success: PropTypes.string,
  error: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  isFetching: PropTypes.bool,
  executeActions: PropTypes.func,
  /**
   * Actions
   */
  fetchUserAction: PropTypes.func,
};

const mapStateToProps = (state, ownProps) => ({
  traveler: getUserDetail(state, ownProps.travelerId),
});

export default connect(mapStateToProps, {
  fetchUserAction: fetchUser,
})(withOperation(TravelerInfo));
