import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { Listings } from '../../../../shared/App/components';
import { userColumns } from '../../userUtils';
import configService from '../../../../config/configService';
import { parseQueryString } from '../../../../utils/urlQuery';

// import MessageReducers
import { getUsers, getFetchStatus } from '../../userReducers';

// import Actions
import { fetchUsers, resetUser } from '../../userActions';

// Components
import FilterForm from '../../../../shared/Form/FilterForm';
import SearchForm from '../../../../shared/Form/SearchForm';

// Utils
import { getSearchData, getFilterData, keyOptions } from '../../userFilters';
import { withOperation } from '../../../../utils/OperationalComponent';
import { withQuery } from '../../../../utils/QueryBasedComponent';

const sortDirection = { asc: '', desc: '-' };

class UserListPage extends Component {
  state = {
    showModalFilter: false,
    sortParams: {},
  };
  componentDidMount() {
    const {
      setQueryCallback,
      users,
      location: { search },
    } = this.props;
    setQueryCallback(this.handleFetchUsers);

    if (users.length === 0) {
      this.handleFetchUsers(parseQueryString(search));
    }
  }

  componentWillUnmount() {
    if (this.cancel) this.cancel();
    this.props.resetUserAction();
  }

  handlePageChange = page => {
    this.props.pushParams({ page });
  };

  handleFetchUsers = params => {
    const { fetchUsersAction } = this.props;
    const { page = 1, ordering } = params || {};
    if (this.cancel) this.cancel();
    this.cancel = fetchUsersAction({
      params: { page, ordering },
    }).cancel;
  };

  handleSearchChange = values => {
    this.props.pushParams({ search: values.search });
  };

  handleOpenModalFilter = () => {
    this.setState({ showModalFilter: true });
  };

  handleCloseModal = () => {
    this.setState({ showModalFilter: false });
  };

  handleFilterChange = values => {
    let params = {};
    values.filter.map(
      (item, index) => (params[item.key + item.type] = item.value)
    );
    params.search = this.props.queryParams.search;
    this.props.replaceParams(params);
    this.handleCloseModal();
  };

  handleSort = values => {
    this.props.pushParams({
      ordering: `${sortDirection[values.sortDirection]}${values.sortBy}`,
    });
    this.setState({ sortParams: values });
  };

  render() {
    const {
      users,
      fetchStatus: { count, isFetching },
      queryParams: { page },
    } = this.props;
    const { sortParams } = this.state;

    // Add options to filter
    const searchData = getSearchData(this.props.queryParams);
    const filterData = getFilterData(this.props.queryParams);

    return (
      <Fragment>
        <Helmet>
          <title>All Users - Inspitrip HQ</title>
        </Helmet>
        <section className="content-header">
          <div className="flex flex-row" style={{ marginTop: '-15px', marginBottom: '15px' }}>
            <ol className="breadcrumb">
              <li>
                <Link to={'/'}>InspitripHQ</Link>
              </li>
              <li className="active">Users</li>
            </ol>
          </div>
          <div className="flex flex-row">
            <div className="flex flex-col gap-y-7">
              <h1 style={{ fontSize: '24px', marginTop: 0 }}>Users</h1>
            </div>
            <div className="flex flex-col gap-y-2	  text-right" style={{ marginLeft: 'auto' }}>
              <Link to="/users/new" className="btn btn-primary">
                <i className="fa fa-plus"></i>
                &nbsp;Add User
              </Link>
            </div>
          </div>
        </section>
        <section className="content-header">
          <div className="flex flex-row">
            <div className="col-sm-12 pl-0 pr-0">
              <SearchForm
                onSubmit={this.handleSearchChange}
                searchContent={searchData}
                filterData={filterData}
                handleOpenModalFilter={this.handleOpenModalFilter}
                placeHolderContent="Type search text"
              />
            </div>
          </div>
        </section>
        <div className="content body">
          <Listings
            listingsTitle="User List"
            data={users}
            columns={userColumns}
            actions={this.actions}
            onSortHeader={this.handleSort}
            page={parseInt(page || 1, 10)}
            dataCount={count}
            itemsPerPage={configService.itemsCountPerPage}
            onPageChange={this.handlePageChange}
            pageRange={3}
            isFetching={isFetching}
            sortParams={sortParams}
            noHeader
          />
        </div>
        <FilterForm
          onSubmit={this.handleFilterChange}
          onDismiss={this.handleCloseModal}
          visible={this.state.showModalFilter}
          arrayData={filterData}
          keyOptions={keyOptions}
        />
      </Fragment>
    );
  }
}

// Retrieve data from store as props
const mapStateToProps = state => {
  return {
    fetchStatus: getFetchStatus(state),
    users: getUsers(state),
  };
};

export default connect(mapStateToProps, {
  fetchUsersAction: fetchUsers,
  resetUserAction: resetUser,
})(withOperation(withQuery(UserListPage)));
