import React from 'react';
import { Link } from 'react-router-dom';
import { Status } from '../../shared/App/components';
import { formatDateTime, timeAgo } from '../../utils/time';

export const buildFormData = emailLog => {
  return emailLog;
};

export const logStatusConfig = {
  pending: { title: 'Pending', color: Status.colors.YELLOW },
  success: { title: 'Success', color: Status.colors.GREEN },
  error: { title: 'Error', color: Status.colors.RED }
};

export const emailLogColumns = () => {
  return [
    {
      id: 'id',
      title: 'ID',
      render: ({ id }) => {
        return <Link to={`/emails/logs/${id}`}>{id}</Link>;
      }
    },
    {
      id: 'event',
      title: 'Event',
      render: ({ event }) => {
        return <span>{event}</span>;
      }
    },
    {
      id: 'log',
      title: 'Log',
      render: ({ log }) => {
        return <span>{log}</span>;
      }
    },
    {
      id: 'state',
      title: 'Status',
      render: ({ state }) => <Status state={`${state}`} statuses={logStatusConfig} />
    },
    {
      id: 'created',
      title: 'Created at',
      render: ({ created }) => <span>{formatDateTime(created)}</span>
    },
    {
      id: 'modified',
      title: 'Updated at',
      render: ({ modified }) => <span>{timeAgo(modified)}</span>
    }
  ];
};
