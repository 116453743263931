import React from 'react';
import { Formik, Form } from 'formik';
import { InputField } from '../App/components';

const SearchForm = ({ onSubmit, onDismiss, visible, searchContent, filterData, placeHolderContent, handleOpenModalFilter }) => (
  <Formik
    enableReinitialize
    initialValues={{
      search: searchContent
    }}
    onSubmit={onSubmit}
  >
    {({ isSubmitting, isValid, values, submitForm }) => (
      <Form>
        <div className="input-group input-group-sm">
          <div className='col-sm-1 pl-0'>
            <div className="form-horizontal form-group">
              <button onClick={handleOpenModalFilter} type="button" className="btn btn-primary btn-flat" style={{ height: '34px' }}>
                <i className="fa fa-filter" />
                &nbsp;Add Filter
              </button>
            </div>
          </div>
          <div className='col-sm-10 pl-0'>
            <InputField name="search" placeholder={placeHolderContent} />
          </div>
          <div className='col-sm-1 text-right pr-0'>
          <span className="form-horizontal form-group" >
            <button type="submit" className="btn btn-primary btn-flat" style={{ height: '34px' }}>Search</button>
          </span>
          </div>
        </div>
        <div className="">
        {
          (filterData && filterData.length || searchContent) && 'Filters: '
        }
        {
          filterData && filterData.length ?
          (
            filterData.map(item => {
              return (<span className="badge">{item.key}{item.type}: {item.value}</span>);
            })
          ) : ''
        }
        {
          searchContent && <span className="badge">search: {searchContent}</span>
        }
        </div>
      </Form>
    )}
  </Formik>
);

export default SearchForm;
