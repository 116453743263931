import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Modal } from '../../../../shared/App/components';

// containers
import FixedPriceForm from './FixedPriceForm';
import NetFixedPriceForm from './NetFixedPriceForm';

// actions
import {
  fetchFixedPrices,
  fetchNetFixedPrices,
  fetchUnit,
} from '../../reducers/unit/actions';
import {
  getUnit,
  getFixedPrices,
  getNetFixedPrices,
} from '../../reducers/unit';

// utils
import { withOperation } from '../../../../utils/OperationalComponent';

export const getSlots = ({ participant_min, participant_max }) => {
  // if the values for min and max does not exist
  // Maybe participan_min === 0
  if (participant_min === null || participant_max === null) {
    return [];
  }

  const parsedMin = parseInt(participant_min, 10);
  const parsedMax = parseInt(participant_max, 10);

  // if cannot parse min and max to int
  if (isNaN(parsedMin) || isNaN(parsedMax)) {
    return [];
  }

  // if min > max
  if (parsedMin > parsedMax) {
    return [];
  }

  const range = parsedMax - parsedMin + 1;

  return [...Array(range).keys()].map(delta => parsedMin + delta);
};

class UnitPriceModal extends Component {
  componentDidMount() {
    const { productId, unitId, variantId } = this.props;
    this.props.fetchUnit({ experienceId: productId, variantId, unitId });
    this.props.fetchFixedPrices({ productId, variantId, unitId });
    this.props.fetchNetFixedPrices({ productId, variantId, unitId });
  }

  getFixedPriceObjects = () => {
    let fixedPricesObj = {};
    const { fixedPrices, netFixedPrices } = this.props;
    if (fixedPrices) {
      fixedPrices.map(priceItem => {
        fixedPricesObj[
          `unit_type_${this.props.unit.id}_${priceItem.quantity}`
        ] = priceItem;
        return true;
      });
    }

    if (netFixedPrices) {
      netFixedPrices.map(priceItem => {
        fixedPricesObj[
          `supplier_price_${this.props.unit.id}_${priceItem.quantity}`
        ] = priceItem;
        return true;
      });
    }

    return fixedPricesObj;
  };

  buildInitialValues = () => {
    if (!this.props.unit) return {};

    const {
      participant_max,
      participant_min,
      price_display,
      price,
      supplier_price,
      ratio,
    } = this.props.unit;

    const initialValues = {
      participant_max,
      participant_min,
      price_display,
      price,
      supplier_price,
      ratio,
    };

    return { ...initialValues, ...this.getFixedPriceObjects() };
  };

  renderPrices = values => {
    return getSlots(values).map(index => (
      <PriceRow
        key={index}
        supplier_price={values.supplier_price * index}
        price={values.price * index}
        fixedPrice={values.price}
        supplierFixedPrice={
          values[`supplier_price_${this.props.unit.id}_${index}`]
        }
        unitTypeFixedPrice={values[`unit_type_${this.props.unit.id}_${index}`]}
        quantity={index}
        unitId={this.props.unit.id}
        unit={this.props.unit}
        variantId={this.props.variantId}
        productId={this.props.productId}
      />
    ));
  };

  render() {
    const values = this.buildInitialValues();

    return (
      <Modal
        title="Edit Fixed Prices"
        hideFooter
        onDismiss={this.props.onDismiss}
        visible
      >
        {this.props.unit ? (
          <Fragment>
            <div className="flex flex-row">
              <div className="col-sm-12">Base price: {values.price}</div>
              <div className="col-sm-12">
                Net price:{' '}
                {values.supplier_price ? values.supplier_price : 'NA'}
              </div>
            </div>
            <hr />
            <div className="flex flex-row">
              <div className="col-sm-4">Quantity #</div>
              <div className="col-sm-4">Net Price</div>
              <div className="col-sm-4">Price</div>
            </div>
            {this.renderPrices(values)}
          </Fragment>
        ) : (
          <div>Loading..</div>
        )}
      </Modal>
    );
  }
}

const PriceRow = ({
  quantity,
  price,
  supplier_price,
  supplierFixedPrice,
  unitTypeFixedPrice,
  unitId,
  productId,
  variantId,
}) => (
  <Fragment>
    <div className="flex flex-row">
      <div className="flex flex-col  gap-y-2">{quantity}</div>
      <div className="flex flex-col gap-y-2	 ">
        <NetFixedPriceForm
          quantity={quantity}
          fixedPrice={supplierFixedPrice}
          fieldName={`supplier_price_${unitId}_${quantity}`}
          priceValue={supplier_price}
          productId={productId}
          variantId={variantId}
          unitId={unitId}
        />
      </div>
      <div className="flex flex-col gap-y-2	 ">
        <FixedPriceForm
          quantity={quantity}
          fixedPrice={unitTypeFixedPrice}
          fieldName={`unit_type_${unitId}_${quantity}`}
          priceValue={price}
          productId={productId}
          variantId={variantId}
          unitId={unitId}
        />
      </div>
    </div>
    <hr />
  </Fragment>
);

UnitPriceModal.propTypes = {
  productId: PropTypes.number.isRequired,
  variantId: PropTypes.number.isRequired,
  unitId: PropTypes.number.isRequired,
};

const mapStateToProps = (state, ownProps) => ({
  unit: getUnit(state, ownProps.unitId),
  fixedPrices: getFixedPrices(state, ownProps.unitId),
  netFixedPrices: getNetFixedPrices(state, ownProps.unitId),
});

const mapDispatchToProps = {
  fetchFixedPrices,
  fetchNetFixedPrices,
  fetchUnit,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withOperation(UnitPriceModal));
