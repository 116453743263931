import { Avatar } from 'antd';
import { Form, Formik } from 'formik';
import PropTypes from 'prop-types';
import { Component } from 'react';
import {
  Button,
  CheckboxField,
  FileInputField,
  InputField,
  TextAreaField,
} from '../../../shared/App/components';

class UserEditForm extends Component {
  render() {
    const {
      initialValues,
      user,
      handleSubmit,
      pristine,
      reset,
      submitting,
      error,
      isError,
      isSuccess,
      submitSucceeded,
    } = this.props;
    return (
      <div className="content">
        <Formik
          initialValues={initialValues}
          onSubmit={handleSubmit}
          enableReinitialize
          isInitialValid
        >
          {({ isSubmitting, isValid, values, errors }) => (
            <Form>
              <div className="col-sm-12">
                <div className="col-sm-8" style={{ paddingLeft: '0px', paddingRight: '30px' }}>
                  <div className="">
                    <div className="">
                      <InputField name="first_name" label="First name" />
                      <InputField name="last_name" label="Last name" />
                      <InputField name="email" label="Email" />
                      <InputField name="phone" label="Phone" />
                      {values && (
                        <InputField
                          type="password"
                          name="password"
                          label="Password"
                          autoComplete="off"
                        />
                      )}
                      <TextAreaField
                        id="about"
                        name="about"
                        label="About"
                        rows={10}
                      />
                    </div>
                  </div>
                </div>
                <div className="col-sm-4">
                  <div className="">
                    <div className="">
                      {user && user.photo && (
                        <Avatar size="large" src={user.photo} />
                      )}
                      <br />
                      <FileInputField label="Profile photo" name="photo" />
                      <CheckboxField name="is_active" label="Is active?" />
                      <CheckboxField name="is_staff" label="Is staff?" />
                      <CheckboxField
                        name="is_localguide"
                        label="Is local guide?"
                      />
                    </div>
                  </div>
                  <div className="box-footer">
                    <Button
                      type="submit"
                      displayType="primary"
                      block
                      disabled={isSubmitting}
                    >
                      <i className="fa fa-save"></i> Save changes
                    </Button>
                  </div>
                </div>
              </div>
              
            </Form>
          )}
        </Formik>
      </div>
    );
  }
}

UserEditForm.propTypes = {
  handleSubmit: PropTypes.func,
  pristine: PropTypes.bool,
  reset: PropTypes.func,
  submitting: PropTypes.bool,
  error: PropTypes.string,
  isSuccess: PropTypes.bool,
  isError: PropTypes.bool,
  submitSucceeded: PropTypes.bool,
  dispatch: PropTypes.func,
};

export default UserEditForm;
