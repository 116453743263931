import React from 'react';
import { Modal } from '../../../../../shared/App/components';

const UnassignBookingItemModal = ({ onSubmit, onDismiss, visible }) => (
  <Modal
    title="Are you sure to unassign this Booking Item?"
    confirmText="Yes, sure"
    dismissText="Cancel"
    onConfirm={() => onSubmit()}
    {...{ onDismiss, visible }}
  >
    <p>
      Mark this booking item as unassigned
    </p>
  </Modal>
);

export default UnassignBookingItemModal;
