import React, { Fragment } from 'react';
import { Formik } from 'formik';
import {
  NumericInputField,
  InputField,
  Button,
  MarkdownField,
} from '../../../../shared/App/components';

const ServiceForm = ({
  handleSubmit,
  handleDelete,
  initialValues,
  isEditing,
}) => (
  <Formik
    onSubmit={handleSubmit}
    initialValues={initialValues}
    enableReinitialize
  >
    {({ isSubmitting, submitForm }) => (
      <Fragment>
        <InputField name="label" label="Label" />
        <MarkdownField name="description" label="Description (optional)" />
        <NumericInputField name="supplier_price" label="Net price" />
        <NumericInputField name="price" label="Price" />
        <InputField name="limit_min" label="Min quantity" />
        <InputField name="limit_max" label="Max quantity" />
        <div className="flex flex-row">
          <div className="col-sm-6">
            {isEditing && (
              <Button
                onClick={handleDelete}
                className="btn btn-flat text-red"
                style={{ marginLeft: '-12px' }}
              >
                <i className="fa fa-trash-o"></i> Delete this Service
              </Button>
            )}
          </div>
          <div className="col-sm-6 text-right">
            <Button
              onClick={() => submitForm()}
              displayType="primary"
              disabled={isSubmitting}
            >
              Save changes
            </Button>
          </div>
        </div>
      </Fragment>
    )}
  </Formik>
);

export default ServiceForm;
