import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import { Form, Formik } from 'formik';
import {
  duplicateExperience,
  fetchExperienceDetail,
  fetchExperienceFields,
  updateExperience,
  fetchDurationTypes,
} from '../../experienceActions';
import {
  getExperienceDetail,
  getExperienceFields,
  getFetchDetailStatus,
  getSubmitStatus,
} from '../../experienceReducer';
import ExperienceHeader from '../../components/ExperienceHeader';
import ExperienceInformation from '../../components/ExperienceInformation';
import ExperienceShortSettings from '../../components/ExperienceShortSettings';
import { validate } from '../../experienceValidators';
import FormStatus from '../../../../shared/App/components/FormStatus';
import { formatDateTime } from '../../../../utils/time';
import { formatObjectToArray } from '../../../../utils/dataUtils';
import { resizeImage } from '../../../../utils/contentUtils';
import { withOperation } from '../../../../utils/OperationalComponent';
import { withQuery } from '../../../../utils/QueryBasedComponent';

// Utils
import { transformExperienceDetail } from '../../experienceUtils';

class ExperienceDetailPage extends Component {
  state = {
    option: {},
    group: '',
  };

  componentDidMount() {
    const {
      executeActions,
      fetchExperienceDetailAction,
      fetchExperienceFieldsAction,
      match,
    } = this.props;

    executeActions({
      actions: [
        () => fetchExperienceDetailAction({ id: match.params.cuid }),
        () => fetchExperienceFieldsAction(),
        () => fetchDurationTypes(),
      ],
    });
  }

  onSubmit = values => {
    this.handleCloseModal();
    const { updateExperienceAction, executeActions, match } = this.props;
    const submitValues = transformExperienceDetail(values);

    if (submitValues.background_photo instanceof File) {
      resizeImage(submitValues.background_photo, resized => {
        // remove background_photo from normal data submit
        delete submitValues.background_photo;

        let data = new FormData();
        data.append('background_photo', resized);

        executeActions({
          actions: [
            () =>
              updateExperienceAction({
                id: match.params.cuid,
                data: submitValues,
              }),
            () => updateExperienceAction({ id: match.params.cuid, data }),
          ],
          successMsg: 'Update successfully',
        });
      });
    } else {
      executeActions({
        actions: [
          () =>
            updateExperienceAction({
              id: match.params.cuid,
              data: submitValues,
            }),
        ],
        successMsg: 'Update successfully',
      });
    }
  };

  handleCloseModal() {
    this.setState({
      option: {},
      group: '',
    });
  }

  handleOpenAddModalPhoto() {
    this.setState({ showAddModalPhoto: true });
  }

  buildSelectValue(options, value) {
    let selectValue = '';
    if (options) {
      Object.keys(options).forEach(key => {
        if (options[key] === value) {
          selectValue = key;
        }
      });
    }
    return selectValue;
  }

  render() {
    const {
      experience = {},
      experienceStatus,
      submitStatus,
      fields,
      duplicateExperienceAction,
      error,
      success,
      match,
      history,
    } = this.props;
    const { isSubmitting } = submitStatus;
    const { isFetching } = experienceStatus;
    const {
      state,
      title,
      slug,
      address,
      overview,
      about,
      inclusion,
      exclusion,
      accommodation,
      place_name,
      place_id,
      advice = '',
      device = '',
      cancellation_policy = '',
      notes,
      cut_off_time = '',
      promotion_text = '',
      user,
      localguide,
      created,
      modified,
      description,
      duration,
      duration_type,
      language,
      rating,
      tag,
      total_reviews,
      pickup_info,
      type,
      page_title,
      page_description,
      guide_introduction,
      instant_confirm,
      skip_the_line,
      chat_with_guide,
      is_extra_service_required,
      currency,
      template,
      content,
      country,
      tagging,
      how_to_use,
      category_meta_display,
    } = experience;

    if (isFetching) return <section className="content">Loading..</section>;
    else if (!experience || Object.keys(experience).length === 0) {
      return <section className="content">404 product not found</section>;
    }

    const aboutValue =
      about && Object.keys(about).length ? formatObjectToArray(about) : [''];
    const inclusionValue =
      inclusion && Object.keys(inclusion).length
        ? formatObjectToArray(inclusion)
        : [];
    const exclusionValue =
      exclusion && Object.keys(exclusion).length
        ? formatObjectToArray(exclusion)
        : [];
    const placeValue = { label: place_name, value: place_id };
    const userValue = localguide
      ? { label: localguide.email, value: localguide.id }
      : '';
    const tagValue = tag
      ? tag.map(item => ({ label: item.term, value: item.term }))
      : [];
    const cutOffTime =
      cut_off_time && cut_off_time.split(':').length - 1 === 2
        ? cut_off_time.substring(0, cut_off_time.lastIndexOf(':'))
        : cut_off_time;
    const categoryDisplay =
      category_meta_display && category_meta_display.length > 0
        ? category_meta_display.map(item => ({
            label: item.title,
            value: item.id,
          }))
        : [];

    const initialValues = {
      // Information
      title,
      slug,
      address,
      overview,
      about: aboutValue,
      inclusion: inclusionValue,
      exclusion: exclusionValue,
      accommodation: accommodation || '',
      pickup_info: pickup_info || '',
      advice,
      device,
      cancellation_policy,
      notes,
      cut_off_time: cutOffTime,
      promotion_text,
      tag: tagValue,
      description,

      // Sidebar
      place: placeValue,
      language,
      user: userValue,
      duration,
      type,
      state,
      createdDate: formatDateTime(created),
      lastUpdate: formatDateTime(modified),
      guide_introduction,
      page_title,
      page_description,
      instant_confirm,
      skip_the_line,
      chat_with_guide,
      is_extra_service_required,
      currency,
      rating,
      total_reviews,
      duration_type,
      template,
      content,
      country,
      localeSetting: `${language}-${country}-${currency}`,
      tagging,
      how_to_use,
      category: categoryDisplay,
    };

    const productId = parseInt(match.params.cuid);
    const errorData = error
      ? error.data || 'Can not save. Please contact admin'
      : '';

    return (
      <Fragment>
        <Helmet>
          <title>
            Edit Product #{`${match.params.cuid}`} - Information - Inspitrip HQ
          </title>
        </Helmet>
        <ExperienceHeader
          experience={experience}
          duplicateExperienceAction={duplicateExperienceAction}
          editMode
        />
        <section className="content body">
          <FormStatus formState={{ error: errorData, success }} />
          <Formik
            initialValues={initialValues}
            onSubmit={this.onSubmit}
            enableReinitialize
            isInitialValid
            validate={validate}
          >
            {({ values, setFieldValue }) => (
              <Form>
                <div className="flex flex-row">
                  <div className="col-sm-8">
                    <div className="nav-tabs-custom">
                      <ul className="nav nav-tabs">
                        <li className="active">
                          <Link to={`/products/${productId}/information`}>
                            Information
                          </Link>
                        </li>
                        <li className="">
                          <Link to={`/products/${productId}/settings`}>
                            Settings
                          </Link>
                        </li>
                        <li className="">
                          <Link to={`/products/${productId}/options`}>
                            Options
                          </Link>
                        </li>
                        <li className="">
                          <Link to={`/products/${productId}/media`}>Media</Link>
                        </li>
                        <li className="">
                          <Link to={`/products/${productId}/faqs`}>FAQs</Link>
                        </li>
                      </ul>
                      <div className="tab-content">
                        <div className="tab-pane active">
                          <ExperienceInformation
                            values={values}
                            experience={experience}
                            editMode
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-sm-4" style={{ marginTop: '66px' }}>
                    <ExperienceShortSettings
                      values={values}
                      fields={fields}
                      experience={experience}
                      editMode
                      isSubmitting={isSubmitting}
                    />
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </section>
      </Fragment>
    );
  }
}

const mapStateToProps = (state, ownProps) => ({
  experienceStatus: getFetchDetailStatus(state),
  submitStatus: getSubmitStatus(state),
  experience: getExperienceDetail(state, ownProps.match.params.cuid),
  fields: getExperienceFields(state).actions.POST,
});

export default connect(mapStateToProps, {
  fetchExperienceDetailAction: fetchExperienceDetail,
  updateExperienceAction: updateExperience,
  fetchExperienceFieldsAction: fetchExperienceFields,
  duplicateExperienceAction: duplicateExperience,
})(withOperation(withQuery(ExperienceDetailPage)));
