/**
 *
 *
 * @summary collectionValidator
 * @author cuonghx
 *
 * Created at     : 2019-03-28 15:04:02
 * Last modified  : 2019-03-28 15:05:47
 */

export const validate = values => {
  const errors = {};

  if (!values.title) {
    errors.title = 'Please enter title.';
  }

  if (!values.limit || values.limit > 10) {
    errors.limit = 'Please enter limit <= 10.';
  }

  return errors;
};
