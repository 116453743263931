import axios, { CancelToken } from 'axios';
import httpAdapter from 'axios/lib/adapters/http';
import configService from '../config/configService';
import { CALL_API } from '../middleware/axiosAPI';

export const PENDING = '_PENDING';
export const FULFILLED = '_FULFILLED';
export const REJECTED = '_REJECTED';
export const CANCELLED = '_CANCELLED';

const AUTH_RESET = 'AUTH_RESET';

/**
 * createCancelToken creates a cancelToken for axios
 * it returns the created token and the method to trigger the cancellation
 * @returns {{token, cancel: cancel}}
 */
const createCancelToken = () => {
  let cancel = () => {};

  const cancelToken = new CancelToken(c => {
    // An executor function receives a cancel function as a parameter
    cancel = c;
  });

  return { cancelToken, cancel };
};

const processErrorResponse = error => {
  const response = {
    status: null,
    data: null,
    headers: null,
    error: null,
  };

  if (error.response) {
    // The request was made and the server responded with a status code
    // that falls out of the range of 2xx
    const { status, headers, data } = error.response;
    response.status = status;
    response.headers = headers;
    response.data = data;
  } else if (error.request) {
    // The request was made but no response was received
    // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
    // http.ClientRequest in node.js
    // maybe error is one of type: ERR_CONNECTION_CLOSED
    response.status = 504;
    response.data = error.request;
  }

  response.data = response.data ? response.data : error.message || ['N/A'];
  response.error = error.response || error.request || error.message || ['N/A'];

  return response;
};

const axiosAPI = (dispatch, params, axiosObject) => {
  const availableToken = params.token;

  if (params.xLogAuthorizationKey) {
    axiosObject.defaults.headers.common['X-LOG-AUTHORIZATION'] =
      params.xLogAuthorizationKey;
  } else {
    delete axiosObject.defaults.headers.common['X-LOG-AUTHORIZATION'];
  }

  if (availableToken) {
    axiosObject.defaults.headers.common.Authorization = `jwt ${availableToken}`;
  } else {
    delete axiosObject.defaults.headers.common.Authorization;
  }

  let promise;

  if (params.type) {
    dispatch({
      type: params.type + PENDING,
      meta: params.meta || null,
    });
  } else if (params.readyType) {
    dispatch({
      type: params.readyType,
      meta: params.meta || null,
    });
  }

  // create cancelToken for this axios request
  const { cancelToken, cancel } = createCancelToken();
  const customPromise = axiosObject({
    method: params.method,
    url: params.url,
    params: params.params ? params.params : {},
    data: params.data ? params.data : {},
    cancelToken,
  })
    .then(response => {
      if (params.type) {
        dispatch({
          type: params.type + FULFILLED,
          data: response.data ? response.data : null,
          meta: params.meta || null,
        });
      } else if (params.successType) {
        dispatch({
          type: params.successType,
          data: response.data ? response.data : null,
          meta: params.meta || null,
        });
      }

      promise = new Promise(resolve => resolve(response));
      if (response && response.status === 401) {
        // Reset token
        dispatch({
          type: AUTH_RESET,
        });
      }

      return promise;
    })
    .catch(error => {
      /**
       * Dispatch cancelled type of action
       */
      console.log(error);

      if (axios.isCancel(error)) {
        if (params.type) {
          dispatch({
            type: params.type + CANCELLED,
            meta: params.meta || null,
          });
        } else if (params.cancelType) {
          dispatch({
            type: params.cancelType,
            meta: params.meta || null,
          });
        }

        return Promise.reject(`ACTION${CANCELLED}`);
      }

      const { status, headers, data, error: response } = processErrorResponse(
        error
      );

      if (status === 401) {
        dispatch({
          type: AUTH_RESET,
        });
      }

      if (params.type) {
        dispatch({
          type: params.type + REJECTED,
          data: { status, headers, data },
          meta: params.meta || null,
        });
      } else if (params.errorType) {
        dispatch({
          type: params.errorType,
          data: { status, headers, data },
          meta: params.meta || null,
        });
      }
      if (status === 404) {
        //handle this
        return;
      }

      return Promise.reject(response);
    });

  // augment the promise with the cancel method for cancellation
  customPromise.cancel = cancel;
  return customPromise;
};

export default axiosAPI;

const callAPI = (dispatch, params, nameAPI) => {
  axios.defaults.baseURL = configService[nameAPI];
  axios.defaults.adapter = httpAdapter;
  return dispatch({
    [CALL_API]: {
      params,
      axios,
      axiosAPI,
    },
  }).catch(err => {
    // Use catch to handle promise all fail
    // TODO: Tracking error api fail at here
    return Promise.reject(err);
  });
};

export const cmsAPI = (dispatch, params) => {
  return callAPI(dispatch, params, 'backendAPIHost');
};

export const userAPI = (dispatch, params) => {
  return callAPI(dispatch, params, 'backendAPIHost');
};

export const messageAPI = (dispatch, params) => {
  return callAPI(dispatch, params, 'backendAPIHost');
};

export const bookingAPI = (dispatch, params) => {
  return callAPI(dispatch, params, 'backendAPIHost');
};

export const productAPI = (dispatch, params) => {
  return callAPI(dispatch, params, 'backendAPIHost');
};

export const notificationAPI = (dispatch, params) => {
  return callAPI(dispatch, params, 'backendAPIHost');
};

export const logAPI = (dispatch, params) => {
  return callAPI(
    dispatch,
    {
      ...params,
      xLogAuthorizationKey: configService.xLogAuthorizationKey,
    },
    'backendAPIHost'
  );
};
