import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { SaveOutlined } from '@ant-design/icons';
import { Button } from 'antd';

// Components
import { Form, Formik } from 'formik';
import {
  CheckboxField,
  InputField,
  SelectField,
  TextAreaField,
  MarkdownField,
} from '../../../shared/App/components';

// Utils
import { validate } from '../emailValidators';

// Constants
const EMAIL_STATUS = ['active', 'inactive'];
const LOCALES = ['en', 'vi-VN', 'ko-KR'];

class EmailEditForm extends Component {
  render() {
    const { editMode, initialValues, handleSubmit } = this.props;

    return (
      <div className="content">
        <Formik
          initialValues={{ ...initialValues }}
          onSubmit={handleSubmit}
          enableReinitialize
          isInitialValid
          validate={validate}
        >
          {({ isSubmitting }) => {
            return (
              <Form>
                <div className="col-sm-12" style={{ padding: 0 }}>
                  <div className="col-sm-8">
                    <div className="">
                      <div className="">
                        <InputField
                          sub="*"
                          name="subject"
                          label="Subject"
                          placeholder="Type Subject"
                        />
                        <InputField
                          sub="*"
                          name="event"
                          label="Event"
                          placeholder="Type event"
                        />
                        <InputField
                          sub="*"
                          name="code"
                          label="Code"
                          placeholder="Type code"
                        />
                        {/* <TextAreaField
                          id="content_html"
                          name="content_html"
                          label="Content HTML"
                          rows={20}
                        /> */}
                        <MarkdownField
                          id="content_html"
                          name="content_html"
                          label="Content HTML"
                          placeholder="Content HTML"
                        />
                        <TextAreaField
                          id="content_plain"
                          name="content_plain"
                          label="Plain text content"
                          rows={10}
                        />
                        <TextAreaField
                          id="description"
                          name="description"
                          label="Additional notes (for staff to do internal notes)"
                          rows={4}
                        />
                        <CheckboxField
                          name="is_bcc_email"
                          label="BCC staffs when the email is sent?"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-sm-4">
                    <div className="box">
                      <div className="box-body">
                        <SelectField
                          name="locale"
                          label="Locale"
                          options={LOCALES.map(level => ({
                            label: level,
                            value: level,
                          }))}
                        />
                        <SelectField
                          name="state"
                          label="Status"
                          options={EMAIL_STATUS.map(status => ({
                            label: status,
                            value: status,
                          }))}
                        />
                        <Button
                          className="mt-4"
                          type="primary"
                          htmlType="submit"
                          disabled={isSubmitting}
                        >
                          <SaveOutlined /> {editMode ? 'Save changes' : 'Create'}
                        </Button>
                      </div>
                    </div>
                  </div>
                </div>
              </Form>
            );
          }}
        </Formik>
      </div>
    );
  }
}

EmailEditForm.propTypes = {
  handleSubmit: PropTypes.func,
  pristine: PropTypes.bool,
  reset: PropTypes.func,
  submitting: PropTypes.bool,
  error: PropTypes.string,
  isSuccess: PropTypes.bool,
  isError: PropTypes.bool,
  submitSucceeded: PropTypes.bool,
  dispatch: PropTypes.func,
  editMode: PropTypes.bool,
};

EmailEditForm.defaultProps = {
  editMode: false,
};

export default EmailEditForm;
