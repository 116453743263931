import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import {
  appendToQueryString,
  compareQueryStrings,
  parseQueryString
} from './urlQuery';

export const withQuery = WrappedComponent => withRouter(
  class QueryBasedComponent extends React.Component {
    static propTypes = {
      location: PropTypes.object.isRequired,
      history: PropTypes.object.isRequired,
    };

    componentDidUpdate(prevProps) {
      const { location: { search } } = this.props;
      const { location: { search: prevSearch } } = prevProps;

      // only invoke callback if 2 queries are not the same
      if (!compareQueryStrings(prevSearch, search)) {
        if (this.callback && typeof this.callback === 'function') {
          this.callback(parseQueryString(search));
        }
      }
    }

    setQueryCallback = (callback) => {
      this.callback = callback;
    };

    pushParams = (params) => {
      const { history, location: { search, pathname } } = this.props;

      const newQueryString = appendToQueryString(search, params);

      // return if 2 queries are the same
      if (compareQueryStrings(newQueryString, search)) {
        return;
      }

      history.push(`${pathname}${newQueryString}`);
    };

    replaceParams = (params) => {
      const { history, location: { search, pathname } } = this.props;

      const newQueryString = appendToQueryString('', params);

      history.push(`${pathname}${newQueryString}`);
    };

    render() {
      const { location: { search } } = this.props;
      const params = parseQueryString(search);

      return (
        <WrappedComponent
          {...this.props}
          setQueryCallback={this.setQueryCallback}
          pushParams={this.pushParams}
          replaceParams={this.replaceParams}
          queryParams={params}
        />
      );
    }
  });
