let configService = {
  // Change to one api host only
  backendAPIHost: process.env.REACT_APP_API_HOST || 'https://api.inspitrip.com',

  // for in-content image uploader api
  pikAPI: process.env.REACT_APP_PIK_API_URL || 'http://api.pik.inspitrip.com',

  publicRoot: process.env.REACT_APP_PUBLIC_ROOT_URL || '',
  mainSite: process.env.REACT_APP_WEB_URL || 'https://shop.inspitrip.com',
  itemsCountPerPage: process.env.REACT_APP_ITEMS_COUNT_PER_PAGE || 50,
  bookingItemsCountPerPage: process.env.REACT_APP_ITEMS_COUNT_PER_PAGE || 50,
  smallItemsCountPerPage: 10,
  xLogAuthorizationKey:
    process.env.REACT_APP_ACTION_LOG_KEY || '123456789',
  // Experience templates. Must be in sync with inspitrip-web
  // Merge language, country, locale => Must be in sync with inspitrip-web
  // Fetch from profile api [ko-KR, vi-VN, en-US]
  localeCurrencySettings: [
    {
      label: 'English - International - USD',
      value: 'en--USD',
      localeValue: 'en-',
    },
    {
      label: 'Vietnamese - Vietnam - VND',
      value: 'vi-VN-VND',
      localeValue: 'vi-VN',
    },
    { label: 'Korean - Korea - KRW', value: 'ko-KR-KRW', localeValue: 'ko-KR' },
  ],
  localeSettings: [
    { label: 'International (en)', value: 'en' },
    { label: 'Vietnamese (vi-VN)', value: 'vi-VN' },
    { label: 'Korean (ko-KR)', value: 'ko-KR' },
  ],
  experienceTemplates: ['v1', 'v2', 'v2_1'],
  currencyCodes: ['VND', 'USD', 'KRW'],
};

if (typeof window !== 'undefined') {
  // use config service injected from server
  configService = { ...configService, ...window.__ENV__ };
  delete window.__ENV__;
}

// avoid envs from being overwritten
export default Object.freeze(configService);
