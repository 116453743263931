import React from 'react';
import { errorToMessage } from './error';

const initialState = {
  isFetching: false,
  error: null,
  success: null,
};

/**
 * OperationalComponent helps encapsulate the fetching status of axiosAPI actions
 * It avoids the use of Redux for fetching logic
 * It injects into the WrappedComponent the following props
 * executeActions: func - a method to execute axiosAPI actions. Receives a config object
 * {
 *   actions: array of actions to execute
 *   onSuccess: success callback
 *   onFailure: failure callback
 *   successMsg: message to return when fetching is success
 *   failureMsg: message to return when fetching fails
 * }
 * success: string - the message when the fetching completes
 * error: object - the error object when the fetching fails
 * @param WrappedComponent - the component to wrap the logic with
 */
export const withOperation = (WrappedComponent) =>
  class OperationalComponent extends React.Component {
    static need = (WrappedComponent.need || []).map(action => {
      return ({ params, state, query, route }) => {
        return action({ params, state, query, route });
      };
    });

    state = initialState;

    componentDidMount() {
      // indicates that any setState is legal
      this._isMounted = true;
    }

    componentWillUnmount() {
      // indicates that any setState is not legal now
      this._isMounted = false;

      if (this.cancels) {
        this.cancels.forEach(cancel => ((cancel && typeof cancel === 'function') ? cancel() : null));
      }
    }

    onOperationPending = () => {
      // handle case where component cancels actions
      // then it should not call setState
      if (this._isMounted) this.setState({ ...initialState, isFetching: true });
    };

    onOperationSuccess = (message) => {
      // handle case where component cancels actions
      // then it should not call setState
      if (this._isMounted) this.setState({ isFetching: false, success: message });
    };

    onOperationFailed = (error) => {
      // handle case where component cancels actions
      // then it should not call setState
      if (this._isMounted) this.setState({ isFetching: false, error: errorToMessage(error) });
    };

    executeActions = ({ actions, onSuccess, onFailure, successMsg, failureMsg }) => {
      const promises = [];
      const selfCancels = [];

      this.onOperationPending();

      actions.forEach(action => {
        const promise = action();
        if (promise && promise.cancel) {
          if (!this.cancels) {
            this.cancels = [promise.cancel];
          } else {
            this.cancels.push(promise.cancel);
          }
          selfCancels.push(promise.cancel);
        }
        promises.push(promise);
      });

      Promise.all(promises)
        .then(() => {
          if (onSuccess && typeof onSuccess === 'function') onSuccess();
          this.onOperationSuccess(successMsg || '');
        })
        .catch((e) => {
          if (onFailure && typeof onFailure === 'function') onFailure();
          this.onOperationFailed(e.data || 'Something went wrong');
        });

      // return a way to explicitly cancel actions
      return () => {
        selfCancels.forEach(cancel => ((cancel && typeof cancel === 'function') ? cancel() : null));
      };
    };

    render() {
      const { isFetching, success, error } = this.state;

      return (
        <WrappedComponent
          {...this.props}
          success={success}
          isFetching={isFetching}
          error={error}
          executeActions={this.executeActions}
        />
      );
    }
  };
