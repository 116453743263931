import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { SaveOutlined } from '@ant-design/icons';
import { Button } from 'antd';

// Components
import { Form, Formik } from 'formik';
import {
  InputField,
  SelectField,
  TextAreaField,
  AsyncSelectField,
  DateField,
  NumericInputField,
  CreatableMultiSelectField,
  CheckboxField,
} from '../../../shared/App/components';

// Utils
import { validate } from '../couponValidators';
import configService from '../../../config/configService';

// Constants
import { COUNT_BY } from '../couponUtils';

class CollectionEditForm extends Component {
  render() {
    // Add editMode
    const {
      editMode,
      initialValues,
      handleSubmit,
      searchLocations,
    } = this.props;

    return (
      <div className="content">
        <Formik
          initialValues={{ ...initialValues }}
          onSubmit={handleSubmit}
          enableReinitialize
          isInitialValid
          validate={validate}
        >
          {({ isSubmitting, isValid, values, errors, setFieldValue }) => {
            return (
              <Form>
                <div className="col-md-12">
                  <div className="">
                    <div className="">
                      <SelectField
                        name="locale"
                        label="Locale"
                        options={configService.localeCurrencySettings}
                      />
                      <InputField
                        sub="*"
                        name="campaign"
                        label="Campaign"
                        placeholder="Type campaign"
                      />
                      <InputField
                        sub="*"
                        name="code"
                        label="Code"
                        placeholder="Type coupon code"
                      />

                      <NumericInputField name="value" label="Amount" />

                      <SelectField
                        name="count_by"
                        label="Unit"
                        options={Object.keys(COUNT_BY).map(key => ({
                          label: key,
                          value: COUNT_BY[key],
                        }))}
                      />

                      <NumericInputField
                        name="value_max"
                        label="Max discount amount (0 means unlimited)"
                      />

                      <InputField name="limit" label="Usage limit" />
                      
                      <InputField
                        name="used"
                        label="Total usages (til now)"
                        disabled
                      />

                      <label>Applicable duration</label>
                      <p>
                        The time duration in which the coupon can be applied
                        (unchecked means no limit)
                      </p>
                      <div
                        className="flex flex-row gap-y-12"
                        style={{ marginTop: 0 }}
                      >
                        <div className="flex flex-col gap-y-2">
                          <div className="form-group">
                            {/* <CheckboxField name="has_start_date" label="Start" /> */}
                            <label>Start</label>
                            {values.has_start_date && (
                              <DateField
                                name="start_date"
                                showTimeSelect
                                timeFormat="HH:mm"
                                dateFormat="YYYY-MM-DD, HH:mm "
                              />
                            )}
                          </div>
                        </div>
                        <div className="flex flex-col  gap-y-2">
                          <div className="form-group">
                            {/* <CheckboxField name="has_expired" label="Expired" /> */}
                            <label>Expired</label>
                            {values.has_expired && (
                              <DateField
                                name="expired"
                                showTimeSelect
                                timeFormat="HH:mm"
                                dateFormat="YYYY-MM-DD, HH:mm "
                              />
                            )}
                          </div>
                        </div>
                      </div>

                      <label>Applicable price</label>
                      <p>
                        The minimum price in which the coupon can be applied
                      </p>
                      <div className="flex flex-row">
                        <div className="flex flex-col  gap-y-2">
                          <NumericInputField name="price_min" />
                        </div>
                      </div>

                      <TextAreaField
                        id="message"
                        name="message"
                        label="Message"
                        rows={4}
                      />

                      <CreatableMultiSelectField
                        name="product_included"
                        label="Applicable products"
                      />

                      <CreatableMultiSelectField
                        name="product_excluded"
                        label="Excluded products"
                      />

                      <AsyncSelectField
                        name="place_code"
                        label="Applicable location"
                        loadOptions={(inputValue, callback) => {
                          if (!searchLocations) {
                            return;
                          }
                          searchLocations({
                            params: { search: inputValue },
                          }).then(response => {
                            const { results } = response.data;
                            const data = results.map(item => {
                              return {
                                label: item.name,
                                value: item.code,
                              };
                            });
                            callback(data);
                          });
                        }}
                      />
                      <CheckboxField
                        name="clear_place_code"
                        label="Clear location"
                      />

                      <Button
                        className="mt-4"
                        type="primary"
                        htmlType="submit"
                        disabled={isSubmitting}
                      >
                        <SaveOutlined /> {editMode ? 'Save changes' : 'Create'}
                      </Button>
                    </div>
                  </div>
                </div>
              </Form>
            );
          }}
        </Formik>
      </div>
    );
  }
}

CollectionEditForm.propTypes = {
  handleSubmit: PropTypes.func,
  pristine: PropTypes.bool,
  reset: PropTypes.func,
  submitting: PropTypes.bool,
  error: PropTypes.string,
  isSuccess: PropTypes.bool,
  isError: PropTypes.bool,
  submitSucceeded: PropTypes.bool,
  dispatch: PropTypes.func,
  editMode: PropTypes.bool,
};

CollectionEditForm.defaultProps = {
  editMode: false,
};

export default CollectionEditForm;
