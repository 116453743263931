import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Box, Button } from '../../../shared/App/components';

// containers
import UnitModal from '../forms/Unit/UnitModal';
import UnitPriceModal from '../forms/UnitPrice/UnitPriceModal';

// utils
import { displayCurrency } from '../../../utils/currency';

// config
import configApp from '../../../config/configApp';

class UnitItem extends Component {
  state = {
    isEditing: false,
    isEditingPrice: false
  };

  toggleEditing = isEditing => this.setState({ isEditing });

  toggleEditingPrice = isEditingPrice => this.setState({ isEditingPrice });

  render() {
    const { unit, variantId, experienceId } = this.props;

    return (
      <div style={{ borderBottom: '1px solid #e4e4e4' }}>
        <div className="row box-comments" style={{ padding: '10px 10px' }}>
          <div className="col-md-7">
            <strong>
              {unit.title} {unit.is_primary ? <span style={{ marginRight: '5px', padding: '5px 10px' }} className={'badge bg-blue'}>
                Is primary
              </span> : ''}
            </strong>
            {
              displayCurrency({
                currency: unit.currency_code,
                amount: unit.price_display
              })
            }
          </div>

          <div className="col-md-5 text-right">
            <Button style={{ marginRight: '7px' }} size="sm" onClick={() => this.toggleEditing(true)}>
              <i className="fa fa-pencil" /> Edit
            </Button>
            <Button size="sm" onClick={() => this.toggleEditingPrice(true)}><i className="fa fa-pencil" /> Price Table</Button>
          </div>
        </div>
        {this.state.isEditing && (
          <UnitModal
            unitId={unit.id}
            variantId={variantId}
            experienceId={experienceId}
            mode={configApp.editMode.EDIT}
            onDismiss={() => this.toggleEditing(false)}
          />
        )}
        {this.state.isEditingPrice && (
          <UnitPriceModal
            productId={experienceId}
            unitId={unit.id}
            variantId={variantId}
            onDismiss={() => this.toggleEditingPrice(false)}
          />
        )}
      </div>
    );
  }
}

UnitItem.propTypes = {
  unit: PropTypes.object.isRequired,
  variantId: PropTypes.number.isRequired,
  experienceId: PropTypes.number.isRequired
};

const mapStateToProps = (state, ownProps) => ({

});

export default connect(mapStateToProps)(UnitItem);
