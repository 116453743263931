import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';

// Component
import CouponEditForm from '../components/CouponEditForm';
import FormStatus from '../../../shared/App/components/FormStatus';
import Breadcrumb from '../../../shared/App/components/Breadcrumb';

// Actions
import { updateCoupon, getCoupon, resetCoupons } from '../couponActions';
import { getCouponDetail, getFetchStatus } from '../couponReducers';
import { searchLocations } from '../../LocationPage/cityActions';

// Utils
import { withOperation } from '../../../utils/OperationalComponent';
import { withQuery } from '../../../utils/QueryBasedComponent';
import { buildFormData, transformCouponDetail } from '../couponUtils';

class CouponEditPage extends Component {
  componentDidMount() {
    const { executeActions, match, getCouponAction } = this.props;
    executeActions({
      actions: [() => getCouponAction({ id: match.params.cuid })],
    });
  }

  handleSubmit = values => {
    const { executeActions, match, updateCouponAction } = this.props;

    // transform data
    const newCoupon = transformCouponDetail(values);

    executeActions({
      actions: [
        () => updateCouponAction({ id: match.params.cuid, params: newCoupon }),
      ],
      onSuccess: () => {
        this.props.resetCouponsAction();
        this.props.history.push('/coupons');
      },
      successMsg: 'Update successfully',
      failureMsg: 'Update fail',
    });
  };

  render() {
    const { coupon, isFetching, error, success } = this.props;

    if (!coupon) return <div>404</div>;

    const initialValues = buildFormData(coupon);
    const errorData = error ? error.data || 'Something went wrong.' : '';
    return (
      <Fragment>
        <Helmet>
          <title>Edit Coupon #{coupon.code} - Inspitrip HQ</title>
        </Helmet>
        <section className="content-header">
          <div className="flex flex-row" style={{ marginTop: '-15px', marginBottom: '15px' }}>
            <Breadcrumb
              history={[
                { title: 'InspitripHQ', url: '/' },
                { title: 'Coupons', url: '/coupons' },
                { title: 'Coupon Detail', url: '' },
              ]}
            />
          </div>

          <div className="nav-tabs-custom">
            <FormStatus formState={{ error: errorData, success }} />
          </div>
          <div className="flex flex-row">
            <div className="flex flex-col gap-y-7">
              <h1 style={{ fontSize: '24px', marginTop: 0 }}>Coupon</h1>
            </div>
          </div>
        </section>

        <div className="content body">
          <div className="">
            {isFetching ? (
              <div className="overlay">
                <i className="fa fa-refresh fa-spin" />
                Loading...
              </div>
            ) : (
              <CouponEditForm
                searchLocations={this.props.searchLocationsAction}
                handleSubmit={this.handleSubmit.bind(this)}
                initialValues={initialValues}
                editMode
              />
            )}
          </div>
        </div>
      </Fragment>
    );
  }
}

// Retrieve data from store as props
const mapStateToProps = (state, props) => {
  return {
    isFetching: getFetchStatus(state),
    coupon: getCouponDetail(state, props.match.params.cuid),
  };
};

export default connect(mapStateToProps, {
  getCouponAction: getCoupon,
  updateCouponAction: updateCoupon,
  resetCouponsAction: resetCoupons,
  searchLocationsAction: searchLocations,
})(withOperation(withQuery(CouponEditPage)));
