import { combineReducers } from 'redux';

import {
  FETCH_PROFILE,
  FETCH_PROFILE_DETAIL,
  RESET_PROFILE,
  UPDATE_PROFILE
} from './profileActions';
import { FULFILLED, PENDING, REJECTED } from '../../utils/axiosAPI';
import { appendByIds } from '../../utils/reducer';
import { transformProfileDetail } from './profileUtils';

export const fetchStatusInitialState = {
  count: 0,
  next: null,
  previous: null,
  isFetching: false,
  error: null
};

export const fetchStatus = (state = fetchStatusInitialState, action) => {
  switch (action.type) {
    case FETCH_PROFILE + FULFILLED: {
      const { results, ...rest } = action.data;
      return { ...state, isFetching: false, ...rest };
    }
    case FETCH_PROFILE + REJECTED: {
      return { ...state, isFetching: false, error: action.data };
    }
    case FETCH_PROFILE + PENDING: {
      return { ...state, isFetching: true, error: null };
    }
    case RESET_PROFILE: {
      return fetchStatusInitialState;
    }
    default: {
      return state;
    }
  }
};

export const byId = (state = {}, action) => {
  switch (action.type) {
    case FETCH_PROFILE + FULFILLED: {
      return appendByIds(state, action.data.results, 'id');
    }
    case FETCH_PROFILE_DETAIL + FULFILLED:
    case UPDATE_PROFILE + FULFILLED: {
      const profile = state[action.data.id];
      const newProfile =
        action.type === UPDATE_PROFILE + FULFILLED
          ? transformProfileDetail(action.data)
          : action.data;

      return {
        ...state,
        [action.data.id]: { ...profile, ...newProfile }
      };
    }
    default: {
      return state;
    }
  }
};

export const visibleIds = (state = [], action) => {
  switch (action.type) {
    case FETCH_PROFILE + FULFILLED: {
      /**
       * Always replace the visible profile
       */
      return action.data.results.map(profile => profile.id);
    }
    case RESET_PROFILE: {
      return [];
    }
    default: {
      return state;
    }
  }
};

export default combineReducers({
  fetchStatus,
  byId,
  visibleIds
});

export const getProfiles = state =>
  state.profile.visibleIds.map(id => state.profile.byId[id]);
export const getFetchStatus = state => state.profile.fetchStatus;
export const getProfileDetail = (state, id) => state.profile.byId[id];
