import { combineReducers } from 'redux';

// reducers
import variant from './variant';
import option from './unit';
import service from './service';

export default combineReducers({
  variant,
  option,
  service
});
