import { getRequestQuery } from '../modules/App/RequestReducer';
import { parseQueryString } from '../utils/urlQuery';
import { getAuthToken } from '../utils/localStorage';

export const CALL_API = 'CALL_API';

export default ({ dispatch, getState }) => next => action => {
  if (!action[CALL_API]) {
    return next(action);
  }

  const token = getAuthToken();
  const callAPI = action[CALL_API];
  const { params, axios, axiosAPI } = callAPI;

  params.token = token;

  const subParams = params.params || {};
  if (typeof window !== 'undefined') {
    params.params = {
      ...subParams,
      ...parseQueryString(window.location.search),
    };
  } else {
    params.params = { ...subParams, ...getRequestQuery(getState()) };
  }
  return axiosAPI(dispatch, params, axios);
};
