import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { withOperation } from '../../../../utils/OperationalComponent';
import { withQuery } from '../../../../utils/QueryBasedComponent';
import {
  duplicateExperience,
  fetchExperienceDetail,
  fetchExperienceStories,
  fetchExperienceStoriesOptions,
  addExperienceStory,
  deleteStoryPhoto,
} from '../../experienceActions';
import {
  getExperienceDetail,
  getExperienceStories,
  getExperienceStoriesOptions,
  getFetchStatus,
} from '../../experienceReducer';
import { validateAddOption } from '../../experienceValidators';
import ExperienceHeader from '../../components/ExperienceHeader';
import ExperiencePhotoModal from '../../components/ExperiencePhotoModal';
import ExperienceAddMediaModal from '../../components/ExperienceAddMediaModal';
import ExperienceMedia from '../../components/ExperienceMedia';

class ProductPhotoPage extends Component {
  state = {
    showAddModalPhoto: false,
    showModalPhoto: false,
    photoModalUrl: '',
    option: {},
    group: '',
  };

  componentDidMount() {
    const {
      executeActions,
      fetchExperienceDetailAction,
      fetchStoriesAction,
      fetchStoriesOptionsAction,
      match,
    } = this.props;

    executeActions({
      actions: [
        () => fetchExperienceDetailAction({ id: match.params.cuid }),
        () => fetchStoriesAction({ id: match.params.cuid }),
        () => fetchStoriesOptionsAction({ id: match.params.cuid }),
      ],
    });
  }

  onSubmitAddMedia(data) {
    const { experience } = this.props;
    return this.props.addStoryAction({ id: experience.id, data });
  }

  handleCloseModal() {
    this.setState({
      showAddModalPhoto: false,
      showModalPhoto: false,
      option: {},
      group: '',
    });
  }

  handleOpenModalPhoto(photoModalUrl) {
    this.setState({ showModalPhoto: true, photoModalUrl });
  }

  handleOpenAddModalPhoto() {
    this.setState({ showAddModalPhoto: true });
  }

  handleDeletePhoto(id) {
    const { experience } = this.props;
    return this.props.deleteStoryPhotoAction({ expId: experience.id, id });
  }

  buildSelectValue(options, value) {
    let selectValue = '';
    if (options) {
      Object.keys(options).forEach(key => {
        if (options[key] === value) {
          selectValue = key;
        }
      });
    }
    return selectValue;
  }

  render() {
    const {
      experience = {},
      experienceStatus,
      duplicateExperienceAction,
      stories: experienceStories,
      storiesOptions,
      error,
      success,
      match,
      history,
    } = this.props;
    const { isFetching } = experienceStatus;

    if (isFetching) return <section className="content">Loading..</section>;
    if (!experience || Object.keys(experience).length === 0) {
      return <section className="content">404 product not found</section>;
    }

    const productId = parseInt(match.params.cuid);

    return (
      <Fragment>
        <Helmet>
          <title>
            Edit Product #{`${match.params.cuid}`} - Media - Inspitrip HQ
          </title>
        </Helmet>
        <ExperienceHeader
          experience={experience}
          duplicateExperienceAction={duplicateExperienceAction}
          editMode
        />
        <section className="content body">
          <div className="flex flex-row">
            <div className="flex flex-col gap-y-8">
              <div className="nav-tabs-custom">
                <ul className="nav nav-tabs">
                  <li className="">
                    <Link to={`/products/${productId}/information`}>
                      Information
                    </Link>
                  </li>
                  <li className="">
                    <Link to={`/products/${productId}/settings`}>Settings</Link>
                  </li>
                  <li className="">
                    <Link to={`/products/${productId}/options`}>Options</Link>
                  </li>
                  <li className="active">
                    <Link to={`/products/${productId}/media`}>Media</Link>
                  </li>
                  <li className="">
                    <Link to={`/products/${productId}/faqs`}>FAQs</Link>
                  </li>
                </ul>
                <div className="tab-content">
                  <div className="tab-pane active">
                    <ExperienceMedia
                      stories={experienceStories}
                      handleOpenModalPhoto={this.handleOpenModalPhoto.bind(
                        this
                      )}
                      handleOpenAddModalPhoto={this.handleOpenAddModalPhoto.bind(
                        this
                      )}
                      handleDeletePhoto={this.handleDeletePhoto.bind(this)}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <ExperienceAddMediaModal
            storiesOptions={storiesOptions}
            showAddModal={this.state.showAddModalPhoto}
            handleCloseModal={this.handleCloseModal.bind(this)}
            media={{}}
            validate={validateAddOption}
            onSubmitAddMedia={this.onSubmitAddMedia.bind(this)}
          />
          <ExperiencePhotoModal
            showModalPhoto={this.state.showModalPhoto}
            photoUrl={this.state.photoModalUrl}
            handleCloseModal={this.handleCloseModal.bind(this)}
          />
        </section>
      </Fragment>
    );
  }
}

ProductPhotoPage.contextTypes = {
  router: PropTypes.object,
};

ProductPhotoPage.propTypes = {
  /**
   * Operational Props
   */
  success: PropTypes.string,
  error: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  isFetching: PropTypes.bool,
  executeActions: PropTypes.func,
  /**
   * ConnectedProps
   */
  experience: PropTypes.object,
  /**
   * Actions
   */
  fetchExperienceDetailAction: PropTypes.func.isRequired,
};

const mapStateToProps = (state, ownProps) => ({
  experienceStatus: getFetchStatus(state),
  experience: getExperienceDetail(state, ownProps.match.params.cuid),
  stories: getExperienceStories(state),
  storiesOptions: getExperienceStoriesOptions(state),
});

export default connect(mapStateToProps, {
  fetchExperienceDetailAction: fetchExperienceDetail,
  duplicateExperienceAction: duplicateExperience,
  fetchStoriesAction: fetchExperienceStories,
  fetchStoriesOptionsAction: fetchExperienceStoriesOptions,
  addStoryAction: addExperienceStory,
  deleteStoryPhotoAction: deleteStoryPhoto,
})(withOperation(withQuery(ProductPhotoPage)));
