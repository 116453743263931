import React from 'react';
import moment, { max, min } from 'moment';

// Components
import { Status } from '../../shared/App/components';
import { Link } from 'react-router-dom';

// Utils
import { formatDateTime } from '../../utils/time';
import { displayCurrency } from '../../utils/currency';

// Constants
export const StatusConfig = {
  [true]: { title: 'publish', color: Status.colors.GREEN },
  [false]: { title: 'pending', color: Status.colors.GREY }
};

export const COUNT_BY = {
  percent: 'percent',
  amount: 'amount'
};

export const transformCouponDetail = coupon => {
  let newCoupon = { ...coupon };

  const locale = newCoupon.locale.split('-');
  if (locale && locale.length > 2) {
    newCoupon.language = locale[0];
    newCoupon.country = locale[1];
    newCoupon.currency_code = locale[2];
  }

  if (newCoupon.product_included && newCoupon.product_included.length > 0) {
    newCoupon.product_included = newCoupon.product_included.map(product => product.value);
  } else {
    newCoupon.product_included = [];
  }

  if (newCoupon.product_excluded && newCoupon.product_excluded.length > 0) {
    newCoupon.product_excluded = newCoupon.product_excluded.map(product => product.value);
  } else {
    newCoupon.product_excluded = [];
  }

  if (coupon.place_code && !coupon.clear_place_code) {
    newCoupon.place_code = coupon.place_code.value;
    newCoupon.place_name = coupon.place_code.label;
  } else {
    newCoupon.place_code = '';
    newCoupon.place_name = '';
  }

  // remove expired/start_date if not checked
  if (!newCoupon.has_expired) {
    newCoupon.expired = '';
  }
  if (!newCoupon.has_start_date) {
    newCoupon.start_date = '';
  }

  delete newCoupon.has_expired;
  delete newCoupon.has_start_date;
  delete newCoupon.locale;
  delete newCoupon.editMode;
  delete newCoupon.used; // no update to this field
  delete newCoupon.users_applied; // no update to this field
  return newCoupon;
};

export const buildFormData = coupon => {
  if (!coupon) return {};
  return {
    ...coupon,
    locale: `${coupon.language}-${coupon.country}-${coupon.currency_code}`,
    expired: coupon.expired ? moment(coupon.expired) : moment(),
    start_date: coupon.start_date ? moment(coupon.start_date) : moment(),
    has_expired: true, // !!coupon.expired,
    has_start_date: true, // !!coupon.start_date,
    product_included: (coupon.product_included || []).map(product => ({ label: product, value: product })),
    product_excluded: (coupon.product_excluded || []).map(product => ({ label: product, value: product })),
    place_code: (coupon.place_code ? { value: coupon.place_code, label: coupon.place_name } : null),
  };
};

export const couponColumns = () => {
  return [
    {
      id: 'id',
      title: 'ID',
      render: ({ id }) => <Link to={`/coupons/${id}`}>{id}</Link>
    },
    {
      id: 'language',
      title: 'Locale',
      render: ({ language, country }) => (
        <span>
          {language} {`${country && `-${country}`}`}
        </span>
      )
    },
    {
      id: 'campaign',
      title: 'Campain',
      render: ({ campaign }) => <span>{campaign}</span>
    },

    {
      id: 'code',
      title: 'Code',
      render: ({ id, code }) => <Link to={`/coupons/${id}`}>{code}</Link>
    },
    {
      id: 'amount',
      title: 'Amount',
      render: ({ value, currency_code, count_by }) => {
        return count_by === COUNT_BY.amount ? <span>{displayCurrency({ amount: value, currency: currency_code })}</span> : <span>{value}%</span>;
      }
    },
    {
      id: 'value_max',
      title: 'Max discount',
      render: ({ value_max, value, currency_code, count_by }) => {
        if (count_by === COUNT_BY.amount) {
          // Amount
          return (
            <span>{displayCurrency({ amount: value, currency: currency_code })}</span>
          );
        } else {
          // Percent
          if (value_max > 0) {
            return (
              <span>{displayCurrency({ amount: value_max, currency: currency_code })}</span>
            );
          }
          return (
            <span className="label label-warning">Unlimited</span>
          );
        }
      }
    },
    {
      id: 'expired',
      title: 'Expired',
      render: ({ expired }) => <span>{formatDateTime(expired)}</span>
    },
    {
      id: 'used',
      title: 'Total usages',
      render: ({ used }) => <span>{used}</span>
    },
    {
      id: 'limit',
      title: 'Usage limit',
      render: ({ limit }) => <span>{limit}</span>
    }
  ];
};
