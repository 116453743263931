import { bookingAPI } from '../../utils/axiosAPI';

export const FETCH_PAYMENTS = 'transaction/FETCH_PAYMENTS';
export const FETCH_PAYMENT = 'transaction/FETCH_PAYMENT';
export const RESET_PAYMENT = 'transaction/RESET_PAYMENT';
export const CREATE_PAYMENT = 'transaction/CREATE_PAYMENT';
export const UPDATE_PAYMENT = 'transaction/UPDATE_PAYMENT';
export const DELETE_PAYMENT = 'transaction/DELETE_PAYMENT';
export const REFUND_PAYMENT = 'transaction/REFUND_PAYMENT';
export const CAPTURE_PAYMENT = 'transaction/CAPTURE_PAYMENT';
export const FORCE_CONFIRM_PAYMENT = 'transaction/FORCE_CONFIRM_PAYMENT';
export const FETCH_PAYMENT_METHOD = 'transaction/FETCH_PAYMENT_METHOD';

export const fetchPayments = params => {
  return dispatch =>
    bookingAPI(dispatch, {
      method: 'get',
      url: '/booking/admin/v1/payments',
      params,
      type: FETCH_PAYMENTS,
    });
};

export const resetPayment = () => ({
  type: RESET_PAYMENT,
});

export const fetchTransaction = id => {
  return dispatch =>
    bookingAPI(dispatch, {
      method: 'get',
      url: `/booking/admin/v1/payments/${id}`,
      type: FETCH_PAYMENT,
    });
};

// TODO: Update action
export const createPayment = ({ bookingId, data }) => dispatch =>
  bookingAPI(dispatch, {
    method: 'post',
    url: '/booking/admin/v1/payments',
    data,
    type: CREATE_PAYMENT,
  });

// TODO: Update action
export const updatePayment = ({ id, data }) => dispatch =>
  bookingAPI(dispatch, {
    method: 'patch',
    url: `/booking/admin/v1/payments/${id}`,
    data,
    type: UPDATE_PAYMENT,
  });

export const refundPayment = ({ token, data }) => dispatch =>
  bookingAPI(dispatch, {
    method: 'post',
    url: `/booking/admin/v1/payments/${token}/refund`,
    data,
    type: REFUND_PAYMENT,
  });

export const capturePayment = ({ token, data }) => dispatch =>
  bookingAPI(dispatch, {
    method: 'post',
    url: `/booking/admin/v1/payments/${token}/capture`,
    data,
    type: CAPTURE_PAYMENT,
  });

export const forceConfirmPayment = ({ token, data }) => dispatch =>
  bookingAPI(dispatch, {
    method: 'post',
    url: `/booking/admin/v1/payments/${token}/force-confirm`,
    data,
    type: UPDATE_PAYMENT,
  });

export const fetchPaymentMethods = () => {
  return dispatch =>
    bookingAPI(dispatch, {
      method: 'get',
      url: '/booking/admin/v1/payment-methods',
      type: FETCH_PAYMENT_METHOD,
    });
};
