import { Link } from 'react-router-dom';
import configApp from '../../config/configApp';
import { Status, Avatar } from '../../shared/App/components';
import { formatDateTime, timeAgo } from '../../utils/time';

export const reviewStatusConfig = {
  [configApp.reviewState.INIT]: { title: 'Init', color: Status.colors.GREY },
  [configApp.reviewState.PENDING]: {
    title: 'Pending',
    color: Status.colors.YELLOW,
  },
  [configApp.reviewState.APPROVED]: {
    title: 'Approved',
    color: Status.colors.GREEN,
  },
  [configApp.reviewState.REJECTED]: {
    title: 'Rejected',
    color: Status.colors.RED,
  },
};

/**
 * transformReviewDetail changes the field user and localguide of review detail
 * to user_id and localguide_id to avoid collision with the user and local object
 * @param {*} review - the review detail object
 */
export const transformReviewDetail = review => {
  let newReview = { ...review };
  delete newReview.created_by;
  delete newReview.reviewee;
  delete newReview.bid;

  if (review.created_by) {
    newReview.created_by = review.created_by.value;
  }

  if (review.reviewee) {
    newReview.reviewee = review.reviewee.value;
  }

  if (review.bid && !isNaN(review.bid)) {
    newReview.bid = parseInt(review.bid, 10);
  }

  return newReview;
};

export const buildFormData = review => {
  if (!review) return {};

  let review_data = {
    rating: review.rating,
    local_rating: review.local_rating,
    activity_rating: review.activity_rating,
    transportation_rating: review.transportation_rating,
    value_rating: review.value_rating,
    comfort_rating: review.comfort_rating,
    experience_id: review.experience_id,
    bid: review.bid,
    is_feature: review.is_feature,
    status: review.status,
    review_display_option: review.review_display_option,
    message: review.message,
    user_name: review.user_name,
  };

  if (review.created_by) {
    review_data.created_by = {
      label: `${review.created_by.first_name} ${review.created_by.last_name} (${review.created_by.email})`,
      value: review.created_by.user_id,
    };
  }

  if (review.reviewee) {
    review_data.reviewee = {
      label: `${review.reviewee.first_name} ${review.reviewee.last_name} (${review.reviewee.email})`,
      value: review.reviewee.user_id,
    };
  }

  return review_data;
};

export const reviewColumns = [
  {
    id: 'id',
    title: 'ID',
    render: ({ id }) => <Link to={`/reviews/${id}`}>#{id}</Link>,
  },
  {
    id: 'display_name',
    title: 'User',
    render: ({ user_name }) => <span>{user_name}</span>,
  },
  {
    id: 'reviewee',
    title: 'Supplier',
    render: ({ reviewee }) => {
      if (reviewee && reviewee.id) {
        return (
          <Link to={`/users/${reviewee.id}`}>
            <span>
              <Avatar
                img_src={reviewee.photo}
                user={reviewee}
              />
              &nbsp;
              {reviewee && reviewee.display_name}
            </span>
          </Link>
        );
      }
      return <div></div>;
    },
  },
  {
    id: 'rating',
    title: 'Rating',
    render: ({ rating }) => <span>{rating}</span>,
  },
  {
    id: 'experience_id',
    title: 'Product',
    render: ({ experience_id }) => {
      if (experience_id)
        return <Link to={`/products/${experience_id}`}>#{experience_id}</Link>;
      return <span>-</span>;
    },
  },
  {
    id: 'status',
    title: 'Status',
    render: ({ status }) => (
      <Status state={status} statuses={reviewStatusConfig} />
    ),
  },
  {
    id: 'createdDate',
    title: 'Created Date',
    render: ({ created }) => (
      <div style={{ width: '100px', whiteSpace: 'normal' }}>
        {formatDateTime(created)}
      </div>
    ),
  },
  {
    id: 'lastUpdate',
    title: 'Last Update',
    render: ({ modified }) => <span>{timeAgo(modified)}</span>,
  },
  {
    id: 'created_by',
    title: 'Created by',
    render: ({ created_by }) => {
      if (created_by && created_by.id) {
        return (
          <Link to={`/users/${created_by.id}`}>
            <span>
              <Avatar
                img_src={created_by.photo}
                user={created_by}
              />
              &nbsp;
              {created_by && created_by.display_name}
            </span>
          </Link>
        );
      }
      return <div>System</div>;
    },
  },
];
