const CURRENCY_CODE = {
  VND: 'VND',
  USD: 'USD',
  KRW: 'KRW',
};

// https://stackoverflow.com/questions/2901102/how-to-print-a-number-with-commas-as-thousands-separators-in-javascript
const formatMoney = amount => amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');

export const displayCurrency = ({ currency, amount }) => {
  let formatted = '';

  if (amount === null || amount === undefined) {
    return 'N/A';
  }

  formatted = formatMoney(amount);

  switch (currency) {
    case CURRENCY_CODE.VND: {
      return `${formatted}đ`;
    }
    case CURRENCY_CODE.USD: {
      return `$${formatted}`;
    }
    case CURRENCY_CODE.KRW: {
      return `₩${formatted}`;
    }
    default: {
      return formatted;
    }
  }
};
