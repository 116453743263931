import { Avatar } from 'antd';
import PropTypes from 'prop-types';
import { Component } from 'react';
import { connect } from 'react-redux';

// Contaners
import FAQsContainer from './FAQsContainer';
import FactsContainers from './FactsContainer';
import InformationContainer from './InformationContainer';
import MediaContainer from './MediaContainer';
import SpecificationContainer from './SpecificationContainer';

// Components
import { Form, Formik } from 'formik';
import {
  Button,
  FileInputField,
  InputField,
  SelectField,
  TabContent,
  Tabs,
} from '../../../shared/App/components';

// Actions
import { searchLocations } from '../pageActions';

// Utils
import { validate } from '../pageValidators';

// Constants
import { PAGE_STATUS } from '../pageUtils';

// const LEVEL = ['page'];
const LOCALES = ['en', 'vi-VN', 'ko-KR'];

class PageEditForm extends Component {
  state = {
    currentTab: 'information',
  };

  render() {
    // Add editMode
    const {
      editMode,
      initialValues,
      page,
      handleSubmit,
      pristine,
      reset,
      submitting,
      error,
      isError,
      isSuccess,
      submitSucceeded,
    } = this.props;
    return (
      <div className="content">
        <Formik
          initialValues={{ ...initialValues, editMode }}
          onSubmit={handleSubmit}
          enableReinitialize
          isInitialValid
          validate={validate}
        >
          {({ isSubmitting, isValid, values, errors, setFieldValue }) => {
            return (
              <Form>
                <div className='col-sm-12'>
                  <div className="col-sm-8">
                    <Tabs
                      tabLabels={[
                        'Information',
                        'FAQs',
                        'Facts',
                        'Specification',
                        'Media',
                      ]}
                      onTabChange={tabId => this.setState({ currentTab: tabId })}
                      activeTab={this.state.currentTab}
                      defaultTab="information"
                    >
                      <TabContent
                        tabId="information"
                        component={InformationContainer}
                        values={values}
                        setFieldValue={setFieldValue}
                      />
                      <TabContent
                        tabId="faqs"
                        component={FAQsContainer}
                        values={values}
                        setFieldValue={setFieldValue}
                      />
                      <TabContent
                        tabId="facts"
                        component={FactsContainers}
                        values={values}
                        setFieldValue={setFieldValue}
                      />
                      <TabContent
                        tabId="specification"
                        component={SpecificationContainer}
                        values={values}
                        setFieldValue={setFieldValue}
                      />
                      <TabContent
                        tabId="media"
                        component={MediaContainer}
                        values={values}
                        setFieldValue={setFieldValue}
                      />
                    </Tabs>
                  </div>
                  <div className="col-sm-4">
                    <div className="box" style={{ marginTop: '42px' }}>
                      <div className="box-body">
                        <SelectField
                          name="is_active"
                          label="Status"
                          options={PAGE_STATUS.map(status => ({
                            label: status,
                            value: status,
                          }))}
                        />
                        <InputField
                          type="number"
                          name="total_reviews"
                          label="Total review"
                        />
                        <InputField type="number" name="rating" label="Rating" />
                        <InputField
                          type="number"
                          name="total_experiences"
                          label="Total Experiences"
                        />
                        <InputField
                          type="number"
                          name="low_price"
                          label="Low price"
                        />
                        <InputField
                          type="number"
                          name="high_price"
                          label="High price"
                        />
                        <InputField type="number" name="sort" label="Order" />
                        {page && page.background_photo && (
                          <Avatar size="small" src={values.background_photo} />
                        )}
                        <FileInputField
                          label="Background Photo"
                          name="background_photo"
                        />
                        <br />
                        {page && page.meta_photo && (
                          <Avatar size="small" src={values.meta_photo} />
                        )}
                        <FileInputField label="Meta Photo" name="meta_photo" />
                        <br />{' '}
                        <FileInputField
                          label="Background Video"
                          name="background_video"
                        />
                      </div>
                    </div>
                    <div className="box-footer">
                      <Button
                        type="submit"
                        displayType="primary"
                        block
                        disabled={isSubmitting}
                      >
                        <i className="fa fa-save" />{' '}
                        {editMode ? 'Save changes' : 'Create'}
                      </Button>
                    </div>
                  </div>
                </div>
                
              </Form>
            );
          }}
        </Formik>
      </div>
    );
  }
}

PageEditForm.propTypes = {
  handleSubmit: PropTypes.func,
  pristine: PropTypes.bool,
  reset: PropTypes.func,
  submitting: PropTypes.bool,
  error: PropTypes.string,
  isSuccess: PropTypes.bool,
  isError: PropTypes.bool,
  submitSucceeded: PropTypes.bool,
  dispatch: PropTypes.func,
  editMode: PropTypes.bool,
};

PageEditForm.defaultProps = {
  editMode: false,
};

export default connect(null, { searchLocations })(PageEditForm);
