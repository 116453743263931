import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import Alert from '../../../shared/App/components/Alert';
import { createProfile } from '../profileActions';
import ProfileEditForm from '../forms/ProfileEditForm';
import { withOperation } from '../../../utils/OperationalComponent';
import { withQuery } from '../../../utils/QueryBasedComponent';

class ProfileCreatePage extends Component {
  onSubmit = values => {
    const { createProfileAction, executeActions } = this.props;
    const keys = Object.keys(values);
    const jsonFields = ['social_links', 'highlights', 'hot_keywords'];
    const fileFields = ['desktop_cover', 'mobile_cover'];
    let data = new FormData();
    keys.forEach(key => {
      if (jsonFields.indexOf(key) >= 0 && values[key] instanceof Object) {
        data.append(key, JSON.stringify(values[key]));
      } else if (fileFields.indexOf(key) < 0 || values[key] instanceof File) {
        data.append(key, values[key]);
      }
    });

    executeActions({
      actions: [() => createProfileAction(data)],
      successMsg: 'Create successfully',
    });
  };

  renderAlert() {
    const { success, error } = this.props;
    let msg = '';
    let title = 'Success';
    let type = 'success';
    if (error) {
      if (error instanceof Object && error.data) {
        msg = JSON.stringify(error.data);
      } else {
        msg = error.toString();
      }
      title = 'Error';
      type = 'danger';
    } else if (success) {
      msg = success;
    }

    if (msg !== '') {
      return <Alert title={title} type={type} content={msg} />;
    }

    return null;
  }

  render() {
    return (
      <Fragment>
        <Helmet>
          <title>Create Audience Profile - Inspitrip HQ</title>
        </Helmet>
        <section className="content-header">
          <div className="flex flex-row" style={{ marginTop: '-15px', marginBottom: '15px' }}>
            <ol className="breadcrumb">
              <li>
                <Link to={'/'}>InspitripHQ</Link>
              </li>
              <li>
                <Link to={'/audience/profiles'}>Audience Profiles</Link>
              </li>
              <li className="active">New Profile</li>
            </ol>
          </div>
          {this.renderAlert()}
          <div className="flex flex-row">
            <div className="flex flex-col gap-y-7">
              <h1 style={{ fontSize: '24px', marginTop: 0 }}>Profile</h1>
            </div>
          </div>
        </section>
        <div className="content body">
          <div className="">
            <ProfileEditForm handleSubmit={this.onSubmit} />
          </div>
        </div>
      </Fragment>
    );
  }
}

// Retrieve data from store as props
const mapStateToProps = (state, props) => {
  return {};
};

ProfileCreatePage.propTypes = {
  /**
   * Operational Props
   */
  success: PropTypes.string,
  error: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  isFetching: PropTypes.bool,
  executeActions: PropTypes.func,
  /**
   * Actions
   */
  createProfileAction: PropTypes.func.isRequired,
};

export default connect(mapStateToProps, {
  createProfileAction: createProfile,
})(withOperation(withQuery(ProfileCreatePage)));
