import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';
import { withOperation } from '../../../../utils/OperationalComponent';
import { withQuery } from '../../../../utils/QueryBasedComponent';
import { parseQueryString } from '../../../../utils/urlQuery';
import { Listings } from '../../../../shared/App/components';
import {
  fetchExperienceFields,
  fetchExperiences,
  resetExperiences,
} from '../../experienceActions';
import {
  getExperienceFields,
  getExperiences,
  getFetchStatus,
} from '../../experienceReducer';
import { experienceColumns } from '../../experienceUtils';

// Components
import FilterForm from '../../../../shared/Form/FilterForm';
import SearchForm from '../../../../shared/Form/SearchForm';

// Utils & Constant
import { getLocalePrefixByLanguage } from '../../../../utils/localeUtils';
import configService from '../../../../config/configService';
import {
  getSearchData,
  getFilterData,
  keyOptions,
} from '../../experienceFilters';
import { languageOptions } from '../../../../utils/filter';

const sortDirection = { asc: '', desc: '-' };

class ExperienceListPage extends Component {
  state = {
    showModalFilter: false,
    sortParams: { sortBy: 'modified', sortDirection: 'desc' },
  };

  componentDidMount() {
    const {
      setQueryCallback,
      experiences,
      location: { search },
      fetchExperienceFieldsAction,
    } = this.props;

    setQueryCallback(this.handleFetchExperiences);
    if (experiences.length === 0) {
      this.handleFetchExperiences(parseQueryString(search));
      fetchExperienceFieldsAction();
    }
  }

  componentWillUnmount() {
    if (this.cancel) this.cancel();
    this.props.resetExperiencesAction();
  }

  actions = [
    {
      title: <i className="fa fa-external-link" />,
      callback: exp => this.handleViewExperience(exp),
      render: () => <i className="fa fa-external-link" />,
      type: 'primary',
    },
    {
      title: 'Edit',
      callback: ({ id }) => {
        this.props.history.push(`/products/${id}`);
      },
      render: () => <i className="fa fa-edit" />,
      type: 'primary',
    },
  ];

  handleFetchExperiences = params => {
    const { fetchExperiencesAction } = this.props;
    const { page = 1, ordering = '-modified' } = params || {};
    if (this.cancel) this.cancel();
    this.cancel = fetchExperiencesAction({ params: { page, ordering } }).cancel;
  };

  handlePageChange = page => {
    this.props.pushParams({ page });
  };

  handleSort = values => {
    this.props.pushParams({
      ordering: `${sortDirection[values.sortDirection]}${values.sortBy}`,
    });
    this.setState({ sortParams: values });
  };

  handleViewExperience = experience => {
    const { id, slug, language } = experience;
    const localePrefix = getLocalePrefixByLanguage(language);
    window.open(
      `${configService.mainSite}${localePrefix}/product/${id}/${slug}`,
      '_blank'
    );
  };

  handleSearchChange = values => {
    this.props.pushParams({ search: values.search });
  };

  handleOpenModalFilter = () => {
    this.setState({ showModalFilter: true });
  };

  handleCloseModal = () => {
    this.setState({ showModalFilter: false });
  };

  handleFilterChange = values => {
    let params = {};
    values.filter.map(
      (item, index) => (params[item.key + item.type] = item.value)
    );
    params.search = this.props.queryParams.search;
    this.props.replaceParams(params);
    this.handleCloseModal();
  };

  render() {
    const {
      experiences,
      fetchStatus: { count, isFetching },
      queryParams: { page },
      fields,
    } = this.props;
    const { sortParams } = this.state;

    // Add options to filter
    const languageOpt = languageOptions();
    const otherOptions = [];
    otherOptions.push(languageOpt);

    if (fields) {
      const { type: fieldType, state: stateType } = fields;
      const typeOptions =
        fieldType && fieldType.choices && fieldType.choices.length > 0
          ? fieldType.choices.map(choice => ({
              label: choice.display_name,
              value: choice.value.toString(),
            }))
          : [];
      const stateOptions = stateType.choices.map(choice => ({
        label: choice.display_name,
        value: choice.value,
      }));
      if (typeOptions) {
        otherOptions.push({
          value: 'type',
          label: 'Product Type',
          type: 'select',
          subOptions: typeOptions,
        });
      }
      if (stateOptions) {
        otherOptions.push({
          value: 'state',
          label: 'Status',
          type: 'select',
          subOptions: stateOptions,
        });
      }
    }

    const searchData = getSearchData(this.props.queryParams);
    const filterData = getFilterData(this.props.queryParams, otherOptions);
    return (
      <Fragment>
        <Helmet>
          <title>All Products - Inspitrip HQ</title>
        </Helmet>
        <section className="content-header">
          <div className="flex flex-row" style={{ marginTop: '-15px', marginBottom: '15px' }}>
            <ol className="breadcrumb">
              <li>
                <Link to={'/'}>InspitripHQ</Link>
              </li>
              <li className="active">Products</li>
            </ol>
          </div>
          <div className="flex flex-row">
            <div className="flex flex-col gap-y-7">
              <h1 style={{ fontSize: '24px', marginTop: 0 }}>Products</h1>
            </div>
            <div className="flex flex-col gap-y-2" style={{ marginLeft: 'auto' }}>
              <Link to="/products/new" className="btn btn-primary">
                <i className="fa fa-plus"></i>
                &nbsp;Add Product
              </Link>
            </div>
          </div>
        </section>
        <section className="content-header">
          <div className="flex flex-row">
            <div className="col-sm-12 pl-0 pr-0">
              <SearchForm
                onSubmit={this.handleSearchChange}
                searchContent={searchData}
                filterData={filterData}
                handleOpenModalFilter={this.handleOpenModalFilter}
                placeHolderContent="Type search text"
              />
            </div>
          </div>
        </section>
        <div className="content body">
          <section style={{ marginTop: '15px' }}>
            <Listings
              listingsTitle="Product List"
              data={experiences}
              columns={experienceColumns(fields)}
              actions={this.actions}
              onSortHeader={this.handleSort}
              page={parseInt(page || 1, 10)}
              dataCount={count}
              itemsPerPage={configService.itemsCountPerPage}
              onPageChange={this.handlePageChange}
              pageRange={3}
              isFetching={isFetching}
              sortParams={sortParams}
              noHeader
            />
          </section>
        </div>
        <FilterForm
          onSubmit={this.handleFilterChange}
          onDismiss={this.handleCloseModal}
          visible={this.state.showModalFilter}
          arrayData={filterData}
          keyOptions={[...keyOptions, ...otherOptions]}
        />
      </Fragment>
    );
  }
}

ExperienceListPage.propTypes = {
  /**
   * ConnectedProps
   */
  fetchStatus: PropTypes.object,
  experiences: PropTypes.array,
  /**
   * Actions
   */
  fetchExperiencesAction: PropTypes.func.isRequired,
  resetExperiencesAction: PropTypes.func.isRequired,
  /**
   * QueryComponentProps
   */
  setQueryCallback: PropTypes.func.isRequired,
  pushParams: PropTypes.func.isRequired,
  queryParams: PropTypes.object,
  /* actions */
  fetchExperienceFieldsAction: PropTypes.func,
};

ExperienceListPage.contextTypes = {
  router: PropTypes.object,
};

const mapStateToProps = state => ({
  fetchStatus: getFetchStatus(state),
  experiences: getExperiences(state),
  fields: getExperienceFields(state).actions.POST,
});

export default connect(mapStateToProps, {
  fetchExperiencesAction: fetchExperiences,
  resetExperiencesAction: resetExperiences,
  fetchExperienceFieldsAction: fetchExperienceFields,
})(withOperation(withQuery(ExperienceListPage)));
