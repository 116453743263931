import { cmsAPI } from '../../utils/axiosAPI';

// FETCH pages list
export const FETCH_PAGES = 'page/FETCH_PAGES';
export const fetchPages = ({ params }) => {
  return dispatch =>
    cmsAPI(dispatch, {
      method: 'get',
      url: '/cms/admin/v1/pages',
      params,
      type: FETCH_PAGES,
    });
};

export const RESET_PAGES = 'page/RESET_PAGES';
export const resetPages = () => ({
  type: RESET_PAGES,
});

export const CREATE_PAGE = 'page/CREATE_PAGE';
export const createPage = ({ params }) => {
  return dispatch =>
    cmsAPI(dispatch, {
      method: 'post',
      url: '/cms/admin/v1/pages',
      data: params,
      type: CREATE_PAGE,
    });
};

export const UPDATE_PAGE = 'page/UPDATE_PAGE';
export const updatePage = ({ id, params }) => {
  return dispatch =>
    cmsAPI(dispatch, {
      method: 'patch',
      url: `/cms/admin/v1/pages/${id}`,
      data: params,
      type: UPDATE_PAGE,
    });
};

export const FETCH_PAGE = 'page/FETCH_PAGE';
export function getPage({ id }) {
  return dispatch =>
    cmsAPI(dispatch, {
      method: 'get',
      url: `/cms/admin/v1/pages/${id}`,
      type: FETCH_PAGE,
    });
}

export const DELETE_PAGE = 'page/DELETE_PAGE';
export function deletePage({ id }) {
  return dispatch =>
    cmsAPI(dispatch, {
      method: 'delete',
      url: `/cms/admin/v1/pages/${id}`,
      type: DELETE_PAGE,
    });
}

export const SEARCH_LOCATION = 'page/SEARCH_LOCATION';
export const searchLocations = ({ params }) => {
  return dispatch =>
    cmsAPI(dispatch, {
      method: 'get',
      url: '/cms/admin/v1/locations',
      params,
      type: SEARCH_LOCATION,
    });
};
