import moment from 'moment';

export const timeAgo = (timeData) => {
  // timeData type must be a string or number
  return timeData ? moment(timeData).fromNow() : undefined;
};

export const formatDateTime = (timeData) => {
  return timeData ? moment(timeData).format('MMM DD, YYYY h:mma') : undefined;
};

export const formatDate = (timeData) => {
  return timeData ? moment(timeData).format('MMM DD, YYYY') : undefined;
};
