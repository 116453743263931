import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Box, Button } from '../../../shared/App/components';

// containers
import ServiceModal from '../forms/Service/ServiceModal';

// utils
import { displayCurrency } from '../../../utils/currency';

// config
import configApp from '../../../config/configApp';

class Service extends Component {
  state = {
    isEditing: false,
  };

  toggleEditing = isEditing => this.setState({ isEditing });

  render() {
    const { service, variantId, experienceId } = this.props;

    return (
      <div style={{ borderBottom: '1px solid #e4e4e4' }}>
        <div className="row box-comments" style={{ padding: '10px 10px' }}>
          <div className="col-sm-10">
            <div>
              <strong>{service.label} </strong>
              {displayCurrency({
                currency: service.currency_code,
                amount: service.price,
              })}
            </div>
            <div dangerouslySetInnerHTML={{ __html: service.description }} />
            <ul>
              <li>Min booking: {service.limit_min || 'N/A'}</li>
              <li>Max booking: {service.limit_max || 'N/A'}</li>
            </ul>
          </div>
          <div className="flex flex-col  gap-y-2 text-right">
            <Button onClick={() => this.toggleEditing(true)} size="sm">
              <i className="fa fa-pencil" /> Edit
            </Button>
          </div>
        </div>

        {this.state.isEditing && (
          <ServiceModal
            serviceId={service.id}
            variantId={variantId}
            experienceId={experienceId}
            mode={configApp.editMode.EDIT}
            onDismiss={() => this.toggleEditing(false)}
          />
        )}
      </div>
    );
  }
}

Service.propTypes = {
  variantId: PropTypes.number.isRequired,
  experienceId: PropTypes.number.isRequired,
  service: PropTypes.object.isRequired,
};

const mapStateToProps = (state, ownProps) => ({});

export default connect(mapStateToProps)(Service);
