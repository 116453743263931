import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';

// Component
import PageEditForm from '../components/PageEditForm';
import Breadcrumb from '../../../shared/App/components/Breadcrumb';
import FormStatus from '../../../shared/App/components/FormStatus';

// Actions
import { updatePage, getPage, resetPages } from '../pageActions';
import { getPageDetail, getFetchStatus } from '../pageReducers';

// Utils
import { withOperation } from '../../../utils/OperationalComponent';
import { buildFormData, transformPageDetail } from '../pageUtils';
import { withQuery } from '../../../utils/QueryBasedComponent';

class PageEditPage extends Component {
  componentDidMount() {
    const { executeActions, match, getPageAction } = this.props;
    executeActions({
      actions: [() => getPageAction({ id: match.params.cuid })],
    });
  }

  handleSubmit = values => {
    const { executeActions, match, updatePageAction } = this.props;

    // transform data
    const newCollection = transformPageDetail(values);

    const data = new FormData();
    if (newCollection.background_photo instanceof File) {
      data.append('background_photo', newCollection.background_photo);
    }
    if (newCollection.background_video instanceof File) {
      data.append('background_video', newCollection.background_video);
    }
    if (newCollection.meta_photo instanceof File) {
      data.append('meta_photo', newCollection.meta_photo);
    }

    delete newCollection.meta_photo;
    delete newCollection.background_video;
    delete newCollection.background_photo;
    const jsonFields = ['facts', 'faqs', 'specification', 'photos'];

    Object.keys(newCollection).map(key => {
      if (jsonFields.indexOf(key) > -1) {
        data.append(key, JSON.stringify(newCollection[key]));
      } else {
        data.append(key, newCollection[key]);
      }
    });

    executeActions({
      actions: [
        () => updatePageAction({ id: match.params.cuid, params: data }),
      ],
      onSuccess: () => {
        this.props.resetPagesAction();
        this.props.history.push('/pages');
      },
      successMsg: 'Update successfully',
      failureMsg: 'Update fail',
    });
  };

  render() {
    const { page, match, isFetching, error, success } = this.props;
    const initialValues = buildFormData(page);
    const errorData = error ? error.data || 'Something went wrong.' : '';

    return (
      <Fragment>
        <Helmet>
          <title>Edit Page #{`${match.params.cuid}`} - Inspitrip HQ</title>
        </Helmet>
        <section className="content-header">
          <div className="flex flex-row" style={{ marginTop: '-15px', marginBottom: '15px' }}>
            <Breadcrumb
              history={[
                { title: 'InspitripHQ', url: '/' },
                { title: 'Pages', url: '/pages' },
                { title: 'Page Detail', url: '' },
              ]}
            />
          </div>
          <div className="nav-tabs-custom">
            <FormStatus formState={{ error: errorData, success }} />
          </div>
          <div className="flex flex-row">
            <div className="flex flex-col gap-y-7">
              <h1 style={{ fontSize: '24px', marginTop: 0 }}>Page</h1>
            </div>
          </div>
        </section>
        <div className="content body">
          <div className="">
            {isFetching ? (
              <div className="overlay">
                <i className="fa fa-refresh fa-spin" />
                Loading...
              </div>
            ) : (
              <PageEditForm
                page={page}
                handleSubmit={this.handleSubmit}
                initialValues={initialValues}
                editMode
              />
            )}
          </div>
        </div>
      </Fragment>
    );
  }
}

// Retrieve data from store as props
const mapStateToProps = (state, props) => {
  return {
    isFetching: getFetchStatus(state),
    page: getPageDetail(state, props.match.params.cuid),
  };
};

export default connect(mapStateToProps, {
  getPageAction: getPage,
  updatePageAction: updatePage,
  resetPagesAction: resetPages,
})(withOperation(withQuery(PageEditPage)));
