export const validate = values => {
  const errors = {};

  if (!values.subject) {
    errors.subject = 'Please enter subject.';
  }

  if (!values.event) {
    errors.event = 'Please enter event.';
  }

  if (!values.code) {
    errors.code = 'Please enter code.';
  }

  return errors;
};
