import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import Alert from '../../../shared/App/components/Alert';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import { createReview } from '../reviewActions';
import ReviewEditForm from '../forms/ReviewEditForm';
import { transformReviewDetail } from '../reviewUtils';
import { withOperation } from '../../../utils/OperationalComponent';
import { withQuery } from '../../../utils/QueryBasedComponent';

class ReviewCreatePage extends Component {
  handleSubmit = values => {
    const updatedData = transformReviewDetail(values);
    const { executeActions, createReviewAction } = this.props;
    executeActions({
      actions: [() => createReviewAction({ data: updatedData })],
      onSuccess: () => this.props.history.push('/reviews'),
      successMsg: 'Create successfully',
      failureMsg: 'Create fail',
    });
  };

  renderAlert() {
    const { success, error } = this.props;

    let msg = '';
    let title = 'Success';
    let type = 'success';
    if (error) {
      msg = error.toString();
      title = 'Error';
      type = 'danger';
    } else if (success) {
      msg = success;
    }

    if (msg !== '') {
      return <Alert title={title} type={type} content={msg} />;
    }

    return null;
  }

  render() {
    return (
      <Fragment>
        <Helmet>
          <title>Create Review - Inspitrip HQ</title>
        </Helmet>
        <section className="content-header">
          <div className="flex flex-row" style={{ marginTop: '-15px', marginBottom: '15px' }}>
            <ol className="breadcrumb">
              <li>
                <Link to={'/'}>InspitripHQ</Link>
              </li>
              <li>
                <Link to={'/reviews'}>Reviews</Link>
              </li>
              <li className="active">Create a review</li>
            </ol>
          </div>
          {this.renderAlert()}
          <div className="flex flex-row">
            <div className="flex flex-col gap-y-7">
              <h1 style={{ fontSize: '24px', marginTop: 0 }}>Review</h1>
            </div>
          </div>
        </section>
        <div className="content body">
          <div className="">
            <ReviewEditForm handleSubmit={this.handleSubmit.bind(this)} />
          </div>
        </div>
      </Fragment>
    );
  }
}

// Retrieve data from store as props
const mapStateToProps = (state, props) => {
  return {};
};

export default connect(mapStateToProps, {
  createReviewAction: createReview,
})(withOperation(withQuery(ReviewCreatePage)));
