import React from 'react';
import { Form, Formik } from 'formik';
import { Modal, InputField } from '../../../../../shared/App/components';

const validate = values => {
  const errors = {};

  if (!values.start_date) {
    errors.start_date = 'Please input a start date';
  } else if (!/^\d{4}\-(0[1-9]|1[012])\-(0[1-9]|[12][0-9]|3[01])$/.test(values.start_date)) {
    errors.start_date = 'Please input a valid date format: yyyy-mm-dd';
  }

  if (!values.end_date) {
    errors.end_date = 'Please input a end date';
  } else if (!/^\d{4}\-(0[1-9]|1[012])\-(0[1-9]|[12][0-9]|3[01])$/.test(values.end_date)) {
    errors.end_date = 'Please input a valid date format: yyyy-mm-dd';
  }

  return errors;
};

const EditBookingItemForm = ({ onSubmit, onDismiss, visible, bookingItem }) => (
  <Formik
    enableReinitialize
    validate={validate}
    initialValues={{
      start_date: (bookingItem && bookingItem.start_date) || '',
      end_date: (bookingItem && bookingItem.end_date) || ''
    }}
    onSubmit={onSubmit}
  >
    {({ isSubmitting, isValid, values, submitForm }) => (
      <Modal
        title="Edit this Item"
        confirmText="Save changes"
        dismissText="Cancel"
        onConfirm={submitForm}
        {...{ onDismiss, visible }}
      >
        <Form>
          <InputField name="start_date" label="Start Date" sub="*" placeholder="Ex: yyyy-mm-dd" />
          <InputField name="end_date" label="End Date" sub="*" placeholder="Ex: yyyy-mm-dd" />
        </Form>
      </Modal>
    )}
  </Formik>
);

export default EditBookingItemForm;
