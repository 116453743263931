import React from 'react';
import { Modal } from '../../../../../shared/App/components';

const CancelBookingItemForm = ({ onSubmit, onDismiss, visible }) => (
  <Modal
    title="Are you sure to cancel this Booking Item?"
    confirmText="Yes, sure"
    dismissText="Cancel"
    onConfirm={() => onSubmit()}
    {...{ onDismiss, visible }}
  >
    <p>
      This would cancel the Booking Item inside the according trip and cannot be
      undone.
    </p>
  </Modal>
);

export default CancelBookingItemForm;
