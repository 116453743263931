import React from 'react';
import { Link } from 'react-router-dom';

// Components
import { Status } from '../../shared/App/components';

// Utils
import { timeAgo } from '../../utils/time';
import { isEmpty } from '../../utils/dataType';

// Constants
export const StatusConfig = {
  [true]: { title: 'active', color: Status.colors.GREEN },
  [false]: { title: 'inactive', color: Status.colors.GREY }
};

export const PAYMENT_TYPE = {
  stripe: 'stripe',
  bank: 'bank',
  payoo: 'payoo',
  paypal: 'paypal'
};

export const transformPaymentDetail = payment => {
  let newPayment = { ...payment };

  const localeSplit = newPayment.locale ? newPayment.locale.split('-') : ['en', ''];
  newPayment.language = localeSplit[0] || '';
  newPayment.country = localeSplit[1] || '';
  newPayment.currency = localeSplit[2] || '';
  newPayment.is_active = newPayment.state === 'active';

  let extraData = {};
  if (newPayment.banks && newPayment.banks.length > 0) {
    extraData.banks = newPayment.banks;
  }
  newPayment.extra_data = JSON.stringify(extraData);
  delete newPayment.locale;
  delete newPayment.banks;

  return newPayment;
};

export const buildFormData = payment => {
  if (!payment) return {};

  let extraData = {
    banks: []
  };

  if (!isEmpty(payment.extra_data)) {
    extraData = JSON.parse(payment.extra_data);
  }

  return {
    ...payment,
    state: payment.is_active ? 'active' : 'inactive',
    banks: extraData.banks,
    locale: `${payment.language}-${payment.country}-${payment.currency}`
  };
};

export const paymentColumns = () => {
  return [
    {
      id: 'id',
      title: 'ID',
      render: ({ id }) => {
        return <div>{id}</div>;
      }
    },
    {
      id: 'language',
      title: 'Locale',
      render: ({ language, country, currency }) => (
        <span>
          {language} {`${country && `-${country}`}`} - {currency}
        </span>
      )
    },
    {
      id: 'title',
      title: 'Title',
      render: ({ id, title }) => {
        return <Link to={`/payments/methods/${id}`}>{title}</Link>;
      }
    },
    {
      id: 'payment_method',
      title: 'Payment Method',
      render: ({ payment_method }) => {
        return <span>{payment_method}</span>;
      }
    },
    {
      id: 'coefficient',
      title: 'Coefficient',
      render: ({ coefficient }) => {
        return <span>{coefficient}</span>;
      }
    },
    {
      id: 'is_active',
      title: 'Status',
      render: ({ is_active }) => <Status state={`${is_active}`} statuses={StatusConfig} />
    },
    {
      id: 'created',
      title: 'Created at',
      render: ({ created }) => <span>{timeAgo(created)}</span>
    },
    {
      id: 'modified',
      title: 'Updated at',
      render: ({ modified }) => <span>{timeAgo(modified)}</span>
    }
  ];
};
