import React from 'react';
import PropTypes from 'prop-types';
import { Form, Formik } from 'formik';
import { Modal, InputField } from '../../../../shared/App/components';
// import {
//   Modal,
//   InputField,
// } from '../../../../../shared/App/components';

const ForceConfirmPaymentForm = ({
  onSubmit,
  onDismiss,
  visible,
  transaction,
}) => (
  <Formik
    enableReinitialize
    onSubmit={onSubmit}
    initialValues={{
      transaction_id: (transaction && transaction.token) || '',
    }}
  >
    {({ isSubmitting, isValid, values, submitForm }) => (
      <Modal
        title="Confirm a payment"
        confirmText="Confirm"
        dismissText="Cancel"
        onConfirm={submitForm}
        onDismiss={onDismiss}
        visible={visible}
      >
        <Form>
          <div className="flex flex-row">
            <div className="col-xs-12">
              <InputField label="Transaction Code" name="transaction_id" />
            </div>
          </div>
        </Form>
      </Modal>
    )}
  </Formik>
);

ForceConfirmPaymentForm.propTypes = {
  onSubmit: PropTypes.func,
  onDismiss: PropTypes.func,
  visible: PropTypes.bool,
  transaction: PropTypes.object,
};

export default ForceConfirmPaymentForm;
