import React, { Fragment, Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import { Alert, Status } from '../../../../shared/App/components';
import { bookingItemStatusConfig } from '../../bookingItemUtils';
import { getBookingItemDetail } from '../../bookingItemReducer';
import configApp from '../../../../config/configApp';
import TimelineBlock from '../../../ActionLog/components/TimelineBlock';
import {
  fetchBookingItemDetail,
  updateBookingItem,
  sendBookingItemNotification,
  assignBookingItem,
  unassignBookingItem,
  cancelBookingItem,
  searchLocalGuide,
} from '../../bookingItemActions';
import { searchUser } from '../../../User/userActions';

import { withOperation } from '../../../../utils/OperationalComponent';
import { withQuery } from '../../../../utils/QueryBasedComponent';
import TravelerInfo from './blocks/TravelerInfo';
import LocalGuideInfo from './blocks/LocalGuideInfo';
import BookingItemInfo from './blocks/BookingItemInfo';
import EditBookingItemForm from './blocks/EditBookingItemForm';
import CancelBookingItemForm from './blocks/CancelBookingItemForm';
import SendBookingItemNotificationForm from './blocks/SendBookingItemNotificationForm';
import AssignBookingItemModal from './blocks/AssignBookingItemModal';
import UnassignBookingItemModal from './blocks/UnassignBookingItemModal';

const initialState = {
  showModalEditBooking: false,
  showModalCancelBookingItem: false,
  showAssignModal: false,
  showUnassignModal: false,
  showModalSendBookingNotification: false,
};

class BookingDetailPage extends Component {
  state = initialState;

  componentDidMount() {
    const { fetchItemDetailAction, executeActions, match } = this.props;
    executeActions({
      actions: [
        () =>
          fetchItemDetailAction({
            bookingId: match.params.bookingId,
            itemId: match.params.itemId,
          }),
      ],
    });
  }

  onSubmit = values => {
    const {
      updateBookingItemAction,
      executeActions,
      match,
      bookingItem,
    } = this.props;
    this.handleCloseModal();
    /**
     * Update booking item info
     */
    values.booking = bookingItem.booking;
    executeActions({
      actions: [
        () =>
          updateBookingItemAction({
            bookingId: match.params.bookingId,
            itemId: match.params.itemId,
            data: values,
          }),
      ],
      successMsg: 'Update successfully',
    });
  };

  handleAssignBookingItem = (data, { resetForm }) => {
    const { assignBookingItemAction, executeActions, match } = this.props;
    this.handleCloseModal();
    let newData = { localguide: null };

    if (data && data.localguide && data.localguide.value) {
      newData.localguide = data.localguide.value;
    }

    resetForm({ localguide: {} });
    executeActions({
      actions: [
        () =>
          assignBookingItemAction({
            itemId: match.params.itemId,
            data: newData,
          }),
      ],
      successMsg: 'Assign booking item successfully',
    });
  };

  handleUnassignBookingItem = data => {
    const { unassignBookingItemAction, executeActions, match } = this.props;
    this.handleCloseModal();
    executeActions({
      actions: [
        () =>
          unassignBookingItemAction({
            itemId: match.params.itemId,
          }),
      ],
      successMsg: 'Unassign booking item successfully',
    });
  };

  handleCancelBookingItem = data => {
    const { cancelBookingItemAction, executeActions, match } = this.props;
    this.handleCloseModal();
    executeActions({
      actions: [
        () =>
          cancelBookingItemAction({
            itemId: match.params.itemId,
          }),
      ],
      successMsg: 'Cancel booking item successfully',
    });
  };

  handleSendBookingItemNotification = data => {
    const {
      sendBookingItemNotificationAction,
      executeActions,
      match,
    } = this.props;
    this.handleCloseModal();
    executeActions({
      actions: [
        () =>
          sendBookingItemNotificationAction({
            itemId: match.params.itemId,
            data,
          }),
      ],
      successMsg: 'Send notification successfully',
    });
  };

  handleCloseModal = () => {
    this.setState(initialState);
  };

  handleOpenModalEditBooking = () => {
    this.setState({ showModalEditBooking: true });
  };

  handleOpenModalSendBookingNotification = () => {
    this.setState({ showModalSendBookingNotification: true });
  };

  handleOpenModalCancelBookingItem = () => {
    this.setState({ showModalCancelBookingItem: true });
  };

  handleOpenAssignModal = () => this.setState({ showAssignModal: true });

  handleOpenUnassignModal = () => this.setState({ showUnassignModal: true });

  renderAlert() {
    const { success, error } = this.props;

    let msg = '';
    let type = 'success';
    let title = 'Success';

    if (error) {
      msg = JSON.stringify(error.data);

      type = 'danger';
      title = 'Error';
    } else if (success) {
      msg = success;
    }

    if (msg !== '') {
      return <Alert title={title} type={type} content={msg} />;
    }

    return null;
  }

  renderItemAssignmentAction = () => {
    const { bookingItem } = this.props;

    if (!bookingItem) {
      return null;
    }

    const status = bookingItem.status.toString();

    return (
      <Fragment>
        {status === configApp.bookingItemState.UNASSIGNED && (
          <li>
            <a onClick={this.handleOpenAssignModal}>
              <i className="fa fa-check" />
              Fulfill the booking item
            </a>
          </li>
        )}
        {status === configApp.bookingItemState.ASSIGNED && (
          <li>
            <a onClick={this.handleOpenUnassignModal}>
              <i className="fa fa-remove" />
              Remove the supplier
            </a>
          </li>
        )}
        {(status === configApp.bookingItemState.ASSIGNED ||
          status === configApp.bookingItemState.UNASSIGNED) && (
          <li>
            <a onClick={this.handleOpenModalCancelBookingItem}>
              <i className="fa fa-remove" />
              Cancel the booking item
            </a>
          </li>
        )}
      </Fragment>
    );
  };

  render() {
    const { bookingItem = {}, isFetching, match } = this.props;
    if (!bookingItem) return <div>404</div>;

    return (
      <Fragment>
        <Helmet>
          <title>
            Fulfillment #{`${match.params.bookingId}-${match.params.itemId}`} -
            Inspitrip HQ
          </title>
        </Helmet>
        <section className="content-header">
          <div className="flex flex-row" style={{ marginTop: '-15px', marginBottom: '15px' }}>
            {bookingItem.booking && (
              <ol className="breadcrumb">
                <li>
                  <Link to={'/'}>InspitripHQ</Link>
                </li>
                <li>
                  <Link
                    to={{
                      pathname: '/fulfillments',
                      search: '?booking__status=1002',
                    }}
                  >
                    Fulfillment
                  </Link>
                </li>
                <li className="active">
                  Fulfillment #{bookingItem.booking}-{match.params.itemId}
                </li>
              </ol>
            )}
          </div>
          {this.renderAlert()}
          <div className="flex flex-row">
            <div className="flex flex-col gap-y-7">
              {bookingItem.booking && (
                <h1 style={{ fontSize: '24px', marginTop: 0 }}>
                  Fulfillment #{bookingItem.booking}-{match.params.itemId}{' '}
                  &nbsp;
                  <Status
                    state={`${bookingItem.status}`}
                    statuses={bookingItemStatusConfig}
                  />
                </h1>
              )}
            </div>
            <div className="flex flex-col gap-y-2 text-right" style={{ marginLeft: 'auto' }}>
              <div
                className="dropdown"
                style={{ display: 'inline-block', marginLeft: '10px' }}
              >
                <a
                  className="dropdown-toggle btn btn-default"
                  data-toggle="dropdown"
                  href="#"
                  aria-expanded="false"
                >
                  &middot;&middot;&middot;
                </a>
                {bookingItem.status === configApp.bookingItemState.CANCELLED ||
                bookingItem.status === configApp.bookingItemState.COMPLETED ? (
                  <ul className="dropdown-menu">
                    <li>
                      <a>No actions available</a>
                    </li>
                  </ul>
                ) : (
                  <ul className="dropdown-menu">
                    <li>
                      <a onClick={this.handleOpenModalEditBooking}>
                        <i className="fa fa-pencil" />
                        Edit booking item info
                      </a>
                    </li>
                    <li role="presentation" className="divider" />
                    {this.renderItemAssignmentAction()}
                    {bookingItem.status ==
                      configApp.bookingItemState.ASSIGNED && (
                      <li>
                        <a
                          onClick={this.handleOpenModalSendBookingNotification}
                        >
                          <i className="fa fa-envelope" />
                          Send confirmation email
                        </a>
                      </li>
                    )}
                  </ul>
                )}
              </div>
            </div>
          </div>
        </section>
        <div className="content body">
          <div className='flex flex-row'>
            <div className="flex flex-col gap-y-4">
              <BookingItemInfo
                isLoading={isFetching}
                bookingItem={bookingItem}
              />
            </div>
          </div>

          <div className="flex flex-row">
            <div className="flex flex-col">
              <div className="flex flex-row">
                <div className="col-sm-6">
                  <TravelerInfo
                    travelerId={
                      bookingItem.user &&
                      (typeof bookingItem.user === 'number'
                        ? bookingItem.user
                        : bookingItem.user.id)
                    }
                  />
                </div>
                <div className="col-sm-6">
                  <LocalGuideInfo
                    localId={
                      bookingItem &&
                      bookingItem.localguide &&
                      (typeof bookingItem.localguide === 'number'
                        ? bookingItem.localguide
                        : bookingItem.localguide.id)
                    }
                  />
                </div>
              </div>
              <div className="flex flex-row">
                <div className="col-sm-12">
                  <TimelineBlock
                    id={match.params.itemId}
                    contentType={'BookingItem'}
                  />
                </div>
              </div>
            </div>
          </div>
          <div>
            <EditBookingItemForm
              onSubmit={this.onSubmit}
              onDismiss={this.handleCloseModal}
              visible={this.state.showModalEditBooking}
              bookingItem={bookingItem}
            />
            <SendBookingItemNotificationForm
              onSubmit={() =>
                this.handleSendBookingItemNotification({
                  event: 'local-booking-confirm',
                })
              }
              onDismiss={this.handleCloseModal}
              visible={this.state.showModalSendBookingNotification}
              bookingItem={bookingItem}
            />
            <CancelBookingItemForm
              onSubmit={() => this.handleCancelBookingItem()}
              onDismiss={this.handleCloseModal}
              visible={this.state.showModalCancelBookingItem}
            />
            <AssignBookingItemModal
              onSubmit={this.handleAssignBookingItem}
              searchLocalGuideAction={this.props.searchLocalGuideAction}
              onDismiss={this.handleCloseModal}
              visible={this.state.showAssignModal}
            />
            <UnassignBookingItemModal
              onSubmit={() => this.handleUnassignBookingItem()}
              onDismiss={this.handleCloseModal}
              visible={this.state.showUnassignModal}
            />
          </div>
        </div>
      </Fragment>
    );
  }
}

BookingDetailPage.propTypes = {
  /**
   * OperationalProps
   */
  success: PropTypes.string,
  error: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  isFetching: PropTypes.bool,
  executeActions: PropTypes.func,
  /**
   * ConnectedProps
   */
  bookingItem: PropTypes.object,
  /**
   * Actions
   */
  fetchItemDetailAction: PropTypes.func,
};

const mapStateToProps = (state, props) => ({
  bookingItem: getBookingItemDetail(state, props.match.params.itemId),
});

export default connect(mapStateToProps, {
  fetchItemDetailAction: fetchBookingItemDetail,
  updateBookingItemAction: updateBookingItem,
  sendBookingItemNotificationAction: sendBookingItemNotification,
  assignBookingItemAction: assignBookingItem,
  unassignBookingItemAction: unassignBookingItem,
  cancelBookingItemAction: cancelBookingItem,
  searchLocalGuideAction: searchUser,
})(withOperation(withQuery(BookingDetailPage)));
