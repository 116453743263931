import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { DragDropContext, Droppable } from 'react-beautiful-dnd';
import { Button } from '../../../shared/App/components';

// containers
import VariantItem from './VariantItem';

// actions
import {
  fetchVariants,
  updateVariantOrder,
  updateVariant,
} from '../reducers/variant/actions';

// selectors
import { getVariantIds } from '../reducers/variant';

// utils
import { withOperation } from '../../../utils/OperationalComponent';

const buildNewOrder = (id, oldIndex, newIndex, ids) => {
  if (oldIndex < newIndex) {
    return [...ids.slice(0, oldIndex), ...ids.slice(oldIndex + 1, newIndex + 1), id, ...ids.slice(newIndex + 1)];
  }

  return [...ids.slice(0, newIndex), id, ...ids.slice(newIndex, oldIndex), ...ids.slice(oldIndex + 1)];
};

class VariantList extends React.Component {
  state = {
    isSorting: false,
  };

  componentDidMount = () => {
    const { executeActions, experienceId } = this.props;
    executeActions({
      actions: [
        () => this.props.fetchVariants(experienceId)
      ]
    });
  };

  updateNewOrder = newOrder => {
    const { experienceId } = this.props;

    this.props.updateVariantOrder(newOrder);

    // batch optimistic order updates
    const actions = newOrder.map((id, index) => () => this.props.updateVariant({
      experienceId,
      variantId: id,
      data: { sort: -index }
    }));

    this.props.executeActions({
      actions,
    });
  };

  toggleSorting = () => this.setState(state => ({ isSorting: !state.isSorting }));

  handleDragEnd = result => {
    if (!result.destination) {
      return;
    }

    if (result.source.index === result.destination.index) {
      return;
    }

    const newOrder = buildNewOrder(result.draggableId, result.source.index, result.destination.index, this.props.variantIds);
    this.updateNewOrder(newOrder);
  };

  render() {
    return (
      <DragDropContext onDragEnd={this.handleDragEnd}>
        <div style={{ marginTop: 8, marginBottom: 8 }}>
          <Button onClick={this.toggleSorting} displayType={this.state.isSorting ? 'primary' : 'default'}>
            <i className="fa fa-sort-amount-asc" /> Sort
          </Button>
        </div>
        <Droppable droppableId="option-group-list">
          {provided => (
            <div
              ref={provided.innerRef}
              {...provided.droppableProps}
            >
              {this.props.variantIds.map((id, index) => (
                <VariantItem key={id} id={id} index={index} experienceId={this.props.experienceId} isSorting={this.state.isSorting} />
              ))}
              {/* {...provided.placeholder} */}
            </div>
          )}
        </Droppable>
      </DragDropContext>
    );
  }
}

VariantList.propTypes = {
  experienceId: PropTypes.number.isRequired,
  variantIds: PropTypes.array.isRequired
};

const mapStateToProps = state => ({
  variantIds: getVariantIds(state)
});

const mapDispatchToProps = {
  updateVariantOrder,
  updateVariant,
  fetchVariants
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withOperation(VariantList));
