import { combineReducers } from 'redux';

import { FULFILLED } from '../../../utils/axiosAPI';
import {
  EDIT_ITINERARY_PHOTO,
  ADD_ITINERARY_PHOTO,
  FETCH_ITINERARIES,
  DELETE_ITINERARY_PHOTO
} from '../experienceActions';

import { appendByIds, deleteKeyValueFromById } from '../../../utils/reducer';

const byId = (state = {}, action) => {
  switch (action.type) {
    case FETCH_ITINERARIES + FULFILLED: {
      return appendByIds(state, action.data.results, 'id');
    }
    case EDIT_ITINERARY_PHOTO + FULFILLED:
    case ADD_ITINERARY_PHOTO + FULFILLED: {
      return appendByIds(state, [action.data], 'id');
    }
    case DELETE_ITINERARY_PHOTO + FULFILLED: {
      return deleteKeyValueFromById(state, action.meta.itinerary_photo_id);
    }
    default: {
      return state;
    }
  }
};

const allIds = (state = [], action) => {
  switch (action.type) {
    case FETCH_ITINERARIES + FULFILLED: {
      // reverse the fetched itineraries list
      return action.data.results.map(itinerary => itinerary.id).reverse();
    }
    case ADD_ITINERARY_PHOTO + FULFILLED: {
      return [...state, action.data.id];
    }
    case DELETE_ITINERARY_PHOTO + FULFILLED: {
      const itineraryId = action.meta.itinerary_photo_id;
      return state.filter(id => id !== itineraryId);
    }
    default: {
      return state;
    }
  }
};

export default combineReducers({ allIds, byId });

const getPath = state => state.experience.itineraries;

export const getItineraries = state => {
  const itineraries = getPath(state).allIds.map(id => getPath(state).byId[id]);
  return itineraries.reduce(
    (groups, current) => {
      const groupList = groups.groupByIds[current.group] || [];
      return {
        ...groups,
        groupByIds: {
          ...groups.groupByIds,
          [current.group]: [...groupList, current]
        },
        groupIds:
          groupList.length === 0
            ? [...groups.groupIds, current.group]
            : groups.groupIds
      };
    },
    { groupByIds: {}, groupIds: [] }
  );
};
