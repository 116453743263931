import React from 'react';
import { Field, FieldArray } from 'formik';

import { DeleteOutlined, PlusCircleOutlined } from '@ant-design/icons';

import Button from './Button';

const ArrayField = ({ name, label, arrayData, ...inputProps }) => (
  <FieldArray
    name={name}
    render={arrayHelpers => (
      <div className="form-horizontal form-group">
        <label>{label}</label>
        {arrayData && arrayData.length > 0 ? (
          arrayData.map((item, index) => (
            <div key={index} style={{ marginBottom: '15px' }}>
              <div
                style={{
                  width: 'calc(100% - 93px)',
                  display: 'inline-block',
                  marginRight: '10px',
                  verticalAlign: 'top',
                }}
              >
                <Field name={`${name}.${index}`} className="form-control" />
              </div>
              <Button
                onClick={() => arrayHelpers.remove(index)}
                displayType={'danger'}
                data-toogle="tooltip"
                title={'Remove'}
              >
                <DeleteOutlined />
              </Button>
              &nbsp;
              <Button
                onClick={() => arrayHelpers.insert(index + 1, '')}
                displayType={'primary'}
                data-toogle="tooltip"
                title={'Add'}
              >
                <PlusCircleOutlined />
              </Button>
            </div>
          ))
        ) : (
          <div>
            <Button
              onClick={() => arrayHelpers.push('')}
              data-toogle="tooltip"
              title={'Add Item'}
            >
              <PlusCircleOutlined />
            </Button>
          </div>
        )}
      </div>
    )}
  />
);

export default ArrayField;
