import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import {
  InputField,
  ArrayField,
  MarkdownField,
} from '../../../shared/App/components';
import Itineraries from './Itineraries';
import { slugify } from '../../../utils/slugify';
import TextEditor from 'modules/App/components/form/TextEditor';

class ExperienceInformation extends Component {
  handleTitleBlur = e => {
    const { values, setFieldValue } = this.props;

    // generate slug if does not exist
    if (!values.slug) {
      setFieldValue(
        'slug',
        slugify(e.target.value, {
          lower: true,
        })
      );
    }
  };

  render() {
    const {
      values,
      handleOpenAddModalPhoto,
      editMode,
      experience,
      handleOpenModalEditPhoto,
    } = this.props;
    const inclusion = (values && values.inclusion) || [];
    const exclusion = (values && values.exclusion) || [];

    return (
      <Fragment>
        <div style={{ paddingBottom: '25px' }}>
          <h4>
            <strong>General information</strong>
          </h4>
          <InputField
            name="title"
            label="Title"
            sub="*"
            onBlur={this.handleTitleBlur}
          />
          <InputField name="slug" label="Slug" sub="*" />
          <MarkdownField id="description" name="description" label="Description" />
          <MarkdownField id="overview" name="overview" label="Overview" />
          <MarkdownField
            id="guide_introduction"
            name="guide_introduction"
            label="Local Guide Introduction"
          />
          <MarkdownField id="content" name="content" label="Content" />
        </div>
        {editMode && experience ? (
          <Itineraries
            experienceId={experience.id}
            // handleOpenModalEditPhoto={handleOpenModalEditPhoto}
            // handleOpenAddModalPhoto={handleOpenAddModalPhoto}
          />
        ) : null}
        <div style={{ paddingBottom: '25px' }}>
          <h4>
            <strong>Inclusion & Exclusion</strong>
          </h4>
          <ArrayField
            name="inclusion"
            label="Inclusion"
            arrayData={inclusion}
          />
          <ArrayField
            name="exclusion"
            label="Exclusion"
            arrayData={exclusion}
          />
        </div>
        <div
          className="box box-default"
          style={{ padding: '10px', paddingBottom: '50px' }}
        >
          <h4>Other Information</h4>
          <MarkdownField
            id="how_to_use"
            name="how_to_use"
            label="How to use"
            placeholder="How to use"
          />
          <MarkdownField
            id="accommodation"
            name="accommodation"
            label="Accomodation"
            placeholder="Accomodation text"
          />
          <MarkdownField name="pickup_info" label="Pickup Information" />
          <MarkdownField name="advice" label="Advice" placeholder="Advice" />
          <p>
            Any suggestions or things travelers should know before they go.
            Examples: bring sunscreen, sunglasses, wear insect repellant.
          </p>
          <MarkdownField
            name="cancellation_policy"
            label="Cancellation Policy"
            placeholder="Cancelation Policy"
          />
          <MarkdownField
            name="notes"
            label="Important Notes (Sent mail)"
            placeholder="Important note"
          />
          <p>
            Things that travelers must know and will be sent along with the
            confirmation email. Examples:
            <br />- Bring your passport
            <br />- This itinerary is subject to change due to weather or sea
            condition
            <br />- Sleeveless shirts/Skirts/Shorts are prohibited in the area
          </p>
        </div>
      </Fragment>
    );
  }
}

ExperienceInformation.propTypes = {
  values: PropTypes.object,
};

export default ExperienceInformation;
