import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';

// Components
import { Listings, Modal } from '../../../shared/App/components';
import { withOperation } from '../../../utils/OperationalComponent';
import { withQuery } from '../../../utils/QueryBasedComponent';
import Breadcrumb from '../../../shared/App/components/Breadcrumb';

// Utils
import { collectionColumns } from '../collectionUtils';
import { parseQueryString } from '../../../utils/urlQuery';

// Actions
import {
  fetchCollections,
  fetchCollectionFields,
  deleteCollection,
} from '../collectionActions';

// Seletector
import { getCollections, getFetchStatus } from '../collectionReducers';

// Constants
import configService from '../../../config/configService';

class CollectionListPage extends Component {
  state = {
    showedDeleteModalConfirm: false,
    deletedId: null,
  };

  componentDidMount() {
    const {
      setQueryCallback,
      collections,
      location: { search },
      fetchCollectionFieldsAction,
    } = this.props;
    setQueryCallback(this.handleFetchCollections);

    if (collections && collections.length === 0) {
      this.handleFetchCollections(parseQueryString(search));
      fetchCollectionFieldsAction();
    }
  }

  handleFetchCollections = params => {
    const { fetchCollectionsAction } = this.props;
    const { page = 1 } = params || {};
    if (this.cancel) this.cancel();
    this.cancel = fetchCollectionsAction({ params: { page } }).cancel;
  };

  handlePageChange = page => {
    this.props.pushParams({ page });
  };

  handleDeleteCollection = () => {
    const { deletedId } = this.state;
    this.props.deleteCollectionAction({ id: deletedId }).then(res => {
      this.setState({ showedDeleteModalConfirm: false }, () => {
        this.handleFetchCollections(1);
      });
    });
  };

  // actions in Listing
  actions = [
    {
      title: 'Edit',
      callback: ({ id }) => {
        this.props.history.push(`/collections/${id}`);
      },
      render: () => <i className="fa fa-edit" />,
      type: 'primary',
    },
    {
      title: 'Delete',
      callback: ({ id }) => {
        // Show confirm popup
        this.setState({ showedDeleteModalConfirm: true, deletedId: id });
      },
      render: () => <i className="fa fa-remove" />,
      type: 'danger',
    },
  ];

  render() {
    // Data
    const {
      collections = [],
      fetchStatus,
      queryParams,
      fields = {},
    } = this.props;
    const { count, isFetching } = fetchStatus || {
      count: 0,
      isFetching: false,
    };
    const { page } = queryParams || { page: 0 };
    const { showedDeleteModalConfirm } = this.state;

    return (
      <div>
        {showedDeleteModalConfirm && (
          <Modal
            title="Are you sure to delete this Collection?"
            confirmText="Yes, sure"
            dismissText="Cancel"
            onConfirm={() => {
              this.handleDeleteCollection();
            }}
            onDismiss={() => {
              this.setState({ showedDeleteModalConfirm: false });
            }}
            visible={showedDeleteModalConfirm}
          >
            <p>This would delete the collection show on home page.</p>
          </Modal>
        )}

        <Helmet>
          <title>All Collections - Inspitrip HQ</title>
        </Helmet>
        <section className="content-header">
          <div className="flex flex-row" style={{ marginTop: '-15px', marginBottom: '15px' }}>
            <Breadcrumb
              history={[
                { title: 'InspitripHQ', url: '/' },
                { title: 'Collections', url: '' },
              ]}
            />
          </div>
          <div className="flex flex-row">
            <div className="flex flex-col gap-y-7">
              <h1 style={{ fontSize: '24px', marginTop: 0 }}>Collections</h1>
            </div>
            <div className="flex flex-col gap-y-2 text-right" style={{ marginLeft: 'auto' }}>
              {/* <Link to="/collections/new" className="btn btn-primary">
                <i className="fa fa-plus" />
                {' Add'}
              </Link> */}
            </div>
          </div>
        </section>
        <div className="content body">
          <section style={{ marginTop: '15px' }}>
            <Listings
              listingsTitle="Experience List"
              data={collections}
              columns={collectionColumns()}
              actions={this.actions}
              onSortHeader={this.handleSort}
              page={parseInt(page || 1, 10)}
              dataCount={count || 0}
              itemsPerPage={configService.itemsCountPerPage}
              onPageChange={this.handlePageChange}
              pageRange={3}
              isFetching={isFetching}
              noHeader
            />
          </section>
        </div>
      </div>
    );
  }
}

CollectionListPage.need = [
  ({ query }) => {
    const { page = 1 } = query || {};
    return fetchCollections({ params: { page } });
  },
];

CollectionListPage.propTypes = {};

const mapStateToProps = state => ({
  fetchStatus: getFetchStatus(state),
  collections: getCollections(state),
});

export default connect(mapStateToProps, {
  fetchCollectionsAction: fetchCollections,
  fetchCollectionFieldsAction: fetchCollectionFields,
  deleteCollectionAction: deleteCollection,
})(withOperation(withQuery(CollectionListPage)));
