/**
 *
 *
 * @summary pageValidator
 * @author cuonghx
 *
 * Created at     : 2019-03-30 12:54:31
 * Last modified  : 2019-03-30 12:56:45
 */

export const validate = values => {
  const errors = {};

  // if (!values.title) {
  //   errors.title = 'Please enter title.';
  // }

  // if (!values.name) {
  //   errors.name = 'Please enter name.';
  // }

  // if (!values.slug) {
  //   errors.slug = 'Please enter slug.';
  // }

  return errors;
};
