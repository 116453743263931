import { logAPI } from '../../utils/axiosAPI';

export const FETCH_LOG = 'log/FETCH_BOOKING_LOG';
export const fetchLog = (id, content_type) => {
  const params = {
    page: 1,
    content_type,
    content_object_id: id,
  };

  return dispatch =>
    logAPI(dispatch, {
      method: 'get',
      url: '/log/admin/v1/logs',
      params,
      type: FETCH_LOG,
    });
};

export const ADD_NOTE = 'log/ADD_NOTE';
export const createNote = (id, content_type, values, user) => {
  const data = {
    actor_id: user.id,
    actor_email: user.email,
    actor_name: user.display_name,
    content_type,
    content_object_id: id,
    action_flag: 'note',
    message: values.message,
  };

  return dispatch =>
    logAPI(dispatch, {
      method: 'post',
      url: '/log/admin/v1/logs',
      data,
      type: ADD_NOTE,
    });
};

export const UPDATE_NOTE = 'log/UPDATE_NOTE';
export const updateNote = (id, data) => {
  return dispatch =>
    logAPI(dispatch, {
      method: 'put',
      url: `/log/admin/v1/logs/${id}`,
      data,
      type: UPDATE_NOTE,
    });
};

export const DELETE_NOTE = 'log/DELETE_NOTE';
export const deleteNote = id => {
  return dispatch =>
    logAPI(dispatch, {
      method: 'delete',
      url: `/log/admin/v1/logs/${id}`,
      type: DELETE_NOTE,
    });
};

export const RESET_NOTE = 'log/RESET_NOTE';
export const resetNote = () => ({
  type: RESET_NOTE,
});
