import React from 'react';
import { Formik, Form } from 'formik';
import {
  InputField,
  MarkdownField,
  CheckboxField,
  Button,
} from '../../../../shared/App/components';

const VariantForm = ({
  handleSubmit,
  handleDelete,
  initialValues,
  isEditing,
  isDeleting,
}) => (
  <Formik
    onSubmit={handleSubmit}
    initialValues={initialValues}
    enableReinitialize
  >
    {({ isSubmitting, submitForm }) => (
      <Form>
        <InputField
          name="code"
          placeholder="experienceID_code"
          label="SKU*"
          sub="Type correct format: experienceID_code"
        />
        <InputField name="title" label="Label" />
        <MarkdownField name="text" label="Description (optional)" />
        <MarkdownField name="explaination" label="Explanation (optional)" />
        <CheckboxField name="is_active" label="Active" />
        <CheckboxField name="is_instant_booking" label="Is instant booking" />
        <div className="flex flex-row">
          <div className="col-sm-6">
            {isEditing && (
              <Button
                disabled={isDeleting}
                onClick={handleDelete}
                className="btn btn-flat text-red"
                style={{ marginLeft: '-12px' }}
              >
                <i className="fa fa-trash-o"></i> Delete this Option Group
              </Button>
            )}
          </div>
          <div className="col-sm-6 text-right">
            <Button
              onClick={() => submitForm()}
              displayType="primary"
              disabled={isSubmitting}
            >
              Save changes
            </Button>
          </div>
        </div>
      </Form>
    )}
  </Formik>
);

export default VariantForm;
