import { bookingAPI } from '../../utils/axiosAPI';

export const FETCH_PAYMENTS = 'method/FETCH_PAYMENTS';
export const fetchPaymentMethods = ({ params }) => {
  return dispatch =>
    bookingAPI(dispatch, {
      method: 'get',
      url: '/booking/admin/v1/payment-methods',
      params,
      type: FETCH_PAYMENTS,
    });
};

export const RESET_PAYMENT = 'method/RESET_PAYMENT';
export const resetPayment = () => ({
  type: RESET_PAYMENT,
});

export const CREATE_PAYMENT = 'method/CREATE_PAYMENT';
export const createPayment = ({ params }) => {
  return dispatch =>
    bookingAPI(dispatch, {
      method: 'post',
      url: '/booking/admin/v1/payment-methods',
      data: params,
      type: CREATE_PAYMENT,
    });
};

export const UPDATE_PAYMENT = 'method/UPDATE_PAYMENT';
export const updatePayment = ({ id, data }) => {
  return dispatch =>
    bookingAPI(dispatch, {
      method: 'patch',
      url: `/booking/admin/v1/payment-methods/${id}`,
      data,
      type: UPDATE_PAYMENT,
    });
};

export const FETCH_PAYMENT = 'method/FETCH_PAYMENT';
export function getPayment({ id }) {
  return dispatch =>
    bookingAPI(dispatch, {
      method: 'get',
      url: `/booking/admin/v1/payment-methods/${id}`,
      type: FETCH_PAYMENT,
    });
}

export const DELETE_PAYMENT = 'method/DELETE_PAYMENT';
export function deletePayment({ id }) {
  return dispatch =>
    bookingAPI(dispatch, {
      method: 'delete',
      url: `/booking/admin/v1/payment-methods/${id}`,
      type: DELETE_PAYMENT,
    });
}
