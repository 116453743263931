import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import { Helmet } from 'react-helmet';

// Components
import CouponEditForm from '../components/CouponEditForm';
import FormStatus from '../../../shared/App/components/FormStatus';
import Breadcrumb from '../../../shared/App/components/Breadcrumb';

// Actions
import { createCoupon } from '../couponActions';

// Utils
import { withOperation } from '../../../utils/OperationalComponent';
import { withQuery } from '../../../utils/QueryBasedComponent';
import { transformCouponDetail } from '../couponUtils';
import configService from '../../../config/configService';

class couponCreatePage extends Component {
  handleSubmit = values => {
    const { executeActions, createCouponAction } = this.props;

    // transform data
    const newcoupon = transformCouponDetail(values);

    executeActions({
      actions: [() => createCouponAction({ params: newcoupon })],
      onSuccess: () => {
        this.props.history.push('/coupons');
      },
      successMsg: 'Create successfully',
      failureMsg: 'Create fail',
    });
  };

  render() {
    const { error, success } = this.props;
    const errorData = error ? error.data || 'Something went wrong.' : '';
    return (
      <Fragment>
        <Helmet>
          <title>Create Coupon - Inspitrip HQ</title>
        </Helmet>
        <section className="content-header">
          <div className="flex flex-row" style={{ marginTop: '-15px', marginBottom: '15px' }}>
            <Breadcrumb
              history={[
                { title: 'InspitripHQ', url: '/' },
                { title: 'Coupons', url: '/coupons' },
                { title: 'Create Coupon', url: '' },
              ]}
            />
          </div>

          <div className="nav-tabs-custom">
            <FormStatus formState={{ error: errorData, success }} />
          </div>

          <div className="flex flex-row">
            <div className="flex flex-col gap-y-7">
              <h1 style={{ fontSize: '24px', marginTop: 0 }}>Create Coupon</h1>
            </div>
          </div>
        </section>
        <div className="content body">
          <div className="">
            <CouponEditForm
              initialValues={{
                locale: configService.localeCurrencySettings[0].value,
                expired: moment(),
                start_date: moment(),
                has_start_date: true,
                has_expired: true,
                value: 0,
                product_included: [],
                product_excluded: [],
              }}
              handleSubmit={this.handleSubmit}
            />
          </div>
        </div>
      </Fragment>
    );
  }
}

export default connect(null, {
  createCouponAction: createCoupon,
})(withOperation(withQuery(couponCreatePage)));
