import { Form, Formik } from 'formik';
import React from 'react';
import { connect } from 'react-redux';
import { Button, InputField, Timeline, Avatar } from '../../../shared/App/components';
import { withOperation } from '../../../utils/OperationalComponent';
import { getUser } from '../../Auth/AuthReducer';
import { createNote, fetchLog, resetNote } from '../logActions';
import { getLog } from '../logReducer';

class TimelineBlock extends React.Component {
  constructor(props) {
    super(props);

    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidMount() {
    const { executeActions, id, fetchLogAction, contentType } = this.props;

    executeActions({
      actions: [() => fetchLogAction(id, contentType)],
    });
  }

  handleSubmit(values, { resetForm }) {
    const {
      id,
      user,
      createNoteAction,
      executeActions,
      contentType,
    } = this.props;

    let actions = [];
    actions.push(() => createNoteAction(id, contentType, values, user));

    executeActions({
      actions,
      onSuccess: () => {
        resetForm({});
        this.props.fetchLogAction(id, contentType);
      },
      successMsg: 'Sent successfully',
    });
  }

  validate(values) {
    if (values && !values.message) {
      return 'Please leave your message';
    }
    return undefined;
  }

  render() {
    const {
      user,
    } = this.props;
    console.log('ser', user);
    return (
      <div className="timeline-block" style={{ paddingBottom: '20px', paddingTop: '20px' }}>
        <label>Timeline</label>
        <Formik
          onSubmit={this.handleSubmit}
          enableReinitialize
          validate={this.validate}
        >
          {({ isSubmitting }) => {
            return (
              <Form className="clearfix">
                {/* <Avatar
                  img_src={user.photo}
                  user={user}
                /> */}
                <div
                  className="input-group pull-right"
                  style={{ width: 'calc(100% - 0px)' }}
                >
                  <InputField
                    type="text"
                    name="message"
                    className="w-full"
                    placeholder="Leave notes for teamwork"
                  />
                  <span className="input-group pull-right">
                    <Button
                      type="submit"
                      displayType="primary"
                      block
                      disabled={isSubmitting}
                    >
                      <i className="fa fa-sticky-note" /> Send
                    </Button>
                  </span>
                </div>
              </Form>
            );
          }}
        </Formik>
        <Timeline actionHistory={this.props.logs} />
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => ({
  user: getUser(state),
  logs: getLog(state),
});

export default connect(mapStateToProps, {
fetchLogAction: fetchLog,
createNoteAction: createNote,
resetNoteAction: resetNote,
})(withOperation(TimelineBlock));
