import { productAPI, cmsAPI } from '../../utils/axiosAPI';

export const FETCH_COLLECTIONS = 'FETCH_COLLECTIONS';
export const fetchCollections = ({ params }) => {
  return dispatch =>
    cmsAPI(dispatch, {
      method: 'get',
      url: '/cms/admin/v1/collections',
      params,
      type: FETCH_COLLECTIONS,
    });
};

export const FETCH_COLLECTION_FIELDS = 'FETCH_COLLECTION_FIELDS';
export const fetchCollectionFields = () => {
  return dispatch =>
    cmsAPI(dispatch, {
      method: 'options',
      url: '/cms/admin/v1/collections',
      type: FETCH_COLLECTION_FIELDS,
    });
};

//
export const RESET_COLLECTIONS = 'RESET_COLLECTIONS';
export const resetCollections = () => ({
  type: RESET_COLLECTIONS,
});

export const CREATE_COLLECTION = 'CREATE_COLLECTION';
export const createCollection = ({ params }) => {
  return dispatch =>
    cmsAPI(dispatch, {
      method: 'post',
      url: '/cms/admin/v1/collections',
      data: params,
      type: CREATE_COLLECTION,
    });
};

export const UPDATE_COLLECTION = 'UPDATE_COLLECTION';
export const updateCollection = ({ id, params }) => {
  return dispatch =>
    cmsAPI(dispatch, {
      method: 'patch',
      url: `/cms/admin/v1/collections/${id}`,
      data: params,
      type: UPDATE_COLLECTION,
    });
};

export const FETCH_COLLECTION = 'FETCH_COLLECTION';
export function getCollection({ id }) {
  return dispatch =>
    cmsAPI(dispatch, {
      method: 'get',
      url: `/cms/admin/v1/collections/${id}`,
      type: FETCH_COLLECTION,
    });
}

export const DELETE_COLLECTION = 'DELETE_COLLECTION';
export function deleteCollection({ id }) {
  return dispatch =>
    cmsAPI(dispatch, {
      method: 'delete',
      url: `/cms/admin/v1/collections/${id}`,
      type: DELETE_COLLECTION,
    });
}

export const SEARCH_PRODUCTS = 'SEARCH_PRODUCTS';
export const searchProducts = ({ params }) => {
  return dispatch =>
    productAPI(dispatch, {
      method: 'get',
      url: '/cms/admin/v1/products',
      params,
      type: SEARCH_PRODUCTS,
    });
};
