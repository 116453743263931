import React from 'react';
import { Link } from 'react-router-dom';
import { Status } from '../../shared/App/components';
import { timeAgo } from '../../utils/time';

// Constants
export const StatusConfig = {
  published: { title: 'published', color: Status.colors.GREEN },
  init: { title: 'init', color: Status.colors.GREY },
  pending: { title: 'pending', color: Status.colors.YELLOW },
};

export const FeaturedValueConfig = {
  [true]: { title: 'Yes', color: Status.colors.GREEN },
  [false]: { title: 'No', color: Status.colors.GREY },
};

export const transformPlaceDetail = place => {
  let newPlace = { ...place };

  const localeSplit = newPlace.locale ? newPlace.locale.split('-') : ['en', ''];
  newPlace.language = localeSplit[0] || '';
  newPlace.country = localeSplit[1] || '';

  if (place.parent) {
    newPlace.parent = place.parent.value;
  }

  delete newPlace.editMode;
  delete newPlace.locale;
  return newPlace;
};

export const buildFormData = place => {
  if (!place) return {};
  return {
    ...place,
    parent: (place.parent ? { value: place.parent.id, label: place.parent.name } : null),
    locale: `${place.language}${place.country && `-${place.country}`}`
  };
};

// TODO: Build locale link

export const cityColumns = () => {
  return [
    {
      id: 'id',
      title: 'ID',
      render: ({ id }) => <Link to={`/locations/${id}`}>{id}</Link>
    },
    {
      id: 'name',
      title: 'Name',
      render: ({ id, name }) => <Link to={`/locations/${id}`}>{name}</Link>
    },
    {
      id: 'level',
      title: 'Level',
      render: ({ level }) => <div>{level}</div>
    },
    {
      id: 'code',
      title: 'Code',
      render: ({ code }) => <div>{code}</div>
    },
    {
      id: 'timezone',
      title: 'TimeZone',
      render: ({ timezone }) => <div>{timezone}</div>
    },
    {
      id: 'created',
      title: 'Created',
      render: ({ created }) => <span>{timeAgo(created)}</span>
    },
    {
      id: 'modified',
      title: 'Updated',
      render: ({ modified }) => <span>{timeAgo(modified)}</span>
    },
  ];
};
