import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

// containers
import UnitItem from './UnitItem';

import { getVariantUnits } from '../reducers/variant';

// utils
import { withOperation } from '../../../utils/OperationalComponent';

class UnitList extends Component {

  render() {
    const { variantId, experienceId, units } = this.props;

    if (this.props.isFetching) {
      return <h5>Loading units...</h5>;
    }

    if (!units || units.length === 0) {
      return null;
    }

    return (
      <div style={{ borderBottom: '1px solid #e4e4e4' }}>
        <h5><i className="fa fa-th"></i> Units</h5>
        {
          units.map(unit => (
            <UnitItem
              key={unit.id}
              unit={unit}
              variantId={variantId}
              experienceId={experienceId}
            />
          ))
        }
      </div>
    );
  }
}

UnitList.propTypes = {
  variantId: PropTypes.number.isRequired,
  experienceId: PropTypes.number.isRequired
};

const mapStateToProps = (state, ownProps) => ({
  units: getVariantUnits(state, ownProps.variantId)
});

const mapDispatchToProps = {

};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withOperation(UnitList));
