import { combineReducers } from 'redux';

// Actions
import { FETCH_PAYMENTS, RESET_PAYMENT, FETCH_PAYMENT, UPDATE_PAYMENT, DELETE_PAYMENT, CREATE_PAYMENT } from './paymentActions';

// Constants
import { FULFILLED, PENDING, REJECTED } from '../../utils/axiosAPI';
import { appendByIds } from '../../utils/reducer';

const fetchStatusInitialState = {
  count: 0,
  next: null,
  previous: null,
  isFetching: false,
  error: null
};

// Seperate reuse for all reducer
export const fetchStatus = (state = fetchStatusInitialState, action) => {
  switch (action.type) {
    case FETCH_PAYMENTS + FULFILLED: {
      const { results, ...rest } = action.data;
      return { ...state, isFetching: false, ...rest };
    }
    case FETCH_PAYMENTS + REJECTED: {
      return { ...state, isFetching: false, error: action.data };
    }
    case FETCH_PAYMENTS + PENDING: {
      return { ...state, isFetching: true, error: null };
    }
    case RESET_PAYMENT: {
      return fetchStatusInitialState;
    }
    default: {
      return state;
    }
  }
};

export const byId = (state = {}, action) => {
  switch (action.type) {
    case FETCH_PAYMENTS + FULFILLED: {
      return appendByIds(state, action.data.results, 'id');
    }
    case UPDATE_PAYMENT + FULFILLED:
    case FETCH_PAYMENT + FULFILLED: {
      return appendByIds(state, [action.data], 'id');
    }
    case CREATE_PAYMENT + FULFILLED: {
      return appendByIds(state, [action.data], 'id');
    }
    default:
      return state;
  }
};

export const visibleIds = (state = [], action) => {
  switch (action.type) {
    case FETCH_PAYMENTS + FULFILLED: {
      return action.data.results.map(paymentMethod => paymentMethod.id);
    }
    case CREATE_PAYMENT + FULFILLED: {
      return [action.data.id, ...state];
    }
    case RESET_PAYMENT: {
      return [];
    }

    default: {
      return state;
    }
  }
};

export default combineReducers({
  fetchStatus,
  visibleIds,
  byId
});

// Selector
export const getPayments = state => state.paymentMethod.visibleIds.map(id => state.paymentMethod.byId[id]);
export const getPaymentDetail = (state, id) => state.paymentMethod.byId[id];
export const getFetchStatus = state => state.paymentMethod.fetchStatus;
