import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

// Components
import { Form, Formik } from 'formik';
import {
  InputField,
  AsyncSelectField,
  Button,
  SelectField,
} from '../../../shared/App/components';

// Actions
import { searchCategories } from '../categoryActions';

// Utils
import { validate } from '../categoryValidators';
import { slugify } from '../../../utils/slugify';
const LOCALES = ['en', 'vi-VN', 'ko-KR'];

class CategoryForm extends Component {
  render() {
    const { editMode, initialValues, handleSubmit } = this.props;

    return (
      <div className="content">
        <Formik
          initialValues={{ ...initialValues, editMode }}
          onSubmit={handleSubmit}
          enableReinitialize
          isInitialValid
          validate={validate}
        >
          {({ values }) => {
            return (
              <Form>
                <div className="col-sm-4">
                  <div className="box-footer">
                    <InputField
                      sub="*"
                      name="title"
                      label="Title"
                      placeholder="Category title. Ex: Sim"
                    />
                    <SelectField
                      name="locale"
                      label="Locale"
                      options={LOCALES.map(level => ({
                        label: level,
                        value: level,
                      }))}
                    />
                    <AsyncSelectField
                      name="parent"
                      label="Parent"
                      loadOptions={(inputValue, callback) => {
                        let params = { search: inputValue };
                        if (values.locale) {
                          const [language, country] = values.locale.split('-');
                          params.language = language;
                          params.country = country;
                        }
                        this.props
                          .searchCategories({ params })
                          .then(response => {
                            const { results } = response.data;
                            const data = results.map(item => {
                              return {
                                label: item.title,
                                value: item.id,
                              };
                            });
                            callback(data);
                          });
                      }}
                    />

                    <Button type="submit" displayType="primary" block>
                      <i className="fa fa-save" />{' '}
                      {editMode ? 'Save changes' : 'Create'}
                    </Button>
                  </div>
                </div>
              </Form>
            );
          }}
        </Formik>
      </div>
    );
  }
}

CategoryForm.propTypes = {
  handleSubmit: PropTypes.func,
  pristine: PropTypes.bool,
  reset: PropTypes.func,
  submitting: PropTypes.bool,
  error: PropTypes.string,
  isSuccess: PropTypes.bool,
  isError: PropTypes.bool,
  submitSucceeded: PropTypes.bool,
  dispatch: PropTypes.func,
  editMode: PropTypes.bool,
};

CategoryForm.defaultProps = {
  editMode: false,
};

export default connect(null, { searchCategories })(CategoryForm);
