import { createStore, applyMiddleware, compose } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension/developmentOnly';
import thunk from 'redux-thunk';
import { createLogger } from 'redux-logger';
import rootReducer from './reducers';
import axiosAPIMiddleware from './middleware/axiosAPI';

const middleware = [thunk, axiosAPIMiddleware];

if (process.env.NODE_ENV === 'development') {
  // Enable DevTools only when rendering on client and during development.
  const loggerMiddleware = createLogger();
  middleware.push(loggerMiddleware);

  // disable all react-beautiful-dnd development warnings
  window['__react-beautiful-dnd-disable-dev-warnings'] = true;
}

const createStoreWithMiddleware = composeWithDevTools(
  applyMiddleware(...middleware)
)(createStore);

const store = createStoreWithMiddleware(rootReducer);

export default store;
