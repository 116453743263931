import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Form, Formik } from 'formik';

import {
  CheckboxField, Button, Modal, InputField, SelectField
} from '../../../shared/App/components';
import FormStatus from '../../../shared/App/components/FormStatus';
import { resizeImage } from '../../../utils/contentUtils';

class ExperienceAddMediaModal extends Component {
  constructor() {
    super();
    this.resetState = this.resetState.bind(this);
    this.state = {
      error: undefined
    };
  }

  onSubmit(values, form) {
    this.resetState();
    const data = new FormData();
    const keys = Object.keys(values);
    keys.forEach(key => {
      if (key !== 'attachment' && key !== 'thumbnail') {
        data.append(key, values[key]);
      }
    });

    const afterResizedCallback = () => {
      const { onSubmitAddMedia, handleCloseModal } = this.props;
      onSubmitAddMedia(data).then(() => {
        handleCloseModal();
        form.resetForm();
      }).catch((error) => {
        this.setState({ error: error.data || 'Oops! Please contact your administrator.' });
      });
    };

    if (values.attachment instanceof File) {
      resizeImage(values.attachment, resized => {
        data.append('attachment', resized);

        if (values.thumbnail instanceof File) {
          resizeImage(values.thumbnail, resizedThumb => {
            data.append('thumbnail', resizedThumb);
            afterResizedCallback();
          });
        } else {
          afterResizedCallback();
        }
      });
    }
  }

  resetState() {
    this.setState({
      error: undefined
    });
  }

  render() {
    const {
      handleCloseModal,
      showAddModal,
      validate,
      storiesOptions
    } = this.props;
    const { resetState } = this;
    const { attachment_type } = storiesOptions;
    const attachmentTypeOptions = attachment_type && attachment_type.choices ? attachment_type.choices.map(
      type => ({ label: type.display_name, value: type.value })
    ) : [];
    const initialValues = {};
    return (
      <Modal
        title="Add Media"
        confirmText="Confirm"
        dismissText="Cancel"
        onConfirm={() => {}}
        onDismiss={handleCloseModal}
        visible={showAddModal}
        hideFooter
      >
        {this.state.error ? <FormStatus formState={{ error: this.state.error }} /> : null}
        <Formik
          initialValues={initialValues}
          onSubmit={this.onSubmit.bind(this)}
          enableReinitialize
          isInitialValid
        >
          {
            ({ isSubmitting, isValid, values, setFieldValue, handleReset }) => (
              <Form>
                <InputField
                  name="title"
                  label="Title"
                />
                <div className="form-horizontal form-group">
                  <label htmlFor="input">Attachment (Video or Photo) *</label>
                  <input
                    className="form-control"
                    type="file"
                    name="attachment"
                    onChange={(event) => setFieldValue(event.currentTarget.name, event.currentTarget.files[0])}
                  />
                </div>
                <div className="form-horizontal form-group">
                  <label htmlFor="input">Thumbnail</label>
                  <input
                    className="form-control"
                    type="file"
                    name="thumbnail"
                    onChange={(event) => setFieldValue(event.currentTarget.name, event.currentTarget.files[0])}
                  />
                </div>
                <InputField
                  name="extra_link"
                  label="Extra link"
                />
                <InputField
                  name="weight"
                  label="Weight *"
                  type="number"
                />
                <SelectField
                  name="attachment_type"
                  label="Type *"
                  options={attachmentTypeOptions}
                />
                <br /><br />
                <div style={{ textAlign: 'right' }}>
                  <Button
                    className="btn btn-default"
                    onClick={() => {
                      handleReset();
                      resetState();
                      handleCloseModal();
                    }}
                  >Cancel</Button>
                  &nbsp;&nbsp;
                  <Button
                    className="btn btn-primary"
                    type="submit"
                    disabled={isSubmitting}
                    isLoading={isSubmitting}
                  >Submit</Button>
                </div>
              </Form>
            )
          }
        </Formik>
      </Modal>
    );
  }
}

ExperienceAddMediaModal.propTypes = {
  handleCloseModal: PropTypes.func,
  showAddModal: PropTypes.bool,
  storiesOptions: PropTypes.object,
  validate: PropTypes.func,
  onSubmitAddOption: PropTypes.func,
};

export default ExperienceAddMediaModal;
