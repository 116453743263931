import { combineReducers } from 'redux';

// actions
import {
  FETCH_VARIANTS,
  UPDATE_VARIANT,
  CREATE_VARIANT,
  DELETE_VARIANT,
  FETCH_VARIANT_DETAIL,
  UPDATE_VARIANT_ORDER
} from './actions';

import {
  CREATE_SERVICE,
  UPDATE_SERVICE,
  DELETE_SERVICE
} from '../service/actions';
import {
  CREATE_UNIT,
  UPDATE_UNIT,
  DELETE_UNIT
} from '../unit/actions';

// utils
import { FULFILLED } from '../../../../utils/axiosAPI';
import {
  appendByIds,
  deleteKeyValueFromById,
  deleteElementFromById
} from '../../../../utils/reducer';

const byId = (state = {}, action) => {
  switch (action.type) {
    case FETCH_VARIANTS + FULFILLED: {
      return appendByIds(state, action.data, 'id');
    }
    case UPDATE_VARIANT + FULFILLED: {
      return {
        ...state,
        [action.data.id]: { ...state[action.data.id], ...action.data }
      };
    }
    case CREATE_VARIANT + FULFILLED:
    case FETCH_VARIANT_DETAIL + FULFILLED: {
      return { ...state, [action.data.id]: action.data };
    }
    case DELETE_VARIANT + FULFILLED: {
      return deleteKeyValueFromById(state, action.meta.variantId);
    }
    case DELETE_UNIT + FULFILLED: {
      const variant = state[action.meta.variantId];
      const index = variant.units.findIndex(x => x.id === action.meta.unitId);
      if (index >= 0) {
        return {
          ...state, [action.meta.variantId]: {
            ...variant,
            units: deleteElementFromById(variant.units, index)
          }
        };
      }
      return state;
    }
    case UPDATE_UNIT + FULFILLED: {
      let variant = state[action.meta.variantId];
      const index = variant.units.findIndex(x => x.id === action.meta.serviceId);
      if (index >= 0) {
        variant.units[index] = action.data;
        return {
          ...state, [action.meta.variantId]: variant
        };
      }
      return state;
    }
    case CREATE_UNIT + FULFILLED: {
      let variant = state[action.meta.variantId];
      variant.units.push(action.data);
      return { ...state, [action.meta.variantId]: variant };
    }
    case DELETE_SERVICE + FULFILLED: {
      const variant = state[action.meta.variantId];
      const index = variant.extra_services.findIndex(x => x.id === action.meta.serviceId);
      if (index >= 0) {
        return {
          ...state, [action.meta.variantId]: {
            ...variant,
            extra_services: deleteElementFromById(variant.extra_services, index)
          }
        };
      }
      return state;
    }
    case UPDATE_SERVICE + FULFILLED: {
      let variant = state[action.meta.variantId];
      const index = variant.extra_services.findIndex(x => x.id === action.meta.serviceId);
      if (index >= 0) {
        variant.extra_services[index] = action.data;
        return {
          ...state, [action.meta.variantId]: variant
        };
      }
      return state;
    }
    case CREATE_SERVICE + FULFILLED: {
      let variant = state[action.meta.variantId];
      variant.extra_services.push(action.data);
      return { ...state, [action.meta.variantId]: variant };
    }
    default: {
      return state;
    }
  }
};

const allIds = (state = [], action) => {
  switch (action.type) {
    case FETCH_VARIANTS + FULFILLED: {
      return action.data.map(variant => variant.id);
    }
    case CREATE_VARIANT + FULFILLED: {
      return [...state, action.data.id];
    }
    case DELETE_VARIANT + FULFILLED: {
      return state.filter(id => id !== action.meta.variantId);
    }
    case UPDATE_VARIANT_ORDER: {
      return action.data;
    }
    default: {
      return state;
    }
  }
};

export default combineReducers({
  byId,
  allIds
});

// the absolute path to this reducer
const getPath = state => state.productOption.variant;

export const getVariantIds = state => getPath(state).allIds;
export const getVariant = (state, id) => getPath(state).byId[id];
export const getVariantUnits = (state, id) => getPath(state).byId[id].units;
export const getVariantServices = (state, id) => getPath(state).byId[id].extra_services;
