import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';

import {
  Button,
  SelectField,
  AsyncSelectField
} from '../../../shared/App/components';
import { connect } from 'react-redux';
import {
  searchExperienceLocation,
  searchLocalGuide
} from '../experienceActions';
import { searchCategories } from '../../Category/categoryActions';
import configService from '../../../config/configService';
import ExperienceShortBrief from '../components/ExperienceShortBrief';
import ExperienceCategory from './ExperienceCategory';

class ExperienceShortSettings extends Component {
  render() {
    const {
      editMode,
      isSubmitting,
      fields,
      values,
      experience,
      searchCategoriesAction
    } = this.props;

    if (!fields || !Object.keys(fields).length) {
      return (
        <div className="box" style={{ borderTop: '0px' }}>
          <div className="box-body">Loading..</div>
        </div>
      );
    }

    const { type: fieldType, language: languageType, state: stateType } = fields;
    const typeOptions = fieldType && fieldType.choices && fieldType.choices.length > 0 ? fieldType.choices.map(choice => ({ label: choice.display_name, value: choice.value })) : [];
    const stateOptions = stateType.choices.map(choice => ({ label: choice.display_name, value: choice.value }));

    return (
      <Fragment>
        <div className="box box-default" style={{ borderTop: '0px' }}>
          <div className="box-body">
            <SelectField
              name="type"
              label="Type"
              className="form-group"
              options={typeOptions}
            />
            <AsyncSelectField
              name="place"
              label="Location"
              loadOptions={(inputValue, callback) => {
                this.props.searchLocationAction({ params: { search: inputValue, level: 'city' } }).then(
                  (response) => {
                    const { results } = response.data;
                    const data = results.map((item) => {
                      return {
                        label: item.name,
                        value: {
                          place_id: item.id,
                          place_code: item.code,
                          place_name: item.name,
                          place_country: item.parent ? item.parent.code : null
                        }
                      };
                    });
                    callback(data);
                  }
                );
              }}
            />
            <AsyncSelectField
              name="user"
              label="Local Guide"
              loadOptions={(inputValue, callback) => {
                this.props.searchLocalGuideAction({ params: { q: inputValue } }).then(
                  (response) => {
                    const data = response.data.map((item) => {
                      return {
                        label: `${item.first_name} ${item.last_name} (${item.email})`,
                        value: item.id
                      };
                    });
                    callback(data);
                  }
                );
              }}
            />
            {/* We could base on this api https://e.inspitrip.com/xyz/places?q=sapa for auto selecting language-currency */}
            {/* TODO: fetch this from api */}
            <SelectField
              name="localeSetting"
              label="Display for (Language - Country - Currency)"
              className="form-group"
              options={configService.localeCurrencySettings}
            />
            <SelectField
              name="template"
              label="Template"
              className="form-group"
              options={configService.experienceTemplates.map(template => ({ label: template, value: template }))}
            />
            <SelectField
              name="state"
              label="Status"
              className="form-group"
              options={stateOptions}
            />
          </div>
        </div>
        <div className="box box-default" style={{ borderTop: '0px' }}>
          <div className="box-body">
            <ExperienceCategory experience={values} searchCategoriesAction={searchCategoriesAction} />
          </div>
        </div>
        <div className="box box-default" style={{ borderTop: '0px' }}>
          <div className="box-body">
            <ExperienceShortBrief experience={values} backgroundPhoto={experience && experience.background_photo} />
          </div>
        </div>
        <div className="box box-default" style={{ borderTop: '0px' }}>
          <div className="box-body">
            {
              editMode ? (
                <div className="box-body">
                  <Button
                    displayType="primary"
                    block type="submit"
                    disabled={isSubmitting}
                    isLoading={isSubmitting}
                  >Save changes</Button>
                </div>
              ) : (
                <div className="box-body">
                  <Button
                    displayType="primary"
                    block type="submit"
                    disabled={isSubmitting}
                    isLoading={isSubmitting}
                  >Create</Button>
                </div>
              )
            }
          </div>
        </div>
      </Fragment>
    );
  }
}

ExperienceShortSettings.defaultProps = {
  fields: {},
  editMode: false,
  isSubmitting: false
};

ExperienceShortSettings.propTypes = {
  fields: PropTypes.object,
  stateOptions: PropTypes.array,
  editMode: PropTypes.bool,
  values: PropTypes.object
};

export default connect(null, {
  searchLocalGuideAction: searchLocalGuide,
  searchLocationAction: searchExperienceLocation,
  searchCategoriesAction: searchCategories
})(ExperienceShortSettings);
