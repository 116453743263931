/*
* Convert an array into object literal collections with predefined property keys
* */
export const convertToCollections = (items, idProp) => {
  return items.reduce((coll, currentItem) => {
    return { ...coll, [currentItem[idProp]]: { ...currentItem } };
  }, {});
};

/*
* Append into byId, update existing object
* */
export const appendByIds = (byId, items, idProp) => {
  return { ...byId, ...convertToCollections(items, idProp) };
};

/*
* Append new items into an id array without duplicated items
* */
export const appendIdArrayWithoutDuplication = (arr, items, idProp) => {
  const arrSet = new Set(arr);
  items.forEach(item => arrSet.add(item[idProp]));
  return Array.from(arrSet);
};

/**
 * Delete key-value pair from byId
 */
export const deleteKeyValueFromById = (byId, key) => {
  let newById = { ...byId };
  delete newById[key];
  return newById;
};

/**
 * Delete element pair from byId array
 */
export const deleteElementFromById = (byId, key) => {
  let newById = [...byId];
  delete newById[key];
  return newById;
};
