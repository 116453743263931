import * as appActions from './AppActions';


export const requestInfo = (state = {
  protocol: null,
  host: null,
  cookie: null,
  query: {}
}, action) => {
  switch (action.type) {
    case appActions.SET_REQUEST_INFO:
      return {
        ...action.data
      };
    default:
      return state;
  }
};

export const getRequestQuery = state => state.requestInfo.query;
