const options = {
  Duration: [
    '< 3 hours',
    '3 - 5 hours',
    '6 - 9 hours',
    '10 - 12 hours',
    '2 -3 days',
    '> 3 days'
  ],
  Cancellation: [
    'Free cancellation before activity time',
    'Free cancellation before redemption',
    'Free cancellation with hours notice',
    'Free cancellation with condition',
    'No cancellation'
  ],
  'Valid duration': ['Fixed date ticket', 'Open date ticket'],
  'How to use': ['Mobile or printed voucher', 'Physical ticket'],
  'Group type': ['Join in group tour', 'Private tour'],
  Guide: ['Live guide', 'Translation machine', 'Booklets'],
  Pickup: ['Hotel pickup', 'Meetup'],
  Services: ['Skip the line', 'Instant confirmation', 'Meals']
};

const labels = Object.keys(options);

const icons = {
  Duration: 'icon_duration',
  'Skip the line': 'icon_skip_the_line',
  'Instant confirmation': 'icon_instant_confirmation',
  Cancellation: 'icon_cancellation',
  'Valid duration': 'icon_valid_duration',
  'Mobile or printed voucher': 'icon_mobile_or_printed_voucher',
  'Physical ticket': 'icon_physical_ticket',
  'Join in group tour': 'icon_join_in_group',
  'Private tour': 'icon_private_tour',
  'Live guide': 'icon_live_guide',
  'Translation machine': 'icon_translation_machine',
  Booklets: 'icon_booklets',
  'Hotel pickup': 'icon_pickup',
  Meetup: 'icon_meetup',
  Meals: 'icon_meals'
};

export default {
  options,
  labels,
  icons
};
