// Utils
import { getFilter, getSearch } from '../../utils/filter';

export const keyOptions = [
  { value: 'max_discount', label: 'Max discount', type: 'input' },
  { value: 'amount', label: 'Amount', type: 'input' },
];

// Can override if you want
export const getFilterData = (queryParams, options = []) => {
  return getFilter(queryParams, [...options, ...keyOptions]);
};

export const getSearchData = queryParams => {
  return getSearch(queryParams);
};
