/* eslint-disable global-require */
import { connect, useDispatch } from 'react-redux';
import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom';
import { getAuthToken, getAuthUser } from './utils/localStorage';
// import App from './modules/App/App2';
import Layout from './Layout';
import { setAuth } from './modules/Auth/AuthActions';
import { getIsAuth, getUser } from './modules/Auth/AuthReducer';

// import App from './modules/App/App';
import LoginPage from './modules/App/pages/LoginPage/LoginPage';
import Homepage from './modules/Home/pages/Homepage';
import ExperienceCreatePage from './modules/Product/pages/ExperienceCreatePage/ExperienceCreatePage';
import ExperienceDetailPage from './modules/Product/pages/ExperienceDetailPage/ExperienceDetailPage';
import ProductFAQPage from './modules/Product/pages/ExperienceDetailPage/faqs';
import ProductPhotoPage from './modules/Product/pages/ExperienceDetailPage/photos';
import ProductSettingPage from './modules/Product/pages/ExperienceDetailPage/settings';
import ExperienceVariantPage from './modules/Product/pages/ExperienceDetailPage/variants';
import ExperienceListPage from './modules/Product/pages/ExperienceListPage/ExperienceListPage';
import UserCreatePage from './modules/User/pages/UserCreatePage/UserCreatePage';
import UserEditPage from './modules/User/pages/UserEditPage/UserEditPage';
import UserListPage from './modules/User/pages/UserListPage/UserListPage';
// import OptionListPage from './modules/Option/pages/OptionListPage/OptionListPage';
// import OptionEditPage from './modules/Option/pages/OptionEditPage/OptionEditPage';
import ProfileCreatePage from './modules/AudienceProfiles/pages/ProfileCreatePage';
import ProfileEditPage from './modules/AudienceProfiles/pages/ProfileEditPage';
import ProfileListPage from './modules/AudienceProfiles/pages/ProfileListPage';
import BookingDetailPage from './modules/Booking/pages/BookingDetailPage/BookingDetailPage';
import BookingListPage from './modules/Booking/pages/BookingListPage/BookingListPage';
import BookingItemDetailPage from './modules/Fulfillment/pages/BookingItemDetailPage/BookingItemDetailPage';
import BookingItemListPage from './modules/Fulfillment/pages/BookingItemListPage/BookingItemListPage';
import ReviewCreatePage from './modules/Reviews/pages/ReviewCreatePage';
import ReviewEditPage from './modules/Reviews/pages/ReviewEditPage';
import ReviewListPage from './modules/Reviews/pages/ReviewListPage';
import TransactionDetailPage from './modules/Transactions/pages/TransactionDetailPage';
import TransactionListPage from './modules/Transactions/pages/TransactionListPage';

// Category Feature
import CategoryCreatePage from './modules/Category/pages/CategoryCreatePage';
import CategoryEditPage from './modules/Category/pages/CategoryEditPage';
import CategoryListPage from './modules/Category/pages/CategoryListPage';

// Location Feature
import LocationCreatePage from './modules/Location/pages/LocationCreatePage';
import LocationEditPage from './modules/Location/pages/LocationEditPage';
import LocationListPage from './modules/Location/pages/LocationListPage';
import LocationPageCreatePage from './modules/LocationPage/pages/LocationPageCreatePage';
import LocationPageEditPage from './modules/LocationPage/pages/LocationPageEditPage';
import LocationPageListPage from './modules/LocationPage/pages/LocationPageListPage';

// Collections Feature
import CollectionCreatePage from './modules/Collections/pages/CollectionCreatePage';
import CollectionEditPage from './modules/Collections/pages/CollectionEditPage';
import CollectionListPage from './modules/Collections/pages/CollectionListPage';

// Coupon Feature
import CouponCreatePage from './modules/Coupon/pages/CouponCreatePage';
import CouponEditPage from './modules/Coupon/pages/CouponEditPage';
import CouponListPage from './modules/Coupon/pages/CouponListPage';

// Payment Feature
import PaymentCreatePage from './modules/PaymentMethod/pages/PaymentCreatePage';
import PaymentEditPage from './modules/PaymentMethod/pages/PaymentEditPage';
import PaymentListPage from './modules/PaymentMethod/pages/PaymentListPage';

// Pages Feature
import PageCreatePage from './modules/Page/pages/PageCreatePage';
import PageEditPage from './modules/Page/pages/PageEditPage';
import PageListPage from './modules/Page/pages/PageListPage';

// Email Template Feature
import EmailCreatePage from './modules/Email/pages/EmailCreatePage';
import EmailEditPage from './modules/Email/pages/EmailEditPage';
import EmailListPage from './modules/Email/pages/EmailListPage';

// Email Log Feature
import EmailLogDetailPage from './modules/EmailLog/pages/EmailLogDetailPage';
import EmailLogListPage from './modules/EmailLog/pages/EmailLogListPage';

const mapStateToProps = state => {
  return {
    user: getUser(state),
    isAuth: getIsAuth(state),
  };
};

const PrivateRoute = connect(
  mapStateToProps
)(({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={props =>
      rest.isAuth ? <Component {...props} /> : <Redirect to="/login" />
    }
  />
));

function AppRouter() {
  const dispatch = useDispatch();
  const token = getAuthToken();
  const user = getAuthUser();

  if (token) {
    dispatch(setAuth(token, user));
  }

  return (
    <BrowserRouter>
      <Switch>
        <Route path="/login" exact component={LoginPage} />
        <Layout>
          <PrivateRoute path="/users" exact component={UserListPage} />
          <PrivateRoute path="/users/new" exact component={UserCreatePage} />
          <PrivateRoute
            path="/users/:cuid([0-9]+)"
            exact
            component={UserEditPage}
          />

          <PrivateRoute path="/products" exact component={ExperienceListPage} />
          <PrivateRoute
            path="/products/new"
            exact
            component={ExperienceCreatePage}
          />
          <PrivateRoute
            path="/products/:cuid([0-9]+)/options"
            exact
            component={ExperienceVariantPage}
          />
          <PrivateRoute
            path="/products/:cuid([0-9]+)/settings"
            exact
            component={ProductSettingPage}
          />
          <PrivateRoute
            path="/products/:cuid([0-9]+)/media"
            exact
            component={ProductPhotoPage}
          />
          <PrivateRoute
            path="/products/:cuid([0-9]+)/faqs"
            exact
            component={ProductFAQPage}
          />
          <PrivateRoute
            path="/products/:cuid([0-9]+)/information"
            exact
            component={ExperienceDetailPage}
          />
          <PrivateRoute
            path="/products/:cuid([0-9]+)"
            exact
            component={ExperienceDetailPage}
          />

          <PrivateRoute
            path="/audience/profiles"
            exact
            component={ProfileListPage}
          />
          <PrivateRoute
            path="/audience/profiles/new"
            exact
            component={ProfileCreatePage}
          />
          <PrivateRoute
            path="/audience/profiles/:pid([0-9]+)"
            exact
            component={ProfileEditPage}
          />

          <PrivateRoute path="/reviews" exact component={ReviewListPage} />
          <PrivateRoute
            path="/reviews/new"
            exact
            component={ReviewCreatePage}
          />
          <PrivateRoute
            path="/reviews/:cuid([0-9]+)"
            exact
            component={ReviewEditPage}
          />

          <PrivateRoute path="/bookings" exact component={BookingListPage} />
          <PrivateRoute
            path="/bookings/:cuid([0-9]+)"
            exact
            component={BookingDetailPage}
          />
          <PrivateRoute
            path="/fulfillments"
            exact
            component={BookingItemListPage}
          />
          <PrivateRoute
            path="/bookings/:bookingId([0-9]+)/fulfillments/:itemId([0-9]+)"
            exact
            component={BookingItemDetailPage}
          />

          <PrivateRoute
            path="/payments/transactions"
            exact
            component={TransactionListPage}
          />
          <PrivateRoute
            path="/payments/transactions/:transactionId([0-9]+)"
            exact
            component={TransactionDetailPage}
          />
          <PrivateRoute
            path="/payments/methods"
            exact
            component={PaymentListPage}
          />
          <PrivateRoute
            path="/payments/methods/new"
            exact
            component={PaymentCreatePage}
          />
          <PrivateRoute
            path="/payments/methods/:cuid([0-9]+)"
            exact
            component={PaymentEditPage}
          />

          <PrivateRoute
            path="/locations/pages"
            exact
            component={LocationPageListPage}
          />
          <PrivateRoute
            path="/locations/pages/new"
            exact
            component={LocationPageCreatePage}
          />
          <PrivateRoute
            path="/locations/pages/:cuid([0-9]+)"
            exact
            component={LocationPageEditPage}
          />

          <PrivateRoute path="/locations" exact component={LocationListPage} />
          <PrivateRoute
            path="/locations/new"
            exact
            component={LocationCreatePage}
          />
          <PrivateRoute
            path="/locations/:cuid([0-9]+)"
            exact
            component={LocationEditPage}
          />

          <PrivateRoute path="/categories" exact component={CategoryListPage} />
          <PrivateRoute
            path="/categories/new"
            exact
            component={CategoryCreatePage}
          />
          <PrivateRoute
            path="/categories/:cuid([0-9]+)"
            exact
            component={CategoryEditPage}
          />

          <PrivateRoute
            path="/collections"
            exact
            component={CollectionListPage}
          />
          <PrivateRoute
            path="/collections/new"
            exact
            component={CollectionCreatePage}
          />
          <PrivateRoute
            path="/collections/:cuid([a-zA-Z0-9-]+)"
            exact
            component={CollectionEditPage}
          />

          <PrivateRoute path="/coupons" exact component={CouponListPage} />
          <PrivateRoute
            path="/coupons/new"
            exact
            component={CouponCreatePage}
          />
          <PrivateRoute
            path="/coupons/:cuid([0-9]+)"
            exact
            component={CouponEditPage}
          />

          <PrivateRoute path="/pages" exact component={PageListPage} />
          <PrivateRoute path="/pages/new" exact component={PageCreatePage} />
          <PrivateRoute
            path="/pages/:cuid([0-9]+)"
            exact
            component={PageEditPage}
          />

          <PrivateRoute
            path="/emails/logs"
            exact
            component={EmailLogListPage}
          />
          <PrivateRoute
            path="/emails/logs/:cuid([0-9]+)"
            exact
            component={EmailLogDetailPage}
          />
          <PrivateRoute path="/emails" exact component={EmailListPage} />
          <PrivateRoute path="/emails/new" exact component={EmailCreatePage} />
          <PrivateRoute
            path="/emails/:cuid([0-9]+)"
            exact
            component={EmailEditPage}
          />

          <PrivateRoute path="/" exact component={Homepage} />
        </Layout>
      </Switch>
    </BrowserRouter>
  );
}

export default connect()(AppRouter);
