import React, { Component, Fragment } from 'react';
import { Helmet } from 'react-helmet';
import { connect } from 'react-redux';

import { Listings } from '../../../shared/App/components';
import Breadcrumb from '../../../shared/App/components/Breadcrumb';

import { fetchEmailLogs } from '../emailLogActions';
import { getEmailLogs, getFetchStatus } from '../emailLogReducers';
import { parseQueryString } from '../../../utils/urlQuery';
import { emailLogColumns } from '../emailLogUtils';
import configService from '../../../config/configService';
import { withOperation } from '../../../utils/OperationalComponent';
import { withQuery } from '../../../utils/QueryBasedComponent';

class EmailLogListPage extends Component {
  componentDidMount() {
    const {
      setQueryCallback,
      emailLogs,
      location: { search },
    } = this.props;

    setQueryCallback(this.handleFetchData);

    if (!emailLogs || emailLogs.length === 0) {
      this.handleFetchData(parseQueryString(search));
    }
  }

  handleFetchData = params => {
    const { fetchEmailLogsAction } = this.props;
    const { page = 1 } = params || {};
    if (this.cancel) this.cancel();
    this.cancel = fetchEmailLogsAction({ params: { page } }).cancel;
  };

  handlePageChange = page => {
    this.props.pushParams({ page });
  };

  render() {
    const { emailLogs, fetchStatus, queryParams } = this.props;
    const { count, isFetching } = fetchStatus || {
      count: 0,
      isFetching: false,
    };
    const { page } = queryParams || { page: 0 };

    return (
      <Fragment>
        <Helmet>
          <title>All Email Logs - Inspitrip HQ</title>
        </Helmet>
        <section className="content-header">
          <div className="flex flex-row" style={{ marginTop: '-15px', marginBottom: '15px' }}>
            <Breadcrumb
              history={[
                { title: 'InspitripHQ', url: '/' },
                { title: 'Notification Logs', url: '' },
              ]}
            />
          </div>
          <div className="flex flex-row">
            <div className="flex flex-col gap-y-7">
              <h1 style={{ fontSize: '24px', marginTop: 0 }}>
                Notification Logs
              </h1>
            </div>
            <div className="flex flex-col gap-y-2	  text-right"></div>
          </div>
        </section>
        <div className="content body">
          <section style={{ marginTop: '15px' }}>
            <Listings
              listingsTitle="Notification Logs"
              data={emailLogs}
              columns={emailLogColumns()}
              actions={this.actions}
              onSortHeader={this.handleSort}
              page={parseInt(page || 1, 10)}
              dataCount={count || 0}
              itemsPerPage={configService.itemsCountPerPage}
              onPageChange={this.handlePageChange}
              pageRange={3}
              isFetching={isFetching}
              noHeader
            />
          </section>
        </div>
      </Fragment>
    );
  }
}

const mapStateToProps = state => ({
  fetchStatus: getFetchStatus(state),
  emailLogs: getEmailLogs(state),
});

export default connect(mapStateToProps, {
  fetchEmailLogsAction: fetchEmailLogs,
})(withOperation(withQuery(EmailLogListPage)));
