export const validate = (values) => {
  const errors = {};

  if (!values.title) {
    errors.title = 'Please enter title.';
  }

  if (!values.slug) {
    errors.slug = 'Please enter slug.';
  }

  if (!values.type) {
    errors.type = 'Please select product type.';
  }

  if (!values.place) {
    errors.place = 'Please select location.';
  }

  if (!values.state) {
    errors.state = 'Please select status.';
  }

  if (!values.user) {
    errors.user = 'Please select supplier.';
  }

  return errors;
};

export const validateAddOption = (values) => {
  const errors = {};
  return errors;
};
