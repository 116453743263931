/**
 * Root Reducer
 */
import { combineReducers } from 'redux';
import { reducer as form } from 'redux-form';

// Import Reducers
import app from './modules/App/AppReducer';
import auth from './modules/Auth/AuthReducer';
import booking from './modules/Booking/bookingReducer';
import actionLog from './modules/ActionLog/logReducer';
import profile from './modules/AudienceProfiles/profileReducer';
import review from './modules/Reviews/reviewReducer';
import bookingItems from './modules/Fulfillment/bookingItemReducer';
import user from './modules/User/userReducers';

import { requestInfo } from './modules/App/RequestReducer';
import experience from './modules/Product/experienceReducer';
import category from './modules/Category/categoryReducers';
import location from './modules/Location/locationReducers';
import locationPage from './modules/LocationPage/cityReducers';
import collection from './modules/Collections/collectionReducers';
import productOption from './modules/ProductOption/reducers';
import coupon from './modules/Coupon/couponReducers';
import paymentMethod from './modules/PaymentMethod/paymentReducers';
import transaction from './modules/Transactions/paymentReducers';
import page from './modules/Page/pageReducers';
import email from './modules/Email/emailReducers';
import emailLog from './modules/EmailLog/emailLogReducers';
import quickFacts from './modules/QuickFacts/reducers';

// Combine all reducers into one root reducer
export default combineReducers({
  requestInfo,
  app,
  auth,
  form,
  user,
  booking,
  actionLog,
  bookingItems,
  profile,
  review,
  transaction,
  experience,
  category,
  location,
  locationPage,
  collection,
  productOption,
  coupon,
  paymentMethod,
  page,
  email,
  emailLog,
  quickFacts,
});
