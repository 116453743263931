import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';

// Component
import CollectionEditForm from '../components/CollectionEditForm';
import FormStatus from '../../../shared/App/components/FormStatus';
import Breadcrumb from '../../../shared/App/components/Breadcrumb';

// Actions
import {
  updateCollection,
  getCollection,
  resetCollections,
} from '../collectionActions';
import { getCollectionDetail, getFetchStatus } from '../collectionReducers';

// Utils

import { withOperation } from '../../../utils/OperationalComponent';
import { withQuery } from '../../../utils/QueryBasedComponent';
import { buildFormData, transformCollectionDetail } from '../collectionUtils';

class CollectionEditPage extends Component {
  componentDidMount() {
    const { executeActions, match, getCollectionAction } = this.props;
    executeActions({
      actions: [() => getCollectionAction({ id: match.params.cuid })],
    });
  }

  handleSubmit = values => {
    const { executeActions, match, updateCollectionAction } = this.props;

    // transform data
    const newCollection = transformCollectionDetail(values);

    executeActions({
      actions: [
        () =>
          updateCollectionAction({
            id: match.params.cuid,
            params: newCollection,
          }),
      ],
      successMsg: 'Update successfully',
      failureMsg: 'Update fail',
    });
  };

  render() {
    const { collection, isFetching, error, success, match } = this.props;

    const initialValues = buildFormData(collection);
    const errorData = error ? error.data || 'Something went wrong.' : '';
    return (
      <Fragment>
        <Helmet>
          <title>
            Edit Collection #{`${match.params.cuid}`} - Inspitrip HQ
          </title>
        </Helmet>
        <section className="content-header">
          <div className="flex flex-row" style={{ marginTop: '-15px', marginBottom: '15px' }}>
            <Breadcrumb
              history={[
                { title: 'InspitripHQ', url: '/' },
                { title: 'Collections', url: '/collections' },
                { title: 'Collection Detail', url: '' },
              ]}
            />
          </div>

          <div className="nav-tabs-custom">
            <FormStatus formState={{ error: errorData, success }} />
          </div>
          <div className="flex flex-row">
            <div className="col-md-7">
              <h1 style={{ fontSize: '24px', marginTop: 0 }}>Collection</h1>
            </div>
          </div>
        </section>

        <div className="content body">
          <div className="">
            {isFetching ? (
              <div className="overlay">
                <i className="fa fa-refresh fa-spin" />
                Loading...
              </div>
            ) : (
              <CollectionEditForm
                handleSubmit={this.handleSubmit.bind(this)}
                initialValues={initialValues}
                editMode
              />
            )}
          </div>
        </div>
      </Fragment>
    );
  }
}

// Retrieve data from store as props
const mapStateToProps = (state, props) => {
  return {
    isFetching: getFetchStatus(state),
    collection: getCollectionDetail(state, props.match.params.cuid),
  };
};

export default connect(mapStateToProps, {
  getCollectionAction: getCollection,
  updateCollectionAction: updateCollection,
  resetCollectionsAction: resetCollections,
})(withOperation(withQuery(CollectionEditPage)));
