import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Form, Formik } from 'formik';
import { Link } from 'react-router-dom';
import { withOperation } from '../../../../utils/OperationalComponent';
import { Helmet } from 'react-helmet';

import { addExperience, fetchExperienceFields } from '../../experienceActions';
import ExperienceHeader from '../../components/ExperienceHeader';
import ExperienceInformation from '../../components/ExperienceInformation';
import ExperienceShortSettings from '../../components/ExperienceShortSettings';
import { validate } from '../../experienceValidators';
import { getExperienceFields } from '../../experienceReducer';
import FormStatus from '../../../../shared/App/components/FormStatus';
import configService from '../../../../config/configService';

// Utils
import { transformExperienceDetail } from '../../experienceUtils';

class ExperienceCreatePage extends Component {
  componentDidMount() {
    const { fields, executeActions, fetchExperienceFieldsAction } = this.props;

    if (!fields) {
      executeActions({
        actions: [() => fetchExperienceFieldsAction()],
      });
    }
  }

  //
  onSubmit = (values, actions) => {
    const { addExperienceAction, executeActions } = this.props;
    const submitValues = transformExperienceDetail(values);
    executeActions({
      actions: [() => addExperienceAction({ data: submitValues })],
      onSuccess: () => actions.setSubmitting(false),
      onFailure: () => actions.setSubmitting(false),
      successMsg: 'Update successfully',
    });
  };

  render() {
    const { fields, isFetching, error, success } = this.props;

    const initialValues = {
      // Information
      title: '',
      slug: '',
      address: '',
      overview: '',
      about: [''],
      inclusion: [],
      exclusion: [],
      accommodation: '',
      pickup_info: '',
      advice: '',
      device: '',
      cancellation_policy: '',
      notes: '',

      // Config the latest experience template
      template:
        configService.experienceTemplates[
          configService.experienceTemplates.length - 1
        ] || '',
      description: '',

      // Sidebar
      place: '',
      language: 'en',
      country: '',
      user: '',
      duration: '',
      localeSetting: configService.localeCurrencySettings[0].value,
      type: '',
      state: '',
    };
    const errorData = error
      ? error.data || 'Can not save. Please contact admin'
      : '';

    return (
      <Fragment>
        <Helmet>
          <title>Create Product - Inspitrip HQ</title>
        </Helmet>
        <ExperienceHeader />
        <section className="content">
          <div className="nav-tabs-custom">
            <FormStatus formState={{ error: errorData, success }} />
          </div>
          <Formik
            initialValues={initialValues}
            onSubmit={this.onSubmit}
            enableReinitialize
            isInitialValid
            validate={validate}
          >
            {({ isSubmitting, isValid, values, setFieldValue }) => (
              <Form>
                <div className="flex flex-row">
                  <div className="col-sm-8">
                    <div className="nav-tabs-custom">
                      <ul className="nav nav-tabs">
                        <li className="active">
                          <Link to={'/products/new'}>Information</Link>
                        </li>
                      </ul>

                      <div className="content">
                        <ExperienceInformation
                          values={values}
                          setFieldValue={setFieldValue}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-sm-4" style={{ marginTop: '50px' }}>
                    <ExperienceShortSettings
                      values={values}
                      fields={fields}
                      isSubmitting={isFetching || isSubmitting}
                    />
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </section>
      </Fragment>
    );
  }
}

ExperienceCreatePage.contextTypes = {
  router: PropTypes.object,
};

ExperienceCreatePage.propTypes = {
  fields: PropTypes.object,
  /**
   * Operational Props
   */
  success: PropTypes.string,
  error: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  isFetching: PropTypes.bool,
  executeActions: PropTypes.func,
  /**
   * ConnectedProps
   */
  experience: PropTypes.object,
  /**
   * Actions
   */
  fetchExperienceFieldsAction: PropTypes.func.isRequired,
};

ExperienceCreatePage.need = [
  () => {
    return fetchExperienceFields();
  },
];

const mapStateToProps = (state, ownProps) => ({
  fields: getExperienceFields(state).actions.POST,
});

export default connect(mapStateToProps, {
  fetchExperienceFieldsAction: fetchExperienceFields,
  addExperienceAction: addExperience,
})(withOperation(ExperienceCreatePage));
