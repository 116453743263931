// Utils
import { getFilter, getSearch } from '../../utils/filter';

// Constants
import configApp from '../../config/configApp';

const stateSubOptions = [
  { value: 'published', label: 'Published' },
  { value: 'init', label: 'Init' },
  { value: 'pending', label: 'Pending' },
];

export const keyOptions = [
  { value: 'state', label: 'Status', type: 'select', subOptions: stateSubOptions },
];

// Can override if you want
export const getFilterData = (queryParams, options = []) => {
  return getFilter(queryParams, [...options, ...keyOptions]);
};

export const getSearchData = queryParams => {
  return getSearch(queryParams);
};
