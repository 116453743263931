import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import Alert from '../../../../shared/App/components/Alert';
import { withOperation } from '../../../../utils/OperationalComponent';
import { buildFormData, transformUserDetail } from '../../userUtils';

// Import Forms
import UserEditForm from '../../forms/UserEditForm';

// Import Actions
import { updateUser, fetchUser } from '../../userActions';

// Import Reducers
import { getUserDetail, getFetchStatus } from '../../userReducers';
import { resizeImage } from '../../../../utils/contentUtils';
import { withQuery } from '../../../../utils/QueryBasedComponent';

class UserEditPage extends Component {
  componentDidMount() {
    const { executeActions, match, fetchUserAction } = this.props;
    executeActions({
      actions: [() => fetchUserAction({ id: match.params.cuid })],
    });
  }

  handleSubmit = values => {
    const { executeActions, match, updateUserAction } = this.props;

    if (values.photo instanceof File) {
      resizeImage(values.photo, resized => {
        // remove background_photo from normal data submit
        delete values.photo;

        const data = new FormData();
        data.append('photo', resized);
        const updatedData = transformUserDetail(values);

        executeActions({
          actions: [
            () =>
              updateUserAction({ id: match.params.cuid, params: updatedData }),
            () => updateUserAction({ id: match.params.cuid, params: data }),
          ],
          successMsg: 'Update successfully',
        });
      });
    } else {
      const updatedData = transformUserDetail(values);
      executeActions({
        actions: [
          () =>
            updateUserAction({ id: match.params.cuid, params: updatedData }),
        ],
        successMsg: 'Update successfully',
      });
    }
  };

  renderAlert() {
    const { success, error } = this.props;

    let msg = '';
    let title = 'Success';
    let type = 'success';
    if (error) {
      msg = error.toString();
      title = 'Error';
      type = 'danger';
    } else if (success) {
      msg = success;
    }

    if (msg !== '') {
      return <Alert title={title} type={type} content={msg} />;
    }

    return null;
  }

  renderLoading = () => {
    const { isFetching } = this.props;

    return isFetching ? (
      <div className="overlay">
        <i className="fa fa-refresh fa-spin" />
        Loading...
      </div>
    ) : null;
  };

  render() {
    const { user, isFetching, match } = this.props;
    const initialValues = buildFormData(user);
    return (
      <Fragment>
        <Helmet>
          <title>Edit User #{`${match.params.cuid}`} - Inspitrip HQ</title>
        </Helmet>
        <section className="content-header">
          <div className="flex flex-row" style={{ marginTop: '-15px', marginBottom: '15px' }}>
            <ol className="breadcrumb">
              <li>
                <Link to={'/'}>InspitripHQ</Link>
              </li>
              <li>
                <Link to={'/users'}>Users</Link>
              </li>
              <li className="active">User Detail</li>
            </ol>
          </div>
          {this.renderAlert()}
          <div className="flex flex-row">
            <div className="flex flex-col gap-y-7">
              <h1 style={{ fontSize: '24px', marginTop: 0 }}>User</h1>
            </div>
          </div>
        </section>
        <div className="content body">
          <div className="">
            {this.renderLoading()}
            {!isFetching && (
              <UserEditForm
                handleSubmit={this.handleSubmit.bind(this)}
                initialValues={initialValues}
                user={user}
              />
            )}
          </div>
        </div>
      </Fragment>
    );
  }
}

// Retrieve data from store as props
const mapStateToProps = (state, props) => {
  return {
    isFetching: getFetchStatus(state),
    user: getUserDetail(state, props.match.params.cuid),
  };
};

export default connect(mapStateToProps, {
  fetchUserAction: fetchUser,
  updateUserAction: updateUser,
})(withOperation(withQuery(UserEditPage)));
