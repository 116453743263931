const validate = values => {
  const errors = {};
  const patternEmail = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  if (!values.email) {
    errors.email = 'Please fill in your email address.';
  } else if (!patternEmail.test(values.email)) {
    errors.email = 'Your email is invalid.';
  }

  if (!values.password) {
    errors.password = 'Please fill in your password.';
  }

  return errors;
};

export default validate;
