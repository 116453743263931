const defaultDataOfLocale = {
  ko: {
    prefix: '/ko',
  },
  vi: {
    prefix: '/vi',
  },
  en: {
    prefix: '',
  }
};


export const getLocalePrefixByLanguage = (language) => {
  if (language) return defaultDataOfLocale[language].prefix || '';
  return '';
};

