import React, { Component } from 'react';
import PropTypes from 'prop-types';

// Components
import { Form, Formik } from 'formik';
import { TextAreaField, SelectField, Button, ArrayObjectField, InputField, NumericInputField } from '../../../shared/App/components';

// Utils
import { validate } from '../paymentValidators';
import configService from '../../../config/configService'

// Constants
import { PAYMENT_TYPE } from '../paymentUtils';
const PAYMENT_STATUS = ['active', 'inactive'];

class PaymentEditForm extends Component {
  render() {
    // Add editMode
    const { editMode, initialValues, handleSubmit } = this.props;

    return (
      <div className="content">
        <Formik initialValues={{ ...initialValues }} onSubmit={handleSubmit} enableReinitialize isInitialValid validate={validate}>
          {({ isSubmitting, isValid, values, errors, setFieldValue }) => {
            return (
              <Form>
                <div className="col-md-12">
                  <div className="">
                    <div className="">
                      <InputField sub="*" name="title" label="Title" placeholder="Payment method, i.e. Pay with Credit card" />
                      <SelectField name="payment_method" label="Payment Methods" options={Object.keys(PAYMENT_TYPE).map(key => ({ label: key, value: PAYMENT_TYPE[key] }))} />
                      <SelectField name="locale" label="Locale" options={configService.localeCurrencySettings.map(item => ({ label: item.label, value: item.value }))} />
                      <TextAreaField id="description" name="description" label="Description" rows={6} />
                      <InputField type="number" id="coefficient" name="coefficient" label="Coefficient" rows={6} />
                      <SelectField name="state" label="Status" options={PAYMENT_STATUS.map(status => ({ label: status, value: status }))} />
                      <br />
                      {values.payment_method === PAYMENT_TYPE.bank && <label>Bank List</label>}
                      {values.payment_method === PAYMENT_TYPE.bank && (
                        <ArrayObjectField
                          name="banks"
                          arrayData={values.banks}
                          renderObject={(item, index) => (
                            <div className="box" key={index}>
                              <div className="box-body">
                                <InputField sub="*" name={`banks.${index}.bank_name`} label="Bank Name" placeholder="" />
                                <InputField sub="*" name={`banks.${index}.account_id`} label="Account Id" placeholder="" />
                                <InputField sub="*" name={`banks.${index}.account_owner`} label="Account Owner" placeholder="" />

                                <InputField sub="*" name={`banks.${index}.logo`} label="Logo Link" placeholder="Copy link and paste to here" />
                                <InputField sub="*" name={`banks.${index}.branch`} label="Branch" placeholder="" />
                                <InputField sub="*" name={`banks.${index}.city`} label="City" placeholder="" />
                              </div>
                            </div>
                          )}
                        />
                      )}
                      <br />
                      <br />
                      <Button type="submit" displayType="primary" block disabled={isSubmitting}>
                        <i className="fa fa-save" /> {editMode ? 'Save changes' : 'Create'}
                      </Button>
                    </div>
                  </div>
                </div>
              </Form>
            );
          }}
        </Formik>
      </div>
    );
  }
}

PaymentEditForm.propTypes = {
  handleSubmit: PropTypes.func,
  pristine: PropTypes.bool,
  reset: PropTypes.func,
  submitting: PropTypes.bool,
  error: PropTypes.string,
  isSuccess: PropTypes.bool,
  isError: PropTypes.bool,
  submitSucceeded: PropTypes.bool,
  dispatch: PropTypes.func,
  editMode: PropTypes.bool
};

PaymentEditForm.defaultProps = {
  editMode: false
};

export default PaymentEditForm;
