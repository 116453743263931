import React, { Component } from 'react';
import { ArrayObjectField, InputField } from '../../../shared/App/components';

import FroalaField from '../../../shared/App/components/FroalaField';

class FAQsContainer extends Component {
  render() {
    const { values } = this.props;
    return (
      <div>
        <label>FAQs</label>
        <ArrayObjectField
          name="faqs"
          arrayData={values.faqs}
          renderObject={(item, index) => (
            <div className="box" key={index}>
              <div className="box-body">
                <InputField sub="*" name={`faqs.${index}.title`} label="Title" placeholder="" />
                <FroalaField name={`faqs.${index}.text`} label="Text" placeholder="" />
              </div>
            </div>
          )}
        />
      </div>
    );
  }
}

export default FAQsContainer;
