import { Alert } from 'antd';
import PropTypes from 'prop-types';
import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { Button } from '../../shared/App/components';
import QuickFactItem from './QuickFactItem';
import QuickFactItemEditor from './QuickFactItemEditor';
import { createQuickFact, fetchQuickFacts } from './actions';
import { getAllIds, getQuickFact } from './reducers';

const requiredFacts = [
  { label: 'Duration', value: true },
  { label: 'Services', value: 'Instant confirmation' },
  { label: 'Cancellation', value: true },
];

class QuickFactList extends React.Component {
  state = {
    isCreating: false,
    isValidate: true,
  };

  componentDidMount() {
    this.props.fetchQuickFacts(this.props.experienceId).then(response => {
      this.setState({ isValidate: this.getWarning(response.data) });
    });
  }

  getWarning(quickfacts) {
    let isValidate = true;

    requiredFacts.some(obj => {
      const idx = quickfacts.findIndex(qf => {
        return (
          obj.label === qf.label &&
          (obj.value === true || obj.value === qf.option)
        );
      });
      if (idx >= 0) isValidate = isValidate && true;
      else isValidate = false;
      return !isValidate;
    });

    return isValidate;
  }

  toggleCreate = isCreating => this.setState({ isCreating });

  handleSubmit = values => {
    const { experienceId } = this.props;
    this.props
      .createQuickFact({ experienceId, data: values })
      .then(() => this.toggleCreate(false))
      .catch(() => this.toggleCreate(false));
  };

  render() {
    const { experienceId } = this.props;

    return (
      <Fragment>
        <div className="flex flex-row">
          {!this.state.isValidate && (
            <Alert
              message="Quick Facts are not validated"
              description="Duration, Instant confirmation service, Cancellation are required. Please make sure these facts are added."
              type="error"
              showIcon
            />
          )}
        </div>
        {this.props.ids.map(quickFactId => (
          <QuickFactItem
            experienceId={experienceId}
            key={quickFactId}
            id={quickFactId}
          />
        ))}
        {this.state.isCreating && (
          <div style={{ marginTop: '8px' }}>
            <QuickFactItemEditor
              onSubmit={this.handleSubmit}
              onCancel={() => this.toggleCreate(false)}
            />
          </div>
        )}
        {!this.state.isCreating && (
          <div style={{ marginTop: '8px' }}>
            <Button onClick={() => this.toggleCreate(true)} type="button">
              Add Quick Fact
            </Button>
          </div>
        )}
      </Fragment>
    );
  }
}

QuickFactList.propTypes = {
  experienceId: PropTypes.number.isRequired,
};

const mapStateToProps = (state, ownProps) => ({
  ids: getAllIds(state),
  quickFact: getQuickFact(state, ownProps.id),
});

const mapDispatchToProps = {
  fetchQuickFacts,
  createQuickFact,
};

export default connect(mapStateToProps, mapDispatchToProps)(QuickFactList);
