import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';

// Component
import LocationPageForm from '../components/LocationPageForm';
import Breadcrumb from '../../../shared/App/components/Breadcrumb';
import FormStatus from '../../../shared/App/components/FormStatus';

// Actions
import { updateCity, getCity, resetCities } from '../cityActions';
import { getCityDetail, getFetchStatus } from '../cityReducers';

// Utils
import { withOperation } from '../../../utils/OperationalComponent';
import { buildFormData, transformPlaceDetail } from '../cityUtils';
import configService from '../../../config/configService';
import { getLocalePrefixByLanguage } from '../../../utils/localeUtils';
import { withQuery } from '../../../utils/QueryBasedComponent';

class LocationPageEditPage extends Component {
  componentDidMount() {
    const { executeActions, match, getCityAction } = this.props;
    executeActions({
      actions: [() => getCityAction({ id: match.params.cuid })],
    });
  }

  handleSubmit = values => {
    const { executeActions, match, updateCityAction } = this.props;

    // transform data
    const newObject = transformPlaceDetail(values);

    let data = new FormData();
    if (newObject.meta_photo && newObject.meta_photo instanceof File) {
      data.append('meta_photo', newObject.meta_photo);
    }
    if (
      newObject.background_photo &&
      newObject.background_photo instanceof File
    ) {
      data.append('background_photo', newObject.background_photo);
    }

    delete newObject.background_photo;
    delete newObject.meta_photo;
    delete newObject.parent;
    delete newObject.gdata;

    let actions = [];
    actions.push(() =>
      updateCityAction({ id: match.params.cuid, data: newObject })
    );
    if (data.has('background_photo') || data.has('meta_photo')) {
      actions.push(() => updateCityAction({ id: match.params.cuid, data }));
    }

    executeActions({
      actions,
      onSuccess: () => {
        this.props.resetCitiesAction();
      },
      successMsg: 'Update successfully',
    });
  };

  render() {
    const { city, isFetching, error, success } = this.props;
    if (!city) return <div>404</div>;

    const initialValues = buildFormData(city);

    return (
      <Fragment>
        <Helmet>
          <title>Edit Location Page #{`${city.id}`} - Inspitrip HQ</title>
        </Helmet>
        <section className="content-header">
          <div className="flex flex-row" style={{ marginTop: '-15px', marginBottom: '15px' }}>
            <Breadcrumb
              history={[
                { title: 'InspitripHQ', url: '/' },
                { title: 'Location Pages', url: '/locations/pages' },
                { title: 'Page', url: '' },
              ]}
            />
          </div>
          <div className="nav-tabs-custom">
            <FormStatus formState={{ error, success }} />
          </div>
          <div className="flex flex-row">
            <div className="col-sm-6">
              <h1 style={{ fontSize: '24px', marginTop: 0 }}>Location Page</h1>
            </div>
            <div className="col-sm-6" style={{ textAlign: 'right' }}>
              <a
                className="btn  btn-default  btn-nor"
                href={`${configService.mainSite}${getLocalePrefixByLanguage(
                  initialValues.language
                )}/${initialValues.slug}`}
                target="_blank"
              >
                <i className="fa fa-external-link" /> View
              </a>
            </div>
          </div>
        </section>
        <div className="content body">
          <div className="flex flex-row">
            {isFetching ? (
              <div className="overlay">
                <i className="fa fa-refresh fa-spin" />
                Loading...
              </div>
            ) : (
              <LocationPageForm
                city={city}
                handleSubmit={this.handleSubmit}
                initialValues={initialValues}
                editMode
              />
            )}
          </div>
        </div>
      </Fragment>
    );
  }
}

// Retrieve data from store as props
const mapStateToProps = (state, props) => {
  return {
    isFetching: getFetchStatus(state),
    city: getCityDetail(state, props.match.params.cuid),
  };
};

export default connect(mapStateToProps, {
  getCityAction: getCity,
  updateCityAction: updateCity,
  resetCitiesAction: resetCities,
})(withOperation(withQuery(LocationPageEditPage)));
