import configApp from '../config/configApp';
import { setCookie, getCookie, removeCookie } from './storage';

export function removeAuth() {
  removeCookie(configApp.authToken);
  removeCookie(configApp.authUser);
}

export function getAuthToken() {
  return getCookie(configApp.authToken);
}

export function getAuthUser() {
  return getCookie(configApp.authUser);
}

export function saveAuth(token, user) {
  setCookie(configApp.authToken, token);
  setCookie(configApp.authUser, user);
}
