// Utils
import { getFilter, getSearch } from '../../utils/filter';

// Constants
import configApp from '../../config/configApp';

/**
 * Booking Filter
 */
const stateSubOptions = [
  { value: configApp.bookingState.CONFIRMED, label: 'Confirmed' },
  { value: configApp.bookingState.PENDING, label: 'Pending' },
  { value: configApp.bookingState.CANCELLED, label: 'Cancelled' },
];
const paymentStatusSubOptions = [
  { value: 'paid', label: 'Paid' },
  { value: 'unpaid', label: 'Unpaid' },
  { value: 'partially_paid', label: 'Partially paid' },
];
export const keyOptions = [
  { value: 'status', label: 'Status', type: 'select', subOptions: stateSubOptions },
  { value: 'payment_status', label: 'Payment Status', type: 'select', subOptions: paymentStatusSubOptions },
  { value: 'created', label: 'Booking date', type: 'input' },
  { value: 'cost', label: 'Price' }
];

export const getFilterData = queryParams => {
  return getFilter(queryParams, keyOptions);
};

export const getSearchData = queryParams => {
  return getSearch(queryParams);
};

