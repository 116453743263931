import React, { Component } from 'react';
import PropTypes from 'prop-types';
import axios, { CancelToken } from 'axios';

// Components
import { Modal } from '../App/components';

// Utils
import { copyStringToClipboard } from '../../utils/clipboard';

// constants
import configService from '../../config/configService';

let cancelRequest = null;

/**
 * createCancelToken creates a cancelToken for axios
 * it returns the created token and the method to trigger the cancellation
 * @returns {{token, cancel: cancel}}
 */
const createCancelToken = () => {
  let cancel = () => {};
  const cancelToken = new CancelToken(c => {
    // An executor function receives a cancel function as a parameter
    cancel = c;
  });

  return { cancelToken, cancel };
};

// Upload image function
const uploadImage = file => {
  let data = new FormData();
  data.append('file', file);
  const config = {
    headers: { 'content-type': 'multipart/form-data' }
  };
  // TODO: handle cancel upload
  const { cancelToken, cancel } = createCancelToken();
  cancelRequest = cancel;
  return axios.post(`${configService.pikAPI}/api/pik`, data, config);

  // TEST upload local
  // const UPLOAD_URL = 'http://localhost:5000/api/pik';
  // return axios.post(UPLOAD_URL, data, config, { cancelToken });
};

const Loading = () => (
  <div className="overlay center">
    <i className="fa fa-refresh fa-spin" />
  </div>
);

const URL_COPY_TYPE = 'url';
const MARKDOWN_COPY_TYPE = 'markdown';
class ImgUploadModal extends Component {
  state = {
    visible: false,
    selectedFit: null,
    selectedCrop: null,
    width: 0,
    height: 0,
    currentCopy: null,
    src: '',
    isUploading: false
  };

  componentDidUpdate() {}

  handleChange = selectedOption => {
    // TODO: handle selectedOption change
  };
  handleChangeInput = e => {
    this.setState({ [e.target.name]: e.target.value });
  };

  handleUpload = e => {
    e.preventDefault();
    const data = e.target.files[0];
    this.setState({ isUploading: true, src: null });

    uploadImage(data)
      .then(resp => {
        copyStringToClipboard(resp.data.url);
        this.setState({ currentCopy: URL_COPY_TYPE, src: resp.data.url, isUploading: false });
      })
      .catch(error => {
        if (axios.isCancel(error)) {
          console.log('post Request canceled');
        }
      });
  };

  render() {
    const { btnTitle, name } = this.props;
    const { isUploading, currentCopy, visible, selectedFit, selectedCrop, width, height, src } = this.state;
    let params = [];
    if (width > 0) params.push(`w=${width}`);
    if (height > 0) params.push(`h=${height}`);
    const queryString = params.join('&') ? `?${params.join('&')}` : '';
    let directUrl = src + queryString;
    if (params.length === 2) directUrl += '&fit=crop&crop=edges';
    const markdown = `![photo](${directUrl})`;

    return (
      <React.Fragment>
        <div
          className="input-group-addon"
          onClick={() => {
            this.setState({ visible: !visible });
          }}
        >
          <i className="fa fa-upload" />
        </div>

        <Modal
          title={btnTitle}
          confirmText="Use"
          onConfirm={() => {
            copyStringToClipboard(directUrl);
            if (typeof this.props.onSuccess === 'function') {
              this.props.onSuccess(this.state.currentCopy === URL_COPY_TYPE ? directUrl : markdown);
            }
            this.setState({ currentCopy: URL_COPY_TYPE, visible: false });
          }}
          onDismiss={() => {
            if (typeof cancelRequest === 'function') {
              cancelRequest();
            }
            this.setState({ visible: false, isUploading: false });
          }}
          visible={visible}
        >
          {isUploading && <Loading />}
          {!isUploading && (
            <div className="col-md-12">
              <label htmlFor={name} style={{ textAlign: 'center', width: '100%', border: '1px solid #ccc', padding: '20px 12px', cursor: 'pointer' }}>
                <i className="fa fa-cloud-upload" /> Choose file
              </label>
              <input
                style={{ display: 'none' }}
                id={name}
                type="file"
                onChange={event => {
                  this.handleUpload(event);
                }}
              />
            </div>
          )}
          {src && (
            <div>
              {/* TODO: fit & crop select */}
              {/* <div className="col-md-6">
                <p>Fit:</p>
                <Select value={selectedFit} onChange={this.handleChange} options={options} />
              </div>
              <div className="col-md-6">
                <p>Crop:</p>
                <Select value={selectedCrop} onChange={this.handleChange} options={options} />
              </div> */}
              <div className="col-md-6">
                <p>Width:</p>
                <input name="width" className="form-control" type="number" value={width} onChange={this.handleChangeInput} />
              </div>
              <div className="col-md-6">
                <p>Height:</p>
                <input name="height" className="form-control" type="number" value={height} onChange={this.handleChangeInput} />
              </div>
              <div className="col-md-12">
                <hr />
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                  <div style={{ textAlign: 'center' }}>
                    <img style={{ maxWidth: '200px' }} src={src} alt="" />
                  </div>
                  <div>
                    <p>Direct Url:</p>
                    <div style={{ display: 'flex' }}>
                      <p className="bg-black" style={{ maxWidth: '90%', padding: '5px 10px' }}>
                        {directUrl}
                      </p>
                      <button
                        type="button"
                        style={{ height: '50px' }}
                        onClick={() => {
                          copyStringToClipboard(src);
                          this.setState({ currentCopy: URL_COPY_TYPE });
                        }}
                      >
                        <i className={`fa fa-${currentCopy === URL_COPY_TYPE ? 'clipboard' : 'files-o'}`} />
                      </button>
                    </div>
                  </div>

                  <div>
                    <p>Markdown:</p>
                    <div style={{ display: 'flex' }}>
                      <p className="bg-black" style={{ maxWidth: '90%', padding: '5px 10px' }}>
                        {markdown}
                      </p>
                      <button
                        type="button"
                        style={{ height: '50px' }}
                        onClick={() => {
                          copyStringToClipboard(markdown);
                          this.setState({ currentCopy: MARKDOWN_COPY_TYPE });
                        }}
                      >
                        <i className={`fa fa-${currentCopy === 'markdown' ? 'clipboard' : 'files-o'}`} />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </Modal>
      </React.Fragment>
    );
  }
}

ImgUploadModal.propTypes = {
  btnTitle: PropTypes.string,
  onSuccess: PropTypes.func
};

ImgUploadModal.defaultProps = {
  btnTitle: 'Upload Image'
};

export default ImgUploadModal;
