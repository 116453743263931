import { combineReducers } from 'redux';

// actions
import {
  FETCH_UNITS,
  FETCH_UNIT,
  CREATE_UNIT,
  UPDATE_UNIT,
  DELETE_UNIT,
  FETCH_FIXED_PRICES,
  FETCH_NET_FIXED_PRICES
} from './actions';

// utils
import { appendByIds, deleteKeyValueFromById } from '../../../../utils/reducer';
import { FULFILLED } from '../../../../utils/axiosAPI';

const byId = (state = {}, action) => {
  switch (action.type) {
    case FETCH_UNITS + FULFILLED: {
      return appendByIds(state, action.data, 'id');
    }
    case FETCH_UNIT + FULFILLED:
    case CREATE_UNIT + FULFILLED:
    case UPDATE_UNIT + FULFILLED: {
      return { ...state, [action.data.id]: action.data };
    }
    case DELETE_UNIT + FULFILLED: {
      return deleteKeyValueFromById(state, action.meta.optionId);
    }
    default: {
      return state;
    }
  }
};

const subAllIds = (state = [], action) => {
  switch (action.type) {
    case FETCH_UNITS + FULFILLED: {
      return [...action.data.map(option => option.id)];
    }
    case CREATE_UNIT + FULFILLED: {
      return [...state, action.data.id];
    }
    case DELETE_UNIT + FULFILLED: {
      return state.filter(id => id !== action.meta.optionId);
    }
    default: {
      return state;
    }
  }
};

const allIds = (state = {}, action) => {
  switch (action.type) {
    case FETCH_UNITS + FULFILLED:
    case CREATE_UNIT + FULFILLED:
    case DELETE_UNIT + FULFILLED: {
      const ids = state[action.meta.variantId];
      return {
        ...state,
        [action.meta.variantId]: subAllIds(ids, action)
      };
    }
    default: {
      return state;
    }
  }
};

const allFixedPriceIds = (state = {}, action) => {
  switch (action.type) {
    case FETCH_FIXED_PRICES + FULFILLED: {
      return [...action.data.map(price => price.id)];
    }
    default: {
      return state;
    }
  }
};

const allFixedPrices = (state = [], action) => {
  switch (action.type) {
    case FETCH_FIXED_PRICES + FULFILLED: {
      return action.data;
    }
    default: {
      return state;
    }
  }
};

const allNetFixedPrices = (state = [], action) => {
  switch (action.type) {
    case FETCH_NET_FIXED_PRICES + FULFILLED: {
      return action.data;
    }
    default: {
      return state;
    }
  }
};

export default combineReducers({
  byId,
  allIds,
  allFixedPriceIds,
  allFixedPrices,
  allNetFixedPrices
});

const getPath = state => state.productOption.option;

export const getUnitIds = (state, variantId) => getPath(state).allIds[variantId] || [];
export const getFixedPriceIds = state => getPath(state).allFixedPriceIds || [];
export const getUnit = (state, id) => getPath(state).byId[id];
export const getFixedPrices = state => getPath(state).allFixedPrices || [];
export const getNetFixedPrices = state => getPath(state).allNetFixedPrices || [];
