export const redirect = url => {
  if (typeof window === 'undefined') {
    return false;
  }
  window.location.href = url;

  return true;
};

export const reload = () => {
  if (typeof window.location === 'undefined') {
    return false;
  }
  window.location.reload();

  return true;
};

export const getParams = (url) => {
  if (!url) {
    return {};
  }

  const query = url.substr(url.indexOf('/?') + 1);
  return (/^[?#]/.test(query) ? query.slice(1) : query)
    .split('&')
    .reduce((params, param) => {
      const [key, value] = param.split('=');
      params[key] = value ? decodeURIComponent(value.replace(/\+/g, ' ')) : '';
      return params;
    }, {});
};

export const calculateActivePage = ({ previous }) => {
  if (!previous) return 1;
  const previousParams = getParams(previous);
  if (!previousParams.page) return 2;
  return parseInt(previousParams.page, 10) + 1;
};
