import React from 'react';

const LoginPage = ({ children }) => {
  return (
    <div className="flex items-center justify-center h-screen bg-red-50">
      <div className="max-w-md w-full p-6 shadow-md ">
        <h2 className="text-2xl mb-4">Login</h2>
        {children}
      </div>
    </div>
  );
};

export default LoginPage;
