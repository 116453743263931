import { combineReducers } from 'redux';

// Actions
import { FETCH_COLLECTIONS, RESET_COLLECTIONS, FETCH_COLLECTION, UPDATE_COLLECTION, DELETE_COLLECTION, CREATE_COLLECTION } from './collectionActions';

// Constants
import { FULFILLED, PENDING, REJECTED } from '../../utils/axiosAPI';
import { appendByIds } from '../../utils/reducer';

const fetchStatusInitialState = {
  count: 0,
  next: null,
  previous: null,
  isFetching: false,
  error: null
};

// Seperate reuse for all reducer
export const fetchStatus = (state = fetchStatusInitialState, action) => {
  switch (action.type) {
    case FETCH_COLLECTIONS + FULFILLED: {
      const { results, ...rest } = action.data;
      return { ...state, isFetching: false, ...rest };
    }
    case FETCH_COLLECTIONS + REJECTED: {
      return { ...state, isFetching: false, error: action.data };
    }
    case FETCH_COLLECTIONS + PENDING: {
      return { ...state, isFetching: true, error: null };
    }
    case RESET_COLLECTIONS: {
      return fetchStatusInitialState;
    }
    default: {
      return state;
    }
  }
};

export const byId = (state = {}, action) => {
  switch (action.type) {
    case FETCH_COLLECTIONS + FULFILLED: {
      return appendByIds(state, action.data.results, 'id');
    }
    case FETCH_COLLECTION + FULFILLED: {
      const collection = state[action.data.id];
      return {
        ...state,
        [action.data.id]: { ...collection, ...action.data }
      };
    }
    case UPDATE_COLLECTION + FULFILLED: {
      const collection = state[action.data.id];
      return {
        ...state,
        [action.data.id]: { ...collection, ...action.data }
      };
    }
    case CREATE_COLLECTION + FULFILLED: {
      return {
        ...state,
        [action.data.id]: action.data
      };
    }

    case RESET_COLLECTIONS: {
      return {};
    }

    default:
      return state;
  }
};

export const visibleIds = (state = [], action) => {
  switch (action.type) {
    case FETCH_COLLECTIONS + FULFILLED: {
      return action.data.results.map(collection => collection.id);
    }

    case UPDATE_COLLECTION + FULFILLED: {
      return [...state];
    }

    case CREATE_COLLECTION + FULFILLED: {
      return [action.data.id, ...state];
    }
    case RESET_COLLECTIONS: {
      return [];
    }
    default: {
      return state;
    }
  }
};

export default combineReducers({
  fetchStatus,
  visibleIds,
  byId
});

// Selector
export const getCollections = state => state.collection.visibleIds.map(id => state.collection.byId[id]);
export const getCollectionDetail = (state, id) => state.collection.byId[id];
export const getFetchStatus = state => state.collection.fetchStatus;
