import { productAPI } from '../../utils/axiosAPI';

export const FETCH_QUICK_FACTS = 'FETCH_QUICK_FACTS';
export const fetchQuickFacts = experienceId => dispatch =>
  productAPI(dispatch, {
    method: 'get',
    url: `/product/admin/v1/products/${experienceId}/facts`,
    type: FETCH_QUICK_FACTS,
  });

export const CREATE_QUICK_FACT = 'CREATE_QUICK_FACT';
export const createQuickFact = ({ experienceId, data }) => dispatch =>
  productAPI(dispatch, {
    method: 'post',
    url: `/product/admin/v1/products/${experienceId}/facts`,
    data,
    type: CREATE_QUICK_FACT,
  });

export const UPDATE_QUICK_FACT = 'UPDATE_QUICK_FACT';
export const updateQuickFact = ({ experienceId, factId, data }) => dispatch =>
  productAPI(dispatch, {
    method: 'patch',
    url: `/product/admin/v1/products/${experienceId}/facts/${factId}`,
    data,
    type: UPDATE_QUICK_FACT,
  });

export const DELETE_QUICK_FACT = 'DELETE_QUICK_FACT';
export const deleteQuickFact = ({ experienceId, factId }) => dispatch =>
  productAPI(dispatch, {
    method: 'delete',
    url: `/product/admin/v1/products/${experienceId}/facts/${factId}`,
    meta: { factId },
    type: DELETE_QUICK_FACT,
  });
