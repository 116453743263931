import React from 'react';
import { Formik } from 'formik';
import {
  Button,
  SelectField,
  TextAreaField,
} from '../../shared/App/components';

import quickFacts from './quickFacts';

const buildSubmitValues = (values, onSubmitHandler) => {
  const submitValues = { ...values };

  // icon
  submitValues.icon =
    quickFacts.icons[submitValues.option] ||
    quickFacts.icons[submitValues.label] ||
    '';

  onSubmitHandler(submitValues);
};

const QuickFactItemEditor = ({ onSubmit, onCancel, initialValues }) => {
  return (
    <Formik
      onSubmit={values => buildSubmitValues(values, onSubmit)}
      initialValues={initialValues}
    >
      {({ submitForm, values }) => (
        <div className="flex flex-row">
          <div className="col-sm-9">
            <div className="flex flex-row">
              <div className="col-sm-6">
                <SelectField
                  name="label"
                  label="Type"
                  options={quickFacts.labels.map(label => ({
                    value: label,
                    label,
                  }))}
                  isSearchable
                />
              </div>
              <div className="col-sm-6">
                <SelectField
                  name="option"
                  label="Option"
                  options={(quickFacts.options[values.label] || []).map(
                    option => ({
                      value: option,
                      label: option,
                    })
                  )}
                  isSearchable
                />
              </div>
            </div>
            <div className="flex flex-row">
              <div className="col-sm-12">
                <TextAreaField
                  id="description"
                  name="description"
                  label="Description"
                />
              </div>
            </div>
          </div>
          <div className="flex flex-row">
            <div className="col-sm-12" style={{ marginTop: '28px' }}>
              <Button
                displayType="primary"
                type="button"
                onClick={submitForm}
                style={{ marginRight: '7px' }}
              >
                <i className="fa fa-check" />
              </Button>
              <Button type="button" onClick={onCancel}>
                <i className="fa fa-remove" />
              </Button>
            </div>
          </div>
          
        </div>
      )}
    </Formik>
  );
};

export default QuickFactItemEditor;
