import React from 'react';
import PropTypes from 'prop-types';
import { Formik, Form } from 'formik';
import {
  Modal,
  ArrayFilterField,
  ArrayField
} from '../../shared/App/components';

const FilterForm = ({ onSubmit, onDismiss, visible, trip, arrayData, keyOptions, typeOptions }) => (
  <Formik
    enableReinitialize
    initialValues={{
      filter: arrayData,
    }}
    onSubmit={onSubmit}
  >
    {({ isSubmitting, isValid, values, submitForm }) => (
      <Modal
        title="Manage Filter"
        confirmText="Save changes"
        dismissText="Cancel"
        onConfirm={submitForm}
        {...{ onDismiss, visible }}
      >
        <Form>
          <ArrayFilterField
            name="filter"
            arrayData={values.filter}
            keyOptions={keyOptions}
            typeOptions={typeOptions}
          />
        </Form>
      </Modal>
    )}
  </Formik>
);

const typeOptionsDefault = [
  { value: '', label: '=' },
  { value: '__gte', label: '>=' },
  { value: '__lte', label: '<=' }
];

FilterForm.propTypes = {
  typeOptions: PropTypes.array
};

FilterForm.defaultProps = {
  typeOptions: typeOptionsDefault
};

export default FilterForm;
