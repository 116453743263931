import { cmsAPI } from '../../utils/axiosAPI';

export const FETCH_REVIEW = 'FETCH_REVIEW';
export const RESET_REVIEW = 'RESET_REVIEW';
export const FETCH_REVIEW_DETAIL = 'FETCH_REVIEW_DETAIL';
export const CREATE_REVIEW = 'CREATE_REVIEW';
export const UPDATE_REVIEW = 'UPDATE_REVIEW';
export const DELETE_REVIEW = 'DELETE_REVIEW';

export const fetchReviews = ({ params }) => {
  return dispatch =>
    cmsAPI(dispatch, {
      method: 'get',
      url: '/cms/admin/v1/reviews',
      params,
      type: FETCH_REVIEW,
    });
};

export const resetReview = () => ({
  type: RESET_REVIEW,
});

export const fetchReviewDetail = ({ id }) => dispatch =>
  cmsAPI(dispatch, {
    method: 'get',
    url: `/cms/admin/v1/reviews/${id}`,
    type: FETCH_REVIEW_DETAIL,
  });

export const createReview = ({ data }) => dispatch =>
  cmsAPI(dispatch, {
    method: 'post',
    url: '/cms/admin/v1/reviews',
    data,
    type: CREATE_REVIEW,
  });

export const updateReview = ({ id, data }) => dispatch =>
  cmsAPI(dispatch, {
    method: 'patch',
    url: `/cms/admin/v1/reviews/${id}`,
    data,
    type: UPDATE_REVIEW,
  });

export const deleteReview = ({ id }) => dispatch =>
  cmsAPI(dispatch, {
    method: 'delete',
    url: `/cms/admin/v1/reviews/${id}`,
    type: DELETE_REVIEW,
  });

export const SEARCH_LOCAL_GUIDE = 'SEARCH_LOCAL_GUIDE';
export const searchLocalGuide = ({ params }) => {
  return dispatch =>
    cmsAPI(dispatch, {
      method: 'get',
      url: '/cms/admin/v1/users',
      params,
      type: SEARCH_LOCAL_GUIDE,
    });
};

export const SEARCH_TRAVELLER = 'SEARCH_TRAVELLER';
export const searchTraveller = ({ params }) => {
  return dispatch =>
    cmsAPI(dispatch, {
      method: 'get',
      url: '/cms/admin/v1/users',
      params,
      type: SEARCH_TRAVELLER,
    });
};
