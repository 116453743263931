import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { withOperation } from '../../../../utils/OperationalComponent';
import { withQuery } from '../../../../utils/QueryBasedComponent';
import {
  addExperienceFAQ,
  deleteExperienceFAQ,
  duplicateExperience,
  fetchExperienceDetail,
  fetchExperienceFAQ,
  fetchFAQTemplates,
  updateExperienceFAQ,
  fetchDurationTypes,
} from '../../experienceActions';
import {
  getExperienceDetail,
  getExperienceFaqs,
  getFAQTemplates,
  getFetchStatus,
} from '../../experienceReducer';
import ExperienceHeader from '../../components/ExperienceHeader';
import ExperienceFAQs from '../../components/ExperienceFAQs';
import ExperienceAddQuestionModal from '../../components/ExperienceAddQuestionModal';
import ExperienceEditQuestionModal from '../../components/ExperienceEditQuestionModal';
import configServices from '../../../../config/configService';
import { resizeImage } from '../../../../utils/contentUtils';

// Utils
import { transformExperienceDetail } from '../../experienceUtils';

class ProductFAQPage extends Component {
  state = {
    showAddModal: false,
    showAddModalFaq: false,
    showAddModalEditFaq: false,
    showAddModalEditSettings: false,
    showAddModalPhoto: false,
    showModalPhoto: false,
    faq: { question: '', answer: '', id: -1 },
    photoModalUrl: '',
    option: {},
    group: '',
  };

  componentDidMount() {
    const {
      executeActions,
      fetchExperienceDetailAction,
      fetchExperienceFAQAction,
      fetchFAQTemplatesAction,
      match,
    } = this.props;

    const limitOptions = configServices.smallItemsCountPerPage;
    const offsetOptions = (parseInt(1, 10) - 1) * limitOptions;

    executeActions({
      actions: [
        () => fetchExperienceDetailAction({ id: match.params.cuid }),
        () =>
          fetchExperienceFAQAction(match.params.cuid, {
            limit: limitOptions,
            offset: offsetOptions,
          }),
        () => fetchDurationTypes(),
        () =>
          fetchFAQTemplatesAction(match.params.cuid, {
            limit: limitOptions,
            offset: offsetOptions,
          }),
      ],
    });
  }

  onSubmit = values => {
    const { updateExperienceAction, executeActions, match } = this.props;
    this.handleCloseModal();
    const submitValues = transformExperienceDetail(values);

    if (submitValues.background_photo instanceof File) {
      resizeImage(submitValues.background_photo, resized => {
        // remove background_photo from normal data submit
        delete submitValues.background_photo;

        const data = new FormData();
        data.append('background_photo', resized);

        executeActions({
          actions: [
            () =>
              updateExperienceAction({
                id: match.params.cuid,
                data: submitValues,
              }),
            () => updateExperienceAction({ id: match.params.cuid, data }),
          ],
          onSuccess: () => {
            window.location.reload();
          },
          successMsg: 'Update successfully',
        });
      });
    } else {
      executeActions({
        actions: [
          () =>
            updateExperienceAction({
              id: match.params.cuid,
              data: submitValues,
            }),
        ],
        onSuccess: () => {
          window.location.reload();
        },
        successMsg: 'Update successfully',
      });
    }
  };

  onSubmitAddMedia(data) {
    const { experience } = this.props;
    return this.props.addStoryAction({ id: experience.id, data });
  }

  onPageChangeFAQs(page) {
    const { match, fetchExperienceFAQAction } = this.props;
    const limit = configServices.smallItemsCountPerPage;
    const offset = (parseInt(page, 10) - 1) * limit;
    fetchExperienceFAQAction({
      id: match.params.cuid,
      params: {
        limit,
        offset,
      },
    });
  }

  handleCloseModal() {
    this.setState({
      showAddModal: false,
      showAddModalFaq: false,
      showAddModalEditFaq: false,
      showAddModalPhoto: false,
      showModalPhoto: false,
      option: {},
      group: '',
    });
  }

  handleOpenModal() {
    this.setState({ showAddModal: true });
  }

  handleOpenModalFaq() {
    console.log('xx');
    this.setState({ showAddModalFaq: true });
  }

  handleOpenModalEditFaq(faq) {
    this.setState({
      showAddModalEditFaq: true,
      faq,
    });
  }

  handleOpenModalPhoto(photoModalUrl) {
    this.setState({ showModalPhoto: true, photoModalUrl });
  }

  handleOpenAddModalPhoto() {
    this.setState({ showAddModalPhoto: true });
  }

  handleDeletePhoto(id) {
    const { experience } = this.props;
    return this.props.deleteStoryPhotoAction({ expId: experience.id, id });
  }

  buildSelectValue(options, value) {
    let selectValue = '';
    if (options) {
      Object.keys(options).forEach(key => {
        if (options[key] === value) {
          selectValue = key;
        }
      });
    }
    return selectValue;
  }

  render() {
    const {
      experience = {},
      experienceStatus,
      faqs,
      faqTemplates,
      duplicateExperienceAction,
      error,
      success,
      match,
      history,
    } = this.props;
    const { isFetching } = experienceStatus;

    if (isFetching) return <section className="content">Loading..</section>;
    if (!experience || Object.keys(experience).length === 0) {
      return <section className="content">404 product not found</section>;
    }

    const productId = parseInt(match.params.cuid);

    return (
      <Fragment>
        <Helmet>
          <title>
            Edit Product #{`${match.params.cuid}`} - FAQs - Inspitrip HQ
          </title>
        </Helmet>
        <ExperienceHeader
          experience={experience}
          duplicateExperienceAction={duplicateExperienceAction}
          editMode
        />
        <section className="content body">
          <div className="flex flex-row">
            <div className="flex flex-col gap-y-8">
              <div className="nav-tabs-custom">
                <ul className="nav nav-tabs">
                  <li className="">
                    <Link to={`/products/${productId}/information`}>
                      Information
                    </Link>
                  </li>
                  <li className="">
                    <Link to={`/products/${productId}/settings`}>Settings</Link>
                  </li>
                  <li className="">
                    <Link to={`/products/${productId}/options`}>Options</Link>
                  </li>
                  <li className="">
                    <Link to={`/products/${productId}/media`}>Media</Link>
                  </li>
                  <li className="active">
                    <Link to={`/products/${productId}/faqs`}>FAQs</Link>
                  </li>
                </ul>
                <div className="tab-content">
                  <div className="tab-pane active">
                    <ExperienceFAQs
                      faqs={faqs}
                      handleOpenModalFaq={this.handleOpenModalFaq.bind(this)}
                      handleOpenModalEditFaq={this.handleOpenModalEditFaq.bind(
                        this
                      )}
                      deleteFAQ={this.props.deleteExperienceFAQAction}
                      onPageChange={this.onPageChangeFAQs.bind(this)}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <ExperienceAddQuestionModal
          showAddModalFaq={this.state.showAddModalFaq}
          handleCloseModal={this.handleCloseModal.bind(this)}
          addExperienceFAQ={this.props.addExperienceFAQAction}
          expId={experience.id}
          faqTemplates={faqTemplates}
        />
        <ExperienceEditQuestionModal
          showAddModalEditFaq={this.state.showAddModalEditFaq}
          handleCloseModal={this.handleCloseModal.bind(this)}
          question={this.state.faq.question}
          answer={this.state.faq.answer}
          id={this.state.faq.id}
          expId={experience.id}
          faqTemplates={faqTemplates}
          deleteFAQ={this.props.deleteExperienceFAQAction}
          updateExperienceFAQ={this.props.updateExperienceFAQAction}
        />
      </Fragment>
    );
  }
}

ProductFAQPage.contextTypes = {
  router: PropTypes.object,
};

ProductFAQPage.propTypes = {
  /**
   * Operational Props
   */
  success: PropTypes.string,
  error: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  isFetching: PropTypes.bool,
  executeActions: PropTypes.func,
  /**
   * ConnectedProps
   */
  experience: PropTypes.object,
  /**
   * Actions
   */
  fetchExperienceDetailAction: PropTypes.func.isRequired,
};

ProductFAQPage.need = [
  ({ params }) => {
    return fetchExperienceDetail({ id: params.cuid });
  },
  ({ params }) => {
    const limitOptions = configServices.smallItemsCountPerPage;
    const offsetOptions = (parseInt(1, 10) - 1) * limitOptions;
    return fetchExperienceFAQ(params.cuid, {
      limit: limitOptions,
      offset: offsetOptions,
    });
  },
  ({ params }) => {
    const limitOptions = configServices.smallItemsCountPerPage;
    const offsetOptions = (parseInt(1, 10) - 1) * limitOptions;
    return fetchFAQTemplates(params.cuid, {
      limit: limitOptions,
      offset: offsetOptions,
    });
  },
];

const mapStateToProps = (state, ownProps) => ({
  experienceStatus: getFetchStatus(state),
  experience: getExperienceDetail(state, ownProps.match.params.cuid),
  faqs: getExperienceFaqs(state),
  faqTemplates: getFAQTemplates(state),
});

export default connect(mapStateToProps, {
  fetchExperienceDetailAction: fetchExperienceDetail,
  fetchExperienceFAQAction: fetchExperienceFAQ,
  addExperienceFAQAction: addExperienceFAQ,
  updateExperienceFAQAction: updateExperienceFAQ,
  deleteExperienceFAQAction: deleteExperienceFAQ,
  duplicateExperienceAction: duplicateExperience,
  fetchFAQTemplatesAction: fetchFAQTemplates,
})(withOperation(withQuery(ProductFAQPage)));
