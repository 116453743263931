import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { Form, Formik, Field } from 'formik';
import {
  Button,
  InputField,
  TextAreaField,
  ArrayObjectField,
  ArrayField,
} from '../../../../shared/App/components';
import { withOperation } from '../../../../utils/OperationalComponent';
import { withQuery } from '../../../../utils/QueryBasedComponent';
import {
  duplicateExperience,
  fetchExperienceDetail,
  updateExperience,
} from '../../experienceActions';
import {
  getExperienceDetail,
  getFetchStatus,
  getSubmitStatus,
} from '../../experienceReducer';
import ExperienceHeader from '../../components/ExperienceHeader';
import FormStatus from '../../../../shared/App/components/FormStatus';
import QuickFactList from '../../../QuickFacts/QuickFactList';

// Utils
import { transformExperienceDetail } from '../../experienceUtils';

class ProductSettingPage extends Component {
  state = {
    showAddModal: false,
    photoModalUrl: '',
    option: {},
    group: '',
  };

  componentDidMount() {
    const { executeActions, fetchExperienceDetailAction, match } = this.props;

    executeActions({
      actions: [() => fetchExperienceDetailAction({ id: match.params.cuid })],
    });
  }

  onSubmit = values => {
    const { updateExperienceAction, executeActions, match } = this.props;
    const data = transformExperienceDetail(values);

    executeActions({
      actions: [() => updateExperienceAction({ id: match.params.cuid, data })],
      successMsg: 'Update successfully',
    });
  };

  buildSelectValue(options, value) {
    let selectValue = '';
    if (options) {
      Object.keys(options).forEach(key => {
        if (options[key] === value) {
          selectValue = key;
        }
      });
    }
    return selectValue;
  }

  renderSEO() {
    return (
      <Fragment>
        <div className="flex flex-row">
          <div className="col-md-6 col-xs-12">
            <h4>
              <strong>Product SEO</strong>
            </h4>
          </div>
        </div>
        <div style={{ marginTop: '15px' }}>
          <InputField
            name="page_title"
            label="Page Title"
            placeholder="Page title text"
          />
          <TextAreaField
            id="page_description"
            name="page_description"
            label="Page Description"
            placeholder="Page description text"
          />
        </div>
      </Fragment>
    );
  }

  render() {
    const {
      experience = {},
      experienceStatus,
      submitStatus,
      duplicateExperienceAction,
      error,
      success,
      match,
    } = this.props;
    const { isSubmitting } = submitStatus;
    const { isFetching } = experienceStatus;

    const {
      cut_off_time = '',
      promotion_text = '',
      description,
      tag,
      tagging,
      cut_off_days,
      page_title,
      page_description,
      keywords,
    } = experience;

    if (isFetching) return <section className="content">Loading..</section>;
    if (!experience || Object.keys(experience).length === 0) {
      return <section className="content">404 product not found</section>;
    }

    const tagValue = tag
      ? tag.map(item => ({ label: item.term, value: item.term }))
      : [];
    const cutOffTime =
      cut_off_time && cut_off_time.split(':').length - 1 === 2
        ? cut_off_time.substring(0, cut_off_time.lastIndexOf(':'))
        : cut_off_time;

    const initialValues = {
      cut_off_time: cutOffTime,
      page_title,
      page_description,
      cut_off_days,
      promotion_text,
      tag: tagValue,
      description,
      tagging,
      keywords,
    };

    const productId = parseInt(match.params.cuid);
    const errorData = error
      ? error.data || 'Can not save. Please contact admin'
      : '';

    return (
      <Fragment>
        <Helmet>
          <title>
            Edit Product #{`${match.params.cuid}`} - Settings - Inspitrip HQ
          </title>
        </Helmet>
        <ExperienceHeader
          experience={experience}
          duplicateExperienceAction={duplicateExperienceAction}
          editMode
        />
        <section className="content body">
          <FormStatus formState={{ error: errorData, success }} />
          <Formik
            initialValues={initialValues}
            onSubmit={this.onSubmit}
            enableReinitialize
            isInitialValid
          >
            {({ values, setFieldValue }) => (
              <Form>
                <div className="flex flex-row">
                  <div className="flex flex-col gap-y-8">
                    <div className="nav-tabs-custom">
                      <ul className="nav nav-tabs">
                        <li className="">
                          <Link to={`/products/${productId}/information`}>
                            Information
                          </Link>
                        </li>
                        <li className="active">
                          <Link to={`/products/${productId}/settings`}>
                            Settings
                          </Link>
                        </li>
                        <li className="">
                          <Link to={`/products/${productId}/options`}>
                            Options
                          </Link>
                        </li>
                        <li className="">
                          <Link to={`/products/${productId}/media`}>Media</Link>
                        </li>
                        <li className="">
                          <Link to={`/products/${productId}/faqs`}>FAQs</Link>
                        </li>
                      </ul>

                      <div className="tab-content pl-0">
                        <div className="flex flex-row">
                          <div className="col-md-12">
                            <h4>
                              <strong>Product Settings</strong>
                            </h4>
                          </div>
                        </div>

                        <div style={{ marginTop: '15px' }}>
                          <div className="flex flex-row">
                            <div className="col-md-12 col-xs-12">
                              <InputField
                                name="cut_off_time"
                                label="Cut off time (in GMT+7)"
                                placeholder="HH:MM"
                              />
                              <InputField
                                name="cut_off_days"
                                label="Cut off days"
                              />
                            </div>
                          </div>

                          <div className="flex flex-row">
                            <div className="col-md-12 col-xs-12">
                              <InputField
                                name="page_title"
                                label="Page Title"
                                placeholder="Page title text"
                              />
                              <TextAreaField
                                id="page_description"
                                name="page_description"
                                label="Page Description"
                                placeholder="Page description text"
                              />
                            </div>
                          </div>

                          <div className="flex flex-row">
                            <div className="col-md-12">
                              <ArrayField
                                name="keywords"
                                label="Keywords"
                                arrayData={values.keywords}
                              />
                            </div>
                          </div>

                          <div className="flex flex-row">
                            <div className="col-md-12 col-xs-12">
                              <ArrayObjectField
                                label="Tags"
                                name="tagging"
                                arrayData={values.tagging}
                                renderObject={(_, index) => (
                                  <div>
                                    <div
                                      className="row col-md-6 col-xs-6"
                                      style={{ marginRight: '4px' }}
                                    >
                                      <Field
                                        name={`tagging.${index}.0`}
                                        className="form-control"
                                        placeholder="Label"
                                      />
                                    </div>
                                    <div className="row col-md-6 col-xs-6">
                                      <Field
                                        name={`tagging.${index}.1`}
                                        className="form-control"
                                        placeholder="Value"
                                      />
                                    </div>
                                  </div>
                                )}
                              />
                            </div>
                          </div>
                          <div className="flex flex-row">
                            <div className="col-md-12">
                              <Button
                                type="submit"
                                displayType="primary"
                                disabled={isSubmitting}
                                isLoading={isSubmitting}
                              >
                                Save changes
                              </Button>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="content">
                        {experience && (
                          <Fragment>
                            <div className="flex flex-row">
                              <div className="col-xs-12 pl-2">
                                <h4>
                                  <strong>Quick Facts</strong>
                                </h4>
                              </div>
                            </div>
                            <QuickFactList experienceId={experience.id} />
                          </Fragment>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </section>
      </Fragment>
    );
  }
}

ProductSettingPage.contextTypes = {
  router: PropTypes.object,
};

ProductSettingPage.propTypes = {
  /**
   * Operational Props
   */
  success: PropTypes.string,
  error: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  isFetching: PropTypes.bool,
  executeActions: PropTypes.func,
  /**
   * ConnectedProps
   */
  experience: PropTypes.object,
  /**
   * Actions
   */
  fetchExperienceDetailAction: PropTypes.func.isRequired,
};

ProductSettingPage.need = [
  ({ params }) => {
    return fetchExperienceDetail({ id: params.cuid });
  },
];

const mapStateToProps = (state, ownProps) => ({
  experienceStatus: getFetchStatus(state),
  submitStatus: getSubmitStatus(state),
  experience: getExperienceDetail(state, ownProps.match.params.cuid),
});

export default connect(mapStateToProps, {
  fetchExperienceDetailAction: fetchExperienceDetail,
  updateExperienceAction: updateExperience,
  duplicateExperienceAction: duplicateExperience,
})(withOperation(withQuery(ProductSettingPage)));
