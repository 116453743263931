// Utils
import { getFilter, getSearch, languageOptions } from '../../utils/filter';

// Constants
import configApp from '../../config/configApp';

const stateSubOptions = [
  { value: 'published', label: 'Published' },
  { value: 'init', label: 'Init' },
  { value: 'pending', label: 'Pending' },
];

export const keyOptions = [
  { value: 'state', label: 'Status', type: 'select', subOptions: stateSubOptions },
  { value: 'template', label: 'Template', type: 'select',
    subOptions: [
      { value: 'country', label: 'Country' },
      { value: 'city', label: 'City' }
    ]
  },
  languageOptions()
];


// Can override if you want
export const getFilterData = (queryParams, options = []) => {
  return getFilter(queryParams, [...options, ...keyOptions]);
};

export const getSearchData = queryParams => {
  return getSearch(queryParams);
};
