import { Avatar } from 'antd';
import PropTypes from 'prop-types';
import React, { Fragment } from 'react';
import { connect } from 'react-redux';

import { Box } from '../../../../../shared/App/components';
import { withOperation } from '../../../../../utils/OperationalComponent';
import { formatDateTime } from '../../../../../utils/time';
import { fetchUser } from '../../../../User/userActions';
import { getUserDetail } from '../../../../User/userReducers';

class LocalGuideInfo extends React.Component {
  componentDidMount() {
    const { localId, executeActions, fetchUserAction } = this.props;
    if (localId) {
      executeActions({
        actions: [() => fetchUserAction({ id: localId })],
      });
    }
  }

  componentDidUpdate(prevProps) {
    const { localId, executeActions, fetchUserAction } = this.props;
    const { localId: prevLocalId } = prevProps;

    if (prevLocalId !== localId) {
      executeActions({
        actions: [() => fetchUserAction({ id: localId })],
      });
    }
  }

  render() {
    const { isLoading, isFetching, localguide = {} } = this.props;
    if (!localguide.id) {
      return (
        <Box
          isLoading={isLoading || isFetching}
          body={
            <Fragment>
              <div className="box-body">
                <div style={{ marginBottom: '10px' }} className="flex flex-row">
                  <div className="col-xs-7">
                    <strong>Local Guide</strong>
                  </div>
                  <div className="col-xs-5 text-right" />
                </div>
                <div>
                  <div
                    style={{ display: 'inline-block', verticalAlign: 'middle' }}
                  >
                    -<br />
                    <span style={{ fontSize: '12px', color: 'grey' }}></span>
                  </div>
                </div>
              </div>
            </Fragment>
          }
          noHeader
        />
      );
    }

    return (
      <Fragment>
        <div className="box-body pl-0">
          <label>Local Guide</label>
        </div>
        <ul className="list-group">
          <li className="list-group-item">
            <div className="flex flex-row">
              <span className="col-xs-5">Email</span>
              <a href={`mailto:${localguide.email}`} className="col-xs-7 text-right" style={{ wordBreak: 'break-all' }}>
                {localguide.email}
              </a>
            </div>
          </li>
          <li className="list-group-item">
            <div className="flex flex-row">
              <span className="col-xs-5">Phone</span>
              <span className="col-xs-7 text-right">{localguide.phone}</span>
            </div>
          </li>
          <li className="list-group-item">
            <div className="flex flex-row">
              <span className="col-xs-5">Whatsapp</span>
              <span className="col-xs-7 text-right">
                {localguide.whatsapp}
              </span>
            </div>
          </li>
          <li className="list-group-item">
            <div className="flex flex-row">
              <span className="col-xs-5">Nationality</span>
              <span className="col-xs-7 text-right">
                {localguide.nationality}
              </span>
            </div>
          </li>
          <li className="list-group-item">
            <div className="flex flex-row">
              <span className="col-xs-5">Registered date</span>
              <span className="col-xs-7 text-right">
                {formatDateTime(localguide.date_joined)}
              </span>
            </div>
          </li>
        </ul>
      </Fragment>
    );
  }
}

LocalGuideInfo.propTypes = {
  isLoading: PropTypes.bool,
  localId: PropTypes.number,
  /**
   * ConnectedProps
   */
  localGuide: PropTypes.object,
  /**
   * Operational Props
   */
  success: PropTypes.string,
  error: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  isFetching: PropTypes.bool,
  executeActions: PropTypes.func,
  /**
   * Actions
   */
  fetchUserAction: PropTypes.func,
};

const mapStateToProps = (state, ownProps) => ({
  localguide: getUserDetail(state, ownProps.localId),
});

export default connect(mapStateToProps, {
  fetchUserAction: fetchUser,
})(withOperation(LocalGuideInfo));
