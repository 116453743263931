import { notificationAPI } from '../../utils/axiosAPI';

export const FETCH_EMAILS = 'FETCH_EMAILS';
export const fetchEmails = ({ params }) => {
  return dispatch =>
    notificationAPI(dispatch, {
      method: 'get',
      url: '/notification/admin/v1/templates',
      params,
      type: FETCH_EMAILS,
    });
};

export const RESET_EMAIL = 'RESET_EMAIL';
export const resetEmail = () => ({
  type: RESET_EMAIL,
});

export const CREATE_EMAIL = 'CREATE_EMAIL';
export const createEmail = ({ params }) => {
  return dispatch =>
    notificationAPI(dispatch, {
      method: 'post',
      url: '/notification/admin/v1/templates',
      data: params,
      type: CREATE_EMAIL,
    });
};

export const UPDATE_EMAIL = 'UPDATE_EMAIL';
export const updateEmail = ({ id, params }) => {
  return dispatch =>
    notificationAPI(dispatch, {
      method: 'patch',
      url: `/notification/admin/v1/templates/${id}`,
      data: params,
      type: UPDATE_EMAIL,
    });
};

export const FETCH_EMAIL = 'FETCH_EMAIL';
export function getEmail({ id }) {
  return dispatch =>
    notificationAPI(dispatch, {
      method: 'get',
      url: `/notification/admin/v1/templates/${id}`,
      type: FETCH_EMAIL,
    });
}

export const DELETE_EMAIL = 'DELETE_EMAIL';
export function deleteEmail({ id }) {
  return dispatch =>
    notificationAPI(dispatch, {
      method: 'delete',
      url: `/notification/admin/v1/templates/${id}`,
      type: DELETE_EMAIL,
    });
}
