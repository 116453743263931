import { Link } from 'react-router-dom';
import { Status } from '../../shared/App/components';
import { formatDateTime, timeAgo } from '../../utils/time';

// Utils
import { displayCurrency } from '../../utils/currency';
import { formatArrayToObject } from '../../utils/dataUtils';

// Constants
export const experienceStatusConfig = {
  draft: { title: 'Draft', color: Status.colors.YELLOW },
  published: { title: 'Published', color: Status.colors.GREEN },
  private: { title: 'Private', color: Status.colors.GREY },
};

export const transformExperienceDetail = ({ place, ...experience }) => {
  let newExperience = { ...experience };
  newExperience.about = formatArrayToObject(newExperience.about);
  newExperience.inclusion = formatArrayToObject(newExperience.inclusion);
  newExperience.exclusion = formatArrayToObject(newExperience.exclusion);
  if (newExperience.user) newExperience.user = newExperience.user.value;
  newExperience.tag =
    (newExperience.tag &&
      newExperience.tag.map(tag => ({ term: tag.value }))) ||
    [];

  // Inject place values
  if (place && place.value) {
    newExperience = { ...newExperience, ...place.value };
  }

  // Category
  newExperience.category =
    newExperience.category && newExperience.category.length > 0
      ? newExperience.category.map(item => item.value)
      : [];

  // Extract language, country, currency from localeSetting
  // Rule localeSetting: language-country-currency
  if (newExperience.localeSetting) {
    const localeSetting = newExperience.localeSetting.split('-');
    if (localeSetting && localeSetting.length > 2) {
      newExperience.language = localeSetting[0];
      newExperience.country = localeSetting[1];
      newExperience.currency = localeSetting[2];
    }
    delete newExperience.localeSetting;
  }

  delete newExperience.createdDate;
  delete newExperience.lastUpdate;

  return newExperience;
};

export const getObjectByValue = (value, options) => {
  let obj = {};
  if (options && options.length > 0) {
    options.forEach(opt => {
      if (opt.value === value) {
        obj = opt;
      }
    });
  }
  return obj;
};

export const experienceColumns = (fields = undefined) => {
  return [
    {
      id: 'id',
      title: 'ID',
      render: ({ id }) => <Link to={`/products/${id}`}>#{id}</Link>,
    },
    {
      id: 'language',
      title: 'Language',
      render: ({ language }) => <span>{language}</span>,
    },
    {
      id: 'title',
      title: 'Title',
      render: ({ id, title }) => <Link to={`/products/${id}`}>{title}</Link>,
    },
    {
      id: 'type',
      title: 'Type',
      render: ({ type }) => (
        <span>
          {fields
            ? getObjectByValue(type, fields.type.choices).display_name
            : type}
        </span>
      ),
    },
    {
      id: 'place_name',
      title: 'Location',
      render: ({ location_name }) => {
        return <a>{location_name}</a>;
      },
    },
    {
      id: 'state',
      title: 'Status',
      render: ({ state }) => {
        return <Status state={state} statuses={experienceStatusConfig} />;
      },
    },
    {
      id: 'price',
      title: 'Price',
      render: ({ price, currency_code }) => (
        <span>
          {displayCurrency({ amount: price, currency: currency_code })}
        </span>
      ),
    },
    {
      id: 'created',
      title: 'Created Date',
      render: ({ created }) => (
        <div style={{ width: '100px', whiteSpace: 'normal' }}>
          {formatDateTime(created)}
        </div>
      ),
    },
    {
      id: 'update',
      title: 'Last Update',
      render: ({ modified }) => <span>{timeAgo(modified)}</span>,
    },
  ];
};
