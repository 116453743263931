import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { Button, Status } from '../../../shared/App/components';
import { experienceStatusConfig } from '../experienceUtils';
import { Link } from 'react-router-dom';
import configService from '../../../config/configService';

// Utils
import { getLocalePrefixByLanguage } from '../../../utils/localeUtils';

class ExperienceHeader extends Component {
  render() {
    const { editMode, experience = {}, duplicateExperienceAction } = this.props;
    const { id, state, language = 'en' } = experience;

    return (
      <section className="content-header">
        <div className="flex flex-row" style={{ marginTop: '-15px', marginBottom: '15px' }}>
          <ol className="breadcrumb">
            <li>
              <Link to={'/'}>InspitripHQ</Link>
            </li>
            <li>
              <Link to={'/products'}>Products</Link>
            </li>
            {editMode ? <li className="active">#{id}</li> : null}
          </ol>
        </div>
        <div className="flex flex-row">
          <div className="col-sm-6">
            <h1 style={{ fontSize: '24px', marginTop: 0 }}>
              {!editMode ? 'Create Product' : `Edit Product #${id}`}
              &nbsp;
              {editMode && (
                <Status state={state} statuses={experienceStatusConfig} />
              )}
            </h1>
          </div>
          {editMode && (
            <div className="col-sm-6" style={{ textAlign: 'right' }}>
              <a
                className="btn  btn-default  btn-nor"
                href={`${configService.mainSite}${getLocalePrefixByLanguage(
                  language
                )}/experiences/${id}`}
                target="_blank"
              >
                <i className="fa fa-external-link" /> View
              </a>
              &nbsp;&nbsp;
            </div>
          )}
        </div>
      </section>
    );
  }
}

ExperienceHeader.propTypes = {
  experience: PropTypes.object,
  editMode: PropTypes.bool,
  duplicateExperienceAction: PropTypes.func,
};

export default ExperienceHeader;
