import { userAPI } from '../../utils/axiosAPI';

// Export Constants
export const FETCH_USERS = 'FETCH_USERS';
export const FETCH_USER = 'FETCH_USER';
export const USER_UPDATE = 'USER_UPDATE';
export const USER_CREATE = 'USER_CREATE';
export const RESET_USER = 'RESET_USER';
export const SEARCH_USER = 'SEARCH_USER';

export const resetUser = () => ({
  type: RESET_USER,
});

// Export Actions
export function fetchUsers({ params }) {
  return dispatch => {
    return userAPI(dispatch, {
      method: 'get',
      url: '/userbase/admin/v1/users',
      params,
      type: FETCH_USERS,
    });
  };
}

export function fetchUser({ id }) {
  return dispatch => {
    return userAPI(dispatch, {
      method: 'get',
      url: `/userbase/admin/v1/users/${id}`,
      type: FETCH_USER,
    });
  };
}

export function updateUser({ id, params }) {
  return dispatch => {
    return userAPI(dispatch, {
      method: 'patch',
      url: `/userbase/admin/v1/users/${id}`,
      data: params,
      type: USER_UPDATE,
    });
  };
}

export function createUser({ params }) {
  return dispatch => {
    return userAPI(dispatch, {
      method: 'post',
      url: '/userbase/admin/v1/users',
      data: params,
      type: USER_CREATE,
    });
  };
}

export const searchUser = ({ params }) => {
  return dispatch =>
    userAPI(dispatch, {
      method: 'get',
      url: '/userbase/admin/v1/users',
      params,
      type: SEARCH_USER,
    });
};
