import React from 'react';
import { Link } from 'react-router-dom';

// Components
import { Status } from '../../shared/App/components';

// Utils
import { formatDateTime, timeAgo } from '../../utils/time';

// Constants
export const PAGE_STATUS = ['active', 'inactive'];
export const StatusConfig = {
  [true]: { title: 'active', color: Status.colors.GREEN },
  [false]: { title: 'inactive', color: Status.colors.GREY }
};

export const transformPageDetail = page => {
  let newPage = { ...page };
  const localeSplit = newPage.locale ? newPage.locale.split('-') : ['en', ''];
  newPage.language = localeSplit[0] || '';
  newPage.country = localeSplit[1] || '';
  newPage.is_active = newPage.is_active === 'active';
  delete newPage.editMode;
  delete newPage.locale;
  return newPage;
};

export const buildFormData = page => {
  if (!page) return {};

  return {
    ...page,
    locale: `${page.language}${page.country && `-${page.country}`}`,
    is_active: page.is_active ? 'active' : 'inactive'
  };
};

// TODO: Build locale link

export const pageColumns = () => {
  return [
    {
      id: 'id',
      title: 'ID',
      render: ({ id }) => {
        return <div>{id}</div>;
      }
    },
    {
      id: 'language',
      title: 'Locale',
      render: ({ country, language }) => (
        <div>
          {language}
          {country && ` - ${country}`}
        </div>
      )
    },
    {
      id: 'title',
      title: 'Title',
      render: ({ id, title }) => <Link to={`/pages/${id}`}>{title}</Link>
    },
    {
      id: 'is_active',
      title: 'Status',
      render: ({ is_active }) => {
        return <Status state={`${is_active}`} statuses={StatusConfig} />;
      }
    },
    {
      id: 'created',
      title: 'Created',
      render: ({ created }) => <span>{formatDateTime(created)}</span>
    },
    {
      id: 'modified',
      title: 'Updated',
      render: ({ modified }) => <span>{timeAgo(modified)}</span>
    }
  ];
};
