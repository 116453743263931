import { bookingAPI, productAPI } from '../../utils/axiosAPI';

export const FETCH_BOOKING = 'booking/FETCH_BOOKING';
export const RESET_BOOKING = 'booking/RESET_BOOKING';
export const FETCH_BOOKING_DETAIL = 'booking/FETCH_BOOKING_DETAIL';
export const CREATE_BOOKING = 'booking/CREATE_BOOKING';
export const UPDATE_BOOKING = 'booking/UPDATE_BOOKING';
export const DELETE_BOOKING = 'booking/DELETE_BOOKING';
export const REFUND_BOOKING = 'booking/REFUND_BOOKING';
export const CANCEL_BOOKING = 'booking/CANCEL_BOOKING';
export const FETCH_BOOKING_TRANSACTIONS = 'booking/FETCH_BOOKING_TRANSACTIONS';
export const RESET_BOOKING_TRANSACTIONS = 'booking/RESET_BOOKING_TRANSACTIONS';

export const fetchBooking = ({ params }) => {
  return dispatch =>
    bookingAPI(dispatch, {
      method: 'get',
      url: '/booking/admin/v1/bookings',
      params,
      type: FETCH_BOOKING,
    });
};

export const resetBooking = () => ({
  type: RESET_BOOKING,
});

export const fetchBookingDetail = ({ id }) => dispatch =>
  bookingAPI(dispatch, {
    method: 'get',
    url: `/booking/admin/v1/bookings/${id}`,
    type: FETCH_BOOKING_DETAIL,
  });

export const createBooking = ({ data }) => dispatch =>
  bookingAPI(dispatch, {
    method: 'post',
    url: '/booking/admin/v1/bookings',
    data,
    type: CREATE_BOOKING,
  });

export const updateBooking = ({ id, data }) => dispatch =>
  bookingAPI(dispatch, {
    method: 'patch',
    url: `/booking/admin/v1/bookings/${id}`,
    data,
    type: UPDATE_BOOKING,
  });

export const deleteBooking = ({ id }) => dispatch =>
  bookingAPI(dispatch, {
    method: 'delete',
    url: `/booking/admin/v1/bookings/${id}`,
    type: DELETE_BOOKING,
  });

export const cancelBooking = ({ id }) => dispatch =>
  bookingAPI(dispatch, {
    method: 'put',
    url: `/booking/admin/v1/bookings/${id}/cancel`,
    type: CANCEL_BOOKING,
  });

export const sendBookingNotification = ({ id, data }) => dispatch =>
  bookingAPI(dispatch, {
    method: 'put',
    url: `/booking/admin/v1/bookings/${id}/notify`,
    data,
    type: '',
  });

export const fetchBookingItems = ({ bookingId }) => dispatch =>
  bookingAPI(dispatch, {
    method: 'get',
    url: `/booking/admin/v1/bookings/${bookingId}/items`,
    type: '',
  });

export const fetchExperienceDetail = ({ id }) => dispatch =>
  productAPI(dispatch, {
    method: 'get',
    url: `/product/admin/v1/products/${id}`,
    type: '',
  });

export const fetchVariantDetail = ({ id, ogId }) => dispatch =>
  productAPI(dispatch, {
    method: 'get',
    url: `/product/admin/v1/products/${id}/variants/${ogId}`,
    type: '',
  });

export const fetchBookingTransactions = params => {
  return dispatch =>
    bookingAPI(dispatch, {
      method: 'get',
      url: '/booking/admin/v1/payments',
      params,
      type: FETCH_BOOKING_TRANSACTIONS,
    });
};
