import { Empty } from 'antd';
import { Link } from 'react-router-dom';
import configApp from '../../config/configApp';
import { Status, Avatar } from '../../shared/App/components';
import { displayCurrency } from '../../utils/currency';
import { formatDate, formatDateTime, timeAgo } from '../../utils/time';

/**
 * transformBookingItemDetail changes the field user and localguide of booking item detail
 * to user_id and localguide_id to avoid collision with the booking item object returned in list
 * @param {*} bookingItem - the booking item detail object
 */
export const transformBookingItemDetail = bookingItem => {
  let newBookingItem = { ...bookingItem };
  delete newBookingItem.user;
  delete newBookingItem.localguide;

  return {
    ...newBookingItem,
  };
};

export const bookingItemStatusConfig = {
  [configApp.bookingItemState.ASSIGNED]: {
    title: 'Fulfilled',
    color: Status.colors.GREEN,
  },
  [configApp.bookingItemState.UNASSIGNED]: {
    title: 'Unfulfilled',
    color: Status.colors.YELLOW,
  },
  [configApp.bookingItemState.CANCELLED]: {
    title: 'Cancelled',
    color: Status.colors.GREY,
  },
  [configApp.bookingItemState.COMPLETED]: {
    title: 'Completed',
    color: Status.colors.BLUE,
  },
};

export const bookingColumns = [
  {
    id: 'id',
    title: 'ID',
    render: ({ booking, id }) => (
      <Link to={`bookings/${booking}/fulfillments/${id}`}>
        #{booking}-{id}
      </Link>
    ),
  },
  {
    id: 'start_date',
    title: 'Travel Date',
    render: ({ start_date }) => <span>{formatDate(start_date)}</span>,
  },
  {
    id: 'experience_title_info',
    title: 'Product',
    render: ({ experience_title_info }) => experience_title_info,
  },
  {
    id: 'status',
    title: 'Status',
    render: ({ status }) => (
      <Status state={`${status}`} statuses={bookingItemStatusConfig} />
    ),
  },
  {
    id: 'localguide',
    title: 'Local Guide',
    render: ({ localguide }) => {
      if (localguide) {
        return (
          <Link to={`/users/${localguide.id}`}>
            <span>
              <Avatar
                img_src={localguide.photo}
                user={localguide}
              />
              &nbsp;
              {localguide.first_name || ''} {localguide.last_name || ''}
            </span>
          </Link>
        );
      }
      return <span>-</span>;
    },
  },
  {
    id: 'booking',
    title: 'Booking',
    render: ({ booking }) => <Link to={`/bookings/${booking}`}>{booking}</Link>,
  },
  {
    id: 'user',
    title: 'Traveler',
    render: ({ user }) => {
      if (!user) return null;

      return (
        <Link to={`/users/${user.id}`}>
          <span>
            <Avatar
              img_src={user.photo}
              user={user}
            />
            &nbsp;{user.first_name || ''} {user.last_name || ''}
          </span>
        </Link>
      );
    },
  },
  {
    id: 'cost',
    title: 'Price',
    render: ({ cost, currency_code }) => (
      <span>
        {displayCurrency({ amount: parseFloat(cost), currency: currency_code })}
      </span>
    ),
  },
  {
    id: 'created',
    title: 'Created Date',
    render: ({ created }) => (
      <div style={{ width: '100px', whiteSpace: 'normal' }}>
        {formatDateTime(created)}
      </div>
    ),
  },
  {
    id: 'modified',
    title: 'Last Update',
    render: ({ modified }) => <span>{timeAgo(modified)}</span>,
  },
];
