import React from 'react';
import { Form, Formik } from 'formik';
import {
  Modal,
  AsyncSelectField,
} from '../../../../../shared/App/components';

const validate = values => {
  const errors = {};

  if (!values.localguide || !values.localguide.value) {
    errors.localguide = 'Please input a localguide';
  }

  return errors;
};

const AssignBookingItemModal = ({ onSubmit, onDismiss, visible, searchLocalGuideAction }) => (
  <Formik
    enableReinitialize
    validate={validate}
    onSubmit={onSubmit}
  >
    {({ isSubmitting, isValid, values, submitForm }) => (
      <Modal
        title="Are you sure to assign this Booking Item?"
        confirmText="Yes, sure"
        dismissText="Cancel"
        onConfirm={submitForm}
        {...{ onDismiss, visible }}
      >
        <Form>
          <AsyncSelectField
              name="localguide"
              label="Local Guide"
              loadOptions={(inputValue, callback) => {
                searchLocalGuideAction({ params: { search: inputValue } }).then(
                  (response) => {
                    const data = response.data.results.map((item) => {
                      return {
                        label: `${item.first_name} ${item.last_name} (${item.email})`,
                        value: item.id
                      };
                    });
                    callback(data);
                  }
                );
              }}
            />
        </Form>
      </Modal>
    )}
  </Formik>
);

export default AssignBookingItemModal;
