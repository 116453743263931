// Utils
import { getFilter, getSearch } from '../../utils/filter';


export const keyOptions = [];

// Can override if you want
export const getFilterData = (queryParams, options = []) => {
  return getFilter(queryParams, [...options, ...keyOptions]);
};

export const getSearchData = queryParams => {
  return getSearch(queryParams);
};
