import React, { Fragment } from 'react';
import { Formik } from 'formik';
import {
  NumericInputField,
  InputField,
  Button,
  CheckboxField,
} from '../../../../shared/App/components';

const UnitInfoForm = ({
  handleSubmit,
  handleDelete,
  initialValues,
  isEditing,
}) => (
  <Formik
    onSubmit={handleSubmit}
    initialValues={initialValues}
    enableReinitialize
  >
    {({ isSubmitting, submitForm }) => (
      <Fragment>
        <InputField name="title" label="Title" />
        <CheckboxField name="is_primary" label="Is Primary?" />
        <NumericInputField name="supplier_price" label="Net price" />
        <NumericInputField name="price" label="Base price" />
        <NumericInputField name="price_display" label="Display price" />
        <NumericInputField name="ratio" label="Ratio" />
        <NumericInputField name="participant_min" label="Min quantity" />
        <NumericInputField name="participant_max" label="Max quantity" />
        <div className="flex flex-row">
          <div className="col-sm-6">
            {isEditing && (
              <Button
                onClick={handleDelete}
                className="btn btn-flat text-red"
                style={{ marginLeft: '-12px' }}
              >
                <i className="fa fa-trash-o"></i> Delete
              </Button>
            )}
          </div>
          <div className="col-sm-6 text-right">
            <Button
              onClick={() => submitForm()}
              displayType="primary"
              disabled={isSubmitting}
            >
              Save changes
            </Button>
          </div>
        </div>
      </Fragment>
    )}
  </Formik>
);

export default UnitInfoForm;
