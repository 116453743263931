import React, { Component, Fragment } from 'react';
import { Helmet } from 'react-helmet';
import { connect } from 'react-redux';
import { Listings } from '../../../shared/App/components';
import Breadcrumb from '../../../shared/App/components/Breadcrumb';
import { fetchPayments } from '../paymentActions';
import { getTransactions, getFetchStatus } from '../paymentReducers';
import { parseQueryString } from '../../../utils/urlQuery';
import { paymentColumns } from '../paymentUtils';
import configService from '../../../config/configService';
import { withOperation } from '../../../utils/OperationalComponent';
import { withQuery } from '../../../utils/QueryBasedComponent';

class PaymentHistory extends Component {
  componentDidMount() {
    const {
      setQueryCallback,
      trandsactions,
      location: { search },
    } = this.props;

    setQueryCallback(this.handlefetchTransactions);

    if (!trandsactions || trandsactions.length === 0) {
      this.handlefetchTransactions(parseQueryString(search));
    }
  }

  handlefetchTransactions = params => {
    const { fetchTransactionsAction } = this.props;
    const { page = 1 } = params || {};
    if (this.cancel) this.cancel();
    this.cancel = fetchTransactionsAction({ params: { page } }).cancel;
  };

  handlePageChange = page => {
    this.props.pushParams({ page });
  };

  render() {
    const { transactions, fetchStatus, queryParams } = this.props;
    const { count, isFetching } = fetchStatus || {
      count: 0,
      isFetching: false,
    };
    const { page } = queryParams || { page: 0 };

    return (
      <Fragment>
        <Helmet>
          <title>All Transactions - Inspitrip HQ</title>
        </Helmet>
        <section className="content-header">
          <div className="flex flex-row" style={{ marginTop: '-15px', marginBottom: '15px' }}>
            <Breadcrumb
              history={[
                { title: 'InspitripHQ', url: '/' },
                { title: 'Transactions', url: '' },
              ]}
            />
          </div>
          <div className="flex flex-row">
            <div className="flex flex-col gap-y-7">
              <h1 style={{ fontSize: '24px', marginTop: 0 }}>Transactions</h1>
            </div>
            <div className="flex flex-col gap-y-2	  text-right"></div>
          </div>
          {/* <div className="flex flex-row" style={{ padding: '10px' }}>
            <div className="col-sm-4">
              <label>Type</label>
              <select className="form-control">
                <option>All</option>
                <option>Refund</option>
                <option>Authorization</option>
                <option>Capture</option>
              </select>
            </div>
            <div className="col-sm-4">
              <label>Payment</label>
              <select className="form-control">
                <option>All</option>
                <option>Wallet</option>
                <option>Paypal</option>
                <option>Stripe</option>
              </select>
            </div>
            <div className="col-sm-4">
              <label>Search</label>
              <input type="text" className="form-control" placeholder="Search" />
            </div>
          </div> */}
        </section>
        <div className="content body">
          <section style={{ marginTop: '15px' }}>
            <Listings
              listingsTitle="Transactions"
              data={transactions}
              columns={paymentColumns()}
              actions={this.actions}
              onSortHeader={this.handleSort}
              page={parseInt(page || 1, 10)}
              dataCount={count || 0}
              itemsPerPage={configService.itemsCountPerPage}
              onPageChange={this.handlePageChange}
              pageRange={3}
              isFetching={isFetching}
              noHeader
            />
          </section>
        </div>
      </Fragment>
    );
  }
}

const mapStateToProps = state => ({
  fetchStatus: getFetchStatus(state),
  transactions: getTransactions(state),
});

export default connect(mapStateToProps, {
  fetchTransactionsAction: fetchPayments,
})(withOperation(withQuery(PaymentHistory)));
