import { Component } from 'react';
import { connect } from 'react-redux';
import { SubmissionError } from 'redux-form';
import { getIsAuth, getUser } from '../../../Auth/AuthReducer';

// Import Actions
import { redirect } from '../../../../utils/router';
import { login } from '../../../Auth/AuthActions';

// Import Forms
import LoginForm from '../../forms/LoginForm';

import LoginLayout from 'LoginLayout';

class LoginPage extends Component {
  loginSubmit = parameters => {
    const { dispatch } = this.props;
    return dispatch(login(parameters))
      .then(response => {
        // TODO: Implement behaviour
        redirect('/');
      })
      .catch(error => {
        throw new SubmissionError({
          _error: 'Incorrect email or password',
        });
      });
  };

  render() {
    return (
      <LoginLayout>
        <div className="login-box-body">
          <p className="login-box-msg">Sign in to Inspitrip Dash</p>
          <LoginForm onSubmit={this.loginSubmit} />
        </div>
      </LoginLayout>
    );
  }
}

// Retrieve data from store as props
const mapStateToProps = state => {
  return {
    user: getUser(state),
    isAuth: getIsAuth(state),
  };
};

export default connect(mapStateToProps)(LoginPage);
