import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Modal } from '../../../../shared/App/components';

// containers
import VariantForm from './VariantForm';

// actions
import {
  fetchVariantDetail,
  createVariant,
  updateVariant,
  deleteVariant,
} from '../../reducers/variant/actions';

// selectors
import { getVariantIds, getVariant } from '../../reducers/variant';

// utils
import { withOperation } from '../../../../utils/OperationalComponent';

// configs
import configApp from '../../../../config/configApp';

class VariantModal extends React.Component {
  state = {
    isDeleting: false
  };

  componentWillMount() {
    const { executeActions, experienceId, variantId } = this.props;

    if (this.isEditMode()) {
      executeActions({
        actions: [
          () => this.props.fetchVariantDetail({ experienceId, variantId })
        ]
      });
    }
  }

  toggleDeleting = isDeleting => this.setState({ isDeleting });

  isEditMode = () => this.props.mode === configApp.editMode.EDIT;

  buildInitialValues = () => {
    if (!this.isEditMode()) {
      return { experience: this.props.experienceId };
    }

    const {
      code,
      title,
      text,
      explaination,
      is_active,
      is_instant_booking,
      price_display
    } = this.props.variant;

    return {
      code,
      title,
      text,
      explaination,
      is_active,
      is_instant_booking,
      price_display
    };
  };

  handleSubmit = values => {
    const { experienceId, executeActions, onDismiss, variant, numberOfVariants } = this.props;

    executeActions({
      actions: [
        () => {
          return this.isEditMode()
            ? this.props.updateVariant({
              experienceId,
              variantId: variant.id,
              data: values
            })
            : this.props.createVariant({
              experienceId,
              data: {
                ...values,
                sort: -numberOfVariants // add the item to the last of the list
              }
            });
        }
      ],
      onSuccess: onDismiss
    });
  };

  handleDelete = () => {
    const { experienceId, executeActions, variant, onDismiss } = this.props;

    if (window.confirm('Delete option group?')) {
      this.toggleDeleting(true);

      executeActions({
        actions: [() => this.props.deleteVariant({ experienceId, variantId: variant.id })],
        onSuccess: onDismiss
      });
    }
  };

  render() {
    return (
      <Modal
        title={`${this.props.mode} Option`}
        hideFooter
        onDismiss={this.props.onDismiss}
        visible
      >
        {
          (!this.isEditMode() || this.props.variant) ?
            <VariantForm
              handleSubmit={this.handleSubmit}
              handleDelete={this.handleDelete}
              initialValues={this.buildInitialValues()}
              isEditing={this.isEditMode()}
              isDeleting={this.state.isDeleting}
            /> :
            <div>Loading..</div>
        }
      </Modal>
    );
  }
}

VariantModal.propTypes = {
  onDismiss: PropTypes.func,
  mode: PropTypes.oneOf([configApp.editMode.EDIT, configApp.editMode.ADD]),
  experienceId: PropTypes.number.isRequired
};

VariantModal.defaultProps = {
  onDismiss: () => {},
  mode: configApp.editMode.ADD
};

const mapStateToProps = (state, ownProps) => ({
  numberOfVariants: getVariantIds(state).length, // used to dictate the sort weight of new item
  variant: getVariant(state, ownProps.variantId), // used to dictate the sort weight of new item
});

const mapDispatchToProps = {
  fetchVariantDetail,
  createVariant,
  updateVariant,
  deleteVariant
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withOperation(VariantModal));
