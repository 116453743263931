import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';

// Components
import { Listings, Modal } from '../../../shared/App/components';
import Breadcrumb from '../../../shared/App/components/Breadcrumb';

// Utils
import { emailColumns } from '../emailUtils';
import { parseQueryString } from '../../../utils/urlQuery';
import { withOperation } from '../../../utils/OperationalComponent';
import { withQuery } from '../../../utils/QueryBasedComponent';

// Actions
import { fetchEmails, deleteEmail } from '../emailActions';

// Seletector
import { getEmails, getFetchStatus } from '../emailReducers';

// Constants
import configService from '../../../config/configService';

class EmailListPage extends Component {
  state = {
    showedDeleteModalConfirm: false,
    deletedId: null,
  };

  // actions in Listing
  actions = [
    {
      title: 'Edit',
      callback: ({ id }) => {
        this.props.history.push(`/emails/${id}`);
      },
      render: () => <i className="fa fa-edit" />,
      type: 'primary',
    },
    {
      title: 'Delete',
      callback: ({ id }) => {
        // Show confirm popup
        this.setState({ showedDeleteModalConfirm: true, deletedId: id });
      },
      render: () => <i className="fa fa-remove" />,
      type: 'danger',
    },
  ];

  componentDidMount() {
    const {
      setQueryCallback,
      email,
      location: { search },
    } = this.props;
    setQueryCallback(this.handlefetchEmails);
    if (!email || email.length === 0) {
      this.handlefetchEmails(parseQueryString(search));
    }
  }

  handlefetchEmails = params => {
    const { fetchEmailsAction } = this.props;
    const { page = 1, ordering = '-modified' } = params || {};
    if (this.cancel) this.cancel();
    this.cancel = fetchEmailsAction({ params: { page, ordering } }).cancel;
  };

  handlePageChange = page => {
    this.props.pushParams({ page });
  };

  handleDeleteEmail = () => {
    const { deletedId } = this.state;
    this.props.deleteEmailAction({ id: deletedId }).then(res => {
      this.setState({ showedDeleteModalConfirm: false }, () => {
        this.handlefetchEmails(1);
      });
    });
  };

  render() {
    // Data
    const { email, fetchStatus, queryParams, fields = {} } = this.props;
    const { count, isFetching } = fetchStatus || {
      count: 0,
      isFetching: false,
    };
    const { page } = queryParams || { page: 0 };
    const { showedDeleteModalConfirm } = this.state;

    return (
      <div>
        {showedDeleteModalConfirm && (
          <Modal
            title="Are you sure to delete this Email?"
            confirmText="Yes, sure"
            dismissText="Cancel"
            onConfirm={() => {
              this.handleDeleteEmail();
            }}
            onDismiss={() => {
              this.setState({ showedDeleteModalConfirm: false });
            }}
            visible={showedDeleteModalConfirm}
          >
            <p>This would delete the email show on home page.</p>
          </Modal>
        )}

        <Helmet>
          <title>All Email Templates - Inspitrip HQ</title>
        </Helmet>
        <section className="content-header">
          <div className="flex flex-row" style={{ marginTop: '-15px', marginBottom: '15px' }}>
            <Breadcrumb
              history={[
                { title: 'InspitripHQ', url: '/' },
                { title: 'Email', url: '' },
              ]}
            />
          </div>
          <div className="flex flex-row">
            <div className="flex flex-col gap-y-7">
              <h1 style={{ fontSize: '24px', marginTop: 0 }}>Email</h1>
            </div>
            <div className="flex flex-col gap-y-2	text-right" style={{ marginLeft: 'auto' }}>
              <Link to="/emails/new" className="btn btn-primary">
                <i className="fa fa-plus" />
                {' Add'}
              </Link>
            </div>
          </div>
        </section>
        <div className="content body">
          <section style={{ marginTop: '15px' }}>
            <Listings
              listingsTitle="Experience List"
              data={email}
              columns={emailColumns()}
              actions={this.actions}
              onSortHeader={this.handleSort}
              page={parseInt(page || 1, 10)}
              dataCount={count || 0}
              itemsPerPage={configService.itemsCountPerPage}
              onPageChange={this.handlePageChange}
              pageRange={3}
              isFetching={isFetching}
              noHeader
            />
          </section>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  fetchStatus: getFetchStatus(state),
  email: getEmails(state),
});

export default connect(mapStateToProps, {
  fetchEmailsAction: fetchEmails,
  deleteEmailAction: deleteEmail,
})(withOperation(withQuery(EmailListPage)));
