import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';

// Component
import CategoryForm from '../components/CategoryForm';
import Breadcrumb from '../../../shared/App/components/Breadcrumb';
import FormStatus from '../../../shared/App/components/FormStatus';

// Actions
import {
  updateCategory,
  getCategory,
  resetCategories,
} from '../categoryActions';
import { getCategoryDetail } from '../categoryReducers';

// Utils
import { withOperation } from '../../../utils/OperationalComponent';
import { withQuery } from '../../../utils/QueryBasedComponent';
import { buildFormData, transformCategoryDetail } from '../categoryUtils';

class CategoryEditPage extends Component {
  componentDidMount() {
    const { executeActions, match, getCategoryAction } = this.props;
    executeActions({
      actions: [() => getCategoryAction({ id: match.params.cuid })],
    });
  }

  handleSubmit = values => {
    const { executeActions, match, updateCategoryAction } = this.props;

    // transform data
    const data = transformCategoryDetail(values);

    executeActions({
      actions: [() => updateCategoryAction({ id: match.params.cuid, data })],
      onSuccess: () => {
        this.props.resetCategoriesAction();
      },
      successMsg: 'Update successfully',
    });
  };

  render() {
    const { categoryObj, error, success } = this.props;

    if (!categoryObj) return <section className="content">Loading..</section>;

    const initialValues = buildFormData(categoryObj);

    return (
      <Fragment>
        <Helmet>
          <title>Edit Category #{`${categoryObj.id}`} - Inspitrip HQ</title>
        </Helmet>
        <section className="content-header">
          <div className="flex flex-row" style={{ marginTop: '-15px', marginBottom: '15px' }}>
            <Breadcrumb
              history={[
                { title: 'InspitripHQ', url: '/' },
                { title: 'Categories', url: '/categories' },
                { title: 'Category Detail', url: '' },
              ]}
            />
          </div>
          <div className="nav-tabs-custom">
            <FormStatus formState={{ error, success }} />
          </div>
          <div className="flex flex-row">
            <div className="">
              <h1 style={{ fontSize: '24px', marginTop: 0 }}>
                Category #{initialValues.id} - {initialValues.name}
              </h1>
            </div>
          </div>
        </section>
        <div className="content body">
          <div className="">
            <CategoryForm
              handleSubmit={this.handleSubmit}
              initialValues={initialValues}
              editMode
            />
          </div>
        </div>
      </Fragment>
    );
  }
}

// Retrieve data from store as props
const mapStateToProps = (state, props) => {
  return {
    categoryObj: getCategoryDetail(state, props.match.params.cuid),
  };
};

export default connect(mapStateToProps, {
  getCategoryAction: getCategory,
  updateCategoryAction: updateCategory,
  resetCategoriesAction: resetCategories,
})(withOperation(withQuery(CategoryEditPage)));
