import React, { Component } from 'react';

import { ArrayObjectField, InputField } from '../../../shared/App/components';
import FroalaField from '../../../shared/App/components/FroalaField';

class FactsContainer extends Component {
  render() {
    const { values } = this.props;

    return (
      <div>
        <label>Facts</label>
        <ArrayObjectField
          name="facts"
          arrayData={values.facts}
          renderObject={(item, index) => (
            <div className="box" key={index}>
              <div className="box-body">
                <InputField sub="*" name={`facts.${index}.title`} label="Title" placeholder="" />
                <FroalaField name={`facts.${index}.text`} label="Text" placeholder="" />
                <FroalaField name={`facts.${index}.description`} label="Description" placeholder="" />
                <InputField name={`facts.${index}.icon`} label="Icon" placeholder="" />
              </div>
            </div>
          )}
        />
      </div>
    );
  }
}

export default FactsContainer;
