import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';

// Component
import PaymentEditForm from '../components/PaymentEditForm';
import FormStatus from '../../../shared/App/components/FormStatus';
import Breadcrumb from '../../../shared/App/components/Breadcrumb';

// Actions
import { updatePayment, getPayment, resetPayment } from '../paymentActions';
import { getPaymentDetail, getFetchStatus } from '../paymentReducers';

// Utils

import { withOperation } from '../../../utils/OperationalComponent';
import { buildFormData, transformPaymentDetail } from '../paymentUtils';
import { withQuery } from '../../../utils/QueryBasedComponent';

class PaymentEditPage extends Component {
  componentDidMount() {
    const { executeActions, match, getPaymentAction } = this.props;
    executeActions({
      actions: [() => getPaymentAction({ id: match.params.cuid })],
    });
  }

  handleSubmit = values => {
    const { executeActions, match, updatePaymentAction } = this.props;

    // transform data
    const data = transformPaymentDetail(values);

    executeActions({
      actions: [() => updatePaymentAction({ id: match.params.cuid, data })],
      onSuccess: () => {
        this.props.resetPaymentAction();
      },
      successMsg: 'Update successfully',
      failureMsg: 'Update fail',
    });
  };

  render() {
    const { paymentMethod, match, isFetching, error, success } = this.props;
    const initialValues = buildFormData(paymentMethod);

    const errorData = error ? error.data || 'Something went wrong.' : '';
    return (
      <Fragment>
        <Helmet>
          <title>
            Edit Payment Method #{`${match.params.cuid}`} - Inspitrip HQ
          </title>
        </Helmet>
        <section className="content-header">
          <div className="flex flex-row" style={{ marginTop: '-15px', marginBottom: '15px' }}>
            <Breadcrumb
              history={[
                { title: 'InspitripHQ', url: '/' },
                { title: 'Payments Methods', url: '/payments/methods' },
                { title: 'Payment Detail', url: '' },
              ]}
            />
          </div>

          <div className="nav-tabs-custom">
            <FormStatus formState={{ error: errorData, success }} />
          </div>
          <div className="flex flex-row">
            <div className="flex flex-col gap-y-7">
              <h1 style={{ fontSize: '24px', marginTop: 0 }}>Payment</h1>
            </div>
          </div>
        </section>

        <div className="content body">
          <div className="">
            {isFetching ? (
              <div className="overlay">
                <i className="fa fa-refresh fa-spin" />
                Loading...
              </div>
            ) : (
              <PaymentEditForm
                handleSubmit={this.handleSubmit.bind(this)}
                initialValues={initialValues}
                editMode
              />
            )}
          </div>
        </div>
      </Fragment>
    );
  }
}

// Retrieve data from store as props
const mapStateToProps = (state, props) => {
  return {
    isFetching: getFetchStatus(state),
    paymentMethod: getPaymentDetail(state, props.match.params.cuid),
  };
};

export default connect(mapStateToProps, {
  getPaymentAction: getPayment,
  updatePaymentAction: updatePayment,
  resetPaymentAction: resetPayment,
})(withOperation(withQuery(PaymentEditPage)));
