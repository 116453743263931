/**
 *
 *
 * @summary couponReducer
 * @author cuonghx
 *
 * Created at     : 2019-03-28 15:04:02
 * Last modified  : 2019-03-28 15:58:45
 */

import { combineReducers } from 'redux';

// Actions
import { FETCH_COUPONS, RESET_COUPONS, FETCH_COUPON, UPDATE_COUPON, DELETE_COUPON, CREATE_COUPON } from './couponActions';

// Constants
import { FULFILLED, PENDING, REJECTED } from '../../utils/axiosAPI';
import { appendByIds } from '../../utils/reducer';

const fetchStatusInitialState = {
  count: 0,
  next: null,
  previous: null,
  isFetching: false,
  error: null
};

// Seperate reuse for all reducer
export const fetchStatus = (state = fetchStatusInitialState, action) => {
  switch (action.type) {
    case FETCH_COUPONS + FULFILLED: {
      const { results, ...rest } = action.data;
      return { ...state, isFetching: false, ...rest };
    }
    case FETCH_COUPONS + REJECTED: {
      return { ...state, isFetching: false, error: action.data };
    }
    case FETCH_COUPONS + PENDING: {
      return { ...state, isFetching: true, error: null };
    }
    case RESET_COUPONS: {
      return fetchStatusInitialState;
    }
    default: {
      return state;
    }
  }
};

export const byId = (state = {}, action) => {
  switch (action.type) {
    case FETCH_COUPONS + FULFILLED: {
      return appendByIds(state, action.data.results, 'id');
    }
    case FETCH_COUPON + FULFILLED: {
      const coupon = state[action.data.id];
      return {
        ...state,
        [action.data.id]: { ...coupon, ...action.data }
      };
    }
    case UPDATE_COUPON + FULFILLED: {
      const coupon = state[action.data.id];
      return {
        ...state,
        [action.data.id]: { ...coupon, ...action.data }
      };
    }
    case CREATE_COUPON + FULFILLED: {
      return {
        ...state,
        [action.data.id]: action.data
      };
    }

    case RESET_COUPONS: {
      return {};
    }

    default:
      return state;
  }
};

export const visibleIds = (state = [], action) => {
  switch (action.type) {
    case FETCH_COUPONS + FULFILLED: {
      return action.data.results.map(coupon => coupon.id);
    }
    // TODO: remove item with id param
    // case DELETE_COUPON + FULFILLED: {
    //   return action.data.results.map(coupon => {
    //     if (action.data.id !== coupon.id) {
    //       return coupon.id;
    //     }
    //   });
    // }

    case UPDATE_COUPON + FULFILLED: {
      return [...state];
    }

    case CREATE_COUPON + FULFILLED: {
      return [action.data.id, ...state];
    }
    case RESET_COUPONS: {
      return [];
    }
    default: {
      return state;
    }
  }
};

export default combineReducers({
  fetchStatus,
  visibleIds,
  byId
});

// Selector
export const getCoupons = state => state.coupon.visibleIds.map(id => state.coupon.byId[id]);
export const getCouponDetail = (state, id) => state.coupon.byId[id];
export const getFetchStatus = state => state.coupon.fetchStatus;
