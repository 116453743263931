import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Button } from '../../../shared/App/components';

// containers
import VariantList from './VariantList';
import VariantModal from '../forms/Variant/VariantModal';

// config
import configApp from '../../../config/configApp';

class ProductOptionContainer extends React.Component {
  state = {
    isAddingUnit: false
  };

  toggleAdding = isAddingUnit => this.setState({ isAddingUnit });

  render() {
    const { experienceId } = this.props;

    return (
      <Fragment>
        <VariantList experienceId={experienceId} />
        <Button onClick={() => this.toggleAdding(true)} displayType="primary">
          <i className="fa fa-plus"></i> Add Option
        </Button>
        {
          this.state.isAddingUnit && (
            <VariantModal
              mode={configApp.editMode.ADD}
              onDismiss={() => this.toggleAdding(false)}
              experienceId={experienceId}
            />
        )}
      </Fragment>
    );
  }
}

ProductOptionContainer.propTypes = {
  experienceId: PropTypes.number.isRequired
};

export default ProductOptionContainer;
