import React from 'react';
import { Formik } from 'formik';
import { InputField, Button } from '../../../../../shared/App/components';
import { displayCurrency } from '../../../../../utils/currency';

const NetPriceForm = ({
  handleSubmit,
  initialValues
}) => (
  <Formik
    onSubmit={handleSubmit}
    initialValues={initialValues}
    enableReinitialize
  >
    {({ isSubmitting, submitForm }) => (
      <div className="content">
        <div className="flex flex-row">
          <h4>Units:</h4>
          <div>
            {
              initialValues.options && initialValues.options.length ? initialValues.options.map((option, idx) => {
                return (<div key={option.id}>
                  {option.title}: {displayCurrency({ currency: initialValues.currency_code, amount: option.price })} x {option.quantity}
                  <InputField type="number" name={`options[${idx}].supplier_price`} label="Net price" />
                </div>);
              }) : 'NA'
            }
          </div>
        </div>

        <div className="flex flex-row">
          <h4>Extra services:</h4>
          <div>
            {
              initialValues.extra_services && initialValues.extra_services.length ? initialValues.extra_services.map((service, idx) => {
                return (<div key={service.id}>
                  {service.title}: {displayCurrency({ currency: initialValues.currency_code, amount: service.price })} x {service.quantity}
                  <InputField type="number" name={`extra_services[${idx}].supplier_price`} label="Net price" />
                </div>);
              }) : 'NA'
            }
          </div>
        </div>

        <div className="flex flex-row">
          <div className="col-sm-6"></div>
          <div className="col-sm-6 text-right">
            <Button
              onClick={() => submitForm()}
              displayType="primary"
              disabled={isSubmitting}
            >
              Save changes
            </Button>
          </div>
        </div>
      </div>
    )}
  </Formik>
);

export default NetPriceForm;
