import { combineReducers } from 'redux';
import {
  FETCH_BOOKING_ITEM_DETAIL, FETCH_BOOKING_ITEMS, RESET_BOOKING_ITEMS, UPDATE_BOOKING_ITEM,
  DELETE_TRIP_ITEM, CREATE_NEW_TRIP_ITEM
} from './bookingItemActions';
import { FULFILLED, PENDING, REJECTED } from '../../utils/axiosAPI';
import { appendByIds } from '../../utils/reducer';
import { transformBookingItemDetail } from './bookingItemUtils';

export const fetchStatusInitialState = {
  count: 0,
  next: null,
  previous: null,
  isFetching: false,
  error: null,
};

export const fetchStatus = (state = fetchStatusInitialState, action) => {
  switch (action.type) {
    case FETCH_BOOKING_ITEMS + FULFILLED: {
      const { results, ...rest } = action.data;
      return { ...state, isFetching: false, ...rest };
    }
    case FETCH_BOOKING_ITEMS + REJECTED: {
      return { ...state, isFetching: false, error: action.data };
    }
    case FETCH_BOOKING_ITEMS + PENDING: {
      return { ...state, isFetching: true, error: null };
    }
    case RESET_BOOKING_ITEMS: {
      return fetchStatusInitialState;
    }
    default: {
      return state;
    }
  }
};

export const byId = (state = {}, action) => {
  switch (action.type) {
    case FETCH_BOOKING_ITEMS + FULFILLED: {
      return appendByIds(state, action.data.results, 'id');
    }
    case FETCH_BOOKING_ITEM_DETAIL + FULFILLED:
    case UPDATE_BOOKING_ITEM + FULFILLED: {
      const bookingItem = state[action.data.id];
      return {
        ...state,
        [action.data.id]: { ...bookingItem, ...action.data },
      };
    }
    case CREATE_NEW_TRIP_ITEM + FULFILLED: {
      const bookingItem = state[action.data.booking_item_id];
      const newBookingItem = { ...bookingItem };
      newBookingItem.trip_id = action.data.trip;
      newBookingItem.state = action.data.state;

      return {
        ...state,
        [action.data.booking_item_id]: { ...bookingItem, ...newBookingItem },
      };
    }
    case DELETE_TRIP_ITEM: {
      const bookingItem = state[action.data.id];
      const newBookingItem = { ...bookingItem };
      newBookingItem.trip_id = null;
      newBookingItem.thread_id = null;

      return {
        ...state,
        [action.data.id]: { ...bookingItem, ...newBookingItem },
      };
    }
    default: {
      return state;
    }
  }
};

export const visibleIds = (state = [], action) => {
  switch (action.type) {
    case FETCH_BOOKING_ITEMS + FULFILLED: {
      /**
       * Always replace the visible booking
       */
      return action.data.results.map(booking => booking.id);
    }
    case RESET_BOOKING_ITEMS: {
      return [];
    }
    default: {
      return state;
    }
  }
};

export default combineReducers({
  fetchStatus,
  byId,
  visibleIds,
});

export const getBookingItems = (state) => state.bookingItems.visibleIds.map(id => state.bookingItems.byId[id]);
export const getFetchStatus = (state) => state.bookingItems.fetchStatus;
export const getBookingItemDetail = (state, id) => state.bookingItems.byId[id];
