import { combineReducers } from 'redux';

import {
  ADD_EXPERIENCE_FAQ,
  ADD_EXPERIENCE_STORY,
  DELETE_EXPERIENCE_FAQ,
  DELETE_EXPERIENCE_STORY,
  FETCH_EXPERIENCE_DETAIL,
  FETCH_EXPERIENCE_FAQS,
  FETCH_EXPERIENCE_FIELDS,
  FETCH_EXPERIENCE_STORIES,
  FETCH_EXPERIENCE_STORIES_OPTIONS,
  FETCH_EXPERIENCES,
  FETCH_FAQ_TEMPLATES,
  RESET_EXPERIENCES,
  UPDATE_EXPERIENCE,
  UPDATE_EXPERIENCE_FAQ,
  FETCH_DURATION_TYPES
} from './experienceActions';
import { FULFILLED, PENDING, REJECTED } from '../../utils/axiosAPI';
import { appendByIds } from '../../utils/reducer';
import itineraries from './reducers/itineraries';

// experience list and experience detail is together usage on fetchStatusInitialState
// so use don't use Link instead anchor
export const fetchStatusInitialState = {
  count: 0,
  next: null,
  previous: null,
  isFetching: false,
  error: null
};

export const fetchStatus = (state = fetchStatusInitialState, action) => {
  switch (action.type) {
    case FETCH_EXPERIENCES + FULFILLED: {
      const { results, ...rest } = action.data;
      return { ...state, isFetching: false, ...rest };
    }
    case FETCH_EXPERIENCES + REJECTED: {
      return { ...state, isFetching: false, error: action.data };
    }
    case FETCH_EXPERIENCES + PENDING: {
      return { ...state, isFetching: true, error: null };
    }
    case RESET_EXPERIENCES: {
      return fetchStatusInitialState;
    }
    default: {
      return state;
    }
  }
};

export const fetchDetailStatus = (state = { isFetching: false }, action) => {
  switch (action.type) {
    case FETCH_EXPERIENCE_DETAIL + FULFILLED: {
      return { ...state, isFetching: false };
    }
    case FETCH_EXPERIENCE_DETAIL + REJECTED: {
      return { ...state, isFetching: false, error: action.data };
    }
    case FETCH_EXPERIENCE_DETAIL + PENDING: {
      return { ...state, isFetching: true, error: null };
    }
    case FETCH_EXPERIENCE_DETAIL: {
      return state;
    }
    default: {
      return state;
    }
  }
};

export const submitStatus = (state = { isSubmitting: false }, action) => {
  switch (action.type) {
    case UPDATE_EXPERIENCE + FULFILLED: {
      return { ...state, isSubmitting: false };
    }
    case UPDATE_EXPERIENCE + REJECTED: {
      return { ...state, isSubmitting: false };
    }
    case UPDATE_EXPERIENCE + PENDING: {
      return { ...state, isSubmitting: true, error: null };
    }
    default: {
      return state;
    }
  }
};

export const byId = (state = {}, action) => {
  switch (action.type) {
    case FETCH_EXPERIENCES + FULFILLED: {
      return appendByIds(state, action.data.results, 'id');
    }
    case FETCH_EXPERIENCE_DETAIL + FULFILLED:
    case UPDATE_EXPERIENCE + FULFILLED: {
      return appendByIds(state, [action.data], 'id');
    }
    default: {
      return state;
    }
  }
};

export const experienceStories = (state = {
  options: { actions: { POST: {} } },
  results: []
}, action) => {
  switch (action.type) {
    case FETCH_EXPERIENCE_STORIES + FULFILLED: {
      return {
        ...state, ...action.data
      };
    }
    case ADD_EXPERIENCE_STORY + FULFILLED: {
      const results = [...state.results, action.data];
      return {
        ...state, results, count: (state.count || 0) + 1
      };
    }
    case FETCH_EXPERIENCE_STORIES_OPTIONS + FULFILLED: {
      return {
        ...state, options: action.data
      };
    }
    case DELETE_EXPERIENCE_STORY + FULFILLED: {
      const results = state.results.filter((story) => story.id !== action.meta.id);
      return {
        ...state, count: state.count - 1, results
      };
    }
    default:
      return state;
  }
};

export const experienceFAQ = (state = { isFetching: false }, action) => {
  switch (action.type) {
    case FETCH_EXPERIENCE_FAQS + PENDING: {
      return {
        ...state, isFetching: true
      };
    }
    case FETCH_EXPERIENCE_FAQS + FULFILLED:
      return {
        ...state, ...action.data, isFetching: false
      };
    case UPDATE_EXPERIENCE_FAQ + FULFILLED: {
      const { results } = state;
      const newResults = results ? results.map(faq => {
        if (faq.id === action.data.id) {
          return action.data;
        }
        return faq;
      }) : [action.data];
      return {
        ...state,
        results: newResults,
        isFetching: false
      };
    }
    case ADD_EXPERIENCE_FAQ + FULFILLED: {
      const { results = [] } = state;
      const newResults = [...results, action.data];
      return {
        ...state,
        count: (state.count || 0) + 1,
        results: newResults,
        isFetching: false
      };
    }

    case DELETE_EXPERIENCE_FAQ + FULFILLED: {
      const { meta } = action;
      const { results } = state;
      const newResults = results ? results.filter(faq => faq.id !== meta.faqId) : [];
      return {
        ...state,
        count: state.count - 1,
        results: newResults,
        isFetching: false
      };
    }
    default:
      return state;
  }
};

export const visibleIds = (state = [], action) => {
  switch (action.type) {
    case FETCH_EXPERIENCES + FULFILLED: {
      /**
       * Always replace the visible booking
       */
      return action.data.results.map(booking => booking.id);
    }
    case RESET_EXPERIENCES: {
      return [];
    }
    default: {
      return state;
    }
  }
};

export const initialExperienceFields = {
  isFetching: false,
  actions: {},
};

export const experienceFields = (state = initialExperienceFields, action) => {
  switch (action.type) {
    case FETCH_EXPERIENCE_FIELDS + FULFILLED:
      return { ...state, ...action.data };
    default:
      return state;
  }
};

export const durationTypes = (state = [], action) => {
  switch (action.type) {
    case `${FETCH_DURATION_TYPES}${FULFILLED}`: {
      return [...state, ...action.data];
    }
    default: {
      return state;
    }
  }
};

export const faqTemplates = (state = { results: [] }, action) => {
  switch (action.type) {
    case FETCH_FAQ_TEMPLATES + FULFILLED:
      return { ...state, ...action.data };
    default:
      return state;
  }
};

export default combineReducers({
  fetchStatus,
  fetchDetailStatus,
  submitStatus,
  byId,
  visibleIds,
  experienceStories,
  experienceFAQ,
  experienceFields,
  durationTypes,
  faqTemplates,
  itineraries,
});

export const getExperiences = state =>
  state.experience.visibleIds.map(id => state.experience.byId[id]);
export const getFetchStatus = state => state.experience.fetchStatus;
export const getFetchDetailStatus = state => state.experience.fetchDetailStatus;
export const getSubmitStatus = state => state.experience.submitStatus;
export const getExperienceDetail = (state, id) => state.experience.byId[id];
export const getExperienceStories = (state) => state.experience.experienceStories;
export const getExperienceStoriesOptions = (state) => state.experience.experienceStories.options.actions.POST;
export const getExperienceFaqs = (state) => state.experience.experienceFAQ;
export const getExperienceFields = (state) => state.experience.experienceFields;
export const getDurationTypes = (state) => state.experience.durationTypes;
export const getFAQTemplates = (state) => state.experience.faqTemplates.results;
