import React from 'react';
import { Link } from 'react-router-dom';
import { Status } from '../../shared/App/components';
import { displayCurrency } from '../../utils/currency';
import { timeAgo } from '../../utils/time';

/**
 * transformPaymentDetail changes the field user and localguide of booking detail
 * to user_id and localguide_id to avoid collision with the user and local object
 * @param {*} booking - the booking detail object
 */
export const transformPaymentDetail = payment => {
  return payment;
};

export const buildFormData = payment => {
  return payment;
};

export const paymentStatusConfig = {
  waiting: { title: 'Init', color: Status.colors.GREY },
  input: { title: 'Init', color: Status.colors.GREY },
  preauth: { title: 'Pending', color: Status.colors.YELLOW },
  confirmed: { title: 'Success', color: Status.colors.GREEN },
  refunded: { title: 'Refunded', color: Status.colors.BLUE },
  rejected: { title: 'Rejected', color: Status.colors.RED },
  error: { title: 'Error', color: Status.colors.RED }
};

export const paymentColumns = () => {
  return [
    {
      id: 'id',
      title: 'ID',
      render: ({ id }) => {
        return <div>{id}</div>;
      }
    },
    {
      id: 'token',
      title: 'Token',
      render: ({ id, token }) => {
        return <Link to={`/payments/transactions/${id}`}>{token}</Link>;
      }
    },
    {
      id: 'transaction_id',
      title: 'Transaction Code',
      render: ({ transaction_id }) => {
        return <span>{transaction_id}</span>;
      }
    },
    {
      id: 'variant',
      title: 'Payment Method',
      render: ({ variant }) => {
        return <span>{variant}</span>;
      }
    },
    {
      id: 'total',
      title: 'Amount',
      render: ({ total, currency }) => <span>{displayCurrency({ amount: parseFloat(total), currency })}</span>
    },
    {
      id: 'status',
      title: 'Status',
      render: ({ status }) => <Status state={`${status}`} statuses={paymentStatusConfig} />
    },
    {
      id: 'created',
      title: 'Created at',
      render: ({ created }) => <span>{timeAgo(created)}</span>
    },
    {
      id: 'modified',
      title: 'Updated at',
      render: ({ modified }) => <span>{timeAgo(modified)}</span>
    }
  ];
};
