// Utils
import { getFilter, getSearch } from '../../utils/filter';

// Constants
import configApp from '../../config/configApp';

const stateSubOptions = [
  { value: configApp.pageState.INIT, label: 'Init' },
  { value: configApp.pageState.PENDING, label: 'Pending' },
  { value: configApp.pageState.PUBLISHED, label: 'Published' },
  { value: configApp.pageState.PRIVATE, label: 'Privated' },
];
export const keyOptions = [
  { value: 'state', label: 'Status', type: 'select', subOptions: stateSubOptions },
];

// Can override if you want
export const getFilterData = (queryParams, options = []) => {
  return getFilter(queryParams, [...options, ...keyOptions]);
};

export const getSearchData = queryParams => {
  return getSearch(queryParams);
};
