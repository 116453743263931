import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Listings } from '../../../shared/App/components';
import { Helmet } from 'react-helmet';
import configService from '../../../config/configService';
import { fetchProfiles, resetProfile } from '../profileActions';
import { getProfiles, getFetchStatus } from '../profileReducer';
import { Link } from 'react-router-dom';
import { parseQueryString } from '../../../utils/urlQuery';
import { profileColumns } from '../profileUtils';
import { withOperation } from '../../../utils/OperationalComponent';
import { withQuery } from '../../../utils/QueryBasedComponent';

const initialState = {
  showModalFilter: false,
};

class ProfileListPage extends Component {
  state = initialState;

  componentDidMount() {
    const {
      setQueryCallback,
      profiles,
      location: { search },
    } = this.props;
    setQueryCallback(this.handleFetchProfile);

    if (profiles.length === 0) {
      this.handleFetchProfile(parseQueryString(search));
    }
  }

  componentWillUnmount() {
    if (this.cancel) this.cancel();
    this.props.resetProfileAction();
  }

  handleFetchProfile = params => {
    const { fetchProfileAction } = this.props;
    const { page = 1 } = params || {};
    const limit = configService.itemsCountPerPage;
    const offset = (parseInt(page, 10) - 1) * limit;
    if (this.cancel) this.cancel();
    this.cancel = fetchProfileAction({ params: { limit, offset } }).cancel;
  };

  handlePageChange = page => {
    this.props.pushParams({ page });
  };

  handleFilterChange = values => {
    let params = {};
    values.filter.map(
      (item, index) => (params[item.key + item.type] = item.value)
    );
    params.search = this.props.queryParams.search;
    this.props.replaceParams(params);
    this.handleCloseModal();
  };

  handleSearchChange = values => {
    this.props.pushParams({ search: values.search });
  };

  handleOpenModalFilter = () => {
    this.setState({ showModalFilter: true });
  };

  handleCloseModal = () => {
    this.setState(initialState);
  };

  handleSort = () => {};

  render() {
    const {
      profiles,
      fetchStatus: { count, isFetching },
      queryParams: { page },
    } = this.props;

    return (
      <Fragment>
        <Helmet>
          <title>All Audience Profiles - Inspitrip HQ</title>
        </Helmet>
        <section className="content-header">
          <div className="flex flex-row" style={{ marginTop: '-15px', marginBottom: '15px' }}>
            <ol className="breadcrumb">
              <li>
                <Link to={'/'}>InspitripHQ</Link>
              </li>
              <li className="active">Audience Profiles</li>
            </ol>
          </div>
          <div className="flex flex-row">
            <div className="flex flex-col gap-y-7">
              <h1 style={{ fontSize: '24px', marginTop: 0 }}>
                Audience Profiles
              </h1>
            </div>
            <div className="flex flex-col gap-y-2	text-right" style={{ marginLeft: 'auto' }}>
              <Link to="/audience/profiles/new" className="btn btn-primary">
                <i className="fa fa-plus"></i>
                &nbsp;Add Profile
              </Link>
            </div>
          </div>
        </section>
        <div className="content body">
          <Listings
            listingsTitle="Profile List"
            data={profiles}
            columns={profileColumns}
            actions={this.actions}
            onSortHeader={this.handleSort}
            page={parseInt(page || 1, 10)}
            dataCount={count}
            itemsPerPage={configService.itemsCountPerPage}
            onPageChange={this.handlePageChange}
            pageRange={3}
            isFetching={isFetching}
            noHeader
          />
        </div>
      </Fragment>
    );
  }
}

ProfileListPage.propTypes = {
  /**
   * ConnectedProps
   */
  fetchStatus: PropTypes.object,
  profiles: PropTypes.array,
  /**
   * Actions
   */
  fetchProfileAction: PropTypes.func.isRequired,
  resetProfileAction: PropTypes.func.isRequired,
  /**
   * QueryComponentProps
   */
  setQueryCallback: PropTypes.func.isRequired,
  pushParams: PropTypes.func.isRequired,
  queryParams: PropTypes.object,
};

ProfileListPage.need = [
  ({ state, query }) => {
    const { page = 1 } = query || {};
    const limit = configService.itemsCountPerPage;
    const offset = (parseInt(page, 10) - 1) * limit;
    return fetchProfiles({ params: { limit, offset } });
  },
];

const mapStateToProps = state => ({
  fetchStatus: getFetchStatus(state),
  profiles: getProfiles(state),
});

export default connect(mapStateToProps, {
  fetchProfileAction: fetchProfiles,
  resetProfileAction: resetProfile,
})(withOperation(withQuery(ProfileListPage)));
