import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Modal } from '../../../../shared/App/components';

// containers
import UnitForm from './UnitForm';

// actions
import {
  fetchUnit,
  updateUnit,
  createUnit,
  deleteUnit,
} from '../../reducers/unit/actions';

import { getUnit } from '../../reducers/unit';

// utils
import { withOperation } from '../../../../utils/OperationalComponent';

// config
import configApp from '../../../../config/configApp';

class UnitModal extends React.Component {

  componentDidMount() {
    const { executeActions, experienceId, variantId, unitId } = this.props;

    if (this.isEditMode()) {
      executeActions({
        actions: [
          () => this.props.fetchUnit({ experienceId, variantId, unitId })
        ]
      });
    }
  }

  isEditMode = () => this.props.mode === configApp.editMode.EDIT;

  buildInitialValues = () => {
    if (!this.isEditMode()) {
      return {
        experience: this.props.experienceId,
        variant: this.props.variantId
      };
    }

    const { is_primary = false } = this.props.unit;
    return { is_primary, ...this.props.unit };
  };

  handleSubmit = values => {
    const {
      experienceId,
      executeActions,
      variantId,
      unit,
      onDismiss
    } = this.props;

    executeActions({
      actions: [
        () => {
          if (this.isEditMode()) {
            return this.props.updateUnit({
              experienceId,
              variantId,
              unitId: unit.id,
              data: values
            });
          }
          return this.props.createUnit({
            experienceId,
            variantId,
            data: values
          });
        }
      ],
      onSuccess: onDismiss
    });
  };

  handleDelete = () => {
    if (window.confirm('Are you sure?')) {
      const { experienceId, executeActions, variantId, unit, onDismiss } = this.props;
      executeActions({
        actions: [
          () =>
            this.props.deleteUnit({ experienceId, variantId, unitId: unit.id })
        ],
        onSuccess: onDismiss
      });
    }
  };

  render() {

    return (
      <Modal
        title={`${this.props.mode} Unit`}
        hideFooter
        onDismiss={this.props.onDismiss}
        visible
      >
      {
        !this.isEditMode() || this.props.unit ?
          <UnitForm
            handleSubmit={this.handleSubmit}
            handleDelete={this.handleDelete}
            initialValues={this.buildInitialValues()}
            isEditing={this.isEditMode()}
          /> :
          <div>loading..</div>
      }
      </Modal>
    );
  }
}

UnitModal.propTypes = {
  onDismiss: PropTypes.func,
  mode: PropTypes.oneOf([configApp.editMode.EDIT, configApp.editMode.ADD]),
  unit: PropTypes.object,
  experienceId: PropTypes.number.isRequired,
  variantId: PropTypes.number.isRequired,
};

UnitModal.defaultProps = {
  mode: configApp.editMode.ADD
};

const mapStateToProps = (state, ownProps) => ({
  unit: getUnit(state, ownProps.unitId)
});

const mapDispatchToProps = {
  fetchUnit,
  createUnit,
  updateUnit,
  deleteUnit
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withOperation(UnitModal));
