import React, { Component } from 'react';
import PropTypes from 'prop-types';
import * as dataTypes from '../../../utils/dataType';


class FormStatus extends Component {
  constructor(props) {
    super(props);
    const { formState: { error, success } } = this.props;
    this.state = {
      error,
      success
    };
  }

  renderErrors() {
    const { formState: { error, status } } = this.props;
    if (!error) return null;

    if (status === 500) {
      return (
        <div className="alert alert-danger">
          <div>Oops! Unexpected error happen!</div>
        </div>
      );
    }

    if (typeof error === 'string') {
      return (
        <div className="alert alert-danger">
          <div dangerouslySetInnerHTML={{ __html: error }} />
        </div>
      );
    }

    return Object.keys(error).map(key => {
      // error message with 2 levels nested
      if (Array.isArray(error[key]) && dataTypes.isObject(error[key][0])) {
        return (
          <div className="alert alert-danger">
            <div key={`l1-${key}`}>
              {error[key].map((item, index) => {
                return (
                  <div key={`l2-${index}`}>
                    {
                      Object.keys(item).map(ikey => {
                        return (
                          <div key={`l3-${ikey}`}>{item[ikey]}</div>
                        );
                      })
                    }
                  </div>
                );
              })}
            </div>
          </div>
        );
      }
      // default with one level nested
      return (
        <div className="alert alert-danger">
          <div key={key}>{`${error[key]}`}</div>
        </div>
      );
    });
  }

  renderSuccess() {
    const { formState: { success } } = this.props;
    if (success) {
      return (
        <div className="alert alert-success">
          {success}
        </div>
      );
    }
    return null;
  }

  closeForm() {
    this.setState({ error: null, success: null });
  }

  componentWillReceiveProps(nextProps) {
    const { formState: { error, success } } = nextProps;
    if (!this.state.error && error) {
      this.setState({ error });
    }

    if (!this.state.success && success) {
      this.setState({ success });
    }
  }

  render() {
    const { success, error } = this.state;
    if (!error && !success) return null;

    const closeStyle = {
      position: 'absolute',
      right: '20px',
      cursor: 'pointer'
    };

    return (
      <div className="form-input__status">
        <span style={closeStyle} onClick={this.closeForm.bind(this)}>x</span>
        {this.renderErrors()}
        {this.renderSuccess()}
      </div>
    );
  }
}


FormStatus.propTypes = {
  formState: PropTypes.object.isRequired
};

export default FormStatus;
