import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { Button, Pagination } from '../../../shared/App/components';
import configService from '../../../config/configService';
import { calculateActivePage } from '../../../utils/router';

class ExperienceFAQs extends Component {
  render() {
    const { faqs, handleOpenModalEditFaq, handleOpenModalFaq } = this.props;
    const { results = [] } = faqs;
    return (
      <Fragment>
        {results &&
          results.map((faq, index) => (
            <div className="box box-primary" key={index}>
              <div
                className="flex flex-row"
                style={{ margin: 0, padding: '10px 0' }}
              >
                <div className="col-xs-12">
                  <div>
                    <strong>{faq.question}</strong>
                  </div>
                  <div dangerouslySetInnerHTML={{ __html: faq.answer }} />
                </div>
                <div
                  className="col-xs-12 text-right"
                  style={{ marginTop: '10px' }}
                >
                  <Button
                    size="sm"
                    onClick={() => {
                      handleOpenModalEditFaq(faq);
                    }}
                  >
                    <i className="fa fa-pencil"></i> Edit
                  </Button>
                </div>
              </div>
            </div>
          ))}
        {faqs.count > configService.smallItemsCountPerPage ? (
          <div className="pull-right">
            <div className="text-right">
              <Pagination
                activePage={calculateActivePage({ previous: faqs.previous })}
                totalItemsCount={faqs.count}
                itemsCountPerPage={configService.smallItemsCountPerPage}
                onChange={this.props.onPageChange}
                pageRangeDisplayed={3}
                isFetching={faqs.isFetching}
                innerClass="pagination no-margin pull-right"
                itemClass="pagination_button"
              />
            </div>
          </div>
        ) : null}
        <Button displayType="primary" onClick={handleOpenModalFaq}>
          <i className="fa fa-plus"></i> Add Question
        </Button>
      </Fragment>
    );
  }
}

ExperienceFAQs.propTypes = {
  faqs: PropTypes.object,
  onPageChange: PropTypes.func,
  handleOpenModalFaq: PropTypes.func,
  handleOpenModalEditFaq: PropTypes.func,
};

export default ExperienceFAQs;
