import { Avatar } from 'antd';
import PropTypes from 'prop-types';
import { Fragment } from 'react';
import { Link } from 'react-router-dom';

import { Box } from '../../../../../shared/App/components';
import { displayCurrency } from '../../../../../utils/currency';
import { formatDateTime } from '../../../../../utils/time';

const BookingInfo = ({
  isLoading,
  booking: {
    amount,
    amount_paid,
    amount_remained,
    discount,
    discount_code,
    currency_code: currency,
    supplier_amount,
    user = {},
    payment_method,
    payment_date,
    created,
    modified,
  },
}) => (
  <div className='box box-default'>
    <div className='box-body pl-0'>
    <div class=""><label>General Booking Info</label></div>
    <ul className="list-group">
  {user && user.first_name && (
    <li className="list-group-item">
      <div className="flex flex-row">
        <span className="col-xs-5">Traveler</span>
          <div className="col-xs-7 text-right">
            <Link to={`/users/${user.id}`}>
              {`${user.first_name} ${user.last_name}`}
            </Link>
          </div>
      </div>
    </li>
  )}
  <li className="list-group-item">
    <div className="flex flex-row">
      <span className="col-xs-5">Price</span>
      <span className="col-xs-7 text-right">
        {displayCurrency({ amount: parseFloat(amount), currency })}
      </span>
    </div>
  </li>
  {discount_code && (
    <Fragment>
      <li className="list-group-item">
        <div className="flex flex-row">
          <span className="col-xs-5">Discount</span>
          <span className="col-xs-7 text-right">
            {displayCurrency({ amount: parseFloat(discount), currency })}
          </span>
        </div>
      </li>
      <li className="list-group-item">
        <div className="flex flex-row">
          <span className="col-xs-5">Applied Coupon</span>
          <span className="col-xs-7 text-right">{discount_code}</span>
        </div>
      </li>
    </Fragment>
  )}
  <li className="list-group-item">
    <div className="flex flex-row">
      <span className="col-xs-5">Net price</span>
      <span className="col-xs-7 text-right">
        {supplier_amount
          ? displayCurrency({
              amount: parseFloat(supplier_amount),
              currency,
            })
          : '-'}
      </span>
    </div>
  </li>
  <li className="list-group-item">
    <div className="flex flex-row">
      <span className="col-xs-5">Paid</span>
      <span className="col-xs-7 text-right">
        {displayCurrency({ amount: parseFloat(amount_paid), currency })}
      </span>
    </div>
  </li>
  {amount_remained ? (
    <li className="list-group-item">
      <div className="flex flex-row">
        <span className="col-xs-5">Remaining</span>
        <span className="col-xs-7 text-right">
          {displayCurrency({
            amount: parseFloat(amount_remained),
            currency,
          })}
        </span>
      </div>
    </li>
  ) : (
    ''
  )}
  <li className="list-group-item">
    <div className="flex flex-row">
      <span className="col-xs-5">Coupon</span>
      <span className="col-xs-7 text-right">
        {discount_code ? discount_code : '-'}
      </span>
    </div>
  </li>
  {discount ? (
    <li className="list-group-item">
      <div className="flex flex-row">
        <span className="col-xs-5">Discount amount</span>
        <span className="col-xs-7 text-right">
          {displayCurrency({ amount: parseFloat(discount), currency })}
        </span>
      </div>
    </li>
  ) : (
    ''
  )}
  <li className="list-group-item">
    <div className="flex flex-row">
      <span className="col-xs-5">Purchased date</span>
      <span className="col-xs-7 text-right">
        {formatDateTime(payment_date)}
      </span>
    </div>
  </li>
  <li className="list-group-item">
    <div className="flex flex-row">
      <span className="col-xs-5">Payment method</span>
      <span className="col-xs-7 text-right">{payment_method}</span>
    </div>
  </li>
  <li className="list-group-item">
    <div className="flex flex-row">
      <span className="col-xs-5">Booking created</span>
      <span className="col-xs-7 text-right">
        {formatDateTime(created)}
      </span>
    </div>
  </li>
  <li className="list-group-item">
    <div className="flex flex-row">
      <span className="col-xs-5">Last updated</span>
      <span className="col-xs-7 text-right">
        {formatDateTime(modified)}
      </span>
    </div>
  </li>
</ul>
    </div>
    
  </div>
);

BookingInfo.propTypes = {
  amount: PropTypes.number,
  amount_paid: PropTypes.number,
  user: PropTypes.object,
  created: PropTypes.string,
  modified: PropTypes.string,
};

export default BookingInfo;
