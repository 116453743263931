import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';

import { Button } from '../../../shared/App/components';
import ConfirmDeleteModal from './ConfirmDeleteModal';

class ExperienceMedia extends Component {
  state = {
    showConfirmDelete: false,
    data: null,
    deleteError: null,
  };

  onConfirm() {
    const { data } = this.state;
    const { handleDeletePhoto } = this.props;
    handleDeletePhoto(data.id)
      .then(() => {
        this.handleCloseModal();
      })
      .catch(error => {
        this.setState({
          deleteError: error.data || 'Please contact your administrator!',
        });
      });
  }

  handleOpenModal(data) {
    this.setState({ showConfirmDelete: true, data, deleteError: null });
  }

  handleCloseModal() {
    this.setState({ showConfirmDelete: false, data: null, deleteError: null });
  }

  render() {
    const {
      handleOpenModalPhoto,
      handleOpenAddModalPhoto,
      stories,
    } = this.props;
    return (
      <Fragment>
        <div className="flex flex-row">
          {stories.results &&
            stories.results.map((data, index) => (
              <div
                className="col-xs-4 col-sm-3"
                style={{ marginBottom: '30px' }}
                key={index}
              >
                <img
                  className="img-responsive"
                  role="presentation"
                  src={data.attachment}
                  style={{ marginBottom: '5px' }}
                  onClick={() => {
                    handleOpenModalPhoto(data.attachment);
                  }}
                />
                <Button
                  displayType="primary"
                  size="sm"
                  onClick={() => {
                    handleOpenModalPhoto(data.attachment);
                  }}
                >
                  <i className="fa fa-search-plus"></i>
                </Button>
                &nbsp;&nbsp;
                <Button
                  displayType="danger"
                  size="sm"
                  onClick={() => {
                    this.handleOpenModal(data);
                  }}
                >
                  <i className="fa fa-trash-o"></i>
                </Button>
              </div>
            ))}
          <div className="col-xs-12">
            <Button
              displayType="primary"
              onClick={() => {
                handleOpenAddModalPhoto();
              }}
            >
              <i className="fa fa-plus"></i> Upload
            </Button>
            <ConfirmDeleteModal
              title="Delete photo"
              confirmText="Delete"
              dismissText="Cancel"
              handleCloseModal={this.handleCloseModal.bind(this)}
              showConfirmDelete={this.state.showConfirmDelete}
              onConfirm={this.onConfirm.bind(this)}
              deleteError={this.state.deleteError}
            />
          </div>
        </div>
      </Fragment>
    );
  }
}

ExperienceMedia.propTypes = {
  handleOpenModalPhoto: PropTypes.func,
  stories: PropTypes.object,
};

export default ExperienceMedia;
