import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import Alert from '../../../shared/App/components/Alert';
import { fetchProfileDetail, updateProfile } from '../profileActions';
import ProfileEditForm from '../forms/ProfileEditForm';
import { getProfileDetail } from '../profileReducer';
import { withOperation } from '../../../utils/OperationalComponent';
import { withQuery } from '../../../utils/QueryBasedComponent';

const initialState = {
  showModalEditProfile: false,
  showModalCancelProfile: false,
  showModalConfirmProfile: false,
  showModalEditProfileSource: false,
};

class ProfileEditPage extends Component {
  state = initialState;

  componentDidMount() {
    const { executeActions, fetchProfileDetailAction, match } = this.props;

    executeActions({
      actions: [() => fetchProfileDetailAction({ id: match.params.pid })],
    });
  }

  onSubmit = values => {
    const { updateProfileAction, executeActions, match } = this.props;
    const keys = Object.keys(values);
    const jsonFields = ['social_links', 'highlights', 'hot_keywords'];
    const fileFields = ['desktop_cover', 'mobile_cover'];
    let data = new FormData();
    keys.forEach(key => {
      if (jsonFields.indexOf(key) >= 0 && values[key] instanceof Object) {
        data.append(key, JSON.stringify(values[key]));
      } else if (fileFields.indexOf(key) < 0 || values[key] instanceof File) {
        data.append(key, values[key]);
      }
    });

    executeActions({
      actions: [() => updateProfileAction(match.params.pid, data)],
      successMsg: 'Update successfully',
    });
  };

  handleCloseModal = () => {
    this.setState(initialState);
  };

  handleOpenModalEditProfile() {
    this.setState({ showModalEditProfile: true });
  }

  handleOpenModalCancelProfile() {
    this.setState({ showModalCancelProfile: true });
  }

  handleOpenModalConfirmProfile() {
    this.setState({ showModalConfirmProfile: true });
  }

  handleOpenModalEditProfileSource() {
    this.setState({ showModalEditProfileSource: true });
  }

  renderAlert() {
    const { success, error } = this.props;
    let msg = '';
    let title = 'Success';
    let type = 'success';
    if (error) {
      if (error instanceof Object && error.data) {
        msg = JSON.stringify(error.data);
      } else {
        msg = error.toString();
      }
      title = 'Error';
      type = 'danger';
    } else if (success) {
      msg = success;
    }

    if (msg !== '') {
      return <Alert title={title} type={type} content={msg} />;
    }

    return null;
  }

  render() {
    const { isFetching, match, profile } = this.props;
    if (isFetching || !profile) {
      return <div>Loading..</div>;
    }

    return (
      <Fragment>
        <Helmet>
          <title>
            Edit Audience Profile #{match.params.pid} - Inspitrip HQ
          </title>
        </Helmet>
        <section className="content-header">
          <div className="flex flex-row" style={{ marginTop: '-15px', marginBottom: '15px' }}>
            <ol className="breadcrumb">
              <li>
                <Link to={'/'}>InspitripHQ</Link>
              </li>
              <li>
                <Link to={'/audience/profiles'}>Audience Profiles</Link>
              </li>
              <li className="active">
                Profile Edit {profile.language}-{profile.country}
              </li>
            </ol>
          </div>
          {this.renderAlert()}
          <div className="flex flex-row">
            <div className="flex flex-col gap-y-7">
              <h1 style={{ fontSize: '24px', marginTop: 0 }}>Profile</h1>
            </div>
          </div>
        </section>
        <div className="content body">
          <div className="">
            <ProfileEditForm
              handleSubmit={this.onSubmit}
              initialValues={profile}
            />
          </div>
        </div>
      </Fragment>
    );
  }
}

ProfileEditPage.propTypes = {
  /**
   * Operational Props
   */
  success: PropTypes.string,
  error: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  isFetching: PropTypes.bool,
  executeActions: PropTypes.func,
  /**
   * ConnectedProps
   */
  profile: PropTypes.shape({
    id: PropTypes.number,
  }),
  /**
   * Actions
   */
  fetchProfileDetailAction: PropTypes.func.isRequired,
  updateProfileAction: PropTypes.func.isRequired,
};

const mapStateToProps = (state, ownProps) => ({
  profile: getProfileDetail(state, ownProps.match.params.pid),
});

export default connect(mapStateToProps, {
  fetchProfileDetailAction: fetchProfileDetail,
  updateProfileAction: updateProfile,
})(withOperation(withQuery(ProfileEditPage)));
