// Export Constants
export const TOGGLE_ADD_POST = 'TOGGLE_ADD_POST';
export const SET_REQUEST_INFO = 'SET_REQUEST_INFO';

// Export Actions
export const toggleAddPost = () => {
  return {
    type: TOGGLE_ADD_POST,
  };
};

export const setRequestInfo = (data) => ({
  type: SET_REQUEST_INFO,
  data
});
