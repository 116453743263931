import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { Listings } from '../../../shared/App/components';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';

import FilterForm from '../../../shared/Form/FilterForm';
import SearchForm from '../../../shared/Form/SearchForm';
import { parseQueryString } from '../../../utils/urlQuery';
import { reviewColumns } from '../reviewUtils';

import configService from '../../../config/configService';
import { fetchReviews, resetReview } from '../reviewActions';
import { getReviews, getFetchStatus } from '../reviewReducer';
import { getSearchData, getFilterData, keyOptions } from '../reviewFilters';
import { languageOptions } from '../../../utils/filter';
import { withOperation } from '../../../utils/OperationalComponent';
import { withQuery } from '../../../utils/QueryBasedComponent';

const sortDirection = { asc: '', desc: '-' };
const initialState = {
  showModalFilter: false,
  sortParams: {},
};

class ReviewListPage extends Component {
  state = initialState;

  componentDidMount() {
    const {
      setQueryCallback,
      reviews,
      location: { search },
    } = this.props;
    setQueryCallback(this.handleFetchReview);

    if (reviews.length === 0) {
      this.handleFetchReview(parseQueryString(search));
    }
  }

  componentWillUnmount() {
    if (this.cancel) this.cancel();
    this.props.resetReviewAction();
  }

  handleFetchReview = params => {
    const { fetchReviewAction } = this.props;
    const { page = 1, ordering } = params || {};
    if (this.cancel) this.cancel();
    this.cancel = fetchReviewAction({ params: { page, ordering } }).cancel;
  };

  handlePageChange = page => {
    this.props.pushParams({ page });
  };

  handleFilterChange = values => {
    let params = {};
    values.filter.map(
      (item, index) => (params[item.key + item.type] = item.value)
    );
    params.search = this.props.queryParams.search;
    this.props.replaceParams(params);
    this.handleCloseModal();
  };

  handleSearchChange = values => {
    this.props.pushParams({ search: values.search });
  };

  handleOpenModalFilter = () => {
    this.setState({ showModalFilter: true });
  };

  handleCloseModal = () => {
    this.setState(initialState);
  };

  handleSort = values => {
    this.props.pushParams({
      ordering: `${sortDirection[values.sortDirection]}${values.sortBy}`,
    });
    this.setState({ sortParams: values });
  };

  render() {
    const {
      reviews,
      fetchStatus: { count, isFetching },
      queryParams,
      queryParams: { page },
    } = this.props;

    // Add options to filter
    const languageOpt = languageOptions();
    const otherOptions = [];
    otherOptions.push(languageOpt);
    const { sortParams } = this.state;
    const searchData = getSearchData(queryParams);
    const filterData = getFilterData(queryParams, otherOptions);

    return (
      <Fragment>
        <Helmet>
          <title>All Reviews - Inspitrip HQ</title>
        </Helmet>
        <section className="content-header">
          <div className="flex flex-row" style={{ marginTop: '-15px', marginBottom: '15px' }}>
            <ol className="breadcrumb">
              <li>
                <Link to={'/'}>InspitripHQ</Link>
              </li>
              <li className="active">Reviews</li>
            </ol>
          </div>
          <div className="flex flex-row">
            <div className="flex flex-col gap-y-7">
              <h1 style={{ fontSize: '24px', marginTop: 0 }}>Reviews</h1>
            </div>
            <div className="flex flex-col gap-y-2 text-right" style={{ marginLeft: 'auto' }}>
              <Link to="/reviews/new" className="btn btn-primary">
                <i className="fa fa-plus"></i>
                &nbsp;Add Review
              </Link>
            </div>
          </div>
        </section>
        <section className="content-header">
          <div className="flex flex-row">
            <div className="col-sm-12 pl-0 pr-0">
              <SearchForm
                onSubmit={this.handleSearchChange}
                searchContent={searchData}
                filterData={filterData}
                handleOpenModalFilter={this.handleOpenModalFilter}
                placeHolderContent="Type search text"
              />
            </div>
          </div>
        </section>
        <div className="content body">
          <Listings
            listingsTitle="Review List"
            data={reviews}
            columns={reviewColumns}
            actions={this.actions}
            onSortHeader={this.handleSort}
            page={parseInt(page || 1, 10)}
            dataCount={count}
            itemsPerPage={configService.itemsCountPerPage}
            onPageChange={this.handlePageChange}
            pageRange={3}
            isFetching={isFetching}
            sortParams={sortParams}
            noHeader
          />
        </div>
        <FilterForm
          onSubmit={this.handleFilterChange}
          onDismiss={this.handleCloseModal}
          visible={this.state.showModalFilter}
          arrayData={filterData}
          keyOptions={[...keyOptions, ...otherOptions]}
        />
      </Fragment>
    );
  }
}

const mapStateToProps = state => ({
  fetchStatus: getFetchStatus(state),
  reviews: getReviews(state),
});

export default connect(mapStateToProps, {
  fetchReviewAction: fetchReviews,
  resetReviewAction: resetReview,
})(withOperation(withQuery(ReviewListPage)));
