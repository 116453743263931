import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { FileInputField } from '../../../shared/App/components';

import { formatDateTime } from '../../../utils/time';

class ExperienceShortBrief extends Component {
  render() {
    const { experience, backgroundPhoto } = this.props;
    const { total_reviews, created, modified } = experience;

    return (
      <Fragment>
        <div className="box-header text-center">
          <img
            className="img-responsive"
            role="presentation"
            src={backgroundPhoto}
          />
        </div>
        <div
          className="col-xs-12"
          style={{ paddingBottom: '10px', borderBottom: '1px solid #f4f4f4' }}
        >
          <div className="form-horizontal form-group">
            <FileInputField label="Thumbnail" name="background_photo" />
          </div>
        </div>
        <ul className="nav nav-pills nav-stacked" style={{ clear: 'both' }}>
          <li>
            <div
              style={{ margin: '0', padding: '10px 0' }}
              className="flex flex-row"
            >
              <span className="col-xs-5">Review</span>
              <span className="col-xs-7 text-right">{total_reviews}</span>
            </div>
          </li>
          <li>
            <div
              style={{ margin: '0', padding: '10px 0' }}
              className="flex flex-row"
            >
              <span className="col-xs-5">Booked</span>
              <span className="col-xs-7 text-right">-</span>
            </div>
          </li>
          <li>
            <div
              style={{ margin: '0', padding: '10px 0' }}
              className="flex flex-row"
            >
              <span className="col-xs-5">Last Booked</span>
              <span className="col-xs-7 text-right">-</span>
            </div>
          </li>
          <li>
            <div
              style={{ margin: '0', padding: '10px 0' }}
              className="flex flex-row"
            >
              <span className="col-xs-5">Last Reviewed</span>
              <span className="col-xs-7 text-right">-</span>
            </div>
          </li>
          <li>
            <div
              style={{ margin: '0', padding: '10px 0' }}
              className="flex flex-row"
            >
              <span className="col-xs-5">Created Date</span>
              <span className="col-xs-7 text-right">
                {formatDateTime(created)}
              </span>
            </div>
          </li>
          <li>
            <div
              style={{ margin: '0', padding: '10px 0' }}
              className="flex flex-row"
            >
              <span className="col-xs-5">Last Update</span>
              <span className="col-xs-7 text-right">
                {formatDateTime(modified)}
              </span>
            </div>
          </li>
        </ul>
      </Fragment>
    );
  }
}

ExperienceShortBrief.propTypes = {
  experience: PropTypes.object,
};

export default ExperienceShortBrief;
