// Utils
import { getFilter, getSearch } from '../../utils/filter';

// Constants
import configApp from '../../config/configApp';

/**
 * Booking Filter
 */
const stateSubOptions = [
  { value: configApp.bookingItemState.ASSIGNED, label: 'Assigned' },
  { value: configApp.bookingItemState.UNASSIGNED, label: 'Unassigned' },
  { value: configApp.bookingItemState.CANCELLED, label: 'Cancelled' },
  { value: configApp.bookingItemState.COMPLETED, label: 'Completed' },
];

const bookingStateSubOptions = [
  { value: configApp.bookingState.PENDING, label: 'Pending' },
  { value: configApp.bookingState.CONFIRMED, label: 'Confirmed' },
  { value: configApp.bookingState.CANCELLED, label: 'Cancelled' },
  { value: configApp.bookingState.COMPLETED, label: 'Completed' },
];

export const keyOptions = [
  { value: 'state', label: 'Status', type: 'select', subOptions: stateSubOptions },
  { value: 'start_date', label: 'Travel date', type: 'input' },
  { value: 'booking', label: 'Booking', type: 'input' },
  { value: 'cost', label: 'Price', type: 'input' },
  { value: 'booking__status', label: 'Booking Status', type: 'select', subOptions: bookingStateSubOptions }
];

// Can override if you want
export const getFilterData = queryParams => {
  return getFilter(queryParams, keyOptions);
};

export const getSearchData = queryParams => {
  return getSearch(queryParams);
};
