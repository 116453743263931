import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';

// Components
import PageEditForm from '../components/PageEditForm';
import Breadcrumb from '../../../shared/App/components/Breadcrumb';
import FormStatus from '../../../shared/App/components/FormStatus';

// Actions
import { createPage } from '../pageActions';

// Utils
import { withOperation } from '../../../utils/OperationalComponent';
import { withQuery } from '../../../utils/QueryBasedComponent';
import { transformPageDetail } from '../pageUtils';

class PageCreatePage extends Component {
  handleSubmit = values => {
    const { executeActions, match, createPageAction } = this.props;

    // transform data
    const newCollection = transformPageDetail(values);

    const data = new FormData();
    if (newCollection.background_photo instanceof File) {
      data.append('background_photo', newCollection.background_photo);
    }
    if (newCollection.background_video instanceof File) {
      data.append('background_video', newCollection.background_video);
    }
    if (newCollection.meta_photo instanceof File) {
      data.append('meta_photo', newCollection.meta_photo);
    }
    const jsonFields = ['facts', 'faqs', 'specification', 'photos'];

    Object.keys(newCollection).map(key => {
      if (jsonFields.indexOf(key) > -1) {
        data.append(key, JSON.stringify(newCollection[key]));
      } else {
        data.append(key, newCollection[key]);
      }
    });

    executeActions({
      actions: [() => createPageAction({ params: data })],
      onSuccess: () => this.props.history.push('/pages'),
      successMsg: 'Create successfully',
      failureMsg: 'Create fail',
    });
  };

  render() {
    const { error, success } = this.props;
    const errorData = error ? error.data || 'Something went wrong.' : '';
    return (
      <Fragment>
        <Helmet>
          <title>Create Page - Inspitrip HQ</title>
        </Helmet>
        <section className="content-header">
          <div className="flex flex-row" style={{ marginTop: '-15px', marginBottom: '15px' }}>
            <Breadcrumb
              history={[
                { title: 'InspitripHQ', url: '/' },
                { title: 'Pages', url: '/pages' },
                { title: 'Create Page', url: '' },
              ]}
            />
          </div>

          <div className="nav-tabs-custom">
            <FormStatus formState={{ error: errorData, success }} />
          </div>

          <div className="flex flex-row">
            <div className="flex flex-col gap-y-7">
              <h1 style={{ fontSize: '24px', marginTop: 0 }}>Create page</h1>
            </div>
          </div>
        </section>
        <div className="content body">
          <div className="">
            <PageEditForm initialValues={{}} handleSubmit={this.handleSubmit} />
          </div>
        </div>
      </Fragment>
    );
  }
}

export default connect(null, {
  createPageAction: createPage,
})(withOperation(withQuery(PageCreatePage)));
