import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Formik, Form, Field } from 'formik';
import {
  ArrayObjectField,
  InputField,
  TextAreaField,
} from '../../../shared/App/components';

class ProfileEditForm extends Component {
  render() {
    const {
      handleSubmit, submitting, initialValues
    } = this.props;

    return (
      <Formik onSubmit={handleSubmit} enableReinitialize initialValues={initialValues}>
      {
        ({ isSubmitting, isValid, setFieldValue, values }) => (
          <Form className="">
          <div className="col-md-12" style={{ padding: 0 }}>
            <div className="">
            <div className="">
              <div className="form-group">
                <label className="col-sm-2 control-label">Title</label>
                <div className="col-sm-10">
                  <InputField name="title" type="text" placeholder="Title" />
                </div>
              </div>
              <div className="form-group">
                <label className="col-sm-2 control-label">Description</label>
                <div className="col-sm-10">
                  <TextAreaField
                    id="description"
                    name="description"
                  />
                </div>
              </div>
              <div className="form-group">
                <label className="col-sm-2 control-label">Facebook page ID</label>
                <div className="col-sm-10">
                  <InputField name="fb_page_id" type="text" placeholder="Facebook page ID" />
                </div>
              </div>
              <div className="form-group">
                <label className="col-sm-2 control-label">Locale Display</label>
                <div className="col-sm-10">
                  <InputField name="locale_display" type="text" placeholder="en_US" />
                </div>
              </div>
              <div className="form-group">
                <label className="col-sm-2 control-label">Twitter site</label>
                <div className="col-sm-10">
                  <InputField name="twitter_site" type="text" placeholder="@inspitriphq" />
                </div>
              </div>
              <div className="form-group">
                <label className="col-sm-2 control-label">Country</label>
                <div className="col-sm-10">
                  <InputField name="country" type="text" placeholder="Country" />
                </div>
              </div>
              <div className="form-group">
                <label className="col-sm-2 control-label">Language</label>
                <div className="col-sm-10">
                  <InputField name="language" type="text" placeholder="Language" />
                </div>
              </div>
              <div className="form-group">
                <label className="col-sm-2 control-label">Desktop Cover</label>
                <div className="col-sm-10">
                  {initialValues && initialValues.desktop_cover && (<img style={{maxWidth: '500px'}} src={initialValues.desktop_cover} alt="Desktop Cover" />)}
                  <input
                    id="desktop_cover"
                    className="form-control"
                    type="file"
                    name="desktop_cover"
                    onChange={(event) => {
                      setFieldValue(event.currentTarget.name, event.currentTarget.files[0]);
                    }}
                  />
                </div>
              </div>

              <div className="form-group">
                <label className="col-sm-2 control-label">Mobile Cover</label>
                <div className="col-sm-10">
                  <br />
                  {initialValues && initialValues.mobile_cover && (<img style={{maxWidth: '500px'}} src={initialValues.mobile_cover} alt="Mobile Cover" />)}
                  <input
                    id="mobile_cover"
                    className="form-control"
                    type="file"
                    name="mobile_cover"
                    onChange={(event) => {
                      setFieldValue(event.currentTarget.name, event.currentTarget.files[0]);
                    }}
                  />
                </div>
              </div>

              <div className="form-group">
                <label className="col-sm-2 control-label">Keywords</label>
                <div className="col-sm-10">
                  <ArrayObjectField
                    name="hot_keywords"
                    arrayData={values.hot_keywords}
                    renderObject={(item, index) => (
                      <Field name={`hot_keywords.${index}`} className="form-control" />
                    )}
                  />
                </div>
              </div>
              <div className="form-group">
                <label className="col-sm-2 control-label">Highlights</label>
                <div className="col-sm-10">
                  <ArrayObjectField
                    name="highlights"
                    arrayData={values.highlights}
                    renderObject={(item, index) => (
                      <div>
                        <label>title</label>
                        <Field name={`highlights.${index}.title`} className="form-control" />
                        <label>description</label>
                        <Field name={`highlights.${index}.description`} className="form-control" />
                        <label>photo url</label>
                        <Field name={`highlights.${index}.photo`} className="form-control" />
                        <label>link</label>
                        <Field name={`highlights.${index}.link`} className="form-control" />
                      </div>
                    )}
                  />
                </div>
              </div>
              <div className="form-group">
                <label className="col-sm-2 control-label">Social links</label>
                <div className="col-sm-10">
                  <ArrayObjectField
                    name="social_links" arrayData={values.social_links}
                    renderObject={(item, index) => (
                      <div>
                        <label>type</label>
                        <Field name={`social_links.${index}.type`} className="form-control" />
                        <label>link</label>
                        <Field name={`social_links.${index}.link`} className="form-control" />
                      </div>
                    )}
                  />
                </div>
              </div>
            </div>
            <div className="box-footer">
              <div className="form-group">
                <div className="col-sm-2">
                </div>
                <div className="col-sm-10" style={{ paddingLeft: '18px' }}>
                  <button type="submit" className="btn btn-info pull-left" disabled={submitting}>Submit</button>
                  <button type="button" className="btn btn-default pull-left ml-10">Cancel</button>
                </div>
              </div>
            </div>
            </div>
            </div>
          </Form>
          )
      }
      </Formik>
    );
  }
}

export default ProfileEditForm;
