import React, { Component } from 'react';

import ImageInputField from '../../../shared/ImageInputField';
import { ArrayObjectField, InputField } from '../../../shared/App/components';
class MediaContainer extends Component {
  state = {
    data: ''
  };
  render() {
    const { values, setFieldValue } = this.props;

    return (
      <div>
        {/* <div className="input-group">
          <input type="text" className="form-control timepicker" />

          <div className="input-group-addon">
            <i className="fa fa-clock-o" />
          </div>
        </div> */}
        <ArrayObjectField
          name="photos"
          arrayData={values.photos}
          renderObject={(item, index) => (
            <div className="box" key={index}>
              <div className="box-body pl-0">
                <InputField style={{ marginLeft: 0, marginRight: 0 }} sub="*" name={`photos.${index}.name`} label="Name" placeholder="" />

                <ImageInputField
                  key={1}
                  label={'Full photo'}
                  name={`photos.${index}.full_photo`}
                  btnTitle="Upload"
                  onUpload={value => {
                    setFieldValue(`photos.${index}.full_photo`, value);
                  }}
                />
                <ImageInputField
                  key={2}
                  label={'Photo'}
                  name={`photos.${index}.photo`}
                  btnTitle="Photo"
                  onUpload={value => {
                    setFieldValue(`photos.${index}.photo`, value);
                  }}
                />
              </div>
            </div>
          )}
        />
        {this.state.data || ''}{' '}
      </div>
    );
  }
}

export default MediaContainer;
