import React from 'react';
import { Link } from 'react-router-dom';
import { Status } from '../../shared/App/components';
import { timeAgo } from '../../utils/time';

export const StatusConfig = {
  published: { title: 'published', color: Status.colors.GREEN },
  draft: { title: 'draft', color: Status.colors.GREY },
  private: { title: 'private', color: Status.colors.YELLOW }
};

export const FeaturedValueConfig = {
  [true]: { title: 'Yes', color: Status.colors.GREEN },
  [false]: { title: 'No', color: Status.colors.GREY },
};

export const transformPlaceDetail = place => {
  let newPlace = { ...place };

  const localeSplit = newPlace.locale ? newPlace.locale.split('-') : ['en', ''];
  newPlace.language = localeSplit[0] || '';
  newPlace.country = localeSplit[1] || '';

  if (place.location) {
    newPlace.location = place.location.value;
  } else {
    newPlace.location = null;
  }

  delete newPlace.editMode;
  delete newPlace.locale;
  return newPlace;
};

export const buildFormData = place => {
  if (!place) return {};
  return {
    ...place,
    location: (place.location ? { value: place.location.id, label: place.location.name } : null),
    locale: `${place.language}${place.country && `-${place.country}`}`
  };
};

// TODO: Build locale link

export const cityColumns = () => {
  return [
    {
      id: 'id',
      title: 'ID',
      render: ({ id }) => <Link to={`/locations/pages/${id}`}>{id}</Link>
    },
    {
      id: 'language',
      title: 'Locale',
      render: ({ language, country }) => (
        <span>{language} {`${country && `-${country}`}`}</span>
      )
    },
    {
      id: 'title',
      title: 'Title',
      render: ({ id, title }) => <Link to={`/locations/pages/${id}`}>{title}</Link>
    },
    {
      id: 'name',
      title: 'Name',
      render: ({ name }) => name
    },
    {
      id: 'slug',
      title: 'Slug',
      render: ({ slug }) => <div>{slug}</div>
    },
    {
      id: 'template',
      title: 'Template',
      render: ({ template }) => <span>{template}</span>
    },
    {
      id: 'sort',
      title: 'Sort',
      render: ({ sort }) => <span>{sort}</span>
    },
    {
      id: 'state',
      title: 'Status',
      render: ({ state }) => {
        return <Status state={`${state}`} statuses={StatusConfig} />;
      }
    },
    {
      id: 'created',
      title: 'Created',
      render: ({ created }) => <span>{timeAgo(created)}</span>
    },
    {
      id: 'modified',
      title: 'Updated',
      render: ({ modified }) => <span>{timeAgo(modified)}</span>
    },
  ];
};
