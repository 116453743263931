import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Form, Formik } from 'formik';

// Components
import {
  InputField,
  TextAreaField,
  AsyncSelectField,
  Button,
} from '../../../shared/App/components';

// Actions
import { searchLocations } from '../locationActions';

// Utils
import { validate } from '../locationValidators';
import { slugify } from '../../../utils/slugify';

class LocationForm extends Component {
  handleTitleBlur = (e, values, setFieldValue) => {
    // generate slug if does not exist
    if (!values.slug) {
      setFieldValue(
        'slug',
        slugify(e.target.value, {
          lower: true,
        })
      );
    }
  };

  render() {
    const { editMode, initialValues, handleSubmit } = this.props;

    return (
      <div className="content">
        <Formik
          initialValues={{ ...initialValues, editMode }}
          onSubmit={handleSubmit}
          enableReinitialize
          isInitialValid
          validate={validate}
        >
          {() => {
            return (
              <Form>
                <div className="flex flex-col gap-y-8">
                  <div className="box">
                    <div className="box-body">
                      <InputField
                        sub="*"
                        name="name"
                        label="Name"
                        placeholder="Place name, for example Ho Chi Minh city. Refer to wikipedia for a correct name"
                      />
                      <InputField name="code" label="Code" />
                      <InputField name="timezone" label="Timezone" />
                      <TextAreaField
                        id="description"
                        name="description"
                        label="Description"
                        rows={4}
                      />

                      <AsyncSelectField
                        name="parent"
                        label="Parent"
                        loadOptions={(inputValue, callback) => {
                          this.props
                            .searchLocations({ params: { search: inputValue } })
                            .then(response => {
                              const { results } = response.data;
                              const data = results.map(item => {
                                return {
                                  label: item.name,
                                  value: item.id,
                                };
                              });
                              callback(data);
                            });
                        }}
                      />

                      <Button type="submit" displayType="primary" block>
                        <i className="fa fa-save" />{' '}
                        {editMode ? 'Save changes' : 'Create'}
                      </Button>
                    </div>
                  </div>
                </div>
              </Form>
            );
          }}
        </Formik>
      </div>
    );
  }
}

LocationForm.propTypes = {
  handleSubmit: PropTypes.func,
  pristine: PropTypes.bool,
  reset: PropTypes.func,
  submitting: PropTypes.bool,
  error: PropTypes.string,
  isSuccess: PropTypes.bool,
  isError: PropTypes.bool,
  submitSucceeded: PropTypes.bool,
  dispatch: PropTypes.func,
  editMode: PropTypes.bool,
};

LocationForm.defaultProps = {
  editMode: false,
};

export default connect(null, { searchLocations })(LocationForm);
