import { combineReducers } from 'redux';

import {
  FETCH_EMAIL_LOGS,
  FETCH_EMAIL_LOG,
  RESET_EMAIL_LOG
} from './emailLogActions';
import { FULFILLED, PENDING, REJECTED } from '../../utils/axiosAPI';
import { appendByIds } from '../../utils/reducer';

export const fetchStatusInitialState = {
  count: 0,
  next: null,
  previous: null,
  isFetching: false,
  error: null
};

export const fetchStatus = (state = fetchStatusInitialState, action) => {
  switch (action.type) {
    case FETCH_EMAIL_LOGS + FULFILLED: {
      const { results, ...rest } = action.data;
      return { ...state, isFetching: false, ...rest };
    }
    case FETCH_EMAIL_LOGS + REJECTED: {
      return { ...state, isFetching: false, error: action.data };
    }
    case FETCH_EMAIL_LOGS + PENDING: {
      return { ...state, isFetching: true, error: null };
    }
    case FETCH_EMAIL_LOGS: {
      return fetchStatusInitialState;
    }
    default: {
      return state;
    }
  }
};

export const fetchDetailStatus = (state = fetchStatusInitialState, action) => {
  switch (action.type) {
    case FETCH_EMAIL_LOG + FULFILLED: {
      const { results, ...rest } = action.data;
      return { ...state, isFetching: false, ...rest };
    }
    case FETCH_EMAIL_LOG + REJECTED: {
      return { ...state, isFetching: false, error: action.data };
    }
    case FETCH_EMAIL_LOG + PENDING: {
      return { ...state, isFetching: true, error: null };
    }
    case FETCH_EMAIL_LOG: {
      return fetchStatusInitialState;
    }
    default: {
      return state;
    }
  }
};

export const byId = (state = {}, action) => {
  switch (action.type) {
    case FETCH_EMAIL_LOGS + FULFILLED: {
      return appendByIds(state, action.data.results, 'id');
    }
    case FETCH_EMAIL_LOG + FULFILLED: {
      const payment = state[action.data.id];
      const newPayment = action.data;

      return {
        ...state,
        [action.data.id]: { ...payment, ...newPayment }
      };
    }
    default: {
      return state;
    }
  }
};

export const visibleIds = (state = [], action) => {
  switch (action.type) {
    case FETCH_EMAIL_LOGS + FULFILLED: {
      /**
       * Always replace the visible booking
       */
      return action.data.results.map(log => log.id);
    }
    case RESET_EMAIL_LOG: {
      return [];
    }
    default: {
      return state;
    }
  }
};

export default combineReducers({
  fetchStatus,
  byId,
  visibleIds
});

export const getEmailLogs = state =>
  state.emailLog.visibleIds.map(id => state.emailLog.byId[id]);
export const getFetchStatus = state => state.emailLog.fetchStatus;
export const getEmailLogDetail = (state, id) => state.emailLog.byId[id];
export const getFetchDetailStatus = state => state.emailLog.fetchDetailStatus;
