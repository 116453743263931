/**
 *
 *
 * @summary Get filter data and mapping with key options
 * @author hainguyencase
 *
 * Created at     : 2019-04-19 10:51:56
 * Last modified  : 2019-04-19 14:20:27
 */

import configService from '../config/configService';


/**
 *
 * @param {Object} queryParams
 * @param {Object} keyOptions
 */
export const getFilter = (queryParams, keyOptions) => {
  const keyOptionValues = keyOptions.map(item => item.value);
  const keyQuerys = Object.keys(queryParams);
  let filterData = keyQuerys.map((item, index) => {
    let key = '';
    let type = '';
    let value = '';
    if (item.indexOf('__gte') !== -1) {
      value = queryParams[item];
      key = item.replace('__gte', '');
      type = '__gte';
    } else if (item.indexOf('__lte') !== -1) {
      value = queryParams[item];
      key = item.replace('__lte', '');
      type = '__lte';
    } else {
      value = queryParams[item];
      key = item;
      type = '';
    }
    return { key, type, value };
  });
  filterData = filterData.filter(item => keyOptionValues.indexOf(item.key) !== -1);

  return filterData;
};

/**
 *
 * @param {Object} queryParams
 */
export const getSearch = queryParams => {
  return queryParams.search;
};

// Get language filter => apply for other pages
export const languageOptions = () => {
  if (!configService.localeSettings || configService.localeSettings.length === 0) {
    return [];
  }

  const subOption = configService.localeSettings.map(item => {
    const label = item.label.split(' - ')[0];
    const value = item.value.split('-')[0];
    return { label, value };
  });

  return { value: 'language', label: 'Language', type: 'select', subOptions: subOption };
};
