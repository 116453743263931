import React, { Component } from 'react';
import PropTypes from 'prop-types';

import {
  InputField, SelectField, Modal, MarkdownField, Button
} from '../../../shared/App/components';
import { Form, Formik } from 'formik';
import FormStatus from '../../../shared/App/components/FormStatus';
import FroalaField from '../../../shared/App/components/FroalaField';

const validate = (values) => {
  const errors = {};

  if (!values.question) {
    errors.question = 'Please enter question text.';
  }

  if (!values.answer) {
    errors.answer = 'Please enter answer text.';
  }

  return errors;
};

class ExperienceAddQuestionModal extends Component {
  state = {
    addError: undefined,
    question: '',
    answer: '',
    template_faq: '',
  };

  onSubmit(values) {
    this.setState({ addError: undefined });
    const { expId, addExperienceFAQ, handleCloseModal } = this.props;
    addExperienceFAQ({ expId, data: values }).then(() => {
      handleCloseModal();
    }).catch((error) => {
      this.setState({ addError: error.data || 'Oops! Please contact your administrator.' });
    });
  }

  render() {
    const { handleCloseModal, showAddModalFaq, faqTemplates } = this.props;
    const selectOptions = faqTemplates && faqTemplates.length > 0 ? faqTemplates.map(faq => ({
      label: faq.question,
      value: faq.id
    })) : [];
    const {
      question,
      answer,
      template_faq
    } = this.state;

    return (
      <Modal
        title="Add Question"
        hideFooter
        onConfirm={() => {}}
        onDismiss={handleCloseModal}
        visible={showAddModalFaq}
      >
        {this.state.addError ? <FormStatus formState={{ error: this.state.addError }} /> : null}
        <Formik
          initialValues={{
            question,
            answer,
            template_faq
          }}
          validate={validate}
          enableReinitialize
          onSubmit={this.onSubmit.bind(this)}
        >
          {
            ({ errors, touched, isSubmitting, isValid, values }) => (
              <Form>
                <SelectField
                  name="template_faq"
                  label="Template"
                  options={selectOptions}
                  onChange={(selectedOption) => {
                    faqTemplates.forEach((faq) => {
                      if (faq.id === parseInt(selectedOption.value, 10)) {
                        this.setState({
                          question: faq.question,
                          answer: faq.answer,
                          template_faq: selectedOption.value
                        });
                      }
                    });
                  }}
                />
                <InputField
                  name="question"
                  label="Question"
                />
                <FroalaField
                  id="answer"
                  name="answer"
                  label="Answer"
                  placeholder="Answer content"
                />
                <div className="text-right">
                  <Button className="btn btn-default" onClick={handleCloseModal}>Cancel</Button>
                  &nbsp;&nbsp;
                  <Button className="btn  btn-primary  btn-nor" type="submit">Add</Button>
                </div>
              </Form>
            )
          }
        </Formik>
      </Modal>
    );
  }
}

ExperienceAddQuestionModal.propTypes = {
  expId: PropTypes.number,
  faqTemplates: PropTypes.array,
  handleCloseModal: PropTypes.func,
  showAddModalFaq: PropTypes.bool,
  addExperienceFAQ: PropTypes.func
};

export default ExperienceAddQuestionModal;
