import React from 'react';
import { Modal } from '../../../../../shared/App/components';

const SendBookingItemNotificationForm = ({ onSubmit, onDismiss, visible }) => (
  <Modal
    title="Are you sure to send notification to Localguide?"
    confirmText="Yes, sure"
    dismissText="Cancel"
    onConfirm={() => onSubmit()}
    {...{ onDismiss, visible }}
  >
    <p>
      This would send Booking confirmation to localguide.
    </p>
  </Modal>
);

export default SendBookingItemNotificationForm;
