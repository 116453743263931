/**
 *
 *
 * @summary pageReducer
 * @author cuonghx
 *
 * Created at     : 2019-03-30 12:54:31
 * Last modified  : 2019-03-30 12:56:28
 */

import { combineReducers } from 'redux';

// Actions
import { FETCH_PAGES, RESET_PAGES, CREATE_PAGE, FETCH_PAGE } from './pageActions';

// Constants
import { FULFILLED, PENDING, REJECTED } from '../../utils/axiosAPI';
import { appendByIds } from '../../utils/reducer';

const fetchStatusInitialState = {
  count: 0,
  next: null,
  previous: null,
  isFetching: false,
  error: null
};

// Seperate reuse for all reducer
export const fetchStatus = (state = fetchStatusInitialState, action) => {
  switch (action.type) {
    case FETCH_PAGES + FULFILLED: {
      const { results, ...rest } = action.data;
      return { ...state, isFetching: false, ...rest };
    }
    case FETCH_PAGES + REJECTED: {
      return { ...state, isFetching: false, error: action.data };
    }
    case FETCH_PAGES + PENDING: {
      return { ...state, isFetching: true, error: null };
    }
    case RESET_PAGES: {
      return fetchStatusInitialState;
    }
    default: {
      return state;
    }
  }
};

export const byId = (state = {}, action) => {
  switch (action.type) {
    case FETCH_PAGES + FULFILLED: {
      return appendByIds(state, action.data.results, 'id');
    }
    case CREATE_PAGE + FULFILLED: {
      return {
        [action.data.id]: action.data,
        ...state
      };
    }
    case FETCH_PAGE + FULFILLED: {
      return {
        [action.data.id]: action.data,
        ...state
      };
    }
    default:
      return state;
  }
};

export const visibleIds = (state = [], action) => {
  switch (action.type) {
    case FETCH_PAGES + FULFILLED: {
      return action.data.results.map(item => item.id);
    }
    case CREATE_PAGE + FULFILLED: {
      return [action.data.id, ...state];
    }
    case RESET_PAGES: {
      return [];
    }
    default: {
      return state;
    }
  }
};

//
const initialPageFields = {
  isFetching: false,
  action: {}
};

export default combineReducers({
  fetchStatus,
  visibleIds,
  byId
});

// Selector
export const getPages = state => state.page.visibleIds.map(id => state.page.byId[id]);
export const getPageDetail = (state, id) => state.page.byId[id];
export const getFetchStatus = state => state.page.fetchStatus;
