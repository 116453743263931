import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';

// Component
import { Breadcrumb, Status } from '../../../shared/App/components';
import FormStatus from '../../../shared/App/components/FormStatus';

// Actions
import { fetchEmailLog } from '../emailLogActions';
import { getEmailLogDetail, getFetchDetailStatus } from '../emailLogReducers';

// Utils
import { withOperation } from '../../../utils/OperationalComponent';
import { logStatusConfig } from '../emailLogUtils';
import { formatDateTime, timeAgo } from '../../../utils/time';
import { withQuery } from '../../../utils/QueryBasedComponent';

class EmailLogDetailPage extends Component {
  componentDidMount() {
    const { executeActions, match, fetchEmailLogAction } = this.props;
    executeActions({
      actions: [() => fetchEmailLogAction(match.params.cuid)],
    });
  }

  render() {
    const { emailLog, match, isFetching, error, success } = this.props;

    return (
      <Fragment>
        <Helmet>
          <title>Email Log #{`${match.params.cuid}`} - Inspitrip HQ</title>
        </Helmet>
        <section className="content-header">
          <div className="flex flex-row" style={{ marginTop: '-15px', marginBottom: '15px' }}>
            <Breadcrumb
              history={[
                { title: 'InspitripHQ', url: '/' },
                { title: 'Notification Logs', url: '/emails/logs' },
                { title: 'Detail', url: '' },
              ]}
            />
          </div>
          <div className="nav-tabs-custom">
            <FormStatus formState={{ error, success }} />
          </div>
          <div className="flex flex-row">
            <div className="flex flex-col gap-y-7">
              <h1 style={{ fontSize: '24px', marginTop: 0 }}>
                Notification Log
              </h1>
            </div>
          </div>
        </section>
        <div className="content body">
          <div className="flex flex-row">
            {isFetching || !emailLog ? (
              <div className="overlay">
                <i className="fa fa-refresh fa-spin" />
                Loading...
              </div>
            ) : (
              <div className="content">
                <div className="col-md-7">
                  <div className="box">
                    <div className="box-header with-border">
                      <h3 className="box-title">Log Detail #{emailLog.id}</h3>
                    </div>
                    <div className="box-body">
                      <ul className="nav nav-pills nav-stacked">
                        <li className="flex flex-row">
                          <span className="col-xs-5">ID</span>
                          <span className="col-xs-7 text-right">
                            {emailLog.id}
                          </span>
                        </li>
                        <li className="flex flex-row">
                          <span className="col-xs-5">Sent to</span>
                          <span className="col-xs-7 text-right">
                            {emailLog.target}
                          </span>
                        </li>
                        <li className="flex flex-row">
                          <span className="col-xs-5">Subject</span>
                          <span className="col-xs-7 text-right">
                            {emailLog.subject}
                          </span>
                        </li>
                        <li className="flex flex-row">
                          <span className="col-xs-5">Email content</span>
                        </li>
                        <li className="flex flex-row">
                          <div
                            className="col-xs-12"
                            dangerouslySetInnerHTML={{
                              __html: emailLog.content_email,
                            }}
                          ></div>
                        </li>
                        <li className="flex flex-row">
                          <span className="col-xs-12">Extra data</span>
                        </li>
                        <li className="flex flex-row">
                          <div className="col-xs-12">
                            <pre>
                              {JSON.stringify(emailLog.extra_data, null, 2)}
                            </pre>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="col-md-5">
                  <div className="box">
                    <div className="box-body">
                      <ul className="nav nav-pills nav-stacked">
                        <li className="flex flex-row">
                          <span className="col-xs-5">Event</span>
                          <span className="col-xs-7 text-right">
                            {emailLog.event}
                          </span>
                        </li>
                        <li className="flex flex-row">
                          <span className="col-xs-5">Template code</span>
                          <span className="col-xs-7 text-right">
                            {emailLog.template_code}
                          </span>
                        </li>
                        <li className="flex flex-row">
                          <span className="col-xs-5">Response</span>
                          <span className="col-xs-7 text-right">
                            {emailLog.log}
                          </span>
                        </li>
                        <li className="flex flex-row">
                          <span className="col-xs-5">Status</span>
                          <span className="col-xs-7 text-right">
                            <Status
                              state={`${emailLog.state}`}
                              statuses={logStatusConfig}
                            />
                          </span>
                        </li>
                        <li className="flex flex-row">
                          <span className="col-xs-5">Created</span>
                          <span className="col-xs-7 text-right">
                            {formatDateTime(emailLog.created)}
                          </span>
                        </li>
                        <li className="flex flex-row">
                          <span className="col-xs-5">Updated</span>
                          <span className="col-xs-7 text-right">
                            {timeAgo(emailLog.modified)}
                          </span>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </Fragment>
    );
  }
}

// Retrieve data from store as props
const mapStateToProps = (state, props) => {
  return {
    isFetching: getFetchDetailStatus(state),
    emailLog: getEmailLogDetail(state, props.match.params.cuid),
  };
};

export default connect(mapStateToProps, {
  fetchEmailLogAction: fetchEmailLog,
})(withOperation(withQuery(EmailLogDetailPage)));
