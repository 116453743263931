import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Modal } from '../../../shared/App/components';
import FormStatus from '../../../shared/App/components/FormStatus';

class ConfirmDeleteModal extends Component {
  render() {
    const {
      handleCloseModal, showConfirmDelete, onConfirm,
      title = 'Delete Question', confirmText = 'Delete',
      dismissText = 'Cancel',
      deleteError
    } = this.props;
    return (
      <Modal
        title={title}
        confirmText={confirmText}
        dismissText={dismissText}
        onConfirm={onConfirm}
        onDismiss={handleCloseModal}
        visible={showConfirmDelete}
      >
        {deleteError ? <FormStatus formState={{ error: deleteError }} /> : null}
        {
          this.props.children ? this.props.children :
            <div>
              Are you sure you want to delete this item? This cannot be undone.
            </div>
        }
      </Modal>
    );
  }
}

ConfirmDeleteModal.propTypes = {
  title: PropTypes.string,
  confirmText: PropTypes.string,
  dismissText: PropTypes.string,
  handleCloseModal: PropTypes.func,
  onConfirm: PropTypes.func,
  showConfirmDelete: PropTypes.bool
};

export default ConfirmDeleteModal;
