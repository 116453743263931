import { Avatar } from 'antd';
import PropTypes from 'prop-types';
import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import configService from '../../../../../config/configService';
import { Box, Status, Table } from '../../../../../shared/App/components';
import { formatDate } from '../../../../../utils/time';
import { bookingItemStatusConfig } from '../../../../Fulfillment/bookingItemUtils';

// utils
import { displayCurrency } from '../../../../../utils/currency';
import { getLocalePrefixByLanguage } from '../../../../../utils/localeUtils';
import {
  fetchBookingItems,
  fetchExperienceDetail,
  fetchVariantDetail,
} from '../../../bookingActions';

const renderOptions = (options = []) =>
  options.length > 0 && (
    <Fragment>
      <strong>Options:</strong>
      <br />
      {options.map(option => (
        <Fragment key={option.unit_type_id}>
          - {option.title} x {option.quantity}
          <br />
        </Fragment>
      ))}
    </Fragment>
  );

const renderServices = (services = []) =>
  services.length > 0 && (
    <Fragment>
      <strong>Services:</strong>
      <br />
      {services.map(service => (
        <Fragment key={service.service_id}>
          - {service.title} x {service.quantity}
          <br />
        </Fragment>
      ))}
    </Fragment>
  );

const itemsColumns = [
  {
    id: 'item',
    title: 'Item',
    render: ({
      id,
      experience = {},
      variant = {},
      experience_variant_title,
      experience_title,
      experience_title_info,
      experience_id,
      background_photo,
      booking,
      start_date,
      localguide = {},
      options,
      extra_services,
      language = 'en',
    }) => {
      const localePrefix = getLocalePrefixByLanguage(language);
      const experienceTitle =
        experience && experience.title ? experience.title : experience_title;
      const experiencePhoto =
        experience && experience.background_photo
          ? experience.background_photo
          : background_photo;
      const experienceVariantTitle =
        variant && variant.title ? variant.title : experience_variant_title;

      return (
        <Fragment>
          <Link to={`/bookings/${booking}/fulfillments/${id}`}>
            #{booking}-{id}
          </Link>{' '}
          <br />
          <div className="flex flex-row">
            <a href={`${configService.mainSite}${localePrefix}/experiences/${experience_id}`} target="_blank" className="col-xs-3 pl-0">
              <img
                className="img-responsive"
                role="presentation"
                src={experiencePhoto}
                style={{ marginTop: '5px' }}
                alt="Experience photo"
                onError={({ currentTarget }) => {
                  currentTarget.onerror = null; // prevents looping
                  currentTarget.src="https://storage.googleapis.com/inspitrip/backend/fallbackphoto/no-product-image.jpeg";
                }}
              />
            </a>
            <div className="col-xs-9" style={{ lineHeight: '22px', whiteSpace: 'normal' }}>
              <a href={`${configService.mainSite}${localePrefix}/experiences/${experience_id}`} target="_blank">
                <strong>{experienceTitle || experience_title_info}</strong>
              </a>
              <br />
              <strong>Travel Date:</strong> {formatDate(start_date)}
              <br />
              <strong>Option Group:</strong> {experienceVariantTitle}
              <br />
              {renderOptions(options)}
              {renderServices(extra_services)}
              {localguide && (
                <a href={`/users/${localguide.id}`}>
                  <Avatar
                    src={
                      localguide.photo
                        ? localguide.photo
                        : 'https://exelord.com/ember-initials/images/default-d5f51047d8bd6327ec4a74361a7aae7f.jpg'
                    }
                    size={'small'}
                  />
                  &nbsp;{localguide.first_name} {localguide.last_name}
                </a>
              )}
            </div>
          </div>
        </Fragment>
      );
    },
  },
  {
    id: 'price',
    title: 'Price',
    render: ({ cost, currency_code }) =>
      displayCurrency({ amount: parseFloat(cost), currency: currency_code }),
  },
  {
    id: 'status',
    title: 'Status',
    render: ({ status }) => (
      <Status state={`${status}`} statuses={bookingItemStatusConfig} />
    ),
  },
];

class BookingItems extends React.Component {
  state = {
    isFetching: false,
    items: [],
  };

  componentDidMount() {
    const { bookingId } = this.props;
    if (bookingId) {
      this.fetchBookingItems(bookingId);
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.bookingId !== prevProps.bookingId) {
      this.fetchBookingItems(this.props.bookingId);
    }
  }

  fetchBookingItems = bookingId => {
    this.setState({ isFetching: true });
    return this.props.fetchBookingItems({ bookingId }).then(itemsRes => {
      if (itemsRes.data.results.length === 1) {
        const item = itemsRes.data.results[0];
        this.props
          .fetchExperienceDetail({ id: item.experience_id })
          .then(expRes => {
            return this.props
              .fetchVariantDetail({
                id: item.experience_id,
                ogId: item.experience_variant_id,
              })
              .then(variantRes => {
                this.setState({
                  isFetching: false,
                  items: [
                    {
                      ...item,
                      variant: variantRes.data,
                      experience: expRes.data,
                    },
                  ],
                });
              });
          })
          .catch(error => {
            this.setState({
              isFetching: false,
              items: [{ ...item }],
            });
          });
      } else {
        this.setState({
          isFetching: false,
          items: itemsRes.data.results,
        });
      }
    });
  };

  render() {
    const { items, isFetching } = this.state;

    return (
      <Box title="Booking Items" isLoading={isFetching}>
        <Table data={items} columns={itemsColumns} />
      </Box>
    );
  }
}

BookingItems.propTypes = {
  bookingId: PropTypes.number.isRequired,
};

const mapDispatchToProps = {
  fetchBookingItems,
  fetchExperienceDetail,
  fetchVariantDetail,
};

export default connect(null, mapDispatchToProps)(BookingItems);
