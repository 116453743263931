/**
 *
 *
 * @summary couponAction
 * @author cuonghx
 *
 * Created at     : 2019-03-28 15:04:02
 * Last modified  : 2019-03-28 15:26:33
 */

import { productAPI } from '../../utils/axiosAPI';

export const FETCH_COUPONS = 'FETCH_COUPONS';
export const fetchCoupons = ({ params }) => {
  return dispatch =>
    productAPI(dispatch, {
      method: 'get',
      url: '/product/admin/v1/coupons',
      params,
      type: FETCH_COUPONS,
    });
};

export const RESET_COUPONS = 'RESET_COUPONS';
export const resetCoupons = () => ({
  type: RESET_COUPONS,
});

export const CREATE_COUPON = 'CREATE_COUPON';
export const createCoupon = ({ params }) => {
  return dispatch =>
    productAPI(dispatch, {
      method: 'post',
      url: '/product/admin/v1/coupons',
      data: params,
      type: CREATE_COUPON,
    });
};

export const UPDATE_COUPON = 'UPDATE_COUPON';
export const updateCoupon = ({ id, params }) => {
  return dispatch =>
    productAPI(dispatch, {
      method: 'patch',
      url: `/product/admin/v1/coupons/${id}`,
      data: params,
      type: UPDATE_COUPON,
    });
};

export const FETCH_COUPON = 'FETCH_COUPON';
export function getCoupon({ id }) {
  return dispatch =>
    productAPI(dispatch, {
      method: 'get',
      url: `/product/admin/v1/coupons/${id}`,
      type: FETCH_COUPON,
    });
}

export const DELETE_COUPON = 'DELETE_COUPON';
export function deleteCoupon({ id }) {
  return dispatch =>
    productAPI(dispatch, {
      method: 'delete',
      url: `/product/admin/v1/coupons/${id}`,
      type: DELETE_COUPON,
    });
}
