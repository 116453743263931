import { combineReducers } from 'redux';

import {
  FETCH_BOOKING,
  FETCH_BOOKING_DETAIL,
  RESET_BOOKING,
  UPDATE_BOOKING,
  CANCEL_BOOKING,
  FETCH_BOOKING_TRANSACTIONS,
  RESET_BOOKING_TRANSACTIONS
} from './bookingActions';
import { FULFILLED, PENDING, REJECTED } from '../../utils/axiosAPI';
import { appendByIds } from '../../utils/reducer';
import { transformBookingDetail } from './bookingUtils';

export const fetchStatusInitialState = {
  count: 0,
  next: null,
  previous: null,
  isFetching: false,
  error: null
};

export const fetchStatus = (state = fetchStatusInitialState, action) => {
  switch (action.type) {
    case FETCH_BOOKING + FULFILLED: {
      const { results, ...rest } = action.data;
      return { ...state, isFetching: false, ...rest };
    }
    case FETCH_BOOKING + REJECTED: {
      return { ...state, isFetching: false, error: action.data };
    }
    case FETCH_BOOKING + PENDING: {
      return { ...state, isFetching: true, error: null };
    }
    case RESET_BOOKING: {
      return fetchStatusInitialState;
    }
    default: {
      return state;
    }
  }
};

export const byId = (state = {}, action) => {
  switch (action.type) {
    case FETCH_BOOKING + FULFILLED: {
      return appendByIds(state, action.data.results, 'id');
    }
    case CANCEL_BOOKING + FULFILLED:
    case FETCH_BOOKING_DETAIL + FULFILLED:
    case UPDATE_BOOKING + FULFILLED: {
      const booking = state[action.data.id];
      const newBooking =
        action.type === UPDATE_BOOKING + FULFILLED
          ? transformBookingDetail(action.data)
          : action.data;

      return {
        ...state,
        [action.data.id]: { ...booking, ...newBooking }
      };
    }
    default: {
      return state;
    }
  }
};

export const visibleIds = (state = [], action) => {
  switch (action.type) {
    case FETCH_BOOKING + FULFILLED: {
      /**
       * Always replace the visible booking
       */
      return action.data.results.map(booking => booking.id);
    }
    case RESET_BOOKING: {
      return [];
    }
    default: {
      return state;
    }
  }
};

export const visibleTransactionIds = (state = [], action) => {
  switch (action.type) {
    case FETCH_BOOKING_TRANSACTIONS + FULFILLED: {
      return action.data.results;
    }
    case RESET_BOOKING_TRANSACTIONS: {
      return [];
    }
    default: {
      return state;
    }
  }
};

export default combineReducers({
  fetchStatus,
  byId,
  visibleIds,
  visibleTransactionIds
});

export const getBookings = state =>
  state.booking.visibleIds.map(id => state.booking.byId[id]);
export const getFetchStatus = state => state.booking.fetchStatus;
export const getBookingDetail = (state, id) => state.booking.byId[id];
export const getTransactions = state => state.booking.visibleTransactionIds;
