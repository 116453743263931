import { Link } from 'react-router-dom';
import { Status, Avatar } from '../../shared/App/components';
import { formatDateTime, timeAgo } from '../../utils/time';

export const userStatusConfig = {
  [true]: { title: 'Yes', color: Status.colors.GREEN },
  [false]: { title: 'No', color: Status.colors.GREY },
};

/**
 * transformReviewDetail changes the field user and localguide of review detail
 * to user_id and localguide_id to avoid collision with the user and local object
 * @param {*} review - the review detail object
 */
export const transformUserDetail = user => {
  // modify data if any

  return user;
};

export const buildFormData = user => {
  if (!user) return {};
  return {
    first_name: user.first_name,
    last_name: user.last_name,
    email: user.email,
    phone: user.phone,
    about: user.about,
    is_active: user.is_active,
    is_staff: user.is_staff,
    is_localguide: user.is_localguide,
  };
};

export const userColumns = [
  {
    id: 'id',
    title: 'ID',
    render: ({ id }) => <Link to={`/users/${id}`}>#{id}</Link>,
  },
  {
    id: 'photo',
    title: '',
    render: (obj) => (
      <span>
        <Avatar
          img_src={obj.photo}
          user={obj}
        />
      </span>
    ),
  },
  {
    id: 'first_name',
    title: 'First name',
    render: ({ first_name }) => <span>{first_name}</span>,
  },
  {
    id: 'last_name',
    title: 'Last name',
    render: ({ last_name }) => <span>{last_name}</span>,
  },
  {
    id: 'email',
    title: 'Email',
    render: ({ email }) => <span>{email}</span>,
  },
  {
    id: 'location',
    title: 'Location',
    render: ({ location }) => <span>{location}</span>,
  },
  {
    id: 'is_active',
    title: 'Active?',
    render: ({ is_active }) => (
      <Status state={`${is_active}`} statuses={userStatusConfig} />
    ),
  },
  {
    id: 'is_online',
    title: 'Is Online?',
    render: ({ is_online }) => (
      <Status state={`${is_online}`} statuses={userStatusConfig} />
    ),
  },
  {
    id: 'date_joined',
    title: 'Joined',
    render: ({ date_joined }) => (
      <div style={{ width: '100px', whiteSpace: 'normal' }}>
        {formatDateTime(date_joined)}
      </div>
    ),
  },
  {
    id: 'last_active',
    title: 'Last Active',
    render: ({ last_active }) => (
      <span>{last_active ? timeAgo(last_active) : 'never'}</span>
    ),
  },
];
