const configApp = {
  // TODO: Implement here
  authToken: 'AUTH_TOKEN_HQ',
  authUser: 'AUTH_USER_HQ',
  bookingState: {
    PENDING: 1001,
    CONFIRMED: 1002,
    CANCELLED: 1003,
    COMPLETED: 1004,
  },
  experienceState: {
    INIT: 'init',
    PENDING: 'pending',
    PUBLISHED: 'published',
    PRIVATE: 'private'
  },
  bookingItemState: {
    UNASSIGNED: '1001',
    ASSIGNED: '1002',
    CANCELLED: '1003',
    COMPLETED: '1004'
  },
  bookingType: {
    group: 'Group Tour',
    standardgroup: 'Standard Group Tour',
    private: 'Private Tour',
    ticket: 'Ticket',
  },
  tripState: {
    INIT: 'init',
    PENDING: 'pending',
    CONFIRMED: 'confirmed',
    ONGOING: 'ongoing',
    COMPLETED: 'completed',
    EXPIRED: 'expired',
    CANCELLED: 'cancelled'
  },
  reviewState: {
    INIT: 'init',
    PENDING: 'pending',
    APPROVED: 'approved',
    REJECTED: 'rejected'
  },
  pageState: {
    INIT: 'init',
    PENDING: 'pending',
    PUBLISHED: 'published',
    PRIVATE: 'private'
  },
  eventType: {
    TRIP_CONFIRM: 'trip-confirm',
    TRIP_REQUEST: 'trip-request',
    BOOKING_CONFIRM: 'booking-confirm'
  },
  limitDimension: 1920,
  editMode: {
    EDIT: 'EDIT',
    ADD: 'ADD'
  }
};

export default configApp;
