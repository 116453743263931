import React from 'react';
import { Link } from 'react-router-dom';

// Components
import { Status } from '../../shared/App/components';

// Utils
import { timeAgo } from '../../utils/time';

// Constants
export const StatusConfig = {
  [true]: { title: 'active', color: Status.colors.GREEN },
  [false]: { title: 'inactive', color: Status.colors.GREY },
};

export const EMAIL_TYPE = {
  standardgroup: 'standardgroup',
  private: 'private',
  group: 'group',
};

export const transformEmailDetail = email => {
  let newEmail = { ...email };
  newEmail.is_active = newEmail.state === 'active';

  const localeSplit = newEmail.locale ? newEmail.locale.split('-') : ['en', ''];
  newEmail.language = localeSplit[0] || '';
  newEmail.country = localeSplit[1] || '';

  delete newEmail.editMode;
  delete newEmail.state;
  return newEmail;
};

export const buildFormData = email => {
  if (!email) return {};
  return {
    ...email,
    locale: `${email.language}${email.country && `-${email.country}`}`,
    state: email.is_active ? 'active' : 'inactive'
  };
};

export const emailColumns = () => {
  return [
    {
      id: 'id',
      title: 'ID',
      render: ({ id }) => {
        return <div>{id}</div>;
      }
    },
    {
      id: 'language',
      title: 'Locale',
      render: ({ language, country }) => {
        return <span>{language}{country ? `-${country}` : ''}</span>;
      }
    },
    {
      id: 'subject',
      title: 'Subject',
      render: ({ id, subject }) => <Link to={`/emails/${id}`}>{subject}</Link>
    },
    {
      id: 'event',
      title: 'Event',
      render: ({ event }) => {
        return <span>{event}</span>;
      }
    },
    {
      id: 'is_active',
      title: 'Status',
      render: ({ is_active }) => <Status state={`${is_active}`} statuses={StatusConfig} />
    },
    {
      id: 'modified',
      title: 'Updated at',
      render: ({ modified }) => <span>{timeAgo(modified)}</span>
    }
  ];
};
