import { combineReducers } from 'redux';

import {
  FETCH_REVIEW,
  FETCH_REVIEW_DETAIL,
  RESET_REVIEW,
  UPDATE_REVIEW
} from './reviewActions';
import { FULFILLED, PENDING, REJECTED } from '../../utils/axiosAPI';
import { appendByIds } from '../../utils/reducer';

export const fetchStatusInitialState = {
  count: 0,
  next: null,
  previous: null,
  isFetching: false,
  error: null
};

export const fetchStatus = (state = fetchStatusInitialState, action) => {
  switch (action.type) {
    case FETCH_REVIEW + FULFILLED: {
      const { results, ...rest } = action.data;
      return { ...state, isFetching: false, ...rest };
    }
    case FETCH_REVIEW + REJECTED: {
      return { ...state, isFetching: false, error: action.data };
    }
    case FETCH_REVIEW + PENDING: {
      return { ...state, isFetching: true, error: null };
    }
    case RESET_REVIEW: {
      return fetchStatusInitialState;
    }
    default: {
      return state;
    }
  }
};

export const byId = (state = {}, action) => {
  switch (action.type) {
    case FETCH_REVIEW + FULFILLED: {
      return appendByIds(state, action.data.results, 'id');
    }
    case FETCH_REVIEW_DETAIL + FULFILLED:
    case UPDATE_REVIEW + FULFILLED: {
      const review = state[action.data.id];
      const newReview = action.data;
      return {
        ...state,
        [action.data.id]: { ...review, ...newReview }
      };
    }
    default: {
      return state;
    }
  }
};

export const visibleIds = (state = [], action) => {
  switch (action.type) {
    case FETCH_REVIEW + FULFILLED: {
      /**
       * Always replace the visible review
       */
      return action.data.results.map(review => review.id);
    }
    case RESET_REVIEW: {
      return [];
    }
    default: {
      return state;
    }
  }
};

export default combineReducers({
  fetchStatus,
  byId,
  visibleIds
});

export const getReviews = state => state.review.visibleIds.map(id => state.review.byId[id]);
export const getFetchStatus = state => state.review.fetchStatus;
export const getReviewDetail = (state, id) => state.review.byId[id];
