import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { Listings } from '../../../../shared/App/components';

import { parseQueryString } from '../../../../utils/urlQuery';
import configService from '../../../../config/configService';
import { bookingColumns } from '../../bookingUtils';
import { getSearchData, getFilterData, keyOptions } from '../../bookingFilters';
import FilterForm from '../../../../shared/Form/FilterForm';
import SearchForm from '../../../../shared/Form/SearchForm';
import { fetchBooking, resetBooking } from '../../bookingActions';
import { getBookings, getFetchStatus } from '../../bookingReducer';
import { withOperation } from '../../../../utils/OperationalComponent';
import { withQuery } from '../../../../utils/QueryBasedComponent';

const initialState = {
  showModalFilter: false,
  sortParams: {},
};

const sortDirection = { asc: '', desc: '-' };

class BookingListPage extends Component {
  state = initialState;

  componentDidMount() {
    const {
      setQueryCallback,
      bookings,
      location: { search },
    } = this.props;
    setQueryCallback(this.handleFetchBooking);

    if (bookings.length === 0) {
      this.handleFetchBooking(parseQueryString(search));
    }
  }

  componentWillUnmount() {
    if (this.cancel) this.cancel();
    this.props.resetBookingAction();
  }

  handleFetchBooking = params => {
    const { fetchBookingAction } = this.props;
    const { page = 1, ordering } = params || {};
    if (this.cancel) this.cancel();
    this.cancel = fetchBookingAction({ params: { page, ordering } }).cancel;
  };

  handlePageChange = page => {
    this.props.pushParams({ page });
  };

  handleFilterChange = values => {
    let params = {};
    values.filter.map(
      (item, index) => (params[item.key + item.type] = item.value)
    );
    params.search = this.props.queryParams.search;
    this.props.replaceParams(params);
    this.handleCloseModal();
  };

  handleSearchChange = values => {
    this.props.pushParams({ search: values.search });
  };

  handleOpenModalFilter = () => {
    this.setState({ showModalFilter: true });
  };

  handleCloseModal = () => {
    this.setState(initialState);
  };

  handleSort = values => {
    this.props.pushParams({
      ordering: `${sortDirection[values.sortDirection]}${values.sortBy}`,
    });
    this.setState({ sortParams: values });
  };

  render() {
    const {
      bookings,
      fetchStatus: { count, isFetching },
      queryParams: { page },
    } = this.props;
    const { sortParams } = this.state;
    const searchData = getSearchData(this.props.queryParams);
    const filterData = getFilterData(this.props.queryParams);

    return (
      <Fragment>
        <Helmet>
          <title>All Bookings - Inspitrip HQ</title>
        </Helmet>
        <section className="content-header">
          <div className="flex flex-row" style={{ marginTop: '-15px', marginBottom: '15px' }}>
            <ol className="breadcrumb">
              <li>
                <Link to={'/'}>InspitripHQ</Link>
              </li>
              <li className="active">Bookings</li>
            </ol>
          </div>
          <div className="flex flex-row">
            <div className="flex flex-col gap-y-7">
              <h1 style={{ fontSize: '24px', marginTop: 0 }}>Bookings</h1>
            </div>
          </div>
        </section>
        <section className="content-header">
          <div className="flex flex-row">
            <div className="col-sm-12 pl-0 pr-0">
              <SearchForm
                onSubmit={this.handleSearchChange}
                handleOpenModalFilter={this.handleOpenModalFilter.bind(this)}
                searchContent={searchData}
                filterData={filterData}
                placeHolderContent="Search: Booking ID, User Name, Email"
              />
            </div>
          </div>
        </section>

        <div className="content body">
          <Listings
            listingsTitle="Booking List"
            data={bookings}
            columns={bookingColumns}
            actions={this.actions}
            onSortHeader={this.handleSort}
            page={parseInt(page || 1, 10)}
            dataCount={count}
            itemsPerPage={configService.itemsCountPerPage}
            onPageChange={this.handlePageChange}
            pageRange={3}
            isFetching={isFetching}
            sortParams={sortParams}
            noHeader
          />
        </div>
        <FilterForm
          onSubmit={this.handleFilterChange}
          onDismiss={this.handleCloseModal}
          visible={this.state.showModalFilter}
          arrayData={filterData}
          keyOptions={keyOptions}
        />
      </Fragment>
    );
  }
}

BookingListPage.propTypes = {
  /**
   * ConnectedProps
   */
  fetchStatus: PropTypes.object,
  bookings: PropTypes.array,
  /**
   * Actions
   */
  fetchBookingAction: PropTypes.func.isRequired,
  resetBookingAction: PropTypes.func.isRequired,
  /**
   * QueryComponentProps
   */
  setQueryCallback: PropTypes.func.isRequired,
  pushParams: PropTypes.func.isRequired,
  queryParams: PropTypes.object,
};

const mapStateToProps = state => ({
  fetchStatus: getFetchStatus(state),
  bookings: getBookings(state),
});

export default connect(mapStateToProps, {
  fetchBookingAction: fetchBooking,
  resetBookingAction: resetBooking,
})(withOperation(withQuery(BookingListPage)));
