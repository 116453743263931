import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { Button } from '../../../shared/App/components';
import PropTypes from 'prop-types';

import {
  fetchItineraries,
  addExperienceItineraryPhoto,
  deleteExperienceItineraryPhoto,
  editExperienceItineraryPhoto,
} from '../experienceActions';
import { getItineraries } from '../reducers/itineraries';
import AddItineraryModal from './AddItineraryModal';

class ItineraryItem extends React.Component {
  componentDidMount() {
    const { setActivePane, itinerary_photo } = this.props;
    setActivePane(itinerary_photo.group);
  }

  render() {
    const { itinerary_photo, handleOpenModalEditPhoto } = this.props;

    return (
      <div className="form-horizontal form-group">
        <div className="box box-primary">
          <div className="flex flex-row" style={{ padding: '10px' }}>
            <div className="col-xs-3">
              <img
                className="img-responsive"
                src={itinerary_photo.photo}
                role="presentation"
              />
            </div>
            <div
              className="col-xs-7"
              dangerouslySetInnerHTML={{ __html: itinerary_photo.description }}
            />
            <div className="col-xs-2 text-right">
              <Button
                size="sm"
                onClick={() => {
                  handleOpenModalEditPhoto(itinerary_photo);
                }}
              >
                <i className="fa fa-pencil" /> Edit
              </Button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const GroupTabs = ({ groups, onChangeTab, activePane }) => (
  <ul className="nav" style={{ display: 'flex', marginBottom: '10px' }}>
    {groups.map(group => (
      <li className="nav-item" key={group}>
        <a
          style={{
            fontWeight: activePane === group ? 'bold' : 'normal',
          }}
          className={`nav-link ${activePane ? 'active' : ''}`}
          onClick={event => {
            onChangeTab(group);
            event.preventDefault();
          }}
          href="#"
        >
          {group}
        </a>
      </li>
    ))}
  </ul>
);

const GroupContent = ({
  group,
  handleOpenModalEditPhoto,
  isActive,
  setActivePane,
}) => (
  <div className={`tab-pane${isActive ? ' active' : ''}`}>
    {group.map(itinerary_photo => {
      return (
        <ItineraryItem
          key={`${itinerary_photo.group}_${itinerary_photo.id}`}
          itinerary_photo={itinerary_photo}
          handleOpenModalEditPhoto={handleOpenModalEditPhoto}
          setActivePane={setActivePane}
        />
      );
    })}
  </div>
);

/* eslint-disable react/no-multi-comp */
class Itineraries extends React.Component {
  state = {
    activePane: '',
    showAllModal: false,
    itinerary: {},
  };

  componentDidMount = () => {
    this.props.fetchItineraries({ id: this.props.experienceId });
  };

  handleChangeTab = group => this.setState({ activePane: group });

  handleOpenAdd = () => this.setState({ showAddModal: true });

  handleOpenEdit = itinerary =>
    this.setState({ showAddModal: true, itinerary });

  handleAddItinerary = data => {
    return this.props.addExperienceItineraryPhoto({
      id: this.props.experienceId,
      data,
    });
  };

  handleEditItinerary = data => {
    const { experienceId } = this.props;
    const { itinerary } = this.state;
    return this.props.editExperienceItineraryPhoto({
      id: experienceId,
      itinerary_id: itinerary.id,
      data,
    });
  };

  render() {
    const { experienceId, itineraries } = this.props;
    return (
      <Fragment>
        <div style={{ paddingBottom: '45px' }}>
          <div style={{ display: 'flex' }}>
            <h4>
              <strong>Itineraries</strong>
            </h4>
          </div>
          <GroupTabs
            groups={itineraries.groupIds}
            onChangeTab={this.handleChangeTab}
            activePane={this.state.activePane}
          />
          <div className="tab-content">
            {itineraries.groupIds.map(id => (
              <GroupContent
                key={id}
                group={itineraries.groupByIds[id]}
                isActive={id === this.state.activePane}
                handleOpenModalEditPhoto={this.handleOpenEdit}
                setActivePane={this.handleChangeTab}
              />
            ))}
          </div>
          <Button
            className="btn btn-primary btn-nor"
            onClick={this.handleOpenAdd}
          >
            <i className="fa fa-plus" /> Add itinerary
          </Button>
        </div>

        <AddItineraryModal
          showAddModal={this.state.showAddModal}
          handleCloseModal={() => this.setState({ showAddModal: false })}
          itinerary_photo={this.state.itinerary}
          group={this.state.activePane}
          onSubmitAddItineraryPhoto={this.handleAddItinerary}
          onSubmitEditItineraryPhoto={this.handleEditItinerary}
          experienceId={experienceId}
          deleteItineraryPhoto={this.props.deleteExperienceItineraryPhoto}
        />
      </Fragment>
    );
  }
}

Itineraries.propTypes = {
  experienceId: PropTypes.number,
  handleOpenModalEditPhoto: PropTypes.func,
  handleOpenAddModalPhoto: PropTypes.func,
  itineraries: PropTypes.object,
};

const mapStateToProps = state => ({
  itineraries: getItineraries(state),
});

const mapDispatchToProps = {
  fetchItineraries,
  addExperienceItineraryPhoto,
  editExperienceItineraryPhoto,
  deleteExperienceItineraryPhoto,
};

export default connect(mapStateToProps, mapDispatchToProps)(Itineraries);
