import { useState } from 'react';
import {
  AntCloudOutlined,
  AppstoreOutlined,
  CarryOutOutlined,
  CreditCardOutlined,
  EnvironmentOutlined,
  FileProtectOutlined,
  HomeOutlined,
  PercentageOutlined,
  ProfileOutlined,
  ShoppingCartOutlined,
  StarOutlined,
  TagsOutlined,
  TransactionOutlined,
  UserOutlined,
} from '@ant-design/icons';
import { Avatar, Layout, Menu } from 'antd';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { getIsAuth, getUser } from './modules/Auth/AuthReducer';
import { redirect } from './utils/router';

const { Header, Content, Footer, Sider } = Layout;

function getItem(label, key, icon, children, type) {
  return {
    key,
    icon,
    children,
    label,
    type,
  };
}

const Label = ({ url, label }) => (
  <Link to={url}>
    <span className="nav-text">{label}</span>
  </Link>
);

const items = [
  getItem(<Label url={'/'} label={'Home'} />, 'home', <HomeOutlined />),
  getItem('Operation', 'operation', null, [
    getItem(
      <Label url={'/bookings'} label={'Bookings'} />,
      'bookings',
      <ShoppingCartOutlined />
    ),
    getItem(
      <Label
        url={'/fulfillments'}
        label={'Fulfillment'}
      />,
      'fulfillments',
      <CarryOutOutlined />
    ),
    getItem(
      <Label url={'/payments/transactions'} label={'Transactions'} />,
      'transactions',
      <TransactionOutlined />
    ),
  ]),

  getItem('Products', 'product-management', null, [
    getItem(
      <Label url={'/products'} label={'Products'} />,
      'products',
      <AppstoreOutlined />
    ),
    getItem(
      <Label url={'/categories'} label={'Categories'} />,
      'categories',
      <AppstoreOutlined />
    ),
    getItem(
      <Label url={'/collections'} label={'Collections'} />,
      'collections',
      <TagsOutlined />
    ),
    getItem(
      <Label url={'/coupons'} label={'Coupons'} />,
      'coupons',
      <PercentageOutlined />
    ),
  ]),

  getItem('Customers', 'customers', null, [
    getItem(
      <Label url={'/users'} label={'Users'} />,
      'users',
      <UserOutlined />
    ),
    getItem(
      <Label url={'/reviews'} label={'Reviews'} />,
      'reviews',
      <StarOutlined />
    ),
  ]),

  getItem('Content', 'cms', null, [
    getItem(
      <Label url={'/locations'} label={'Location'} />,
      'location',
      <EnvironmentOutlined />
    ),
    getItem(
      <Label url={'/locations/pages'} label={'Location Pages'} />,
      'location-pages',
      <FileProtectOutlined />
    ),
    getItem(
      <Label url={'/pages'} label={'Pages (Highlights)'} />,
      'pages',
      <ProfileOutlined />
    ),
    getItem(
      <Label url={'/audience/profiles'} label={'Audience Profiles'} />,
      'profiles',
      <UserOutlined />
    ),
  ]),

  getItem('Settings', 'settings', null, [
    getItem(
      <Label url={'/payments/methods'} label={'Payment Methods'} />,
      'payment-methods',
      <CreditCardOutlined />
    ),
    getItem(
      <Label url={'/emails'} label={'Email Templates'} />,
      'email-templates',
      <AntCloudOutlined />
    ),
  ]),
];

const rootSubmenuKeys = [
  'operation',
  'product-management',
  'customers',
  'cms',
  'settings',
];

const MainLayout = props => {
  const { user, isAuth, children } = props;

  const [openKeys, setOpenKeys] = useState(['operation']);

  const horizontalItems = [
    getItem(
      <>
        <span className="nav-text">View Site</span>
        <a href="https://shop.inspitrip.com" target="_blank"></a>
      </>,
      'view-site'
    ),

    getItem(user.full_name, 'avatar', <Avatar src={user.photo} />, [
      getItem('Logout', 'logout'),
    ]),
  ];

  const onOpenChange = keys => {
    const latestOpenKey = keys.find(key => openKeys.indexOf(key) === -1);
    if (rootSubmenuKeys.indexOf(latestOpenKey) === -1) {
      setOpenKeys(keys);
    } else {
      setOpenKeys(latestOpenKey ? [latestOpenKey] : []);
    }
  };

  const onClick = ({ key }) => {
    console.log(key, 'key');
    if (key === 'logout') {
      if (window.confirm('Are you sure?')) {
        props.dispatch({
          type: 'AUTH_RESET',
        });
        redirect('/');
      }
    }
  };

  return (
    <Layout hasSider>
      <Sider
        style={{
          overflow: 'auto',
          height: '100vh',
          position: 'fixed',
          left: 0,
          top: 0,
          bottom: 0,
        }}
      >
        <Link to="/" className="logo">
          Inspitrip <strong>Dash</strong>
        </Link>

        <Menu
          mode="inline"
          openKeys={openKeys}
          onOpenChange={onOpenChange}
          theme="dark"
          items={items}
        />
      </Sider>
      <Layout
        className="site-layout"
        style={{
          marginLeft: 200,
        }}
      >
        <Header
          style={{
            padding: 0,
            background: '#fff',
          }}
        >
          <Menu
            onClick={onClick}
            className="flex justify-end"
            mode="horizontal"
            items={horizontalItems}
          />
        </Header>
        <Content
          style={{
            margin: '24px 16px ',
            flex: 1,
          }}
        >
          {children}
        </Content>
        <Footer style={{ textAlign: 'center' }}>
          Inspitrip © {new Date().getFullYear()}
        </Footer>
      </Layout>
    </Layout>
  );
};

const mapStateToProps = state => {
  return {
    user: getUser(state),
    isAuth: getIsAuth(state),
  };
};

export default connect(mapStateToProps)(MainLayout);
