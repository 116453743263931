import { combineReducers } from 'redux';

import { FETCH_LOG } from './logActions';
import { FULFILLED, PENDING, REJECTED } from '../../utils/axiosAPI';

export const fetchStatusInitialState = {
  count: 0,
  next: null,
  previous: null,
  isFetching: false,
  error: null,
};

export const fetchStatus = (state = fetchStatusInitialState, action) => {
  switch (action.type) {
    case FETCH_LOG + FULFILLED: {
      const { results, ...rest } = action.data;
      return { ...state, isFetching: false, ...rest };
    }
    case FETCH_LOG + REJECTED: {
      return { ...state, isFetching: false, error: action.data };
    }
    case FETCH_LOG + PENDING: {
      return { ...state, isFetching: true, error: null };
    }
    case FETCH_LOG: {
      return fetchStatusInitialState;
    }
    default: {
      return state;
    }
  }
};

const actionNames = {
  note: 'leaves notes on the',
  update: 'makes changes on the',
  create: 'creates the',
  delete: 'deletes the'
};

export const logs = (state = [], action) => {
  switch (action.type) {
    case FETCH_LOG + FULFILLED: {
      return action.data.results.map(log => ({
        id: log.id,
        user: `${log.actor_name} (${log.actor_email})`,
        actionName: actionNames[log.action_flag.toLowerCase()],
        time: log.action_time,
        target: log.content_type,
        body: log.message,
        bgColor: log.action_flag.toLowerCase() === 'note' ? '#FFF3CD' : '',
        extra_data: log.extra_data && log.extra_data !== '[]' ? log.extra_data : null
      }));
    }
    default: {
      return state;
    }
  }
};


export default combineReducers({
  fetchStatus,
  logs
});

export const getLog = state => state.actionLog.logs;
export const getFetchStatus = state => state.actionLog.fetchStatus;
