import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  InputField,
  TextAreaField,
  SelectField,
  Button,
  AsyncSelectField,
} from '../../../shared/App/components';
import PropTypes from 'prop-types';
import { Form, Formik, ErrorMessage } from 'formik';
import {} from '../reviewReducer';
import { searchTraveller, searchLocalGuide } from '../reviewActions';

class ReviewEditForm extends Component {
  render() {
    const {
      handleSubmit,
      handleDelete,
      pristine,
      submitting,
      review,
    } = this.props;

    return (
      <div className="content">
        <Formik
          initialValues={review}
          onSubmit={handleSubmit}
          enableReinitialize
          isInitialValid
        >
          {({ isSubmitting, isValid, values, errors }) => (
            <Form>
              <div className="col-sm-8">
                <div className="">
                  <div className="">
                    <InputField
                      type="text"
                      name="user_name"
                      label="Traveller display name"
                    />
                    <AsyncSelectField
                      name="created_by"
                      label="Traveller"
                      loadOptions={(inputValue, callback) => {
                        this.props
                          .searchTravellerAction({
                            params: { search: inputValue },
                          })
                          .then(response => {
                            const { results } = response.data;
                            const data = results.map(item => {
                              return {
                                label: `${item.first_name} ${item.last_name} (${item.email})`,
                                value: item.id,
                              };
                            });
                            callback(data);
                          });
                      }}
                    />
                    <AsyncSelectField
                      name="reviewee"
                      label="Supplier"
                      loadOptions={(inputValue, callback) => {
                        this.props
                          .searchLocalGuideAction({
                            params: { search: inputValue },
                          })
                          .then(response => {
                            const { results } = response.data;
                            const data = results.map(item => {
                              return {
                                label: `${item.first_name} ${item.last_name} (${item.email})`,
                                value: item.id,
                              };
                            });
                            callback(data);
                          });
                      }}
                    />
                    <InputField
                      id="abstract_message"
                      name="abstract_message"
                      label="Title (headline)"
                    />
                    <TextAreaField
                      id="message"
                      name="message"
                      label="Message"
                      rows={10}
                    />
                  </div>
                </div>
                <div className="">
                  <div className="">
                    <InputField
                      type="number"
                      name="experience_id"
                      label="Product ID"
                    />
                    <InputField
                      type="number"
                      name="bid"
                      label="Booking ID"
                      placeHolder="Leave empty if it's seed review"
                    />
                    <InputField name="coupon_code" label="Coupon code" />
                  </div>
                </div>
              </div>
              <div className="col-sm-4">
                <div className="box">
                  <div className="box-body">
                    <div className="form-horizontal form-group">
                      <label>Photos</label>
                      <div>(Not available yet)</div>
                    </div>
                    <SelectField
                      name="status"
                      label="Status"
                      options={[
                        { label: 'Approved', value: 'approved' },
                        { label: 'Init', value: 'init' },
                        { label: 'Rejected', value: 'rejected' },
                      ]}
                    />
                  </div>
                </div>
                <div className="box">
                  <div className="box-body">
                    <InputField type="number" name="rating" label="Rating" />
                    <InputField
                      type="number"
                      name="local_rating"
                      label="Local guide rating"
                    />
                    <InputField
                      type="number"
                      name="activity_rating"
                      label="Activity rating"
                    />
                    <InputField
                      type="number"
                      name="transportation_rating"
                      label="Transportaion rating"
                    />
                    <InputField
                      type="number"
                      name="value_rating"
                      label="Value rating"
                    />
                    <InputField
                      type="number"
                      name="comfort_rating"
                      label="Comfort rating"
                    />
                  </div>
                </div>
                <div className="box-footer">
                  <div className="col-xs-6">
                    {review && (
                      <Button
                        displayType="danger"
                        block
                        onClick={() => {
                          handleDelete();
                        }}
                      >
                        <i className="fa fa-trash-o"></i> Delete
                      </Button>
                    )}
                  </div>
                  <div className="col-xs-6">
                    <Button
                      type="submit"
                      displayType="primary"
                      block
                      disabled={isSubmitting}
                    >
                      <i className="fa fa-save"></i> Save changes
                    </Button>
                  </div>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    );
  }
}

ReviewEditForm.propTypes = {
  handleSubmit: PropTypes.func,
  pristine: PropTypes.bool,
  reset: PropTypes.func,
  submitting: PropTypes.bool,
  error: PropTypes.string,
  isSuccess: PropTypes.bool,
  isError: PropTypes.bool,
  submitSucceeded: PropTypes.bool,
  dispatch: PropTypes.func,
};

export default connect(null, {
  searchLocalGuideAction: searchLocalGuide,
  searchTravellerAction: searchTraveller,
})(ReviewEditForm);
