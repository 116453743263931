import React, { Component, Fragment } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';

// Components
import { Listings, Modal } from '../../../shared/App/components';
import Breadcrumb from '../../../shared/App/components/Breadcrumb';

// Utils
import { paymentColumns } from '../paymentUtils';
import { parseQueryString } from '../../../utils/urlQuery';

// Actions
import { fetchPaymentMethods, deletePayment } from '../paymentActions';

// Seletector
import { getPayments, getFetchStatus } from '../paymentReducers';

// Constants
import configService from '../../../config/configService';
import { withOperation } from '../../../utils/OperationalComponent';
import { withQuery } from '../../../utils/QueryBasedComponent';

class PaymentListPage extends Component {
  state = {
    showedDeleteModalConfirm: false,
    deletedId: null,
  };

  componentDidMount() {
    const {
      setQueryCallback,
      payment,
      location: { search },
    } = this.props;
    setQueryCallback(this.handlefetchPaymentMethods);
    if (!payment || payment.length === 0) {
      this.handlefetchPaymentMethods(parseQueryString(search));
    }
  }

  handlefetchPaymentMethods = params => {
    const { fetchPaymentMethodsAction } = this.props;
    const { page = 1 } = params || {};
    if (this.cancel) this.cancel();
    this.cancel = fetchPaymentMethodsAction({ params: { page } }).cancel;
  };

  handlePageChange = page => {
    this.props.pushParams({ page });
  };

  handleDeletePayment = () => {
    const { deletedId } = this.state;
    this.props.deletePaymentAction({ id: deletedId }).then(res => {
      this.setState({ showedDeleteModalConfirm: false }, () => {
        this.handlefetchPaymentMethods();
      });
    });
  };

  // actions in Listing
  actions = [
    {
      title: 'Edit',
      callback: ({ id }) => {
        this.props.history.push(`/payments/methods/${id}`);
      },
      render: () => <i className="fa fa-edit" />,
      type: 'primary',
    },
    {
      title: 'Delete',
      callback: ({ id }) => {
        // Show confirm popup
        this.setState({ showedDeleteModalConfirm: true, deletedId: id });
      },
      render: () => <i className="fa fa-remove" />,
      type: 'danger',
    },
  ];

  render() {
    // Data
    const { payment, fetchStatus, queryParams, fields = {} } = this.props;
    const { count, isFetching } = fetchStatus || {
      count: 0,
      isFetching: false,
    };
    const { page } = queryParams || { page: 0 };
    const { showedDeleteModalConfirm } = this.state;

    return (
      <div>
        {showedDeleteModalConfirm && (
          <Modal
            title="Are you sure?"
            confirmText="Yes, sure"
            dismissText="Cancel"
            onConfirm={() => {
              this.handleDeletePayment();
            }}
            onDismiss={() => {
              this.setState({ showedDeleteModalConfirm: false });
            }}
            visible={showedDeleteModalConfirm}
          >
            <p>This would delete the payment show on home page.</p>
          </Modal>
        )}

        <Fragment>
          <Helmet>
            <title>All Payment Methods - Inspitrip HQ</title>
          </Helmet>
          <section className="content-header">
            <div className="flex flex-row" style={{ marginTop: '-15px', marginBottom: '15px' }}>
              <Breadcrumb
                history={[
                  { title: 'InspitripHQ', url: '/' },
                  { title: 'Payments', url: '' },
                ]}
              />
            </div>
            <div className="flex flex-row">
              <div className="flex flex-col gap-y-7">
                <h1 style={{ fontSize: '24px', marginTop: 0 }}>Payments</h1>
              </div>
              <div className="flex flex-col gap-y-2	text-right" style={{ marginLeft: 'auto' }}>
                <Link to="/payments/methods/new" className="btn btn-primary">
                  <i className="fa fa-plus" />
                  {' Add'}
                </Link>
              </div>
            </div>
          </section>
          <div className="content body">
            <section style={{ marginTop: '15px' }}>
              <Listings
                listingsTitle="Payment Methods"
                data={payment}
                columns={paymentColumns()}
                actions={this.actions}
                onSortHeader={this.handleSort}
                page={parseInt(page || 1, 10)}
                dataCount={count || 0}
                itemsPerPage={configService.itemsCountPerPage}
                onPageChange={this.handlePageChange}
                pageRange={3}
                isFetching={isFetching}
                noHeader
              />
            </section>
          </div>
        </Fragment>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  fetchStatus: getFetchStatus(state),
  payment: getPayments(state),
});

export default connect(mapStateToProps, {
  fetchPaymentMethodsAction: fetchPaymentMethods,
  deletePaymentAction: deletePayment,
})(withOperation(withQuery(PaymentListPage)));
