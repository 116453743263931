/**
 *
 *
 * @summary paymentValidator
 * @author cuonghx
 *
 * Created at     : 2019-03-28 15:04:02
 * Last modified  : 2019-03-28 15:05:47
 */

export const validate = values => {
  const errors = {};

  if (!values.coefficient) {
    errors.coefficient = 'Coefficient must be greater than 0';
  } else if (values.coefficient <= 0) {
    errors.coefficient = 'Coefficient must be greater than 0';
  }

  return errors;
};
