// utils and config
import { productAPI } from '../../../../utils/axiosAPI';

export const FETCH_VARIANTS = 'FETCH_VARIANTS';
export const fetchVariants = experienceId => dispatch =>
  productAPI(dispatch, {
    method: 'get',
    url: `/product/admin/v1/products/${experienceId}/variants`,
    type: FETCH_VARIANTS,
  });

export const FETCH_VARIANT_DETAIL = 'FETCH_VARIANT_DETAIL';
export const fetchVariantDetail = ({ experienceId, variantId }) => dispatch =>
  productAPI(dispatch, {
    method: 'get',
    url: `/product/admin/v1/products/${experienceId}/variants/${variantId}`,
    type: FETCH_VARIANT_DETAIL,
  });

export const CREATE_VARIANT = 'CREATE_VARIANT';
export const createVariant = ({ experienceId, data }) => dispatch =>
  productAPI(dispatch, {
    method: 'post',
    url: `/product/admin/v1/products/${experienceId}/variants`,
    type: CREATE_VARIANT,
    data,
  });

export const UPDATE_VARIANT = 'UPDATE_VARIANT';
export const updateVariant = ({ experienceId, variantId, data }) => dispatch =>
  productAPI(dispatch, {
    method: 'patch',
    url: `/product/admin/v1/products/${experienceId}/variants/${variantId}`,
    type: UPDATE_VARIANT,
    data,
  });

export const DELETE_VARIANT = 'DELETE_VARIANT';
export const deleteVariant = ({ experienceId, variantId }) => dispatch =>
  productAPI(dispatch, {
    method: 'delete',
    url: `/product/admin/v1/products/${experienceId}/variants/${variantId}`,
    type: DELETE_VARIANT,
    meta: { variantId },
  });

export const UPDATE_VARIANT_ORDER = 'UPDATE_VARIANT_ORDER';
export const updateVariantOrder = newOrder => ({
  type: UPDATE_VARIANT_ORDER,
  data: newOrder,
});
