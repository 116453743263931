import React, { Component } from 'react';

import { ArrayObjectField, InputField } from '../../../shared/App/components';
import FroalaField from '../../../shared/App/components/FroalaField';

class SpecificationContainer extends Component {
  render() {
    const { values } = this.props;
    return (
      <div>
        <label>Specification</label>
        <ArrayObjectField
          name="specification"
          arrayData={values.specification}
          renderObject={(item, index) => (
            <div className="box" key={index}>
              <div className="box-body">
                <InputField sub="*" name={`specification.${index}.title`} label="Title" placeholder="" />
                <FroalaField name={`specification.${index}.text`} label="Text" placeholder="" />
                <InputField name={`specification.${index}.photo`} label="Photo" placeholder="" />
                <label>Description</label>
                <ArrayObjectField
                  name={`specification.${index}.description`}
                  arrayData={item.description || []}
                  renderObject={(itemDes, idx) => (
                    <div className="box" key={idx}>
                      <div className="box-body">
                        <FroalaField name={`specification.description.${idx}.description`} label="Description" placeholder="" />
                        <InputField name={`specification.description.${idx}.photo`} label="Photo" placeholder="" />
                      </div>
                    </div>
                  )}
                />
              </div>
            </div>
          )}
        />
      </div>
    );
  }
}

export default SpecificationContainer;
