// Utils
import { getFilter, getSearch } from '../../utils/filter';

// Constants
import configApp from '../../config/configApp';

const languageSubOptions = [
  { value: 'en', label: 'English' },
  { value: 'vi', label: 'Vietnamese' },
  { value: 'ko', label: 'Korean' },
];

export const keyOptions = [
];

// Can override if you want
export const getFilterData = (queryParams, options = []) => {
  return getFilter(queryParams, [...options, ...keyOptions]);
};

export const getSearchData = queryParams => {
  return getSearch(queryParams);
};
