import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';

// Components
import CategoryForm from '../components/CategoryForm';
import Breadcrumb from '../../../shared/App/components/Breadcrumb';
import FormStatus from '../../../shared/App/components/FormStatus';

// Actions
import { createCategory } from '../categoryActions';

// Utils
import { withOperation } from '../../../utils/OperationalComponent';
import { withQuery } from '../../../utils/QueryBasedComponent';
import { transformCategoryDetail } from '../categoryUtils';

class CategoryCreatePage extends Component {
  handleSubmit = values => {
    const { executeActions, match, createCategoryAction } = this.props;
    const data = transformCategoryDetail(values);

    executeActions({
      actions: [() => createCategoryAction({ data })],
      onSuccess: () => this.props.history.push('/categories'),
      successMsg: 'Create successfully',
    });
  };

  render() {
    const { error, success } = this.props;
    return (
      <Fragment>
        <Helmet>
          <title>Create Categories - Inspitrip HQ</title>
        </Helmet>
        <section className="content-header">
          <div className="flex flex-row" style={{ marginTop: '-15px', marginBottom: '15px' }}>
            <Breadcrumb
              history={[
                { title: 'InspitripHQ', url: '/' },
                { title: 'Categories', url: '/categories' },
                { title: 'Create category', url: '' },
              ]}
            />
          </div>

          <div className="nav-tabs-custom">
            <FormStatus formState={{ error, success }} />
          </div>

          <div className="flex flex-row">
            <div className="flex flex-col gap-y-7">
              <h1 style={{ fontSize: '24px', marginTop: 0 }}>
                Create category
              </h1>
            </div>
          </div>
        </section>
        <div className="content body">
          <div className="">
            <CategoryForm
              initialValues={{ level: 'city' }}
              handleSubmit={this.handleSubmit}
            />
          </div>
        </div>
      </Fragment>
    );
  }
}

export default connect(null, {
  createCategoryAction: createCategory,
})(withOperation(withQuery(CategoryCreatePage)));
