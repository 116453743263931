import React, { Component } from 'react';
import { Field } from 'formik';
import PropTypes from 'prop-types';
import FroalaEditorComponent from 'react-froala-wysiwyg';

class FroalaField extends Component {
  render() {
    const { name, validate, label, sub, notes } = this.props;
    return (
      <Field
        name={name}
        validate={validate}
        render={({ field, form }) => {
          const { value } = field;
          const { setFieldValue } = form;
          const { errors } = form;
          return (
            <div className="form-horizontal form-group">
              {label ? <label>{label} <span className="text-muted" style={{ fontWeight: 'normal' }}>{sub}</span></label> : null}
              {
                value ?
                  <FroalaEditorComponent
                    tag="textarea"
                    model={value}
                    onModelChange={(content) => {
                      setFieldValue(name, content);
                    }}
                  /> : null
              }
              {errors[name]
                ? (
                  <div className="has-error" style={{ color: '#dd4b39' }}>
                    <span className="help-block">{errors[name]}</span>
                  </div>
                ) : null}
              {notes ? <div className="text-muted">{notes}</div> : null}
            </div>
          );
        }}
      />
    );
  }
}

export default FroalaField;
