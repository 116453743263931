import React from 'react';
import TextEditor from 'modules/App/components/form/TextEditor';

class MarkdownEditor extends React.Component {
  render() {
    const { label, touched, error, ...others } = this.props;

    return (
      <div className="form-group">
        {label && <label>{label}</label>}
        <TextEditor {...others} />
        {touched && error && (
          <div className="form-horizontal form-group has-error">
            <span className="help-block">{error}</span>
          </div>
        )}
      </div>
    );
  }
}

export default MarkdownEditor;
