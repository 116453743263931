// utils
import { productAPI } from '../../../../utils/axiosAPI';

export const FETCH_SERVICES = 'FETCH_SERVICES';
export const fetchServices = ({ experienceId, variantId }) => dispatch =>
  productAPI(dispatch, {
    method: 'get',
    url: `/product/admin/v1/products/${experienceId}/variants/${variantId}/extra-services`,
    type: FETCH_SERVICES,
    meta: { variantId },
  });

export const FETCH_SERVICE = 'FETCH_SERVICE';
export const fetchService = ({
  experienceId,
  variantId,
  serviceId,
}) => dispatch =>
  productAPI(dispatch, {
    method: 'get',
    url: `/product/admin/v1/products/${experienceId}/variants/${variantId}/extra-services/${serviceId}`,
    type: FETCH_SERVICE,
    meta: { variantId },
  });

export const CREATE_SERVICE = 'CREATE_SERVICE';
export const createService = ({ experienceId, variantId, data }) => dispatch =>
  productAPI(dispatch, {
    method: 'post',
    url: `/product/admin/v1/products/${experienceId}/variants/${variantId}/extra-services`,
    type: CREATE_SERVICE,
    data,
    meta: { variantId },
  });

export const UPDATE_SERVICE = 'UPDATE_SERVICE';
export const updateService = ({
  experienceId,
  variantId,
  serviceId,
  data,
}) => dispatch =>
  productAPI(dispatch, {
    method: 'patch',
    url: `/product/admin/v1/products/${experienceId}/variants/${variantId}/extra-services/${serviceId}`,
    type: UPDATE_SERVICE,
    meta: { variantId },
    data,
  });

export const DELETE_SERVICE = 'DELETE_SERVICE';
export const deleteService = ({
  experienceId,
  variantId,
  serviceId,
}) => dispatch =>
  productAPI(dispatch, {
    method: 'delete',
    url: `/product/admin/v1/products/${experienceId}/variants/${variantId}/extra-services/${serviceId}`,
    type: DELETE_SERVICE,
    meta: { variantId, serviceId },
  });
