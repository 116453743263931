import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { Formik } from 'formik';
import { NumericInputField, Button } from '../../../../shared/App/components';
import { withOperation } from '../../../../utils/OperationalComponent';

// actions
import {
  fetchFixedPrices,
  fetchNetFixedPrices,
  createFixedPrice,
  updateFixedPrice,
  deleteFixedPrice
} from '../../reducers/unit/actions';

class FixedPriceForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = { isEdit: false };
  }

  handleSubmit = (values, actions) => {
    const {
      executeActions,
      fetchFixedPricesAction,
      fetchNetFixedPricesAction,
      createFixedPriceAction,
      updateFixedPriceAction,
      quantity,
      productId,
      variantId,
      unitId,
      fieldName,
      fixedPrice,
    } = this.props;

    const data = {
      quantity,
      price: values[fieldName]
    };

    executeActions({
      actions: [
        () => {
          if (fixedPrice && fixedPrice.id) {
            return updateFixedPriceAction(productId, variantId, unitId, fixedPrice.id, data)
              .then(response => {
                fetchFixedPricesAction({ productId, variantId, unitId });
                // fetchNetFixedPricesAction({ productId, variantId, unitId });
              });
          }
          return createFixedPriceAction(productId, variantId, unitId, data)
            .then(response => {
              fetchFixedPricesAction({ productId, variantId, unitId });
            });
        }
      ],
      onSuccess: () => {
        setTimeout(() => {
          this.setState({ isEdit: false });
          actions.setSubmitting(false);
        }, 500);
      }
    });
  }

  delete = (e) => {
    const {
      executeActions,
      fetchFixedPricesAction,
      deleteFixedPriceAction,
      productId,
      variantId,
      unitId,
      fixedPrice,
    } = this.props;

    executeActions({
      actions: [
        () => deleteFixedPriceAction(productId, variantId, unitId, fixedPrice.id).then(response => {
          fetchFixedPricesAction({ productId, variantId, unitId });
        })
      ],
      onSuccess: () => {
        this.setState({ isEdit: false });
      }
    });
  }

  render() {
    const {
      fieldName, priceValue, fixedPrice
    } = this.props;

    const initialValues = {};
    initialValues[fieldName] = priceValue;

    return (
      <Formik
        onSubmit={this.handleSubmit}
        initialValues={initialValues}
        enableReinitialize
      >
        {({ isSubmitting, submitForm }) => (
          <Fragment>
            {
              this.state.isEdit ?
                <Fragment>
                  <NumericInputField name={fieldName} />
                  <Button
                    displayType="primary"
                    style={{ marginRight: '7px' }}
                    size="sm"
                    disabled={isSubmitting}
                    onClick={() => submitForm()}
                  >
                    <i className="fa fa-save" /> Save
                  </Button>
                  <Button style={{ marginRight: '7px' }} size="sm" onClick={() => { this.setState({ isEdit: false }); }}>
                    <i className="fa fa-close" /> Cancel
                  </Button>
                  {
                    fixedPrice && fixedPrice.price ?
                      <Button displayType="danger" style={{ marginRight: '7px' }} size="sm" onClick={this.delete}>
                        <i className="fa fa-trash-o" />
                      </Button> : ''
                  }
                </Fragment> :
                <Fragment>
                  <Button style={{ marginRight: '7px' }} size="sm" onClick={() => { this.setState({ isEdit: true }); }}>
                    <i className="fa fa-pencil" />
                  </Button>
                  {fixedPrice && fixedPrice.price ? `${fixedPrice.price} (Fixed)` : priceValue}
                </Fragment>
            }
          </Fragment>
        )}
      </Formik>
    );
  }
}

const mapStateToProps = (state, ownProps) => ({

});

const mapDispathToProps = {
  fetchFixedPricesAction: fetchFixedPrices,
  fetchNetFixedPricesAction: fetchNetFixedPrices,
  updateFixedPriceAction: updateFixedPrice,
  createFixedPriceAction: createFixedPrice,
  deleteFixedPriceAction: deleteFixedPrice,
};

export default connect(
  mapStateToProps,
  mapDispathToProps
)(withOperation(FixedPriceForm));
