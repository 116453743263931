import React from 'react';
import PropTypes from 'prop-types';
import { Form, Formik } from 'formik';
import {
  InputField,
  SelectField,
  Modal,
  NumericInputField,
} from '../../../../../shared/App/components';

const CreatePaymentForm = ({ onSubmit, onDismiss, visible, currencyOptions, paymentMethodOptions, statusOptions, booking }) => (
  <Formik
    onSubmit={onSubmit}
    enableReinitialize
    initialValues={{
      total: 0,
      currency: 'USD',
      bid: booking && booking.id
    }}
  >
    {({ isSubmitting, isValid, values, submitForm }) => (
      <Modal
        title="Create a payment"
        confirmText="Save changes"
        dismissText="Cancel"
        onConfirm={submitForm}
        onDismiss={onDismiss}
        visible={visible}
      >
        <Form>
          <div className="flex flex-row">
            <div className="col-md-12 col-sm-12 col-xs-12">
              <NumericInputField
                name="total"
                label="Amount"
              />
            </div>
            <div className="col-md-12 col-sm-12 col-xs-12">
              <SelectField
                name="currency"
                label="Currency"
                options={currencyOptions}
                validate={value => {
                  if (!value) {
                    return 'Currency cannot be empty';
                  }
                  return undefined;
                }}
              />
            </div>
            <div className="col-md-12 col-sm-12 col-xs-12">
              <SelectField
                name="variant"
                label="Payment Method"
                options={paymentMethodOptions}
                validate={value => {
                  if (!value) {
                    return 'Payment method cannot be empty';
                  }
                  return undefined;
                }}
              />
            </div>
            <div className="col-md-12 col-sm-12 col-xs-12">
              <InputField
                name="bid"
                label="Booking ID"
                disabled
              />
            </div>
          </div>
        </Form>
      </Modal>
    )}
  </Formik>
);

CreatePaymentForm.propTypes = {
  onSubmit: PropTypes.func,
  onDismiss: PropTypes.func,
  visible: PropTypes.bool,
};

export default CreatePaymentForm;
