// React Hook Wrapper for Quill.
//
// https://github.com/gtgalone/react-quilljs

import React, { useEffect } from 'react';
import { useQuill } from 'react-quilljs';
import 'styles/quill/quill.snow.css';

export default function TextEditor({ value, onChange }) {
  const { quill, quillRef, Quill } = useQuill({
    modules: {
      toolbar: ['bold', 'italic', 'link', 'image', { header: [2, 3, false] }],
    },
  });

  useEffect(() => {
    if (quill) {
      if (value) {
        quill.clipboard.dangerouslyPasteHTML(value);
      }

      quill.on('text-change', (delta, oldDelta, source) => {
        onChange(quill.root.innerHTML);
      });
    }
  }, [quill]);

  return (
    <div
      ref={quillRef}
      style={{
        width: '100%',
        minHeight: '200px',
        backgroundColor: 'white',
        margin: '0 0 40px 0',
      }}
    />
  );
}
