import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import Alert from '../../../../shared/App/components/Alert';
import { transformUserDetail } from '../../userUtils';
import { createUser } from '../../userActions';
import UserEditForm from '../../forms/UserEditForm';
import { withOperation } from '../../../../utils/OperationalComponent';
import { withQuery } from '../../../../utils/QueryBasedComponent';

class UserCreatePage extends Component {
  handleSubmit = values => {
    const { executeActions, match, createUserAction } = this.props;

    const updatedData = transformUserDetail(values);
    executeActions({
      actions: [
        () => createUserAction({ id: match.params.cuid, params: updatedData }),
      ],
      onSuccess: () => this.props.history.push('/users'),
      successMsg: 'Create successfully',
      failureMsg: 'Create fail',
    });
  };

  renderAlert() {
    const { success, error } = this.props;

    let msg = '';
    let title = 'Success';
    let type = 'success';
    if (error) {
      msg = error.toString();
      title = 'Error';
      type = 'danger';
    } else if (success) {
      msg = success;
    }

    if (msg !== '') {
      return <Alert title={title} type={type} content={msg} />;
    }

    return null;
  }

  render() {
    return (
      <Fragment>
        <Helmet>
          <title>Create User - Inspitrip HQ</title>
        </Helmet>
        <section className="content-header">
          <div className="flex flex-row" style={{ marginTop: '-15px', marginBottom: '15px' }}>
            <ol className="breadcrumb">
              <li>
                <Link to={'/'}>InspitripHQ</Link>
              </li>
              <li>
                <Link to={'/users'}>Users</Link>
              </li>
              <li className="active">Create User</li>
            </ol>
          </div>
          {this.renderAlert()}
          <div className="flex flex-row">
            <div className="flex flex-col gap-y-7">
              <h1 style={{ fontSize: '24px', marginTop: 0 }}>User</h1>
            </div>
            <div className="flex flex-col gap-y-2	text-right" style={{ marginLeft: 'auto' }}>
              <a
                href=""
                className="btn btn-default"
                style={{ lineHeight: '20px', fontSize: '20px' }}
              >
                <i className="fa fa-angle-left" />
              </a>
            </div>
          </div>
        </section>
        <div className="content body">
          <div className="">
            <UserEditForm handleSubmit={this.handleSubmit.bind(this)} />
          </div>
        </div>
      </Fragment>
    );
  }
}

// Retrieve data from store as props
const mapStateToProps = (state, props) => {
  return {};
};

export default connect(mapStateToProps, {
  createUserAction: createUser,
})(withOperation(withQuery(UserCreatePage)));
