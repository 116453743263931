import { Provider } from 'react-redux';
import AppRouter from './AppRouter';
import store from './store';

import 'tailwindcss/tailwind.css';
import 'antd/dist/antd.min.css';
import './assets/css/style.scss';

const App = () => (
  <Provider store={store}>
    <AppRouter />
  </Provider>
);

export default App;
