export const errorToMessage = error => {
  if (!error || typeof error === 'string' || error instanceof String)
    return error;
  let array = [];
  Object.keys(error).map(x => {
    if (Array.isArray(error[x])) {
      array.push(`${x}: ${error[x][0]}`);
    } else {
      array.push(`${x}: ${error[x]}`);
    }
    return array;
  });
  return array.join('\n');
};
