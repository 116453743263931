import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';

// Components
import EmailEditForm from '../components/EmailEditForm';
import FormStatus from '../../../shared/App/components/FormStatus';
import Breadcrumb from '../../../shared/App/components/Breadcrumb';

// Actions
import { createEmail } from '../emailActions';

// Utils
import { withOperation } from '../../../utils/OperationalComponent';
import { transformEmailDetail } from '../emailUtils';
import { withQuery } from '../../../utils/QueryBasedComponent';

class EmailCreatePage extends Component {
  handleSubmit = values => {
    const { executeActions, createEmailAction } = this.props;
    // transform data
    const newEmail = transformEmailDetail(values);

    executeActions({
      actions: [() => createEmailAction({ params: newEmail })],
      onSuccess: response => {
        this.props.history.push('/emails');
      },
      successMsg: 'Create successfully',
      failureMsg: 'Create fail',
    });
  };

  render() {
    const { error, success } = this.props;

    return (
      <Fragment>
        <Helmet>
          <title>Create Email Template - Inspitrip HQ</title>
        </Helmet>
        <section className="content-header">
          <div className="flex flex-row" style={{ marginTop: '-15px', marginBottom: '15px' }}>
            <Breadcrumb
              history={[
                { title: 'InspitripHQ', url: '/' },
                { title: 'Email', url: '/emails' },
                { title: 'Create Email', url: '' },
              ]}
            />
          </div>

          <div className="nav-tabs-custom">
            <FormStatus formState={{ error, success }} />
          </div>

          <div className="flex flex-row">
            <div className="flex flex-col gap-y-7">
              <h1 style={{ fontSize: '24px', marginTop: 0 }}>Create Email</h1>
            </div>
          </div>
        </section>
        <div className="content body">
          <div className="">
            <EmailEditForm
              initialValues={{ is_bcc_email: true, state: 'active' }}
              handleSubmit={this.handleSubmit}
            />
          </div>
        </div>
      </Fragment>
    );
  }
}

export default connect(null, {
  createEmailAction: createEmail,
})(withOperation(withQuery(EmailCreatePage)));
