import PropTypes from 'prop-types';
import { Component } from 'react';
import { Helmet } from 'react-helmet';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

class Homepage extends Component {
  render() {
    return (
      <div className="overview-wrapper" style={{ position: 'relative' }}>
        <Helmet>
          <title>Overview - Inspitrip HQ</title>
        </Helmet>
        <div className="content-header">
          <h1>
            Inspitrip HQ
            <small> Version 2.1</small>
          </h1>
        </div>

        <section className="content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-lg-12 col-12">
                <label>Today</label>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-3 col-6">
                <div className="small-box bg-info">
                  <div className="inner">
                    <h3>0</h3>
                    <p>New Bookings</p>
                  </div>
                  <div className="icon">
                    <i className="ion ion-bag"></i>
                  </div>
                  <Link to="/bookings" className="small-box-footer">More info</Link>
                </div>
              </div>

              <div className="col-lg-3 col-6">
                <div className="small-box bg-success">
                  <div className="inner">
                    <h3>0<sup style={{ fontSize: '14px' }}>%</sup></h3>
                    <p>Fulfillment Rate</p>
                  </div>
                  <div className="icon">
                    <i className="ion ion-stats-bars"></i>
                  </div>
                  <Link to="/fulfillments" className="small-box-footer">More info</Link>
                </div>
              </div>

              <div className="col-lg-3 col-6">
                <div className="small-box bg-warning">
                  <div className="inner">
                    <h3>0</h3>
                    <p>New Customers</p>
                  </div>
                  <div className="icon">
                    <i className="ion ion-person-add"></i>
                  </div>
                  <Link to="/users" className="small-box-footer">More info</Link>
                </div>
              </div>

              <div className="col-lg-3 col-6">
                <div className="small-box bg-danger">
                  <div className="inner">
                    <h3>0</h3>
                    <p>New Reviews</p>
                  </div>
                  <div className="icon">
                    <i className="ion ion-pie-graph"></i>
                  </div>
                  <Link to="/reviews" className="small-box-footer">More info</Link>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  }
}

// Retrieve data from store as props
function mapStateToProps(state) {
  return {};
}

Homepage.contextTypes = {
  router: PropTypes.object,
};

export default connect(mapStateToProps)(Homepage);
