import React from 'react';
import { Field, reduxForm } from 'redux-form';
import PropTypes from 'prop-types';
import { Button } from 'antd';

import LoginFormValidate from './LoginFormValidate';

const CustomInput = ({ input, meta: { touched, error }, ...rest }) => (
  <div className="mb-4">
    <input
      {...input}
      {...rest}
      className={`w-full p-2 border rounded ${
        touched && error ? 'border-red-500' : 'border-gray-300'
      }`}
    />
    {touched && error && <div className="text-red-500">{error}</div>}
  </div>
);

const LoginForm = props => {
  const { handleSubmit, pristine, submitting, invalid, error } = props;
  return (
    <form onSubmit={handleSubmit} method="post">
      {invalid && <div className="alert alert-danger">{error}</div>}
      <div className="form-group has-feedback">
        <Field
          name="email"
          component={CustomInput}
          type="email"
          placeholder="Email"
        />
        <span className="glyphicon glyphicon-envelope form-control-feedback"></span>
      </div>
      <div className="form-group has-feedback">
        <Field
          name="password"
          component={CustomInput}
          type="password"
          className="form-control"
        />
        <span className="glyphicon glyphicon-lock form-control-feedback"></span>
      </div>
      <div className="flex flex-row">
        <div className="col-xs-8"></div>
        <div className="col-xs-4">
          <Button
            className="mt-4"
            type="primary"
            htmlType="submit"
            disabled={pristine || submitting}
          >
            Sign In
          </Button>
        </div>
      </div>
    </form>
  );
};

LoginForm.propTypes = {
  pristine: PropTypes.bool.isRequired,
  submitting: PropTypes.bool.isRequired,
  invalid: PropTypes.bool.isRequired,
  error: PropTypes.string,
  handleSubmit: PropTypes.func,
};

const LoginReduxForm = reduxForm({
  form: 'LoginForm',
  LoginFormValidate,
})(LoginForm);

export default LoginReduxForm;
